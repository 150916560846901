import { useMutation, useQueryClient } from 'react-query'
import { OrdersApi } from '@trybeapp/sdk.shop'
import { OrdersApi as OrdersApiTs, UpdateItemSoldBy } from '@trybeapp/sdk'
import { DateTime } from 'luxon'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new OrdersApi()
const ordersApi = new OrdersApiTs(apiConfiguration)

export const useAddOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (args: [string, any, any?]) => {
      const [orderId, orderItem, options = {}] = args
      return api.orderAddItem(orderId, orderItem, options)
    },
    {
      onSuccess: (result, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useEditOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (args: [string, string, any, any?]) => {
      const [orderId, orderItemId, orderItem, options = {}] = args
      return api.orderItemUpdate(orderId, orderItemId, formatOrderItem(orderItem), options)
    },
    {
      onSuccess: (result, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useDeleteOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string]) => {
      const [orderId, orderItemId] = args
      return await api.orderItemDestroy(orderId, orderItemId)
    },
    {
      onSuccess: (result, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useAddPackageOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string, any, any?]) => {
      const [orderId, orderItemId, orderPackageItem, options = {}] = args

      if (orderPackageItem.item_configuration?.time) {
        orderPackageItem.item_configuration.time = DateTime.fromJSDate(
          orderPackageItem.item_configuration.time
        ).toISO({
          suppressMilliseconds: true,
        })
      }

      return await api.addOrderItemPackageItem(orderId, orderItemId, orderPackageItem, options)
    },
    {
      onSuccess: (result, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useUpdatePackageOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string, string, any, any?]) => {
      const [orderId, orderItemId, packageItemId, orderPackageItem, options = {}] = args

      if (orderPackageItem.item_configuration?.time) {
        orderPackageItem.item_configuration.time = DateTime.fromJSDate(
          orderPackageItem.item_configuration.time
        ).toISO({
          suppressMilliseconds: true,
        })
      }

      return await api.updateOrderItemPackageItem(
        orderId,
        orderItemId,
        packageItemId,
        orderPackageItem,
        options
      )
    },
    {
      onSuccess: (result, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useRemovePackageOrderItem = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string, string]) => {
      const [orderId, orderItemId, orderPackageItemId] = args
      return await api.deleteOrderPackageItem(orderId, orderItemId, orderPackageItemId)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useUpdateOrderItemPrice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string, any]) => {
      const [orderId, orderItemId, updateObject] = args
      return await api.orderItemPriceUpdate(orderId, orderItemId, updateObject)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useUpdateItemSoldBy = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string, UpdateItemSoldBy]) => {
      const [orderId, itemId, updateItemSoldBy] = args

      return await ordersApi.updateItemSoldBy({
        orderId,
        itemId,
        updateItemSoldBy,
      })
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

const formatOrderItem = (orderItem) => {
  const formattedItem = { ...orderItem }

  if (formattedItem?.item_configuration?.time !== undefined) {
    formattedItem.item_configuration.time = DateTime.fromJSDate(
      formattedItem.item_configuration.time
    ).toISO({ suppressMilliseconds: true })
  }

  return formattedItem
}
