import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query'
import { AvailabilityApi } from '@trybeapp/sdk.shop'
import { DateTime } from 'luxon'
import { CheckAvailabilityApi } from '@trybeapp/sdk.shop'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new AvailabilityApi()

const checkAvailabilityApi = new CheckAvailabilityApi()

interface OfferingAvailabilityParams {
  offeringId?: string
  offeringType: OfferingTypes
  date: DateTime
}

type AppointmentOfferingType = 'appointment'
type SessionOfferingType = 'appointment'
type PackageOfferingType = 'appointment'

type OfferingTypes = AppointmentOfferingType | SessionOfferingType | PackageOfferingType

export const useOfferingAvailability = (
  params: OfferingAvailabilityParams,
  options?: UseQueryOptions
) => {
  return useQuery(
    ['offeringAvailability', params.date.toISODate(), params],
    async () => {
      const response = await api.monthlyOfferingAvailability(
        params.date.toISODate(),
        params.offeringType,
        params.offeringId
      )

      response.data.forEach((dateAvailability) => {
        dateAvailability.date = sdkDateToSystemDateTime(dateAvailability.date).toJSDate()
      })

      return response
    },
    options
  )
}

export const useAddDayUnavailability = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (params: OfferingAvailabilityParams) =>
      await api.offeringAddDayUnavailability({
        updateUnavailabilityParams: {
          offering_id: params.offeringId,
          offering_type: params.offeringType,
          date: params.date.toISODate(),
        },
      }),
    {
      ...options,
      onSuccess: (result, params: OfferingAvailabilityParams) => {
        queryClient.invalidateQueries(['offeringAvailability', params.date.toISODate()])
      },
    }
  )
}

export const useRemoveDayUnavailability = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (params: OfferingAvailabilityParams) =>
      await api.offeringRemoveDayUnavailability({
        updateUnavailabilityParams: {
          offering_id: params.offeringId,
          offering_type: params.offeringType,
          date: params.date.toISODate(),
        },
      }),
    {
      ...options,
      onSuccess: (result, params: OfferingAvailabilityParams) => {
        queryClient.invalidateQueries(['offeringAvailability', params.date.toISODate()])
      },
    }
  )
}

export const useAreaBookingSlots = (
  bookingTypeId: string,
  params: any = {},
  options: UseQueryOptions = {}
) => {
  const { enabled = true } = options
  options.cacheTime = options.cacheTime ?? 0

  return useQuery(
    ['areaBookingSlots', params],
    () => checkAvailabilityApi.getAreaBookingSlots(bookingTypeId, params),
    { ...options, enabled: !!bookingTypeId && enabled }
  )
}

export const useOfferingDateAvailability = (
  offeringId: string,
  offeringType: string,
  dateFrom: Date,
  dateTo: Date,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  return useQuery(
    ['offeringDateAvailability', { offeringId, offeringType, dateFrom, dateTo }],
    async () => {
      const response = await checkAvailabilityApi.getOfferingDatesAvailability(
        offeringId,
        offeringType,
        toIsoDate(dateFrom),
        toIsoDate(dateTo)
      )

      response.data.forEach((dateAvailability) => {
        dateAvailability.date = sdkDateToSystemDateTime(dateAvailability.date).toJSDate()
      })

      return response
    },
    options
  )
}
