import { useOrganisationConfig } from 'hooks/UseOrganisationConfig'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { useUser } from 'hooks/UseUser'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const Segment = () => {
  useEffect(() => {
    if (import.meta.env.VITE_APP_SEGMENT_KEY) {
      const script = document.createElement('script')
      script.innerText = `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
        analytics.load("${import.meta.env.VITE_APP_SEGMENT_KEY}");
        }}();`
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  usePageViews()
  useIdentify()
  return null
}

export const withTrackableEvent =
  (Component) =>
  ({ event, eventProperties = {}, onClick: parentOnClick, ...props }) => {
    const handleOnClick = (e, ...rest) => {
      // Fire the parent function if it exists
      if (parentOnClick && typeof parentOnClick === 'function') {
        parentOnClick(e, ...rest)
      }

      if (event) {
        trackEvent(event, eventProperties)
      }
    }

    return <Component onClick={handleOnClick} {...props} />
  }

export const trackEvent = (name, properties = {}) => {
  window.analytics && window.analytics.track(name, properties)
}

export const trackPage = (name, properties = {}) => {
  window.analytics && window.analytics.page(name, properties)
}

const usePageViews = () => {
  const location = useLocation()
  useEffect(() => {
    trackPage(location.pathname, {
      path: location.pathname,
      search: location.search,
    })
  }, [location.pathname, location.search])
}

const useIdentify = () => {
  const { email, name, sub } = useUser()
  const { siteId } = useSiteConfig()
  const organisationId = useOrganisationConfig()

  useEffect(() => {
    if (sub) {
      const version =
        (import.meta.env.VITE_APP_VERSION && import.meta.env.VITE_APP_VERSION.substring(0, 8)) ||
        'LOCAL'

      window.analytics &&
        window.analytics.identify(sub, {
          name,
          email,
          company: organisationId,
          organisationId,
          siteId,
          appVersion: version,
        })

      window.analytics && window.analytics.group(sub)
    }
  }, [email, name, organisationId, siteId, sub])
}
