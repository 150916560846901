import { map } from 'lodash'
import pluralize from 'pluralize'

export const sentenceJoin = (array, separator = ',', finalJoin = 'and') => {
  return array.length === 0
    ? ''
    : array.length === 1
    ? array[0]
    : array.slice(0, -1).join(`${separator} `) + ` ${finalJoin} ` + array.slice(-1)
}

export const snake = (string, delimiter = '_') => {
  return string
    .trim()
    .replace(/(.)(?=[A-Z])/g, `$1${delimiter}`)
    .toLowerCase()
}

export const formatGuestNames = (guests) => {
  const guestNames = map(guests, 'name').filter(Boolean)

  // Split out the guests into those ending with a number (e.g. 'Guest 1') and those without
  const [regularGuestNames, guestNamesWithNumbers] = guestNames.reduce(
    (groups, name) => {
      if (name.match(/Guest [\d]+$/i) === null) {
        groups[0].push(name)
      } else {
        groups[1].push(name)
      }

      return groups
    },
    [[], []]
  )

  // Just return the count if there's more than one guest with a number and none without
  if (regularGuestNames.length === 0 && guestNamesWithNumbers.length > 1) {
    return `${guestNames.length} ${pluralize('guest', guestNames.length)}`
  }

  if (guestNames.length > 2) {
    const numOtherGuests = guestNamesWithNumbers.length
    return `${sentenceJoin(regularGuestNames)} and ${numOtherGuests} other ${pluralize(
      'guest',
      numOtherGuests
    )}`
  }

  return guestNames.length > 0 ? sentenceJoin(guestNames) : 'Unknown guest'
}
