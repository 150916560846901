export const EmptyState = ({ title, icon }) => {
  return (
    <div className="relative block w-full p-12 text-center bg-gray-50">
      {icon({
        className: 'mx-auto h-12 w-12 text-gray-300',
        'aria-hidden': true,
      })}
      <span className="mt-2 block text-sm font-medium text-gray-500">{title}</span>
    </div>
  )
}
