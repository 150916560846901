import { pick } from 'lodash'
import { createSelector } from 'reselect'

const selectBookingSummaryIds = (state) => state.resources.BookingSummariesReducer.allIds

/**
 * @deprecated
 */
export const selectBookingSummariesById = (state) => state.resources.BookingSummariesReducer.byId

/**
 * @deprecated
 */
export const selectAllBookingSummaries = createSelector(
  [selectBookingSummaryIds, selectBookingSummariesById],
  (allIds, byId) => allIds.map((id) => byId[id]).filter((v) => typeof v !== 'undefined')
)

/**
 * @deprecated
 */
export const selectBookingSummary = (bookingSummaryId) =>
  createSelector([selectBookingSummariesById], (byId = {}) => byId[bookingSummaryId])

/**
 * @deprecated
 */
export const selectBookingSummaries = (summaryIds = []) =>
  createSelector([selectBookingSummariesById], (byId = {}) => Object.values(pick(byId, summaryIds)))
