import { SitesApi } from '@trybeapp/sdk.shop'
import { useQuery, QueryOptions } from 'react-query'

const api = new SitesApi()

export const useCustomPaymentTypes = (siteId: string, options: QueryOptions = {}) =>
  useQuery<any>(
    ['customPaymentTypes', { siteId }],
    () => api.siteCustomPaymentTypesIndex(siteId),
    options
  )
