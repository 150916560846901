import { classNames } from 'ui/utilities'

export const Card = ({ children, withPadding = true, className }) => (
  <div
    className={classNames(
      className,
      (withPadding ? 'p-4 ' : '') + ' text-sm rounded-lg border border-violet-200 shadow-sm'
    )}
  >
    {children}
  </div>
)
