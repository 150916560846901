import { ROTAS_VIEW } from 'constants/permissions'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const PractitionerAvailabilityCalendar = ReactLazyPreload(
  () =>
    import(
      /* webpackChunkName: 'PractitionerAvailabilityCalendar' */ '../screens/PractitionerAvailabilityCalendar'
    )
)

export const useRoutes = () => {
  return [
    {
      path: '/practitioner-availability',
      name: 'Rota',
      component: PractitionerAvailabilityCalendar,
      exact: true,
      permissions: [ROTAS_VIEW],
    },
  ]
}
