import { PrinterIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useEscPosConnectors, usePrintOrderReceipt } from 'api/EscPosConnectors'
import { useState } from 'react'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { Select } from 'ui/components/Form/Select'
import { Toggle } from 'ui/components/Form/Toggle'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'

interface ReceiptPrintButtonProps {
  orderId: string
}

export const ReceiptPrintButton: React.FC<ReceiptPrintButtonProps> = ({ orderId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedConnectorId, setSelectedConnectorId] = useState(null)
  const notifications = useNotificationContext()
  const [guestInputName, setGuestInputName] = useState(false)
  const [guestInputSignature, setGuestInputSignature] = useState(false)
  const [guestInputRoomNumber, setGuestInputRoomNumber] = useState(false)
  const [guestInputGratuity, setGuestInputGratuity] = useState(false)

  const { isLoading: isLoadingPrinters, data: { data: printers = [] } = {} } = useEscPosConnectors({
    onSuccess: ({ data }) => {
      if (data.length > 0) {
        setSelectedConnectorId(data[0].id)
      }
    },
  })

  const { isLoading: isPrinting, mutate } = usePrintOrderReceipt()

  const handlePrint = () => {
    mutate(
      [
        orderId,
        {
          esc_pos_connector_id: selectedConnectorId,
          guest_input_fields: {
            name: guestInputName,
            signature: guestInputSignature,
            room_number: guestInputRoomNumber,
            gratuity: guestInputGratuity,
          },
        },
      ],
      {
        onSuccess: () => {
          setIsOpen(false)

          notifications.notify(
            <Notification
              title="Receipt printing"
              description={`A summary of this order is being printed`}
              variant="success"
              autoDismiss
            />
          )
        },
        onError: (e) => {
          notifications.notify(<ApiErrorNotification error={e} />)
        },
      }
    )
  }

  if (isLoadingPrinters || printers.length === 0) {
    return null
  }

  const printerOptions = printers.map((printer) => ({
    name: printer.name,
    value: printer.id,
  }))

  return (
    <>
      <Button
        label="Print receipt"
        leadingIcon={PrinterIcon}
        onClick={() => setIsOpen(true)}
        size="xs"
        variant="primary"
      />

      <Modal
        size="sm"
        title="Print receipt"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        styleReset
      >
        <p className="text-sm text-gray-500">
          Print a summary of this order to your till receipt as a customer copy.
        </p>

        <div className="mt-4 space-y-8">
          {printerOptions.length > 1 && (
            <div>
              <Select
                selected={printerOptions.find((printer) => printer.value === selectedConnectorId)}
                options={printerOptions}
                label="Pick a printer"
                onChange={(e) => setSelectedConnectorId(e.value)}
              />
            </div>
          )}

          <div className="space-y-4">
            <Toggle
              value={guestInputName}
              onChange={(val) => setGuestInputName(val)}
              label="Include space for name"
              description="Include a space for the customer to write their name on the receipt"
            />
            <Toggle
              value={guestInputSignature}
              onChange={(val) => setGuestInputSignature(val)}
              label="Include space for signature"
              description="Include a space for the customer to sign the receipt"
            />
            <Toggle
              value={guestInputRoomNumber}
              onChange={(val) => setGuestInputRoomNumber(val)}
              label="Include space for room number"
              description="Include a space for the customer to write their room number the receipt"
            />
            <Toggle
              value={guestInputGratuity}
              onChange={(val) => setGuestInputGratuity(val)}
              label="Include space for gratuity"
              description="Include a space for the customer to write a gratuity amount on the receipt"
            />
          </div>
        </div>

        <ModalButtons>
          <div className="flex-1">
            <Button
              label="Print"
              variant="primary"
              onClick={handlePrint}
              className="w-full"
              loading={isPrinting}
              disabled={selectedConnectorId === null}
            />
          </div>
        </ModalButtons>
        <p className="text-xs text-gray-500 mt-2 text-center">
          Want to save paper? You can{' '}
          <Link
            to={`/orders/${orderId}/messages`}
            className="font-medium text-violet hover:underline"
          >
            send by email
          </Link>{' '}
          instead
        </p>
      </Modal>
    </>
  )
}
