import { Button } from 'ui/components/Button'
import { Modal } from 'ui/Modal'

export const ViewPhotoModal = ({ avatarUrl, isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} title="View photo" styleReset>
    <img src={avatarUrl} className="w-full rounded-lg" alt="Preview" />

    <div className="mt-4">
      <Button onClick={onClose} fullWidth label="Close" />
    </div>
  </Modal>
)
