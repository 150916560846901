import moment from 'moment'
import { DateTime } from 'luxon'

export const buildTimeSelectOptions = (startTime, endTime, options = {}) => {
  const { interval = 60, renderLabel = (moment) => moment.format('HH:mm') } = options
  const timeOptions = []
  const startTimeMoment = moment(startTime, 'HH:mm')
  const endTimeMoment = moment(endTime, 'HH:mm')

  if (endTimeMoment.isSameOrBefore(startTimeMoment)) {
    endTimeMoment.add(1, 'days')
  }

  const labels = []

  while (startTimeMoment.isBefore(endTimeMoment)) {
    const option = {
      label: renderLabel(startTimeMoment),
      value: startTimeMoment.format('HH:mm'),
      moment: startTimeMoment.clone(),
      dateTime: DateTime.fromJSDate(startTimeMoment.toDate()),
    }

    if (labels.indexOf(option.value) < 0) {
      timeOptions.push(option)
      labels.push(option.label)
    }

    startTimeMoment.add(interval, 'minutes')
  }

  return timeOptions
}

export const renderFriendlyLabel = (moment) => {
  return moment.format(renderFriendlyLabelFormat(moment))
}

export const renderFriendlyLabelFormat = (moment) =>
  moment.hours() === 0 ? 'm[min]' : 'h[h] m[min]'

export const durationToMoment = (duration) => {
  const durationMoment = moment.duration(duration, 'minutes')
  return moment().hours(durationMoment.hours()).minutes(durationMoment.minutes())
}
