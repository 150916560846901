import { useDeletePaymentMethod, usePaymentMethodsForCustomer } from 'api/PaymentMethods'
import { useParams } from 'react-router-dom'
import { Section } from '../Shared/Section'
import { EmptyState } from 'ui/components/EmptyState'
import { CreditCardIcon } from '@heroicons/react/outline'
import { PaymentMethodIcon } from 'ui/components/PaymentMethodIcon'
import { Key } from 'react'
import { Badge } from 'ui/components/Badge'
import { DateTime } from 'luxon'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { XIcon } from '@heroicons/react/solid'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'

export const PaymentMethodRow = ({ paymentMethod }) => {
  const icon = () => {
    if (paymentMethod.type === 'direct_debit') {
      return <PaymentMethodIcon size={32} type="direct_debit" />
    }

    return <PaymentMethodIcon size={32} type={paymentMethod.card_brand} />
  }

  const paymentMethodCreated = DateTime.fromJSDate(paymentMethod.created_at).toLocaleString(
    DateTime.DATETIME_MED
  )

  const paymentMethodRevoked = DateTime.fromJSDate(paymentMethod.revoked_at).toLocaleString(
    DateTime.DATETIME_MED
  )

  const status = () => {
    if (!paymentMethod.status) return null

    switch (paymentMethod.status) {
      case 'active':
        return <Badge variant="success" label="Active" />
      case 'revoked':
        return (
          <Badge
            variant="danger"
            label={paymentMethod.type === 'direct_debit' ? 'Revoked' : 'Unusable'}
          />
        )
      case 'pending':
        return <Badge variant="info" label="Pending" />
      default:
        return <Badge variant="info" label={paymentMethod.status} />
    }
  }

  const { mutate, isLoading } = useDeletePaymentMethod()
  const notifications = useNotificationContext()

  const onDelete = () => {
    mutate([paymentMethod.id], {
      onSuccess: () => {
        notifications.notify(
          <Notification
            title="Payment Type deleted"
            description="This payment type was successfully deleted"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notifications.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <li className="flex text-left items-center space-x-4 p-3">
      <div className="flex-shrink-0">{icon()}</div>
      <div className="flex-1 text-sm">
        <div className="font-medium flex space-x-2">
          <span>{paymentMethod.cardholder_name}</span>
          {status()}
        </div>
        <div className="text-gray-500">
          {paymentMethod.type === 'card' && (
            <span>
              &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{' '}
              {paymentMethod.last_4} &middot; Exp {paymentMethod.card_expiry ?? '??/??'}
            </span>
          )}

          {paymentMethod.type === 'direct_debit' && (
            <>
              &bull;&bull;&bull;&bull;{paymentMethod.last_4} &middot; Created {paymentMethodCreated}
              {paymentMethod.revoked_at !== null && ` · Revoked ${paymentMethodRevoked}`}
            </>
          )}
        </div>
      </div>
      {paymentMethod.status !== 'revoked' && (
        <div>
          <Dropdown threeDots>
            <DropdownItem label="Cancel" onClick={onDelete} icon={XIcon} disabled={isLoading} />
          </Dropdown>
        </div>
      )}
    </li>
  )
}

export const PaymentMethods = () => {
  const { clientId } = useParams()
  const { isLoading, data: { data: paymentMethods = [] } = {} } = usePaymentMethodsForCustomer(
    clientId,
    {
      refetchOnMount: false,
    }
  )

  return (
    <Section title="Payment methods" button={null}>
      {isLoading && <div className="bg-gray-200 animate-pulse h-16" />}

      {!isLoading && paymentMethods.length === 0 && (
        <EmptyState title="This client has no payment methods" icon={CreditCardIcon} />
      )}

      {!isLoading && paymentMethods.length > 0 && (
        <ul className="divide-y divide-violet-100">
          {paymentMethods.map((paymentMethod: { id: Key }) => (
            <PaymentMethodRow key={paymentMethod.id} paymentMethod={paymentMethod} />
          ))}
        </ul>
      )}
    </Section>
  )
}
