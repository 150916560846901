import { ReactLazyPreload } from 'utilities/PreloadLinks'

const Visits = ReactLazyPreload(
  () => import(/* webpackChunkName: 'Calendar' */ '../screens/Visits/List')
)

export const useRoutes = () => {
  return [
    {
      path: '/visits',
      name: 'Visits',
      component: Visits,
      exact: true,
    },
  ]
}
