import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { Textarea } from 'ui/components/Form/Textarea'
import { useEditOrderItem } from 'api/OrderItems'

interface IEditVoucherCustomMessageOverlayProps {
  item: any
  isOpen: boolean
  onClose: () => void
}

export const EditVoucherCustomMessageOverlay: React.FC<IEditVoucherCustomMessageOverlayProps> = ({
  item,
  isOpen = false,
  onClose,
}) => {
  const [customMessage, setCustomMessage] = useState(
    item.item_configuration.custom_message !== null ? item.item_configuration.custom_message : ''
  )
  const title = item.item_configuration.custom_message
    ? 'Edit custom message'
    : 'Add custom message'
  const { orderId } = useParams()
  const notification = useNotificationContext()
  const { mutate, isLoading } = useEditOrderItem()

  const handleSave = () => {
    mutate(
      [
        orderId,
        item.id,
        {
          item_configuration: {
            custom_message: customMessage,
          },
        },
      ],
      {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Custom message updated"
              description="The custom message has been updated successfully."
              variant="success"
              autoDismiss
            />
          )
          onClose()
        },
        onError: (error) => {
          notification.notify(<ApiErrorNotification error={error} />)
        },
      }
    )
  }

  const handleCancel = () => {
    onClose()
    setCustomMessage(item.item_configuration.custom_message)
  }

  return (
    <Modal title={title} isOpen={isOpen} onClose={() => onClose()} styleReset size="sm">
      <div>
        <Textarea value={customMessage} onChange={(e) => setCustomMessage(e.target.value)} />
        <ModalButtons>
          <Button variant="primary" onClick={handleSave} label="Update" loading={isLoading} />
          <Button onClick={handleCancel} label="Cancel" />
        </ModalButtons>
      </div>
    </Modal>
  )
}
