import { useRemoveOrderItemDiscount } from 'api/OrderDiscounts'
import { ConfirmationModal } from 'ui/Modal'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'

export const RemoveDiscountFromItem = ({ discount, orderId, itemId, onClose }) => {
  const { mutate, isLoading: isDeleting } = useRemoveOrderItemDiscount()
  const notification = useNotificationContext()

  const handleDelete = () => {
    mutate(
      { orderId, itemId, discountId: discount.id },
      {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Discount removed"
              description="The discount was removed successfully"
              variant="success"
              autoDismiss
            />
          )
          onClose()
        },
        onError: (e) => {
          notification.notify(<ApiErrorNotification error={e} />)
        },
      }
    )
  }

  return (
    <ConfirmationModal
      isOpen={discount !== null}
      onClose={onClose}
      title="Remove discount"
      confirmButtonLabel="Remove"
      onConfirm={handleDelete}
      confirming={isDeleting}
    >
      <div className="space-y-2 text-sm text-gray-500">
        <p>Are you sure you want to remove this discount?</p>
        <p>This will remove the discount currently applied to this item.</p>
      </div>
    </ConfirmationModal>
  )
}
