/* This example requires Tailwind CSS v2.0+ */
import { Fragment, ReactNode, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, ExclamationIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Button } from 'ui/components/Button'

interface ModalProps {
  title: string
  children?: ReactNode
  isOpen: boolean
  onClose(value: boolean): void
  icon?: React.ElementType
  iconVariant?: 'success' | 'info' | 'danger' | 'warning'
  showHideIcon?: boolean
  styleReset?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  prompt?: boolean
  disallowClose?: boolean
  initialFocus?: React.MutableRefObject<HTMLElement | null>
}

export const Modal = ({
  title,
  children,
  isOpen,
  onClose,
  icon: Icon = null,
  iconVariant = 'danger',
  styleReset = false,
  showHideIcon = true,
  disallowClose = false,
  size = 'lg',
  prompt = false,
  initialFocus,
}: ModalProps) => {
  const modalSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'sm:max-w-xs'
      case 'sm':
        return 'sm:max-w-sm'
      case 'md':
        return 'sm:max-w-md'
      case 'lg':
        return 'sm:max-w-lg'
      case 'xl':
        return 'sm:max-w-xl'
      case '2xl':
        return 'sm:max-w-2xl'
      case '3xl':
        return 'sm:max-w-3xl'
    }
  }, [size])
  const [iconColor, iconBackgoundColor] = useMemo(() => {
    switch (iconVariant) {
      case 'success':
        return ['text-green-600', 'bg-green-100']
      case 'info':
        return ['text-blue-600', 'bg-blue-100']
      case 'danger':
        return ['text-red-600', 'bg-red-100']
      case 'warning':
        return ['text-yellow-600', 'bg-yellow-100']
    }
  }, [iconVariant])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        className={classNames('relative z-50', { 'style-reset': styleReset })}
        onClose={(value) => (disallowClose ? null : onClose(value))}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 bg-gray-500/25 dark:bg-black/50 transition-opacity backdrop-filter backdrop-blur"
            aria-hidden="true"
          />
        </Transition.Child>

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 flex justify-center p-4 overflow-y-auto">
          {/* Container to center the panel */}
          <div
            className={classNames(
              'flex min-h-full mt-auto items-center justify-center w-full',
              modalSize
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="text-left sm:m-0 inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg shadow-xl transform transition-all sm:my-8 sm:mb-48 sm:align-middle w-full p-6">
                {showHideIcon && (
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet"
                      onClick={() => onClose(true)}
                      tabIndex={-1}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                )}
                <div className="flex items-center mb-4">
                  {Icon && (
                    <div
                      className={`flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${iconBackgoundColor}`}
                    >
                      <Icon className={`h-6 w-6 ${iconColor}`} aria-hidden="true" />
                    </div>
                  )}

                  {title && (
                    <div className={`text-left mt-0 flex-1 ${Icon ? 'ml-2' : ''}`}>
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  )}
                </div>
                <div className="mt-2">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const ModalButtons = ({ children, prompt = false }) => (
  <div
    className={`mt-6 pb-0 flex space-y-2 space-y-reverse text-center ${
      !prompt ? 'sm:space-y-0 sm:space-x-2 sm:space-x-reverse sm:flex-row-reverse' : ''
    } justify-start flex-col-reverse`}
  >
    {children}
  </div>
)

interface ConfirmationModalProps {
  title: string
  children: ReactNode
  isOpen: boolean
  setIsOpen?(value: boolean): void
  onClose(value: boolean): void
  onConfirm(): void
  variant?: 'success' | 'info' | 'danger' | 'warning'
  icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  confirmButtonLabel: string
  confirming?: boolean
  confirmButtonIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  cancelButtonLabel?: string
  disabled?: boolean
}

export const ConfirmationModal = ({
  title,
  isOpen,
  onClose,
  variant = 'danger',
  confirmButtonLabel,
  onConfirm,
  confirming = false,
  confirmButtonIcon: ConfirmButtonIcon = null,
  setIsOpen,
  disabled = false,
  cancelButtonLabel = 'Cancel',
  children,
  icon = ExclamationIcon,
}: ConfirmationModalProps) => {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      styleReset={true}
      icon={icon}
      iconVariant={variant}
      showHideIcon={false}
    >
      <>
        <div className="sm:pl-12">{children}</div>
        <ModalButtons>
          <Button
            variant="primary"
            disabled={disabled}
            label={confirmButtonLabel}
            onClick={onConfirm}
            loading={confirming}
            leadingIcon={ConfirmButtonIcon}
          />
          <Button label={cancelButtonLabel} onClick={onClose} />
        </ModalButtons>
      </>
    </Modal>
  )
}
