import { all } from 'redux-saga/effects'
import { OrdersSaga, OrderEmailsSaga, OrderLabelsSaga } from '@trybeapp/ducks.orders'
import { PractitionerScheduledAvailabilitySaga } from '@trybeapp/ducks.practitioner-scheduled-availability'
import { PackagesSaga, PackageItemChoicesSaga } from '@trybeapp/ducks.packages'
import {
  BrandsSaga as InventoryBrandsSaga,
  CategoriesSaga as InventoryCategoriesSaga,
  OrdersSaga as InventoryOrdersSaga,
  OrderItemsSaga as InventoryOrderItemsSaga,
  ProductsSaga as InventoryProductsSaga,
  SupplierProductsSaga as InventorySupplierProductsSaga,
  SuppliersSaga as InventorySuppliersSaga,
  StockTakesSaga as InventoryStockTakesSaga,
  StockTakeItemsSaga as InventoryStockTakeItemsSaga,
} from '@trybeapp/ducks.inventory'

export default function* rootSaga() {
  yield all([
    InventoryBrandsSaga(),
    InventoryCategoriesSaga(),
    InventoryOrdersSaga(),
    InventoryOrderItemsSaga(),
    InventoryProductsSaga(),
    InventorySupplierProductsSaga(),
    InventorySuppliersSaga(),
    InventoryStockTakesSaga(),
    InventoryStockTakeItemsSaga(),
    OrderEmailsSaga(),
    OrderLabelsSaga(),
    OrdersSaga(),
    PackageItemChoicesSaga(),
    PackagesSaga(),
    PractitionerScheduledAvailabilitySaga(),
  ])
}
