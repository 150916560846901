import classNames from 'classnames'

interface LabelProps {
  text?: string
  htmlFor?: string
  hide?: boolean
  required?: boolean
  className?: string
  children?: string
}

export const Label: React.FC<LabelProps> = ({
  text,
  htmlFor,
  hide,
  required,
  children,
  className,
}) => (
  <label
    htmlFor={htmlFor}
    className={classNames(
      hide ? 'sr-only' : 'block text-sm font-medium text-gray-700 dark:text-gray-400',
      className
    )}
  >
    {text ?? children}
    {required && <span className="ml-1 text-gray-500">*</span>}
  </label>
)
