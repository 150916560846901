import { Link } from 'react-router-dom'

export const NestedLink = ({ title, icon, path, isActive = false }) => (
  <Link
    to={path}
    title={title}
    className={`${
      isActive ? 'border-violet' : 'border-transparent hover:border-violet/50 dark:text-gray-300'
    } flex items-center space-x-1 border-b-4 p-3`}
  >
    {icon({
      className: `${isActive ? 'text-violet' : 'dark:text-gray-500'} flex-shrink-0 w-5 h-5`,
    })}
    <span className="whitespace-nowrap">{title}</span>
  </Link>
)
