import { useEffect } from 'react'
import { fromEvent } from 'rxjs'
import { buffer, filter, debounceTime } from 'rxjs/operators'

interface BarcodeListenerConfig {
  /**
   * Determines if the barcode listener should be listening for scans.
   */
  active?: boolean
  /**
   * An array of keyCodes which the scanner adds as a prefix, but are not included
   * in the barcode payload. ie, if the scanner is programmed to add the keyCode
   * `Digit1` to the beginning of the input, but the scanned barcode does not
   * include "1" at the front. Any prefixes listed here will be trimmed off
   * the code returned on the `onScan()` callback.
   */
  prefixCodes?: string[]
  /**
   * An array of keyCodes which the scanner adds as a suffix, but are not included
   * in the barcode payload. ie, if the scanner is programmed to add the keyCode
   * `Enter` after scanning a barcode. Any suffixes listed here will be trimmed off
   * the code returned on the `onScan()` callback.
   */
  suffixCodes?: string[]
  /**
   * The maximum time in between keystrokes in milliseconds for us to consider
   * this a barcode scan.
   */
  timeout?: number
  /**
   * The minimum number of characters a barcode contains for us to consider this
   * a barcode scan. This excludes prefixes and suffixes.
   */
  minCharLength?: number
  /**
   * When we detect what looks like a barcode scan, this callback will be called.
   * The `code` argument contains the barcode payload, minus any prefixes or suffixes.
   * We output it as a string of concatenated `key`s. For example, a scan of the following
   * keyCodes : `Digit1`, `Digit2`, `Digit3` will return `123`.
   */
  onScan?: (code: string) => unknown
}

export const BarcodeListener = ({
  active = true,
  prefixCodes = [],
  suffixCodes = [],
  timeout = 50,
  minCharLength = 4,
  onScan = () => {},
}: BarcodeListenerConfig) => {
  const source = fromEvent<KeyboardEvent>(document, 'keypress')

  const doesStreamLookLikeBarcode = (events: KeyboardEvent[]): boolean => {
    // The amount of items must be at least the length of the prefix + suffix + 1
    const minLength = prefixCodes.length + suffixCodes.length + minCharLength

    if (events.length <= minLength) {
      // Too short.
      return false
    }

    // Now check the first character(s) match the prefix.
    const firstChars = events.slice(0, prefixCodes.length).map((e) => e.code)

    if (firstChars.join('') !== prefixCodes.join('')) {
      // Prefix doesn't match.
      return false
    }

    // Now check the last character(s) match the suffix.
    const lastChars = events.slice(events.length - suffixCodes.length).map((e) => e.code)

    if (lastChars.join('') !== suffixCodes.join('')) {
      // Suffix doesn't match.
      return false
    }

    // It looks like it could be a barcode.
    return true
  }

  const handlePotentialBarcode = (events: KeyboardEvent[]) => {
    const withoutPrefixes = events.slice(prefixCodes.length)
    const withoutPrefixesAndSuffixes = withoutPrefixes.slice(
      0,
      withoutPrefixes.length - suffixCodes.length
    )

    // Some scanners might end the string with Enter or Tab. Remove these.
    const withoutEnterOrTab = withoutPrefixesAndSuffixes.filter(
      (e) => e.code !== 'Enter' && e.code !== 'Tab'
    )

    onScan(withoutEnterOrTab.map((e) => e.key).join(''))
  }

  useEffect(() => {
    if (active) {
      const subscription = source
        .pipe(buffer(source.pipe(debounceTime(timeout))), filter(doesStreamLookLikeBarcode))
        .subscribe(handlePotentialBarcode)

      return () => subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return null
}
