import styled, { css } from '@xstyled/styled-components'

import { Text } from 'components/Text'
import { Box } from '@trybeapp/ui.box'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'

const styles = {
  default: {
    selected: {
      backgroundColor: 'light.500',
      color: 'nude.900',
    },
    backgroundColor: 'light.700',
    color: 'nude.700',
    borderColor: 'light.500',
  },
  info: {
    selected: {
      backgroundColor: 'info.500',
      color: 'light.900',
    },
    backgroundColor: 'info.100',
    color: 'info.700',
    borderColor: 'light.500',
  },
  light: {
    selected: {
      backgroundColor: 'light.900',
      color: 'nude.900',
      borderColor: 'nude.200',
    },
    backgroundColor: 'light.900',
    color: 'nude.700',
    borderColor: 'light.200',
  },
}

/**
 * @deprecated
 */
export const Pill = ({
  selected,
  disabled,
  onClick,
  children,
  fontSize = 'body2',
  variant = 'default',
  showIcon = true,
  ...props
}) => {
  const handleOnClick = (event) => {
    if (disabled) {
      return
    }

    onClick(event)
  }

  return (
    <StyledBox
      as="button"
      borderRadius="sm"
      selected={selected}
      disabled={disabled}
      backgroundColor={selected ? 'light.500' : 'light.700'}
      opacity={disabled ? 0.6 : 1}
      p="sm"
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      display="flex"
      alignItems="center"
      onClick={handleOnClick}
      borderColor="light.500"
      borderStyle="solid"
      borderWidth="sm"
      mb="sm"
      fontSize={fontSize}
      {...styles[variant]}
      {...(selected ? styles[variant].selected : undefined)}
      {...props}
    >
      {showIcon && (
        <FontAwesomeIcon
          icon={selected ? 'check-square' : ['far', 'square']}
          fontSize="inherit"
          color="inherit"
          mr="sm"
          fixedWidth
        />
      )}

      <Text color="inherit" variant="inherit" alignItems="center" as="div">
        {children}
      </Text>
    </StyledBox>
  )
}

const StyledBox = styled(Box)(
  ({ selected = false, disabled = false }) => css`
    &:hover {
      ${disabled
        ? css`
            background-color: light.700;
          `
        : selected
        ? css`
            background-color: light.200;
          `
        : css`
            background-color: light.500;
            border-width: xs;
          `}
    }
  `
)
