import React from 'react'
import { Link } from 'react-router-dom'

interface FeatureCardProps {
  title: string | JSX.Element
  subtitle?: string
  truncateTitle?: boolean
  iconBackground?: string
  iconForeground?: string
  icon?: (_: any) => React.ReactNode
  links?: any[]
  children?: any
  isLinked?: boolean
}

interface LinkedFeatureCardProps extends FeatureCardProps {
  to: string
}

export const LinkedFeatureCard: React.FC<LinkedFeatureCardProps> = (props) => (
  <Link to={props.to} className="focus:ring-1 focus:ring-violet">
    <FeatureCard {...props} isLinked />
  </Link>
)

export const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  subtitle = null,
  truncateTitle = false,
  iconBackground,
  iconForeground,
  icon = null,
  links = [],
  children = null,
  isLinked = false,
}) => (
  <div
    className={`bg-white dark:bg-gray-700 dark:border-transparent dark:text-gray-100 p-6 border border-gray-200 rounded-lg ${
      isLinked ? 'group hover:border-gray-300 dark:hover:border-gray-600' : ''
    }`}
  >
    <div className={`flex space-x-4 ${subtitle ? 'items-start' : 'items-center'}`}>
      {icon && (
        <div
          className={`rounded-lg p-3 ring-4 ring-white dark:ring-gray-700 ${iconBackground} ${iconForeground}`}
        >
          {icon({
            className: 'w-6 h-6',
          })}
        </div>
      )}
      <div>
        <div className={`font-bold text-xl ${truncateTitle ? 'truncate' : ''}`}>{title}</div>
        {subtitle && <p className="text-gray-500 text-sm">{subtitle}</p>}
      </div>
    </div>

    <div className="space-y-4">
      {children && <div className="mt-4 text-sm text-gray-500">{children}</div>}

      {links.length > 0 && (
        <ul className="mt-4 font-medium text-gray-700 dark:text-gray-300 ml-16 space-y-1">
          {links
            .filter((link) => !!link)
            .map((link) => (
              <li className="hover:underline" key={link.to}>
                <Link to={link.to}>{link.label}</Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  </div>
)
