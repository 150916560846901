import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { TableReservationTypesApi } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new TableReservationTypesApi(apiConfiguration)

export const useTableReservationTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['tableReservationTypes', params],
    () => api.listTableReservationTypes({ siteId: siteId }),
    options
  )
}

export const useInfiniteTableReservationTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(
    ['inifiniteTableReservationTypes', { params }],
    fetchTableReservationTypes,
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextPage
      },
    }
  )
}

const fetchTableReservationTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const siteId = useCurrentSiteId()
  const response = await api.listTableReservationTypes({ siteId, page })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetTableReservationType = (tableReservationTypeId: string, options = {}) =>
  useQuery(
    ['tableReservationType', { tableReservationTypeId }],
    () => api.getTableReservationType({ tableReservationTypeId }),
    options
  )

export const useCreateTableReservationType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (tableReservationType: any) =>
      api.createTableReservationType({
        createTableReservationTypeRequest: {
          ...tableReservationType,
          site_id: siteId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableReservationTypes'])
      },
    }
  )
}

export const useUpdateTableReservationType = (tableReservationTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, any>(
    (tableReservationType) =>
      api.updateTableReservationType({
        tableReservationTypeId,
        updateTableReservationTypeRequest: tableReservationType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableReservationTypes'])
        queryClient.invalidateQueries(['tableReservationType', { tableReservationTypeId }])
      },
    }
  )
}

export const useAddTableReservationOption = (tableReservationTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (option: any) =>
      api.createTableReservationOption({
        tableReservationTypeId,
        createTableReservationOptionRequest: option,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableReservationTypes'])
        queryClient.invalidateQueries(['tableReservationType', { tableReservationTypeId }])
      },
    }
  )
}

export const useRemoveTableReservationOption = (tableReservationTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (optionId: string) =>
      api.deleteTableReservationOption({
        tableReservationTypeId,
        optionId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tableReservationTypes'])
        queryClient.invalidateQueries(['tableReservationType', { tableReservationTypeId }])
      },
    }
  )
}
