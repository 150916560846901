import { FetchParams, Middleware, RequestContext } from '@trybeapp/sdk'

class AuthMiddleware implements Middleware {
  public token: string

  setToken(token: string) {
    this.token = token
  }

  async pre(context: RequestContext): Promise<void | FetchParams> {
    const accessToken = this.token

    if (accessToken) {
      return {
        url: context.url,
        init: {
          ...context.init,
          headers: {
            ...context.init?.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        },
      }
    }
  }
}

export const authMiddleware = new AuthMiddleware()
