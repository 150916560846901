import { CUSTOMERS_VIEW } from 'constants/permissions'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const ClientList = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'SettingsClientList' */ '../components/ClientList').then((file) => {
    return { default: file.ClientList }
  })
)

const MembershipList = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'SettingsMembershipList' */ '../components/MembershipList').then(
    (file) => {
      return { default: file.MembershipList }
    }
  )
)

export const useRoutes = () => {
  const featureFlags = useActiveFeatureFlags()

  return [
    {
      path: featureFlags.includes('new_client_list') ? '/beta/clients' : '/clients',
      name: 'Client profiles',
      component: ClientList,
      exact: true,
      permissions: [CUSTOMERS_VIEW],
    },
    {
      path: '/memberships',
      name: 'Memberships',
      component: MembershipList,
      exact: true,
      permissions: [CUSTOMERS_VIEW],
    },
  ]
}
