import { forwardRef } from 'react'
import { createEvent } from '@trybeapp/ui.utilities'
import { Select } from '@trybeapp/ui.select'
import { useBookableAreas } from 'api/BookableAreas'

export const BookableAreaSelect = forwardRef(({ ...props }, ref) => {
  const { data: { data: areas = [] } = {}, isLoading } = useBookableAreas(
    { perPage: -1 },
    { refetchOnWindowFocus: false }
  )

  const options = areas.map((area) => ({
    value: area.id,
    label: area.name,
  }))

  if (isLoading) return null

  return <Select options={options} isClearable={true} ref={ref} {...props} />
})

export const FormikBookableAreaSelect = forwardRef(
  ({ onChange: parentOnChange, name, ...props }, ref) => {
    const onChange = (value) => {
      parentOnChange(createEvent({ name, value }))
    }

    return <BookableAreaSelect name={name} onChange={onChange} ref={ref} {...props} />
  }
)
