import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { SitesApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new SitesApi()

export const useGetShopConfig = (shopId, options: UseQueryOptions = {}): UseQueryResult<any> =>
  useQuery(['shopConfig', shopId], () => api.showShopConfig(shopId), options)

export const useUpdateShopConfig = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(([siteId, shopConfig]: any) => api.updateShopConfig(siteId, shopConfig), {
    onSuccess: () => {
      queryClient.invalidateQueries('shopConfig')
    },
  })
}

export const useGetOfferingOrder = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(['shopOfferingOrder', { siteId }], () => api.getOrderedOfferings(siteId), options)
}

export const useUpdateOfferingOrder = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (offeringIds: any) => api.updateOfferingOrder(siteId, { offering_ids: offeringIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['shopOfferingOrder', { siteId }])
      },
    }
  )
}
