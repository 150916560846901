import { createSelector } from 'reselect'
import { chain, pick, map } from 'lodash'
import {
  STATUS_CANCELLED,
  STATUS_RESERVATION_EXPIRED,
  STATUS_RESERVED,
} from 'constants/orderStatuses'
import { TYPE_APPOINTMENT, TYPE_SESSION } from 'constants/offeringTypes'
import { selectBookingSummariesById } from 'selectors/BookingSummarySelectors'
import { DateTime } from 'luxon'
import { sdkDateToSystemDateTime } from 'utilities/DateUtils/dateUtils'

/**
 * @deprecated
 */
export const selectOrdersReducer = (state) => state.resources.OrdersReducer
/**
 * @deprecated
 */
export const selectOrdersById = (state) => state.resources.OrdersReducer.byId
const selectGuestsById = (state) => state.resources.GuestsReducer.byId

/**
 * @deprecated
 */
export const selectBookingOrderItemsById = (state) => {
  return Object.keys(state.resources.BookingOrderItemsReducer.byId ?? {}).reduce(
    (itemsById, key) => {
      const item = state.resources.BookingOrderItemsReducer.byId[key]

      // Convert all date-only values to the system timezone before returning them
      itemsById[key] = {
        ...item,
        date: item.date ? sdkDateToSystemDateTime(item.date).toJSDate() : item.date,
      }

      return itemsById
    },
    {}
  )
}

/**
 * @deprecated
 */
export const selectPackageItemsById = (state) => {
  return Object.keys(state.resources.PackageItemsReducer.byId || {}).reduce((itemsById, key) => {
    const item = state.resources.PackageItemsReducer.byId[key]

    // Convert all date-only values to the system timezone before returning them
    itemsById[key] = {
      ...item,
      date: item.date ? sdkDateToSystemDateTime(item.date).toJSDate() : item.date,
    }

    return itemsById
  }, {})
}

/**
 * @deprecated
 */
export const selectPackageOrderItemsById = (state) =>
  state.resources.PackageOrderItemsReducer.byId || {}

/**
 * @deprecated
 */
export const selectPurchaseOrderItemsById = (state) =>
  state.resources.PurchaseOrderItemsReducer.byId || {}

/**
 * @deprecated
 */
export const selectPurchaseOrderItemsReducer = (state) => state.resources.PurchaseOrderItemsReducer

/**
 * @deprecated
 */
export const selectBookingOrderItemsReducer = (state) => state.resources.BookingOrderItemsReducer

/**
 * @deprecated
 */
export const selectPractitionerSummariesReducer = (state) =>
  state.resources.PractitionerSummariesReducer

/**
 * @deprecated
 */
export const selectAllOrders = () =>
  createSelector([selectOrdersById], (byId = {}) => Object.values(byId) || [])

/**
 * @deprecated
 */
export const selectOrder = (orderId) =>
  createSelector([selectOrdersById], (byId = {}) => byId[orderId])

/**
 * @deprecated
 */
export const selectBookingOrderItems = (itemIds = []) =>
  createSelector([selectBookingOrderItemsById], (byId = {}) => Object.values(pick(byId, itemIds)))

/**
 * @deprecated
 */
export const selectPackageOrderItems = (itemIds = []) =>
  createSelector([selectPackageOrderItemsById], (byId) => Object.values(pick(byId, itemIds)))

/**
 * @deprecated
 */
export const selectPackageItems = (itemIds = []) =>
  createSelector([selectPackageItemsById], (byId = {}) => Object.values(pick(byId, itemIds)))

/**
 * @deprecated
 */
export const selectAppointmentItemsIds = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById, bookingOrderItemsById) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return chain(bookingOrderItemsById)
        .pick(bookingItemIds)
        .filter(({ item_type: type }) => type === TYPE_APPOINTMENT)
        .value()
        .map(({ id }) => id)
    }
  )

/**
 * @deprecated
 */
export const selectActiveAppointmentItemsIds = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById, bookingOrderItemsById) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return chain(bookingOrderItemsById)
        .pick(bookingItemIds)
        .filter(
          ({ item_type: type, status }) => type === TYPE_APPOINTMENT && status !== STATUS_CANCELLED
        )
        .value()
        .map(({ id }) => id)
    }
  )

/**
 * @deprecated
 */
export const selectAppointmentItemIdsByGuestId = (orderId) =>
  createSelector(
    [selectAppointmentItemsIds(orderId), selectBookingOrderItemsById],
    (appointmentIds = [], bookingOrderItemsById = {}) => {
      return chain(bookingOrderItemsById)
        .pick(appointmentIds)
        .groupBy('guest')
        .mapValues((items) => map(items, 'id'))
        .value()
    }
  )

/**
 * @deprecated
 */
export const selectAppointmentItemIdsForGuestId = (orderId, guestId) =>
  createSelector(
    [selectAppointmentItemIdsByGuestId(orderId)],
    (orderItems = {}) => orderItems[guestId] || []
  )

/**
 * @deprecated
 */
export const selectBookingOrderItemsIds = (orderId) =>
  createSelector([selectOrdersById], (ordersById = {}) => {
    const { booking_items: bookingItemIds = [] } = ordersById[orderId] || {
      booking_items: [],
    }

    return bookingItemIds
  })

/**
 * @deprecated
 */
export const selectPurchaseOrderItemsIds = (orderId) =>
  createSelector([selectOrdersById], (ordersById = {}) => {
    const { purchase_items: purchaseItemIds = [] } = ordersById[orderId] || {
      purchase_items: [],
    }

    return purchaseItemIds
  })

/**
 * @deprecated
 */
export const selectPackageOrderItemsIds = (orderId) =>
  createSelector([selectOrdersById], (ordersById = {}) => {
    const { package_items: packageItemIds = [] } = ordersById[orderId] || {
      package_items: [],
    }

    return packageItemIds
  })

/**
 * @deprecated
 */
export const selectActivePackageOrderItemsIds = (orderId) =>
  createSelector(
    [selectOrdersById, selectPackageOrderItemsById],
    (ordersById = {}, packageOrderItemIds = {}) => {
      const { package_items: packageItemIds = [] } = ordersById[orderId] || {
        package_items: [],
      }
      return chain(packageOrderItemIds)
        .pick(packageItemIds)
        .filter(({ status }) => status !== STATUS_CANCELLED)
        .value()
        .map(({ id }) => id)
    }
  )

/**
 * @deprecated
 */
export const selectOrderItemsUnion = (orderId) =>
  createSelector([selectAllOrderItems(orderId)], (orderItems = []) => {
    return orderItems.map((item) => ({
      id: item.id,
      schema: item.item_type,
    }))
  })

/**
 * @deprecated
 */
export const selectActiveOrderItemsUnion = (orderId) =>
  createSelector([selectAllOrderItems(orderId)], (orderItems = []) => {
    return orderItems
      .filter(({ status }) => status !== STATUS_CANCELLED)
      .map((item) => ({
        id: item.id,
        schema: item.item_type,
      }))
  })

/**
 * @deprecated
 */
export const selectOrderItemsUnionForGuestId = (orderId, guestId) =>
  createSelector([selectAllOrderItems(orderId)], (orderItems = []) => {
    return orderItems
      .filter((item) => item.guest === guestId)
      .map((item) => ({
        id: item.id,
        schema: item.item_type,
      }))
  })

/**
 * @deprecated
 */
export const selectOrderItem = (itemId) =>
  createSelector([selectOrderItemsById], (itemsById = {}) => itemsById[itemId])

/**
 * @deprecated
 */
export const selectOrderItemsById = createSelector(
  [selectBookingOrderItemsById, selectPurchaseOrderItemsById, selectPackageOrderItemsById],
  (bookingOrderItemsById, purchaseOrderItemsById, packageOrderItemsById) => {
    return {
      ...bookingOrderItemsById,
      ...purchaseOrderItemsById,
      ...packageOrderItemsById,
    }
  }
)

/**
 * @deprecated
 */
export const selectAllOrderItems = (orderId) =>
  createSelector(
    [
      selectOrderItemsById,
      selectBookingOrderItemsIds(orderId),
      selectPurchaseOrderItemsIds(orderId),
      selectPackageOrderItemsIds(orderId),
    ],
    (orderItems, bookingOrderItemIds, purchaseOrderItemIds, packageOrderItemIds) => {
      const itemIds = [...bookingOrderItemIds, ...purchaseOrderItemIds, ...packageOrderItemIds]

      return Object.values(pick(orderItems, itemIds)).sort(function (a, b) {
        return a.created_at.getTime() - b.created_at.getTime()
      })
    }
  )

/**
 * @deprecated
 */
export const selectPackageItemIdsByGuestId = (orderId) =>
  createSelector(
    [selectPackageOrderItemsIds(orderId), selectPackageOrderItemsById],
    (packageIds = [], packageOrderItemsById = {}) => {
      return chain(packageOrderItemsById)
        .pick(packageIds)
        .groupBy('guest')
        .mapValues((items) => map(items, 'id'))
        .value()
    }
  )

/**
 * @deprecated
 */
export const selectPackageItemIdsForGuestId = (orderId, guestId) =>
  createSelector(
    [selectPackageItemIdsByGuestId(orderId)],
    (orderItems = {}) => orderItems[guestId] || []
  )

/**
 * @deprecated
 */
export const selectGuests = (orderId) =>
  createSelector([selectOrder(orderId), selectGuestsById], (order, guestsById) => {
    const { guests: guestIds = [] } = order || {}
    return Object.values(pick(guestsById, guestIds))
  })

/**
 * @deprecated
 */
export const selectGuestIds = (orderId) =>
  createSelector([selectGuests(orderId)], (guests = []) => guests.map(({ id }) => id))

/**
 * @deprecated
 */
export const selectHasGuests = (orderId) =>
  createSelector([selectGuestIds(orderId)], (guestIds = []) => guestIds.length > 0)

/**
 * @deprecated
 */
export const selectPurchaseOrderItems = (itemIds = []) =>
  createSelector([() => itemIds, selectPurchaseOrderItemsReducer], (itemIds, { byId }) =>
    Object.values(pick(byId, itemIds))
  )

/**
 * @deprecated
 */
export const selectBookingOrderItem = (orderItemId) =>
  createSelector([selectBookingOrderItemsById], (byId) => byId[orderItemId] || {})

/**
 * @deprecated
 */
export const selectPackageItem = (packageId) =>
  createSelector([selectPackageItemsById], (byId) => byId[packageId] || {})

/**
 * @deprecated
 */
export const selectPackageOrderItem = (orderItemId) =>
  createSelector([selectPackageOrderItemsById], (byId) => byId[orderItemId] || {})

/**
 * @deprecated
 */
export const selectPractitionerSummary = (practitionerId) =>
  createSelector(
    [selectPractitionerSummariesReducer, () => practitionerId],
    ({ byId }, practitionerId) => byId[practitionerId] || {}
  )

/**
 * @deprecated
 */
export const selectPurchaseOrderItem = (orderItemId) =>
  createSelector(
    [selectPurchaseOrderItemsReducer, () => orderItemId],
    ({ byId }, orderItemId) => byId[orderItemId] || {}
  )

/**
 * @deprecated
 */
export const selectAllActiveOrderItemDates = (basketId) =>
  createSelector(
    [selectActiveBookingOrderItemDates(basketId), selectAllActivePackageOrderItemDates(basketId)],
    (bookingDates = [], packageDates = []) =>
      chain(bookingDates)
        .concat(packageDates)
        .uniqBy((date) => date.getTime())
        .sortBy((date) => date.getTime())
        .value()
  )

/**
 * @deprecated
 */
export const selectActiveBookingOrderItemDates = (basketId) =>
  createSelector([selectOrderBookingOrderItems(basketId)], (items = []) =>
    chain(items)
      .filter(({ status }) => status !== STATUS_CANCELLED)
      .map((item) => item.date)
      .filter()
      .uniqBy((date) => date.getTime())
      .sortBy((date) => date.getTime())

      .value()
  )

/**
 * @deprecated
 */
export const selectOrderBookingOrderItems = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById = {}, itemsById = {}) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return Object.values(pick(itemsById, bookingItemIds))
    }
  )

/**
 * @deprecated
 */
export const selectBookingOrderItemIdsRequiringReservationForOrderId = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById = {}, itemsById = {}) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return Object.values(pick(itemsById, bookingItemIds)).filter(({ status }) =>
        [STATUS_RESERVED, STATUS_RESERVATION_EXPIRED].includes(status)
      )
    }
  )

/**
 * @deprecated
 */
export const selectBookingSummaryIdsForPackageOrderItem = (itemId) =>
  createSelector(
    [selectPackageOrderItem(itemId), selectPackageItemsById, selectBookingSummariesById],
    (packageOrderItem = { package_items: [] }, packageItemsById = {}) => {
      const { package_items: packageItemIds } = packageOrderItem
      const packageItems = Object.values(pick(packageItemsById, packageItemIds))

      return Object.values(packageItems)
        .filter(({ booking_summary: bookingSummaryId }) => typeof bookingSummaryId === 'string')
        .map(({ booking_summary: bookingSummaryId }) => bookingSummaryId)
    }
  )

/**
 * @deprecated
 */
export const selectPackageOrderItemIdsRequiringReservationForOrderId = (orderId) =>
  createSelector(
    [
      selectPackageOrderItemsIds(orderId),
      selectPackageOrderItemsById,
      selectPackageItemsById,
      selectBookingSummariesById,
    ],
    (
      packageItemIds = [],
      packageOrderItemsById = {},
      packageItemsById = {},
      bookingSummariesById = {}
    ) => {
      const packageOrderItems = Object.values(pick(packageOrderItemsById, packageItemIds))
      return chain(packageOrderItems)
        .filter(({ package_items: packageItemIds }) => {
          const packageItems = Object.values(pick(packageItemsById, packageItemIds))

          const bookingSummaryIds = Object.values(packageItems)
            .filter(({ booking_summary: bookingSummaryId }) => typeof bookingSummaryId === 'string')
            .map(({ booking_summary: bookingSummaryId }) => bookingSummaryId)

          return (
            Object.values(pick(bookingSummariesById, bookingSummaryIds)).filter(({ status }) =>
              [STATUS_RESERVED, STATUS_RESERVATION_EXPIRED].includes(status)
            ).length > 0
          )
        })
        .map(({ id }) => id)
        .value()
    }
  )

/**
 * @deprecated
 */
export const selectPackageOrderItemDates = (packageOrderItemId) =>
  createSelector(
    [selectBookingSummaryIdsForPackageOrderItem(packageOrderItemId), selectBookingSummariesById],
    (bookingSummaryIds = [], bookingSummariesById = {}) => {
      const bookingSummaries = Object.values(pick(bookingSummariesById, bookingSummaryIds))

      return chain(bookingSummaries)
        .map((item) =>
          DateTime.fromJSDate(item.start_time).startOf('day').toISO({ suppressMilliseconds: true })
        )
        .filter()
        .uniq()
        .map((dateString) => new Date(dateString))
        .value()
    }
  )

/**
 * @deprecated
 */
export const selectAllActivePackageOrderItemDates = (orderId) =>
  createSelector(
    [selectPackageOrderItemsIds(orderId), selectPackageOrderItemsById],
    (packageItemIds = [], packageOrderItemsById = {}) => {
      const packageOrderItems = Object.values(pick(packageOrderItemsById, packageItemIds))

      return chain(packageOrderItems)
        .filter(({ status }) => status !== STATUS_CANCELLED)
        .map((item) => item.date)
        .filter()
        .uniqBy((date) => date.getTime())
        .sortBy((date) => date.getTime())
        .value()
    }
  )

/**
 * @deprecated
 */
export const selectOrderLabelIds = (orderId) =>
  createSelector([selectOrder(orderId)], ({ labels = [] }) => labels)

/**
 * @deprecated
 */
export const selectSessionItemsIds = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById, bookingOrderItemsById) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return chain(bookingOrderItemsById)
        .pick(bookingItemIds)
        .filter(({ item_type: type }) => type === TYPE_SESSION)
        .value()
        .map(({ id }) => id)
    }
  )

/**
 * @deprecated
 */
export const selectActiveSessionItemsIds = (orderId) =>
  createSelector(
    [selectOrdersById, selectBookingOrderItemsById],
    (ordersById, bookingOrderItemsById) => {
      const order = ordersById[orderId] || { booking_items: [] }
      const { booking_items: bookingItemIds = [] } = order

      return chain(bookingOrderItemsById)
        .pick(bookingItemIds)
        .filter(
          ({ item_type: type, status }) => type === TYPE_SESSION && status !== STATUS_CANCELLED
        )
        .value()
        .map(({ id }) => id)
    }
  )

/**
 * @deprecated
 */
export const selectSessionItemIdsByGuestId = (orderId) =>
  createSelector(
    [selectSessionItemsIds(orderId), selectBookingOrderItemsById],
    (appointmentIds = [], bookingOrderItemsById = {}) => {
      return chain(bookingOrderItemsById)
        .pick(appointmentIds)
        .groupBy('guest')
        .mapValues((items) => map(items, 'id'))
        .value()
    }
  )
/**
 * @deprecated
 */
export const selectSessionItemIdsForGuestId = (orderId, guestId) =>
  createSelector(
    [selectSessionItemIdsByGuestId(orderId)],
    (orderItems = {}) => orderItems[guestId] || []
  )
