import classNames from 'classnames'

export const StatBox = ({ title, stat, statText = '', compact = false }) => {
  return (
    <EmptyStatBox compact={compact}>
      <StatBoxTitle>{title}</StatBoxTitle>
      <StatBoxStat stat={stat} text={statText} />
    </EmptyStatBox>
  )
}

export const EmptyStatBox = ({ children, compact = false }) => (
  <div
    className={`${
      compact ? 'px-2 py-3 sm:p-4' : 'px-4 py-5 sm:p-6'
    } bg-white shadow-sm border border-gray-200 rounded-lg overflow-hidden`}
  >
    {children}
  </div>
)

export const StatBoxTitle = ({ children }) => (
  <dt className="text-sm font-medium text-gray-500 truncate">{children}</dt>
)

export const StatBoxStat = ({ stat, text = '', reverse = false, vertical = false }) => (
  <dd
    className={classNames(
      'mt-1 items-baseline',
      vertical ? 'flex flex-col items-start' : 'md:block lg:flex',
      reverse ? (vertical ? 'items-end' : 'justify-end') : 'justify-between'
    )}
  >
    <div
      className={classNames(
        vertical
          ? 'flex flex-col items-start gap-2 text-3xl font-semibold text-gray-900'
          : 'flex items-baseline text-3xl font-semibold text-gray-900 gap-2',
        reverse && (vertical ? 'items-end' : 'flex-row-reverse')
      )}
    >
      <div>{stat}</div>
      {text && (
        <span
          className={
            vertical
              ? 'mt-1 text-sm font-medium text-gray-500'
              : 'text-sm font-medium text-gray-500'
          }
        >
          {text}
        </span>
      )}
    </div>
  </dd>
)

export const StatBoxSkeleton = () => {
  return (
    <div className="px-4 py-5 bg-white shadow-sm border border-gray-200 rounded-lg overflow-hidden sm:p-6 animate-pulse">
      <div className="h-5 bg-gray-200" />
      <div className="h-8 bg-gray-200 mt-2" />
    </div>
  )
}
