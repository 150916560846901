import { useMutation, UseQueryOptions, useQuery } from 'react-query'
import { CouponsApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new CouponsApi()

export const useCreateCoupon = () => {
  const siteId = useCurrentSiteId()

  return useMutation(
    async (coupon: any) => (await api.createCoupon({ ...coupon, site_id: siteId })).data
  )
}

export const useCoupon = (couponId?: string, options?: UseQueryOptions) =>
  useQuery<any>(['coupon', { couponId }], async () => await api.getCoupon(couponId), options)

export const useUpdateCoupon = () =>
  useMutation<any, any, any>(
    async ({ couponId, coupon }) => await api.updateCoupon(couponId, coupon)
  )

export const useCreateOfferingDiscount = () => {
  const siteId = useCurrentSiteId()

  return useMutation<any, any, any>(
    async ({ couponId, offeringDiscount }) =>
      (
        await api.createOfferingDiscount(couponId, {
          createOfferingDiscountRequest: {
            ...offeringDiscount,
            site_id: siteId,
          },
        })
      ).data
  )
}

export const useUpdateOfferingDiscount = () =>
  useMutation<any, any, any>(
    async ({ couponId, offeringDiscountId, offeringDiscount }) =>
      (await api.updateOfferingDiscount(couponId, offeringDiscountId, offeringDiscount)).data
  )
