/*
 * Adapted from Blueprint
 * https://github.com/palantir/blueprint/blob/e0dd35e8e65e1429ca8cb3fa84dd71740cbc1369/packages/core/src/components/spinner/spinner.tsx
 */

/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createElement } from 'react'
import classNames from 'classnames'
import './LoadingSpinner.css'

export enum SpinnerSize {
  xs = 10,
  sm = 20,
  md = 30,
  lg = 50,
  xl = 75,
}

interface SpinnerProps {
  size?: SpinnerSize | number
  className?: string
  variant?: string
  value?: number
  tagName?: string
}

// see http://stackoverflow.com/a/18473154/3124288 for calculating arc path
const R = 45
const SPINNER_TRACK = `M 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${R * 2} a ${R},${R} 0 1 1 0,-${R * 2}`

// unitless total length of SVG path, to which stroke-dash* properties are relative.
// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/pathLength
// this value is the result of `<path d={SPINNER_TRACK} />.getTotalLength()` and works in all browsers:
const PATH_LENGTH = 280

const MIN_SIZE = 10
const STROKE_WIDTH = 4
const MIN_STROKE_WIDTH = 16

export const LoadingSpinner: React.FC<SpinnerProps> = ({
  size = SpinnerSize.md,
  className,
  variant = 'default',
  value,
  tagName = 'div',
}) => {
  size = getSize(size)

  const classes = classNames('spinner', { 'spinner--no-spin': value != null }, className)

  const headClasses = classNames('spinner-head', {
    'stroke-default': variant === 'default',
    'stroke-info': variant === 'info',
    'stroke-danger': variant === 'danger',
    'stroke-warning': variant === 'warning',
  })

  // keep spinner track width consistent at all sizes (down to about 10px).
  const strokeWidth = Math.min(MIN_STROKE_WIDTH, (STROKE_WIDTH * SpinnerSize.md) / size)

  const strokeOffset = PATH_LENGTH - PATH_LENGTH * (value == null ? 0.25 : clamp(value, 0, 1))

  // multiple DOM elements around SVG are necessary to properly isolate animation:
  // - SVG elements in IE do not support anim/trans so they must be set on a parent HTML element.
  // - SPINNER_ANIMATION isolates svg from parent display and is always centered inside root element.
  return createElement(
    tagName,
    {
      className: classes,
      role: 'progressbar',
    },
    createElement(
      tagName,
      { className: 'animate-loading-spinner' },
      <svg
        width={size}
        height={size}
        strokeWidth={strokeWidth.toFixed(2)}
        viewBox={getViewBox(strokeWidth)}
      >
        <path className="spinner-track" d={SPINNER_TRACK} />
        <path
          className={headClasses}
          d={SPINNER_TRACK}
          pathLength={PATH_LENGTH}
          strokeDasharray={`${PATH_LENGTH} ${PATH_LENGTH}`}
          strokeDashoffset={strokeOffset}
        />
      </svg>
    )
  )
}

/**
 * Resolve size to a pixel value.
 */
const getSize = (size: any) => {
  if (isNaN(parseInt(size)) && size in SpinnerSize) {
    size = SpinnerSize[size]
  } else {
    size = parseInt(size)
  }

  return Math.max(MIN_SIZE, size)
}

/** Compute viewbox such that stroked track sits exactly at edge of image frame. */
const getViewBox = (strokeWidth: number) => {
  const radius = R + strokeWidth / 2
  const viewBoxX = (50 - radius).toFixed(2)
  const viewBoxWidth = (radius * 2).toFixed(2)

  return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`
}

/**
 * Clamps the given number between min and max values. Returns value if within
 * range, or closest bound.
 */
const clamp = (val: number, min: number, max: number) => {
  if (val == null) {
    return val
  }

  if (max < min) {
    throw new Error('Max must be greater than min')
  }

  return Math.min(Math.max(val, min), max)
}
