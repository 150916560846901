import { useGetRoom } from 'api/Rooms'
import { createContext, useContext } from 'react'

export const RoomContext = createContext()

export const RoomProvider = ({ id, room: object, children }) => {
  const value = useProvideRoom(id)

  return <RoomContext.Provider value={object ?? value}>{children}</RoomContext.Provider>
}

const useProvideRoom = (roomId) => {
  const { data: { data: room = {} } = {} } = useGetRoom(roomId, {
    enabled: !!roomId,
  })

  return room
}

export const withRoom =
  (Component) =>
  ({ id, ...props }) => (
    <RoomProvider id={id}>
      <Component {...props} />
    </RoomProvider>
  )

export const useRoom = () => useContext(RoomContext)
