import { useContext } from 'react'
import { Form, Formik } from 'formik'
import { ModalContext } from './contexts'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useDoSudo, useSendSudoRequestEmail } from 'api/Sudo'
import { Notification, useNotificationContext } from 'ui/components/Notification'
import { Input } from 'ui/components/Form/Input'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import AuthCode from 'react-auth-code-input'
import { useCurrentUserIsManaged } from 'hooks/UseCurrentUserIsManaged'

const ConnectedField = BaseConnectedField as any

export const SudoOverlay = () => {
  const { modal } = useContext(ModalContext)

  return modal.visible && <SudoWrapper />
}

const SudoWrapper = () => {
  const notification = useNotificationContext()
  const isManagedBySSO = useCurrentUserIsManaged()

  const { modal, onSudoError, onSudoSuccess } = useContext(ModalContext)
  const initialValues = {
    password: '',
    code: '',
  }

  const { mutate, isLoading } = useDoSudo()

  const {
    isLoading: isSending,
    isSuccess: hasSentCode,
    mutate: sendCode,
  } = useSendSudoRequestEmail()

  const handleOnSubmit = async (values) => {
    if (isManagedBySSO && !hasSentCode) {
      sendCode()

      return
    }

    // Strip out empty values
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key]
      }
    })

    mutate(values, {
      onSuccess: () => {
        onSudoSuccess()
      },
      onError: (error: any) => {
        onSudoError(error?.body?.message ?? '')

        notification.notify(
          <Notification
            variant="danger"
            title={'An error occurred'}
            description={"We couldn't authenticate you. Please try again."}
          />
        )
      },
    })
  }

  return (
    <Modal
      isOpen={modal.visible}
      onClose={() => modal.hide()}
      size="sm"
      title="Secure content"
      styleReset
      disallowClose={true}
    >
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ setFieldValue, submitForm }) => (
          <Form>
            <div className="space-y-4">
              <div className="text-gray-500 text-sm">
                In order to access this secured content, you'll need to confirm your identity first.
              </div>

              <div className="text-gray-500 font-medium text-sm mt-2">
                {isManagedBySSO && !hasSentCode && "We'll send you an email containing a code"}
                {isManagedBySSO && hasSentCode && "We've just sent you an email containing a code"}
                {!isManagedBySSO && 'Please enter your password'}
              </div>

              {isManagedBySSO && hasSentCode && (
                <div className="text-gray-500 text-sm mt-2">Enter your code to gain access</div>
              )}

              {isManagedBySSO && hasSentCode && (
                <div className="space-y-4">
                  <AuthCode
                    allowedCharacters="numeric"
                    onChange={(val) => {
                      setFieldValue('code', val)

                      if (val.length === 6) {
                        submitForm()
                      }
                    }}
                    inputClassName="text-2xl text-center border border-gray-300 rounded-md focus:ring-violet focus:ring-2 focus:ring-offset-2 shadow-sm focus:border-gray-300 dark:bg-gray-700 dark:border-transparent dark:text-white"
                    containerClassName="grid grid-cols-6 gap-2"
                  />
                </div>
              )}

              {!isManagedBySSO && (
                <ConnectedField
                  name="password"
                  id="password"
                  component={Input}
                  type="password"
                  placeholder="Password"
                  required
                />
              )}
            </div>
            <ModalButtons>
              <Button
                variant="primary"
                className="w-full"
                label={isManagedBySSO && !hasSentCode ? 'Send code' : 'Continue'}
                type="submit"
                loading={isLoading || isSending}
              />
            </ModalButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
