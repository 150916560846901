import { Form, Formik } from 'formik'
import { object, number } from 'yup'
import { useParams } from 'react-router-dom'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from '@trybeapp/ui.button'
import { useUpdateOrderItemPrice } from 'api/OrderItems'
import { ConnectedField } from 'components/ConnectedField'
import { useCurrentOrder } from 'contexts/Order'
import { FormikCurrencyInput } from 'ui/components/Form/Input'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'

export const EditItemPriceOverlay = ({ onCancel, isOpen = false, item }) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    title="Edit price"
    hideOnClickOutside={false}
    showHideIcon={false}
  >
    <EditPriceForm onCancel={onCancel} item={item} />
  </Modal>
)

const EditPriceForm = ({ onCancel, item }) => {
  const { orderId } = useParams()
  const { mutate, isLoading } = useUpdateOrderItemPrice()
  const notification = useNotificationContext()

  const initialValues = {
    price: item.price || '',
  }

  const itemBasePrice = item.base_price ?? item.price ?? item.unit_price

  const validationSchema = object({
    price: number()
      .min(itemBasePrice, 'Cannot be lower than the original price')
      .required('Price is required'),
  })

  const handleSubmit = (values) => {
    mutate([orderId, item.id, { price: values.price }], {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Price updated"
            description="The item price was successfully updated"
            variant="success"
            autoDismiss
          />
        )
        onCancel()
      },
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <PriceField />
        <ModalButtons>
          <Button variant="primary" type="submit" loading={isLoading}>
            Update
          </Button>
          <Button variant="ghost" ml="auto" onClick={onCancel}>
            Cancel
          </Button>
        </ModalButtons>
      </Form>
    </Formik>
  )
}

const PriceField = () => {
  const { order: { currency } = {} } = useCurrentOrder()

  return (
    <div className="flex">
      <div className="w-36 leading-10">New price</div>
      <div className="flex-1">
        <ConnectedField
          name="price"
          id="price"
          component={FormikCurrencyInput}
          type="text"
          placeholder="Price"
          required
          currency={currency}
          size="lg"
          maxWidth="200px"
        />
      </div>
    </div>
  )
}
