import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useMemo } from 'react'

const LoadingRow = () => (
  <div className="p-4 animate-pulse">
    <div className="w-32 h-5 bg-gray-100" />
    <div className="w-3/4 h-4 mt-3 bg-gray-100" />
  </div>
)

interface MiniListProps {
  /** Whether the contents of the list is loading */
  isLoading?: boolean
  /** Whether there was an error fetching the contents of the list */
  isError?: boolean
  /** An array of components to render for each row */
  rows?: React.ReactNode[]
  /** A message to display when the list is empty */
  emptyMessage?: string
  /** The minimum height of the list */
  minHeight?: number
}

export const MiniList: React.FC<MiniListProps> = ({
  isLoading = false,
  isError = false,
  rows = [],
  emptyMessage = 'No results',
  minHeight = 150,
}) => {
  const isEmpty = useMemo(() => {
    return !isLoading && !isError && rows.length === 0
  }, [isLoading, isError, rows])
  return (
    <ul
      style={{
        minHeight: minHeight ? minHeight : 150,
      }}
      className={`text-sm overflow-hidden border-gray-300 shadow-sm rounded-lg divide-y divide-gray-200 ${
        isError || isEmpty
          ? `flex border-2 border-dashed`
          : 'bg-white border dark:bg-gray-700 dark:border-transparent'
      }`}
    >
      {isLoading && (
        <>
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
          <LoadingRow />
        </>
      )}

      {isError && (
        <div className="self-center text-center w-full text-lg font-medium text-gray-600">
          <ExclamationCircleIcon className="w-8 h-8 mb-2 mx-auto" aria-hidden="true" />
          An error occurred when loading
        </div>
      )}

      {!isLoading && !isError && rows.length === 0 && (
        <div className="self-center text-center w-full text-lg font-medium text-gray-600">
          {emptyMessage}
        </div>
      )}

      {!isLoading && !isError && rows.map((row) => row)}
    </ul>
  )
}
