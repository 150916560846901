import { orderActionTypes, orderItemActionTypes } from '@trybeapp/ducks.inventory'
import { LISTEN } from './actionTypes'

const INITIAL_STATE = {
  deleted: false,
  deleting: false,
  error: false,
  updated: false,
  updating: false,
  orderItemdeleted: false,
  orderItemdeleting: false,
  fetchingOrder: false,
  fetchedOrder: false,
  submitting: false,
  submitted: false,
}

export const InventoryOrderDetailsReducer = (state = INITIAL_STATE, action) => {
  if (action.type !== LISTEN && !state.listen) {
    return state
  }

  switch (action.type) {
    case LISTEN:
      if (action.value) {
        return {
          ...state,
          listen: action.value,
        }
      }

      return { ...INITIAL_STATE }

    case orderActionTypes.GET_ORDER:
      return {
        ...state,
        error: null,
        fetchingOrder: true,
      }

    case orderActionTypes.GET_ORDER_ERROR:
      return {
        ...state,
        error: action.message,
        fetchingOrder: false,
      }

    case orderActionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        fetchedOrder: true,
        fetchingOrder: false,
      }

    case orderActionTypes.UPDATE_ORDER:
      return {
        ...state,
        error: null,
        updated: false,
        updating: true,
      }

    case orderActionTypes.UPDATE_ORDER_ERROR:
      return {
        ...state,
        error: action.message,
        updating: false,
      }

    case orderActionTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        updated: true,
        updating: false,
      }

    case orderActionTypes.DELETE_ORDER:
      return {
        ...state,
        error: null,
        deleted: false,
        deleting: true,
      }

    case orderActionTypes.DELETE_ORDER_ERROR:
      return {
        ...state,
        error: action.message,
        deleting: false,
      }

    case orderActionTypes.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        deleted: true,
        deleting: false,
      }

    case orderActionTypes.SUBMIT_ORDER:
      return {
        ...state,
        error: null,
        submitted: false,
        submitting: true,
      }

    case orderActionTypes.SUBMIT_ORDER_ERROR:
      return {
        ...state,
        error: action.message,
        submitting: false,
      }

    case orderActionTypes.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        submitted: true,
        submitting: false,
      }
    case orderActionTypes.RECEIVE_ORDER:
      return {
        ...state,
        receiveError: null,
        received: false,
        receiving: true,
      }

    case orderActionTypes.RECEIVE_ORDER_ERROR:
      return {
        ...state,
        receiveError: action.message,
        receiving: false,
      }

    case orderActionTypes.RECEIVE_ORDER_SUCCESS:
      return {
        ...state,
        received: true,
        receiving: false,
      }
    case orderItemActionTypes.DELETE_ORDER_ITEM:
      return {
        ...state,
        orderItemDeleting: true,
        orderItemDeleted: false,
      }

    case orderItemActionTypes.DELETE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        orderItemDeleting: false,
        orderItemDeleted: true,
      }

    case orderItemActionTypes.DELETE_ORDER_ITEM_ERROR:
      return {
        ...state,
        error: action.message,
        orderItemDeleting: false,
      }

    default:
      return state
  }
}
