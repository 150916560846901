import { createContext, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectBookingOrderItem,
  selectPurchaseOrderItem,
  selectPractitionerSummary,
} from 'selectors/OrdersSelectors'
import { useModalState } from '@trybeapp/ui.modal'
import { selectBookingSummary } from 'selectors/BookingSummarySelectors'
import { useGetRoom } from 'api/Rooms'
import { get, set } from 'lodash'

export const OrderDetailsContext = createContext()

export const OrderDetailsProvider = ({ value: initialValue = {}, children }) => {
  const [values, setValues] = useState(initialValue)

  // Get the value of `key` from values, with dot notation
  const getValue = (key, defaultValue) => {
    return get(values, key, defaultValue)
  }

  // Set the property at `key` to `value`, with dot notation
  const setValue = (key, value) => {
    const newValues = { ...values }
    set(newValues, key, value)
    setValues(newValues)
  }

  // Update multiple values at once
  const updateValues = (updateValues) => {
    const newValues = { ...values }

    for (let key in updateValues) {
      set(newValues, key, updateValues[key])
    }

    setValues(newValues)
  }

  const value = { values, setValues, getValue, setValue, updateValues }

  return <OrderDetailsContext.Provider value={value}>{children}</OrderDetailsContext.Provider>
}

export const useOrderDetails = () => useContext(OrderDetailsContext)

export const BookingOrderItemDetailsContext = createContext()

export const BookingOrderItemDetailsProvider = ({ itemId, children }) => {
  const value = useProvideBookingOrderItemDetails(itemId)

  return (
    <BookingOrderItemDetailsContext.Provider value={value}>
      {children}
    </BookingOrderItemDetailsContext.Provider>
  )
}

const useProvideBookingOrderItemDetails = (itemId) => {
  const item = useSelector(selectBookingOrderItem(itemId))
  const { booking_summary: bookingSummaryId } = item
  const bookingSummary = useSelector(selectBookingSummary(bookingSummaryId))
  const { practitioner: practitionerId, room: roomId } = bookingSummary
  const practitioner = useSelector(selectPractitionerSummary(practitionerId))
  const { data: { data: room = {} } = {} } = useGetRoom(roomId, {
    enabled: !!roomId,
  })

  return { item, bookingSummary, practitioner, room }
}

export const withBookingOrderItemDetails =
  (Component) =>
  ({ itemId, ...props }) => {
    return (
      <BookingOrderItemDetailsProvider itemId={itemId}>
        <Component {...props} />
      </BookingOrderItemDetailsProvider>
    )
  }

export const PurchaseOrderItemDetailsContext = createContext()

export const PurchaseOrderItemDetailsProvider = ({ itemId, children }) => {
  const value = useProvidePurchaseOrderItemDetails(itemId)

  return (
    <PurchaseOrderItemDetailsContext.Provider value={value}>
      {children}
    </PurchaseOrderItemDetailsContext.Provider>
  )
}

const useProvidePurchaseOrderItemDetails = (itemId) => {
  const item = useSelector(selectPurchaseOrderItem(itemId))

  return { item }
}

export const withPurchaseOrderItemDetails =
  (Component) =>
  ({ itemId, ...props }) => {
    return (
      <PurchaseOrderItemDetailsProvider itemId={itemId}>
        <Component {...props} />
      </PurchaseOrderItemDetailsProvider>
    )
  }

export const AddBookableItemModalContext = createContext()

export const AddBookableItemModalProvider = ({ children }) => {
  const value = useProvideAddBookableItemModal()

  return (
    <AddBookableItemModalContext.Provider value={value}>
      {children}
    </AddBookableItemModalContext.Provider>
  )
}

const useProvideAddBookableItemModal = () => {
  const modal = useModalState()

  return { modal }
}

export const withAddBookableItemModal =
  (Component) =>
  ({ ...props }) => {
    return (
      <AddBookableItemModalProvider>
        <Component {...props} />
      </AddBookableItemModalProvider>
    )
  }

export const AddGuestModalContext = createContext()

export const AddGuestModalProvider = ({ basketId, children }) => {
  const modal = useModalState()

  return (
    <AddGuestModalContext.Provider value={{ modal, basketId }}>
      {children}
    </AddGuestModalContext.Provider>
  )
}

export const withAddGuestModal =
  (Component) =>
  ({ ...props }) => {
    return (
      <AddGuestModalProvider>
        <Component {...props} />
      </AddGuestModalProvider>
    )
  }
