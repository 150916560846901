import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  useInfiniteQuery,
} from 'react-query'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import {
  CreateVisitTypeRequest,
  ListVisitTypesRequest,
  UpdateVisitTypeRequest,
  VisitTypeApi,
} from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new VisitTypeApi(apiConfiguration)

export const usePagedVisitTypes = (params: any = {}, options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['visitTypes', siteId, params],
    () => api.listVisitTypes({ siteId, ...params }),
    options
  )
}

export const useInfiniteVisitTypes = (params: ListVisitTypesRequest, options) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['infiniteVisitTypes', { params }], fetchVisitTypes, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage
    },
    ...options,
  })
}

const fetchVisitTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listVisitTypes({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useVisitType = (visitTypeId: string, options = {}) => {
  return useQuery(
    ['visitType', visitTypeId],
    () =>
      api.getVisitType({
        visitTypeId,
      }),
    options
  )
}

export const useCreateVisitType = (): UseMutationResult => {
  const siteId = useCurrentSiteId()

  return useMutation((visitType: CreateVisitTypeRequest) =>
    api.createVisitType({
      siteId,
      createVisitTypeRequest: visitType,
    })
  )
}

export const useUpdateVisitType = (): UseMutationResult => {
  const queryClient = useQueryClient()

  return useMutation(
    ([visitTypeId, visitType]: [string, UpdateVisitTypeRequest]) =>
      api.updateVisitType({
        visitTypeId,
        updateVisitTypeRequest: visitType,
      }),
    {
      onSuccess: (_, [visitTypeId]) => {
        queryClient.invalidateQueries(['visitType', visitTypeId])
      },
    }
  )
}

export const useDeleteVisitType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (visitTypeId: string) =>
      api.deleteVisitType({
        visitTypeId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['visitType'])
      },
    }
  )
}
