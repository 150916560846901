import { orderActionTypes } from '@trybeapp/ducks.inventory'
import { without } from 'lodash'
import { PAGINATION_META } from 'constants/paginationMeta'
import { RESET_STATE } from '../../actionTypes'
import { LISTEN } from './actionTypes'

export const INITIAL_STATE = {
  orderDeleted: false,
  ordersFetched: false,
  deleteError: null,
  paginationMeta: { ...PAGINATION_META },
  selectedIds: [],
  listen: false,
}

export const InventoryOrdersSectionReducer = (state = INITIAL_STATE, action) => {
  if (action.type !== LISTEN && !state.listen) {
    return state
  }

  switch (action.type) {
    case LISTEN:
      if (action.value) {
        return {
          ...state,
          listen: action.value,
        }
      }

      return { ...INITIAL_STATE }

    case orderActionTypes.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        ordersFetched: true,
        paginationMeta: action.meta,
        selectedIds: action.result,
      }

    case orderActionTypes.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        orderDeleted: true,
        selectedIds: without(state.selectedIds, action.orderId),
      }

    case orderActionTypes.DELETE_ORDER_ERROR:
      return {
        ...state,
        deleteError: action.message,
      }

    case RESET_STATE:
      return {
        orderDeleted: false,
        deleteError: null,
        paginationMeta: { ...PAGINATION_META },
        ...state,
      }

    default:
      return state
  }
}
