import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { PractitionersApi } from '@trybeapp/sdk.shop'
import { DateTime } from 'luxon'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new PractitionersApi()

export const usePractitioners = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = siteId

  return useQuery(['practitioners', params], () => api.practitionersIndex(params), options)
}

export const useAllPractitioners = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? Infinity

  return usePractitioners({ perPage: 1000 }, options)
}

export const usePagedPractitioners = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()
  const siteId = useCurrentSiteId()
  params.siteId = siteId

  return useQuery(
    ['practitioners', { siteId, page, params }],
    () => api.practitionersIndex({ siteId, page, ...params }),
    options
  )
}

export const useInfinitePractitioners = (
  params: any = {},
  options: UseInfiniteQueryOptions = {}
): UseInfiniteQueryResult => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useInfiniteQuery(
    ['infinitePractitioners', { params }],
    async (queryOptions) => {
      const { queryKey, pageParam: page = 1 } = queryOptions as any
      const { params } = queryKey[1]
      const response = await api.practitionersIndex({ page, ...params })

      return {
        data: response.data,
        page,
        nextPage: response.meta.last_page > page ? page + 1 : undefined,
        total: response.meta.total,
      }
    },
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextPage
      },
      ...options,
    }
  )
}

export const useGetPractitioner = (practitionerId, options = {}) => {
  return useQuery(
    ['practitioners', practitionerId],
    async () => await api.practitionerShow(practitionerId),
    options
  )
}

export const useOrderedPractitioners = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  options.staleTime = options.staleTime ?? 10 * 60 * 1000 // 10 minutes

  return useQuery(
    ['practitioners', siteId, params],
    async () => await api.getOrderedPractitioners(siteId, params),
    options
  )
}

export const useUpdateOrderedPractitioners = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (practitionerIds: any) =>
      api.updatePractitionerOrder({
        practitioner_ids: practitionerIds,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['practitioners'])
      },
    }
  )
}

export const useCreatePractitioner = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async (practitioner: any) =>
      await api.practitionerStore({ ...practitioner, site_ids: [siteId] }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('practitioners')
      },
    }
  )
}

export const useCopyPractitioner = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async ([practitionerId, practitioner]: [string, any]) => {
      practitioner.site_id = siteId

      return await api.practitionerCopy(practitionerId, { practitioner })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('practitioners')
      },
    }
  )
}

export const useUpdatePractitioner = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, any]) => {
      const [practitionerId, practitioner] = args
      return await api.practitionerUpdate(practitionerId, practitioner)
    },
    {
      onSuccess: (result, args) => {
        const [practitionerId] = args
        queryClient.invalidateQueries(['practitioners', practitionerId])
      },
    }
  )
}

export const useDeletePractitioner = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (practitionerId) => {
      return await api.practitionerDestroy(practitionerId)
    },
    {
      onSuccess: (_, practitionerId) => {
        queryClient.invalidateQueries('practitioners')
      },
    }
  )
}

export const useCreatePractitionerCustomAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, any]) => {
      const [practitionerId, rule] = args
      const newRule = { ...rule }
      newRule.date_time_from = DateTime.fromJSDate(rule.date_time_from).toISO({
        suppressMilliseconds: true,
      })
      newRule.date_time_to = DateTime.fromJSDate(rule.date_time_to).toISO({
        suppressMilliseconds: true,
      })

      return await api.customAvailabilityStore(practitionerId, newRule)
    },
    {
      onSuccess: (_, args) => {
        const [practitionerId] = args
        queryClient.invalidateQueries(['practitionerCustomAvailability', practitionerId])
        queryClient.invalidateQueries(['practitonerLeave', practitionerId])
      },
    }
  )
}

export const useUpdatePractitionerCustomAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (args: [string, string, any]) => {
      const [practitionerId, customAvailabilityId, rule] = args
      const updatedRule = { ...rule }
      updatedRule.date_time_from = DateTime.fromJSDate(rule.date_time_from).toISO({
        suppressMilliseconds: true,
      })
      updatedRule.date_time_to = DateTime.fromJSDate(rule.date_time_to).toISO({
        suppressMilliseconds: true,
      })

      return api.customAvailabilityUpdate(practitionerId, customAvailabilityId, updatedRule)
    },
    {
      onSuccess: (_, args) => {
        const [practitionerId] = args
        queryClient.invalidateQueries(['practitionerCustomAvailability', practitionerId])
        queryClient.invalidateQueries(['practitonerLeave', practitionerId])
      },
    }
  )
}

export const useDeletePractitionerCustomAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [string, string]) => {
      const [practitionerId, customAvailabilityId] = args
      return await api.customAvailabilityDestroy(practitionerId, customAvailabilityId)
    },
    {
      onSuccess: (_, args) => {
        const [practitionerId, customAvailabilityId] = args
        queryClient.invalidateQueries([
          'practitionerCustomAvailability',
          practitionerId,
          customAvailabilityId,
        ])
        queryClient.invalidateQueries(['practitonerLeave', practitionerId])
      },
    }
  )
}

export const usePractitionerScheduledAvailability = (params: any = {}, options = {}) => {
  if (Array.isArray(params.practitionerIds)) {
    params.practitionerIds = params.practitionerIds.join(',')
  }

  if (params.dateFrom !== undefined) {
    params.dateFrom = toIsoDate(params.dateFrom)
  }

  if (params.dateTo !== undefined) {
    params.dateTo = toIsoDate(params.dateTo)
  }

  return useQuery(
    ['practitionerScheduledAvailability', params],
    () => api.practitionerScheduledAvailabilityIndex(params),
    options
  )
}
