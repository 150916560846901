import { AddGuestOverlayButton, AddOrderGuestOverlay } from 'components/AddOrderGuestOverlay'
import { ErrorMessage, useField } from 'formik'
import { filter } from 'lodash'
import { Box } from '@trybeapp/ui.box'
import { Hint } from 'ui/components/Hint'
import { Alert } from '@trybeapp/ui.alert'
import { useGetOrder } from 'api/Orders'
import { Pill } from 'components/Pill'
import { Text } from 'components/Text'
import { GuestProvider, useGuest } from 'contexts/Guest'

export const GuestSelection = ({
  orderId,
  value: guestIds = [],
  max = 1,
  onChange,
  inFormik = false,
  disabled = false,
  ...props
}) => {
  const handleGuestAdded = (guest) => {
    if (max === null || guestIds.length < max) {
      onChange([...guestIds, guest.id])
    }
  }

  return (
    <>
      <GuestSelectionList
        {...props}
        orderId={orderId}
        guestIds={guestIds}
        max={max}
        onChange={onChange}
        inFormik={inFormik}
        disabled={disabled}
      />
      <AddOrderGuestOverlay onChange={handleGuestAdded} orderId={orderId} />
    </>
  )
}

export const FormikGuestSelection = ({ onChange: parentOnChange, name, ...props }) => {
  const [{ value }, , { setValue }] = useField(name)

  return (
    <GuestSelection
      value={value}
      onChange={(value) => setValue(value)}
      inFormik={true}
      {...props}
    />
  )
}

const GuestSelectionList = ({
  orderId,
  max = 1,
  onChange,
  guestIds = [],
  addingItemText = 'an appointment',
  inFormik = false,
  disabled = false,
}) => {
  const { data: { data: order = {} } = {} } = useGetOrder(orderId, {
    enabled: !!orderId,
  })
  const { guests = [] } = order
  const hasGuests = guests.length > 0

  if (!hasGuests) {
    const { first_name: firstName = '', last_name: lastName } = order

    const name = firstName || lastName ? filter([firstName, lastName]).join(' ') : 'Guest 1'

    return (
      <Box mb="lg">
        <Alert variant="info" mb="sm">
          You are adding {addingItemText} for <strong>{name}</strong>
        </Alert>
        <AddGuestOverlayButton orderId={orderId} />
        {inFormik && (
          <ErrorMessage name="guestId">
            {(error) => <Hint variant="error">{error}</Hint>}
          </ErrorMessage>
        )}
      </Box>
    )
  }

  const handleGuestSelect = (guestId) => {
    const currentlySelected = isGuestSelected(guestId)

    if (max === 1 && currentlySelected) {
      onChange([])
    } else if (currentlySelected) {
      onChange(guestIds.filter((id) => id !== guestId))
    } else if (max === 1) {
      onChange([guestId])
    } else {
      onChange([...guestIds, guestId])
    }
  }

  const isGuestSelected = (guestId) => guestIds.indexOf(guestId) >= 0

  return (
    <Box mb="lg">
      <Text mb="sm">Select guest{max !== 1 && 's'}:</Text>
      {guests.map((guest) => (
        <GuestProvider guest={guest} key={guest.id}>
          <GuestSelect
            onClick={handleGuestSelect}
            selected={isGuestSelected(guest.id)}
            disabled={disabled || (max > 1 && guestIds.length >= max && !isGuestSelected(guest.id))}
          />
        </GuestProvider>
      ))}
      {inFormik && (
        <ErrorMessage name="guestId">
          {(error) => <Hint variant="error">{error}</Hint>}
        </ErrorMessage>
      )}
      <AddGuestOverlayButton orderId={orderId} />
    </Box>
  )
}

const GuestSelect = ({ onClick, selected, disabled }) => {
  const { name, id: guestId } = useGuest()

  return (
    <Pill selected={selected} disabled={disabled} onClick={() => onClick(guestId)} width={1}>
      {name}
    </Pill>
  )
}
