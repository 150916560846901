import { ApiClient as ShopApiClient } from '@trybeapp/sdk.shop'
import { ApiClient as InventoryApiClient } from '@trybeapp/sdk.inventory'
import { ApiClient as AuthClient } from '@trybeapp/sdk.auth'
import { ApiClient as CustomerClient } from '@trybeapp/sdk.customer'
import cookie from 'react-cookies'
import { useAuth } from 'react-oidc-context'
import { Configuration, ConfigurationParameters } from '@trybeapp/sdk'
import { authMiddleware } from './AuthMiddleware'
import { DateTime } from 'luxon'

const baseConfiguration: ConfigurationParameters = {
  basePath: `${import.meta.env.VITE_APP_BASE_API_URL}`,
  credentials: 'include',
  middleware: [authMiddleware],
}

export const apiConfiguration = new Configuration({
  ...baseConfiguration,
  headers: {
    ...baseConfiguration.headers,
    Accept: 'application/json',
  },
})
export const nonJsonApiConfiguration = new Configuration(baseConfiguration)

export const shopClient = ShopApiClient.instance
shopClient.basePath = `${import.meta.env.VITE_APP_BASE_API_URL}/shop`
shopClient.enableCookies = true

export const inventoryClient = InventoryApiClient.instance
inventoryClient.basePath = `${import.meta.env.VITE_APP_BASE_API_URL}/inventory`

export const customerClient = CustomerClient.instance
customerClient.basePath = `${import.meta.env.VITE_APP_BASE_API_URL}/customers`
customerClient.enableCookies = true

export const authClient = AuthClient.instance
authClient.basePath = `${import.meta.env.VITE_APP_BASE_API_URL}`
authClient.enableCookies = true

export const useTrybeApiSetup = () => {
  const { user } = useAuth()

  const unauthorizedHandler = (request) => {
    request.on('error', (error) => {
      if (
        error &&
        error?.response?.status === 403 &&
        error?.response?.body.message === 'Sudo cookie is invalid'
      ) {
        cookie.remove('sudo')
      }
    })
  }

  const versionHandler = (request) => {
    const version =
      (import.meta.env.VITE_APP_VERSION && import.meta.env.VITE_APP_VERSION.substring(0, 8)) ||
      'LOCAL'
    const environment = import.meta.env.VITE_APP_PROJECT_ENVIRONMENT ?? 'LOCAL'

    request.header['X-Trybe-App-Version'] = version
    request.header['X-Trybe-App-Environment'] = environment
  }

  if (user && user.access_token) {
    shopClient.authentications['BearerAuth'].accessToken = user.access_token
    inventoryClient.authentications['BearerAuth'].accessToken = user.access_token
    customerClient.authentications['TrybeUser'].accessToken = user.access_token
    authClient.authentications['BearerAuth'].accessToken = user.access_token

    shopClient.plugins = [unauthorizedHandler, versionHandler]
    inventoryClient.plugins = [unauthorizedHandler, versionHandler]
    customerClient.plugins = [unauthorizedHandler, versionHandler]
    authClient.plugins = [unauthorizedHandler, versionHandler]
    authMiddleware.setToken(user.access_token)
  }

  return null
}

// Dates eg from our datepicker are formatted in the browser's local time,
// but JS uses UTC when converting to an ISO Date string (YYYY-MM-DD).
// This method converts the given date to UTC without converting the time,
// so we can pass what the user expected
export const formatDateForApi = (date) => {
  if (!date) {
    return date
  }

  return DateTime.fromJSDate(date).setZone('UTC', { keepLocalTime: true }).toJSDate()
}

export const formatDatesForApi = (params, dateParams) => {
  const formatted = { ...params }

  dateParams.forEach((param) => {
    if (formatted[param]) {
      formatted[param] = formatDateForApi(formatted[param])
    }
  })

  return formatted
}

export default useTrybeApiSetup
