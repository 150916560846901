import { formatMoney } from 'ui/components/Money'
import { Modal, ModalButtons } from 'ui/Modal'
import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import { Button } from 'ui/components/Button'
import { useAddMembershipChargePayment } from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { classNames } from 'ui/utilities'
import { useCustomPaymentTypes } from 'api/CustomPaymentTypes'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useBillingPeriod } from './Memberships'

/**
 * This modal is used to record a payment against a specific membership charge,
 * as opposed to PaymentModal which adds a payment against the membership and
 * attempts to increment the next billing date.
 */
export const ChargePaymentModal = ({ charge, onClose, isOpen }) => {
  const billingPeriod = useBillingPeriod(charge)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const { isLoading, mutate } = useAddMembershipChargePayment()
  const notifications = useNotificationContext()

  if (!isOpen) {
    return null
  }

  const formattedAmount = formatMoney({
    amount: charge.amount,
    currency: charge.currency,
  })

  const handleSubmit = () => {
    mutate(
      [
        charge.membership.id,
        charge.id,
        {
          payment_type_id: paymentMethod,
        },
      ],
      {
        onSuccess: () => {
          onClose()
          notifications.notify(
            <Notification
              title="Payment recorded"
              description="The payment was successfully recorded"
              variant="success"
              autoDismiss
            />
          )
        },
        onError: (e: any) => {
          notifications.notify(<ApiErrorNotification error={e} />)
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Record payment" styleReset>
      {charge !== null && (
        <div className="space-y-4 text-sm text-gray-500">
          <p>
            You're recording a manual payment of{' '}
            <strong className="font-medium text-gray-700">{formattedAmount}</strong> to this charge
            for {billingPeriod}.
          </p>

          <p className="font-medium text-gray-700">How is this payment being made?</p>

          <PaymentButtons value={paymentMethod} onChange={setPaymentMethod} />

          <p>
            By clicking{' '}
            <strong className="font-medium text-gray-700">Record {formattedAmount}</strong> the
            charge will be marked as paid and any due credits will be issued.
          </p>

          <ModalButtons>
            <Button
              variant="primary"
              label={`Record ${formattedAmount}`}
              onClick={handleSubmit}
              loading={isLoading}
              disabled={paymentMethod === null}
            />
            <Button label="Cancel" onClick={onClose} />
          </ModalButtons>
        </div>
      )}
    </Modal>
  )
}

const PaymentButtons = ({ onChange, value }) => {
  const siteId = useCurrentSiteId()
  const { isLoading, data: { data: paymentTypes = [] } = {} } = useCustomPaymentTypes(siteId)

  if (isLoading) {
    return (
      <div className="animate-pulse border border-gray-100 rounded-md divide-y divide-gray-100">
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
      </div>
    )
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className="sr-only">Payment method</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {paymentTypes.map(({ id, name }, index) => (
          <RadioGroup.Option
            key={id}
            value={id}
            className={({ checked }) =>
              classNames(
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === paymentTypes.length - 1 ? 'rounded-b-md' : '',
                checked ? 'bg-violet-50 border-violet-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-violet border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-violet' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label as="span" className={'text-gray-900 block text-sm font-medium'}>
                    {name}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
