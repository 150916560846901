interface KeyCommandProps {
  className?: string
  children: string
}

export const KeyCommand: React.FC<KeyCommandProps> = (props) => (
  <span
    className={`${props.className} inline-block h-[21px] font-medium leading-relaxed px-1 text-xs bg-gray-50 text-gray-500 rounded border border-gray-300`}
  >
    {props.children}
  </span>
)
