import { createContext, useContext, useEffect, useState } from 'react'
import { useModalState } from '@trybeapp/ui.modal'

export const TYPE_SELECTION_STAGE = 'type'
export const TIMES_STAGE = 'times'
export const DEFAULT_STAGE = TYPE_SELECTION_STAGE
export const ALL_STAGES = [TYPE_SELECTION_STAGE, TIMES_STAGE]

const withStageProvider =
  (Component) =>
  ({ id, ...props }) => (
    <StageProvider>
      <Component {...props} />
    </StageProvider>
  )

const StageContext = createContext()

const StageProvider = ({ children }) => {
  const [stage, setStage] = useState(DEFAULT_STAGE)

  return <StageContext.Provider value={{ stage, setStage }}>{children}</StageContext.Provider>
}

export const useStage = () => useContext(StageContext)

export const AddAreaBookingOrderItemOverlayModalContext = createContext()

export const AddAreaBookingOrderItemOverlayModalProvider = withStageProvider(({ children }) => {
  const modal = useModalState()
  const { stage, setStage } = useStage()

  useEffect(() => {
    if (stage !== DEFAULT_STAGE && !modal.visible) {
      setStage(DEFAULT_STAGE)
    }
  }, [modal.visible, setStage, stage])

  return (
    <OrderItemProvider>
      <AddAreaBookingOrderItemOverlayModalContext.Provider value={{ modal }}>
        {children}
      </AddAreaBookingOrderItemOverlayModalContext.Provider>
    </OrderItemProvider>
  )
})

export const useAddAreaBookingOrderItemOverlay = () =>
  useContext(AddAreaBookingOrderItemOverlayModalContext)

export const OrderItemContext = createContext()

export const OrderItemProvider = ({ children }) => {
  const [orderItem, setOrderItem] = useState({})

  return (
    <OrderItemContext.Provider value={{ orderItem, setOrderItem }}>
      {children}
    </OrderItemContext.Provider>
  )
}

export const useOrderItem = () => useContext(OrderItemContext)
