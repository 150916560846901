export const PROCESSOR_MANUAL = 'manual'
export const PROCESSOR_PARTNER = 'partner'
export const PROCESSOR_ADYEN = 'adyen'
export const PROCESSOR_STRIPE = 'stripe'
export const PROCESSOR_VOUCHER = 'voucher'
export const PROCESSOR_EXTERNAL_VOUCHER = 'external_voucher'
export const PROCESSOR_PMS_FOLIO = 'pms_folio'

export const DISPLAY_NAMES = {
  [PROCESSOR_MANUAL]: 'Manual',
  [PROCESSOR_PARTNER]: 'Partner',
  [PROCESSOR_STRIPE]: 'Stripe',
  [PROCESSOR_ADYEN]: 'Trybe Pay',
  [PROCESSOR_VOUCHER]: 'Trybe Vouchers',
  [PROCESSOR_EXTERNAL_VOUCHER]: 'Vouchers integration',
  [PROCESSOR_PMS_FOLIO]: 'Room charge',
}
