import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { OrdersApi } from '@trybeapp/sdk.shop'

const api = new OrdersApi()

export const useGetOrderGuests = (
  orderId: string,
  options: UseQueryOptions = {}
): UseQueryResult => {
  return useQuery(
    ['orderGuests', orderId],
    async () => await api.orderGuestsIndex(orderId),
    options
  )
}

export const useAddOrderGuest = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [String, any]) => {
      const [orderId, guest] = args
      return await api.orderGuestStore(orderId, guest)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderGuests', orderId])
      },
    }
  )
}

export const useUpdateOrderGuest = (): UseMutationResult => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [String, any, any]) => {
      const [orderId, guestId, updateObj] = args
      return await api.orderGuestUpdate(orderId, guestId, updateObj)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderGuests', orderId])
      },
    }
  )
}

export const useDeleteOrderGuest = (): UseMutationResult => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: [String, any]) => {
      const [orderId, guestId] = args
      return await api.orderGuestDestroy(orderId, guestId)
    },
    {
      onSuccess: (_, args) => {
        const [orderId] = args
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderGuests', orderId])
      },
    }
  )
}
