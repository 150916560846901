import { RadioGroup } from '@headlessui/react'
import { DatePicker } from 'ui/components/Form/DatePicker'
import { classNames } from 'ui/utilities'
import { Input } from 'ui/components/Form/Input'
import { DateTime } from 'luxon'

interface CustomDeliveryDateSelectProps {
  dateValue: Date
  onChange: (date: Date) => void
  minDate?: Date
  disabled?: boolean
  timeValue?: Date
  onTimeChange?: (time: string) => void
  specifyTime?: boolean
  sendImmediately: boolean
  onSendImmediatelyChange: (val: boolean) => void
}

export const CustomDeliveryDateSelect: React.FC<CustomDeliveryDateSelectProps> = ({
  dateValue,
  onChange,
  minDate,
  disabled = false,
  timeValue,
  onTimeChange,
  specifyTime = false,
  sendImmediately,
  onSendImmediatelyChange,
}) => {
  return (
    <RadioGroup
      value={sendImmediately}
      onChange={(value) => onSendImmediatelyChange(value)}
      disabled={disabled}
    >
      <RadioGroup.Label className="sr-only">Delivery Timing</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {[
          { name: 'Send manually', value: true },
          { name: 'Schedule send', value: false },
        ].map(({ value, name }, index) => (
          <RadioGroup.Option
            key={index}
            value={value}
            className={({ checked }) =>
              classNames(
                index === 0 ? 'rounded-t-md' : '',
                index === 1 ? 'rounded-b-md' : '',
                checked ? 'bg-violet-50 border-violet-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-violet border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-violet' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label as="span" className={'text-gray-900 block text-sm font-medium'}>
                    {name}
                  </RadioGroup.Label>
                  {checked && !value && (
                    <div className="mt-2 grid auto-cols-auto grid-flow-col space-x-2 items-center">
                      <DatePicker
                        value={dateValue}
                        minDate={minDate}
                        onChange={(date: Date) => onChange(date)}
                      />
                      {specifyTime && (
                        <>
                          <span>at</span>
                          <Input
                            name="send_time"
                            type="time"
                            label="time"
                            hideLabel={true}
                            value={
                              timeValue ? DateTime.fromJSDate(timeValue).toFormat('HH:mm') : ''
                            }
                            onChange={(event) => {
                              onTimeChange(event.target.value)
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
