import { useParams } from 'react-router-dom'
import { Text } from '@trybeapp/ui.text'
import { STATUS_CANCELLED } from 'constants/orderStatuses'
import { Col, Row } from '@trybeapp/ui.grid'
import { Card } from '../../../components/Card'
import { StatusTag } from '../Overview'
import {
  useAddProductOrderItemOverlay,
  useNewItem,
} from 'components/AddProductOrderItemOverlay/contexts'
import { useDeleteOrderItem } from 'api/OrderItems'
import { CostColumn } from '../Columns'
import { ItemActions } from './ItemActions'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon } from '@heroicons/react/outline'
import { useOrderIsSettled } from '../hooks'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useOrderDetails } from 'screens/OrderDetails/contexts'

export const ProductItemRow = ({ item }) => {
  const { orderId } = useParams()
  const orderIsCheckedOut = useOrderIsSettled()
  const featureFlags = useActiveFeatureFlags()
  const { id: itemId, type_name: name = '', quantity } = item
  const status = item.status
  const itemIsCancelled = status === STATUS_CANCELLED
  const canModify = !itemIsCancelled && !orderIsCheckedOut

  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <Col col={0.7} mb="lg">
            <Text as="span" variant="body3" color="nude.700">
              <strong>{name}</strong>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col col={0.13} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Status
            </Text>
          </Col>
          <Col col={0.27} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Quantity
            </Text>
          </Col>
          <Col col={0.3} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Product
            </Text>
          </Col>
          <Col col={0.15} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Total
            </Text>
          </Col>
          <Col col={0.1} mb="xs"></Col>
        </Row>
        <Row>
          <Col col={0.13}>
            <StatusTag status={status} />
          </Col>
          <Col col={0.27}>
            <Text variant="body2" color="nude.900" lineHeight={1.2}>
              {quantity}
            </Text>
          </Col>
          <Col col={0.3}>
            <Text variant="body2" color="nude.900" lineHeight={1.2}>
              {name}
            </Text>
          </Col>
          <CostColumn item={item} />
          <Col col={0.1}>
            <ItemActions item={item} handleDelete={handleDelete} isDeleting={isDeleting}>
              {canModify && !featureFlags.includes('add_product_wizard') && (
                <EditItemButton item={item} />
              )}
              {canModify && featureFlags.includes('add_product_wizard') && (
                <EditItemButtonNew item={item} />
              )}
            </ItemActions>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const EditItemButton = ({ item }) => {
  const { modal } = useAddProductOrderItemOverlay()
  const { setNewItem } = useNewItem()
  const handleOnClick = () => {
    setNewItem({
      ...item,
    })
    modal.show()
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}

const EditItemButtonNew = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('productWizard.editingProduct', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}
