import { useMutation } from 'react-query'
import { SudoApi } from '@trybeapp/sdk.auth'

const api = new SudoApi()

interface SudoRequestValues {
  password?: string
  code?: string
}

export const useDoSudo = () => {
  return useMutation(async (values: SudoRequestValues) => await api.sudo(values))
}

export const useSendSudoRequestEmail = () => useMutation(() => api.sudoEmailRequest())
