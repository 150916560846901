import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CheckInsApi } from '@trybeapp/sdk.customer'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new CheckInsApi()

export const useCheckIn = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ([customerId, vals]: [string, { [key: string]: string }]) =>
      api.createCheckIn({
        createCheckInRequest: {
          customer_id: customerId,
          site_id: siteId,
          ...vals,
        },
      }),
    {
      onSuccess: ({ data: checkIn }) => {
        queryClient.invalidateQueries(['checkIns', { customerId: checkIn.customer.id, siteId }])
        queryClient.invalidateQueries(['checkIns', { siteId }])
      },
    }
  )
}

export const useListCheckIns = (customerId?: string, siteId?: string, options = {}) =>
  useQuery(
    ['checkIns', { customerId, siteId }],
    () => api.listCheckIns({ customerId, siteId }),
    options
  )

export const useListAllCheckIns = (params = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(['checkIns', { siteId, ...params }], () =>
    api.listCheckIns({ siteId, ...params })
  )
}

export const useListAllCheckInsQueued = (options = {}) => {
  const siteId = useCurrentSiteId()

  return useMutation((filters: any) => api.queueListCheckIns({ siteId, ...filters }), options)
}
