import { MembershipChargesApi } from '@trybeapp/sdk.customer'
import { MembershipChargesApi as MembershipChargesApiTs } from '@trybeapp/sdk'
import { DateTime } from 'luxon'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const membershipChargesApi = new MembershipChargesApi()
const membershipChargesApiTs = new MembershipChargesApiTs(apiConfiguration)

export const useChargesForMembership = (membershipId: string, params = {}, options = {}) =>
  useQuery(
    ['chargesForMembership', { membershipId, ...params }],
    async () => membershipChargesApiTs.listMembershipCharges({ membershipId, ...params }),
    options
  )

export const useCharges = (params: any = {}) => {
  const siteId = useCurrentSiteId()

  if (params.dateTo !== undefined) {
    params.dateTo = DateTime.fromJSDate(params.dateTo).endOf('day').toJSDate()
  }

  return useQuery(['membershipCharges', { siteId, ...params }], async () =>
    membershipChargesApi.listMembershipCharges({ siteId, ...params })
  )
}

export const useExportCharges = () => {
  const siteId = useCurrentSiteId()

  return useMutation(['exportMembershipCharges'], (params: any) => {
    if (params.dateTo !== undefined) {
      params.dateTo = DateTime.fromJSDate(params.dateTo).endOf('day').toJSDate()
    }

    return membershipChargesApi.queueListMembershipCharges({
      siteId,
      ...params,
    })
  })
}

export const useDownloadChargeReceipt = () =>
  useMutation((chargeId: string) => membershipChargesApi.getReceipt(chargeId))

export const useRefundMembershipCharge = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ chargeId, amount }: any) =>
      membershipChargesApi.refundMembershipCharge(chargeId, {
        refundMembershipChargeRequest: {
          amount,
        },
      }),
    {
      onSuccess: (e: any) => {
        const membershipId = e.data?.membership.id
        queryClient.invalidateQueries(['chargesForMembership', { membershipId }])
      },
    }
  )
}
