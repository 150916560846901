import { TYPE_APPOINTMENT, TYPE_SESSION } from 'constants/offeringTypes'
import { BookingSummaryProvider, useBookingSummary } from 'contexts/BookingSummary'
import { PractitionerProvider } from 'contexts/Practitioner'
import { RoomProvider } from 'contexts/Room'
import { createContext, useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectPackageOrderItem, selectPackageItem } from 'selectors/OrdersSelectors'

export const PackageOrderItemContext = createContext()

export const PackageOrderItemProvider = ({ id, children }) => {
  const value = useSelector(selectPackageOrderItem(id)) || {}

  return (
    <PackageOrderItemContext.Provider value={value}>{children}</PackageOrderItemContext.Provider>
  )
}

export const PackageItemContext = createContext()

export const PackageItemProvider = ({ id, children }) => {
  const value = useSelector(selectPackageItem(id)) || {}

  return (
    <PackageItemContext.Provider value={value}>
      <WithPackageItemResources>{children}</WithPackageItemResources>
    </PackageItemContext.Provider>
  )
}

export const withPackageItem =
  (Component) =>
  ({ id, ...props }) => (
    <PackageItemProvider id={id}>
      <Component {...props} />
    </PackageItemProvider>
  )

export const usePackageItem = () => useContext(PackageItemContext)

const WithPackageItemResources = ({ children }) => {
  const { offering_type: type, booking_summary: bookingSummary } = usePackageItem()

  const TypeComponent = useCallback(() => {
    switch (type) {
      case TYPE_SESSION:
      case TYPE_APPOINTMENT:
        return (
          <BookingSummaryProvider id={bookingSummary}>
            <WithResources>{children}</WithResources>
          </BookingSummaryProvider>
        )
      default:
        return children
    }
  }, [bookingSummary, children, type])

  return <TypeComponent>{children}</TypeComponent>
}

const WithResources = ({ children }) => {
  const { practitioner: practitionerId, room: roomId } = useBookingSummary() || {}

  return (
    <PractitionerProvider id={practitionerId}>
      <RoomProvider id={roomId}>{children}</RoomProvider>
    </PractitionerProvider>
  )
}

export const withPackageOrderItem =
  (Component) =>
  ({ id, packageOrderItem, ...props }) => (
    <PackageOrderItemProvider id={id} packageOrderItem={packageOrderItem}>
      <Component {...props} />
    </PackageOrderItemProvider>
  )

export const usePackageOrderItem = () => useContext(PackageOrderItemContext)
