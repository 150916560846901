import { useGetOrder } from 'api/Orders'
import { createContext, memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { selectOrder } from 'selectors/OrdersSelectors'
import { useQueryParam } from 'use-query-params'

export const OrderContext = createContext()

export const OrderProvider = memo(
  ({ order: object, children }) => (
    <OrderContext.Provider value={object}>{children}</OrderContext.Provider>
  ),
  (previousProps, nextProps) =>
    JSON.stringify(previousProps.order) === JSON.stringify(nextProps.order)
)

export const ReduxOrderContext = createContext()

/**
 * @deprecated
 */
export const ReduxOrderProvider = ({ id, children }) => {
  const value = useSelector(selectOrder(id)) || {}

  return <ReduxOrderContext.Provider value={value}>{children}</ReduxOrderContext.Provider>
}

/**
 * Try to get the order ID from:
 * 1. Route params, or
 * 2. The `orderId` query param
 * 3. The ID of the order from the order details context
 **/
export const useActiveOrderId = () => {
  const { orderId: paramsOrderId } = useParams()
  const [queryOrderId] = useQueryParam('orderId')
  const { values: { orderId: contextOrderId } = {} } = useOrderDetails() ?? {}

  return paramsOrderId ?? queryOrderId ?? contextOrderId
}

export const useCurrentOrder = () => {
  const query = useGetOrder(useActiveOrderId())

  return { order: query.data?.data, ...query }
}

export const useOrder = () => useContext(OrderContext)
export const useReduxOrder = () => useContext(ReduxOrderContext)
