export const STATUS_CONFIRMED = 'confirmed'
export const STATUS_SUBMITTED = 'submitted'
export const STATUS_SETTLED = 'settled'
export const STATUS_IN_PROGRESS = 'in_progress'
export const STATUS_RESERVED = 'reserved'
export const STATUS_PENDING_CONFIRMATION = 'pending_confirmation'
export const STATUS_RESERVATION_EXPIRED = 'reservation_expired'
export const STATUS_NEW = 'new'
export const STATUS_CANCELLED = 'cancelled'
export const STATUS_WAITLISTED = 'waitlisted'
export const STATUS_NO_SHOW = 'no_show'

export const ORDER_STATUSES = [
  {
    value: STATUS_NEW,
    label: 'New',
  },
  {
    value: STATUS_NO_SHOW,
    label: 'No show',
  },
  {
    value: STATUS_IN_PROGRESS,
    label: 'In progress',
  },
  {
    value: STATUS_SUBMITTED,
    label: 'Submitted',
  },
  {
    value: STATUS_SETTLED,
    label: 'Settled',
  },
  {
    value: STATUS_CANCELLED,
    label: 'Cancelled',
  },
]

export const FINALISED_ORDER_STATUSES = [STATUS_NO_SHOW, STATUS_CANCELLED, STATUS_SETTLED]
