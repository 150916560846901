import { SpinnerOverlay } from 'components/SpinnerOverlay'
import { SudoOverlay } from 'components/SudoOverlay'
import { ModalProvider } from 'components/SudoOverlay/contexts'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { Sidebar as NewSidebar } from './NewSidebar'
import { Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { GlobalHeader } from 'ui/components/GlobalHeader'
import classNames from 'classnames'
import { useWindowWidth } from 'hooks/UseWindowWidth'
import { SidebarContext } from './contexts'
import cookies from 'react-cookies'

const SidebarProvider = ({ children }) => {
  const [_minimised, _setMinimised] = useState(() => {
    return cookies.load('sidebarMinimised') === 'true'
  })

  const [previousPages, setPreviousPages] = useState([])
  const [title, setTitle] = useState(null)
  const width = useWindowWidth()

  useEffect(() => {
    _setMinimised(cookies.load('sidebarMinimised') === 'true')
  }, [])

  const minimised = useMemo(() => {
    // If the width is less than 768 (md), always return false
    if (width < 768) {
      return false
    }

    return _minimised
  }, [width, _minimised])

  const setMinimised = (state) => {
    _setMinimised(state)
    cookies.save('sidebarMinimised', state, {
      path: '/',
    })
  }

  return (
    <SidebarContext.Provider
      value={{
        minimised,
        setMinimised,
        setTitle,
        title,
        setPreviousPages,
        previousPages,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

const SidebarLayout = ({ children }) => {
  const contentSectionClasses = classNames(
    'flex flex-col w-full flex-1 relative h-full overflow-hidden style-reset'
  )

  return (
    <div className="overflow-hidden h-screen flex flex-col md:flex-row bg-gray-50 dark:bg-gray-800">
      <SidebarProvider>
        <QueryParamProvider ReactRouterRoute={Route}>
          <NewSidebar />

          <div className={contentSectionClasses}>
            <GlobalHeader />
            <ModalProvider>
              <div className="overflow-y-auto">
                <Suspense fallback={<SpinnerOverlay />}>{children}</Suspense>
              </div>
              <SudoOverlay />
            </ModalProvider>
          </div>
        </QueryParamProvider>
      </SidebarProvider>
    </div>
  )
}

export default SidebarLayout
