export const TYPE_APPOINTMENT = 'appointment'
export const TYPE_APPOINTMENT_ENQUIRY = 'appointment_enquiry'
export const TYPE_AREA_BOOKING = 'area_booking'
export const TYPE_COURSE = 'course'
export const TYPE_MEMBERSHIP = 'membership'
export const TYPE_PACKAGE = 'package'
export const TYPE_TABLE_RESERVATION = 'table_reservation'
export const TYPE_PRODUCT = 'product'
export const TYPE_ROOM_RESERVATION = 'room_reservation'
export const TYPE_HOTEL_ROOM_RESERVATION = 'hotel_room_reservation'
export const TYPE_SESSION = 'session'
export const TYPE_VOUCHER = 'voucher'

export const FRIENDLY_TYPES = {
  [TYPE_APPOINTMENT]: 'Appointment',
  [TYPE_APPOINTMENT_ENQUIRY]: 'Appointment enquiry',
  [TYPE_AREA_BOOKING]: 'Area booking',
  [TYPE_COURSE]: 'Course',
  [TYPE_MEMBERSHIP]: 'Membership',
  [TYPE_PACKAGE]: 'Package',
  [TYPE_PRODUCT]: 'Product',
  [TYPE_ROOM_RESERVATION]: 'Room reservation',
  [TYPE_HOTEL_ROOM_RESERVATION]: 'Hotel room reservation',
  [TYPE_TABLE_RESERVATION]: 'Table reservation',
  [TYPE_SESSION]: 'Session',
  [TYPE_VOUCHER]: 'Voucher',
}

export const OFFERING_TYPE_OPTIONS = [
  {
    value: TYPE_APPOINTMENT,
    name: FRIENDLY_TYPES[TYPE_APPOINTMENT],
  },
  {
    value: TYPE_AREA_BOOKING,
    name: FRIENDLY_TYPES[TYPE_AREA_BOOKING],
  },
  {
    value: TYPE_COURSE,
    name: FRIENDLY_TYPES[TYPE_COURSE],
  },
  {
    value: TYPE_MEMBERSHIP,
    name: FRIENDLY_TYPES[TYPE_MEMBERSHIP],
  },
  {
    value: TYPE_PACKAGE,
    name: FRIENDLY_TYPES[TYPE_PACKAGE],
  },
  {
    value: TYPE_PRODUCT,
    name: FRIENDLY_TYPES[TYPE_PRODUCT],
  },
  {
    value: TYPE_SESSION,
    name: FRIENDLY_TYPES[TYPE_SESSION],
  },
  {
    value: TYPE_VOUCHER,
    name: FRIENDLY_TYPES[TYPE_VOUCHER],
  },
  {
    value: TYPE_HOTEL_ROOM_RESERVATION,
    name: FRIENDLY_TYPES[TYPE_HOTEL_ROOM_RESERVATION],
  },
]
