import { UserIcon } from '@heroicons/react/outline'
import { CameraIcon, EyeIcon, PhotographIcon, TrashIcon } from '@heroicons/react/solid'
import { useState, useRef } from 'react'
import { Dropdown, DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import { TakePhotoModal } from './TakePhotoModal'
import { ViewPhotoModal } from './ViewPhotoModal'
import { RemovePhotoModal } from './RemovePhotoModal'
import { useUploadMedia } from 'api/Media'
import { useParams } from 'react-router-dom'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { useUpdateCustomer } from 'api/Customers'

export const ClientAvatar = ({ avatarUrl }) => (
  <div
    className="w-[75px] h-[75px] bg-gray-200 rounded-full relative flex items-center justify-center bg-cover bg-center"
    style={avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : {}}
  >
    {!avatarUrl && <UserIcon className="w-10 h-10 text-gray-400" />}
  </div>
)

export const ClientAvatarWithEdit = ({ avatarUrl, isLocked }) => {
  const { clientId } = useParams()
  const [takePhotoModalOpen, setTakePhotoModalOpen] = useState(false)
  const [viewPhotoModalOpen, setViewPhotoModalOpen] = useState(false)
  const [removePhotoModalOpen, setRemovePhotoModalOpen] = useState(false)
  const hiddenFileInput = useRef(null)
  const notifications = useNotificationContext()
  const maxSizeKb = 1024 // 1mb
  const { mutateAsync: uploadMedia } = useUploadMedia()
  const { mutateAsync: updateClient } = useUpdateCustomer()
  const handleImageUpload = async (file) => (await uploadMedia(file)).data

  const validateFile = (file: File): boolean => {
    const sizeInKb = file.size / 1024

    if (sizeInKb > maxSizeKb) {
      notifications.notify(
        <Notification
          variant="danger"
          title="Image too large"
          description={`The image you uploaded was too large. Please upload an image less than ${maxSizeKb}kb.`}
        />
      )

      return false
    }

    return true
  }

  const handleFileUploaded = async (event) => {
    if (validateFile(event.target.files[0])) {
      let uploadedMedia

      try {
        uploadedMedia = await handleImageUpload(event.target.files[0])

        await updateClient([
          clientId,
          {
            avatar_id: uploadedMedia.id,
          },
        ])

        notifications.notify(
          <Notification
            title="Avatar uploaded"
            description={`The profile picture for this client has successfully been saved.`}
            variant="success"
            autoDismiss
          />
        )
      } catch (e) {
        notifications.notify(<ApiErrorNotification error={e} />)
      }
    }
  }

  return (
    <div
      className="w-[75px] h-[75px] bg-gray-200 rounded-full relative flex items-center justify-center bg-cover bg-center"
      style={avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : {}}
    >
      {!avatarUrl && <UserIcon className="w-10 h-10 text-gray-400" />}

      <div className="absolute bottom-0 right-0">
        <Dropdown
          threeDots
          buttonClass="bg-white border border-gray-300 hover:bg-gray-50 shadow-sm"
        >
          {avatarUrl && (
            <>
              <DropdownItem
                label="View photo"
                onClick={() => setViewPhotoModalOpen(true)}
                icon={EyeIcon}
              />
              <DropdownDivider />
            </>
          )}

          <DropdownItem
            label="Take photo"
            onClick={() => setTakePhotoModalOpen(true)}
            icon={CameraIcon}
            disabled={isLocked}
          />

          <div className="relative">
            <input
              type="file"
              ref={hiddenFileInput}
              className="absolute opacity-0 w-0 h-0 top-0 left-0 cursor-pointer"
              onChange={handleFileUploaded}
            />
            <DropdownItem
              label="Upload photo"
              onClick={() => hiddenFileInput.current.click()}
              icon={PhotographIcon}
              disabled={isLocked}
              hideOnClick={false}
            />
          </div>

          {avatarUrl && (
            <>
              <DropdownDivider />
              <DropdownItem
                label="Remove photo"
                onClick={() => setRemovePhotoModalOpen(true)}
                icon={TrashIcon}
                disabled={isLocked}
              />
            </>
          )}
        </Dropdown>
      </div>

      <TakePhotoModal isOpen={takePhotoModalOpen} onClose={() => setTakePhotoModalOpen(false)} />

      {avatarUrl && (
        <>
          <RemovePhotoModal
            isOpen={removePhotoModalOpen}
            onClose={() => setRemovePhotoModalOpen(false)}
          />

          <ViewPhotoModal
            isOpen={viewPhotoModalOpen}
            avatarUrl={avatarUrl}
            onClose={() => setViewPhotoModalOpen(false)}
          />
        </>
      )}
    </div>
  )
}
