import classNames from 'classnames'

interface CardProps {
  children: React.ReactNode
  className?: string
}

export const Card = ({ children, className = '' }: CardProps) => {
  const classes = classNames([className, 'shadow-sm rounded-md border border-gray-100 bg-white'])

  return <div className={classes}>{children}</div>
}

Card.Body = ({ children }) => <div className="p-3">{children}</div>
