import { Section } from '../Shared/Section'
import { Button } from 'ui/components/Button'
import { PlusIcon } from '@heroicons/react/solid'
import { useCallback, useMemo, useState } from 'react'
import { Modal, ConfirmationModal, ModalButtons } from 'ui/Modal'
import {
  useCustomerMarketingPreferences,
  useOptIn,
  useOptOut,
} from 'api/CustomerMarketingPreferences'
import { EmptyState } from 'ui/components/EmptyState'
import { SpeakerphoneIcon } from '@heroicons/react/outline'
import { TrashIcon } from '@heroicons/react/solid'
import { useParams } from 'react-router-dom'
import { useSiteMarketingPreferences } from 'api/MarketingPreferences'
import { Select } from 'ui/components/Form/Select'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { DateTime } from 'luxon'

const MarketingPreferencesModal = ({ preferences }) => {
  const { clientId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedPreference, setSelectedPreference] = useState(null)
  const notification = useNotificationContext()

  const { isLoading, mutate } = useOptIn(clientId)

  const handleOptIn = () => {
    mutate(selectedPreference.value, {
      onSuccess: () => {
        setSelectedPreference(null)
        setModalOpen(false)

        notification.notify(
          <Notification
            title="Opted in"
            description="The client was successfully opted in"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: () => {
        setSelectedPreference(null)
        setModalOpen(false)

        notification.notify(
          <Notification
            title="Couldn't opt in"
            description="An error occurred when opting in to this preference"
            variant="danger"
            autoDismiss
          />
        )
      },
    })
  }

  return (
    <>
      <Button label="Opt in" leadingIcon={PlusIcon} onClick={() => setModalOpen(true)} />

      <Modal
        isOpen={modalOpen}
        title="Opt into preference"
        onClose={() => setModalOpen(false)}
        styleReset
      >
        <div className="space-y-4 text-sm">
          <p className="bg-yellow-100 text-yellow-600 p-4 rounded-lg">
            It's your responsibility to ensure the client has provided consent.
          </p>
          <p className="text-gray-500">Select the marketing preference to opt the user into.</p>

          <Select
            selected={selectedPreference}
            onChange={setSelectedPreference}
            options={preferences.map(({ id, text }) => ({
              value: id,
              name: text,
            }))}
          />
        </div>

        <ModalButtons>
          <Button
            disabled={!selectedPreference}
            variant="primary"
            label="Opt in"
            loading={isLoading}
            onClick={handleOptIn}
          />
          <Button label="Cancel" onClick={() => setModalOpen(false)} />
        </ModalButtons>
      </Modal>
    </>
  )
}

const OptIn = ({ optIn, onOptOut, preferenceName }) => (
  <li className="flex p-3 justify-between items-center">
    <div>
      <div className="font-medium">{preferenceName}</div>
      <div className="text-gray-500">
        Opted in{' '}
        <time
          dateTime={optIn.opted_in_at}
          title={DateTime.fromJSDate(optIn.opted_in_at).toLocaleString(DateTime.DATETIME_FULL)}
        >
          {DateTime.fromJSDate(optIn.opted_in_at).toLocaleString(DateTime.DATE_MED)}
        </time>
      </div>
    </div>
    <div>
      <Dropdown threeDots>
        <DropdownItem label="Opt out" onClick={onOptOut} icon={TrashIcon} />
      </Dropdown>
    </div>
  </li>
)

export const MarketingPreferences = () => {
  const [showingOptOutConfirmation, setShowingOptOutConfirmation] = useState(null)
  const notifications = useNotificationContext()
  const { clientId } = useParams()
  const { data: { data: preferences = [] } = {} } = useSiteMarketingPreferences({
    refetchOnMount: false,
  })

  const { isLoading, data: { data: optIns = [] } = {} } = useCustomerMarketingPreferences(clientId)

  const { isLoading: isOptingOut, mutate: optOut } = useOptOut(clientId)

  const handleOptOut = () => {
    optOut(showingOptOutConfirmation, {
      onSuccess: () => {
        setShowingOptOutConfirmation(null)
        notifications.notify(
          <Notification
            title="Opted out"
            description="The client was successfully opted out"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: () => {
        setShowingOptOutConfirmation(null)
        notifications.notify(
          <Notification
            title="Couldn't opt out"
            description="An error occurred when opting out of this preference"
            variant="danger"
            autoDismiss
          />
        )
      },
    })
  }

  const getPreferenceFromId = useCallback(
    (id) => {
      return preferences.find((preference) => preference.id === id)
    },
    [preferences]
  )

  const consentedOptIn = useMemo(() => {
    // Filter out ones that have been opted out,
    // and ones that have been soft deleted.
    return optIns
      .filter((optIn) => optIn.opted_in)
      .filter((optIn) => getPreferenceFromId(optIn.id) !== undefined)
  }, [optIns, getPreferenceFromId])

  if (preferences.length === 0) {
    // No preferences have been set up on this site, so don't render anything.
    return null
  }

  return (
    <Section
      title="Marketing preferences"
      button={<MarketingPreferencesModal preferences={preferences} />}
    >
      {isLoading && <div className="bg-grape-200 animate-pulse h-16" />}

      {!isLoading && consentedOptIn.length === 0 && (
        <EmptyState title="This client hasn't opted into any marketing" icon={SpeakerphoneIcon} />
      )}

      <ul className="text-sm divide-y divide-violet-100">
        {!isLoading &&
          consentedOptIn.length > 0 &&
          consentedOptIn.map((optIn) => (
            <OptIn
              key={`marketingPreference_${optIn.id}`}
              optIn={optIn}
              preferenceName={getPreferenceFromId(optIn.id).text}
              onOptOut={() => setShowingOptOutConfirmation(optIn.id)}
            />
          ))}
      </ul>

      <ConfirmationModal
        isOpen={!!showingOptOutConfirmation}
        title="Opt out"
        onClose={() => setShowingOptOutConfirmation(null)}
        onConfirm={handleOptOut}
        confirmButtonLabel="Opt out"
        confirming={isOptingOut}
        variant="danger"
      >
        <p className="text-gray-500 text-sm">
          Are you sure you want to opt this client out of this marketing preference?
        </p>
      </ConfirmationModal>
    </Section>
  )
}
