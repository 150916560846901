import { useGetCustomerStats } from 'api/Customers'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useParams } from 'react-router-dom'
import { StatBox, StatBoxSkeleton } from 'screens/Dashboards/components/StatBox'
import { formatMoney } from 'ui/components/Money'

export const Stats = () => {
  const { clientId } = useParams()
  const featureFlags = useActiveFeatureFlags()
  const enabled = featureFlags.includes('organisation_reports')

  const {
    isLoading,
    isSuccess,
    data: { data: stats = {}, meta = {} } = {},
  } = useGetCustomerStats(clientId, {
    enabled,
  })

  if (!enabled) {
    return null
  }

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <StatBoxSkeleton />
      </div>
    )
  }

  if (!isSuccess) {
    return null
  }

  return (
    <div>
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <StatBox title="Number of orders" stat={stats.totals.num_orders} />
        <StatBox
          title="Total order value"
          stat={formatMoney({
            amount: stats.totals.total_order_value,
            currency: meta.currency,
            showDecimals: false,
          })}
          statText={`${formatMoney({
            amount: stats.totals.average_order_value,
            currency: meta.currency,
            showDecimals: false,
          })} average`}
        />
      </dl>
    </div>
  )
}
