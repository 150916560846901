import { Text as BaseText } from '@trybeapp/ui.text'

/**
 * @deprecated
 */
export const Text = ({ variant = 'body2', color = 'nude.900', children, ...props }) => {
  return (
    <BaseText variant={variant} color={color} {...props}>
      {children}
    </BaseText>
  )
}

export default Text
