import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { useOfferingSearch } from 'api/OfferingSearch'
import classNames from 'classnames'
import { FRIENDLY_TYPES } from 'constants/offeringTypes'
import { inputClasses } from 'ui/components/Form/Input'

export const OfferingSearch = ({
  onSelect,
  offeringType = null,
  placeholder = 'Type to search',
  value = undefined,
}) => {
  const [query, setQuery] = useState('')
  const [selectedOffering, setSelectedOffering] = useState(value)
  const { data: { data: results = [] } = {} } = useOfferingSearch({
    query,
    offeringType,
  })

  const handleSelect = (offering) => {
    setSelectedOffering(offering)
    onSelect(offering)
  }

  return (
    <Combobox as="div" value={selectedOffering} onChange={handleSelect}>
      {() => {
        return (
          <div className="relative mt-1">
            <Combobox.Input
              className={inputClasses}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
              displayValue={(offering: any) => offering?.name}
            />
            {results.length > 0 && (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {results.map((result) => (
                  <Combobox.Option
                    key={result.id}
                    value={result}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex">
                          <span className={classNames('truncate', selected && 'font-semibold')}>
                            {result.name}
                          </span>
                          {!offeringType && (
                            <span
                              className={classNames(
                                'ml-2 truncate text-gray-500',
                                active ? 'text-indigo-200' : 'text-gray-500'
                              )}
                            >
                              {FRIENDLY_TYPES[result.offering_type]}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        )
      }}
    </Combobox>
  )
}
