import { Box } from '@trybeapp/ui.box'
import styled from '@xstyled/styled-components'
import { Text } from 'components/Text'
import { useUserHasTrybeEmail } from 'hooks/UseUser'
import { useMemo } from 'react'

const InternalOnlyBoxStyled = styled(Box)`
  background-color: secondary.100;
  padding: lg;
  border-radius: lg;
  border: 2px dashed;
  border-color: secondary.500;
`

const FooterText = (showOnProduction) => {
  let footerText = "🤫 You're seeing this because you're logged in with an @try.be user"

  if (!showOnProduction) {
    footerText += ' and because this is a non-production environment.'
  } else {
    footerText += '.'
  }

  return footerText
}

const InternalOnlyBox = ({ showOnProduction = false, children }) => {
  const footerText = useMemo(() => FooterText(showOnProduction), [showOnProduction])
  const shouldDisplayForUser = useIsInternalUser()

  const shouldDisplayForEnvironment =
    import.meta.env.VITE_APP_PROJECT_ENVIRONMENT !== 'production' || showOnProduction

  if (!shouldDisplayForUser && shouldDisplayForEnvironment) return null

  return (
    <InternalOnlyBoxStyled>
      {children}

      <Text variant="meta2" color="secondary.700" fontWeight="bold" mt="lg" fontSize="11px">
        {footerText}
      </Text>
    </InternalOnlyBoxStyled>
  )
}

export const useIsInternalUser = () => {
  return useUserHasTrybeEmail()
}

export default InternalOnlyBox
