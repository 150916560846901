import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { chain } from 'lodash'
import { NotAuthorized } from 'components/Authentication'
import {
  CUSTOMERS_VIEW,
  INVENTORY_VIEW,
  REPORTS_VIEW,
  RESERVATIONS_VIEW,
  ROTAS_VIEW,
  SETTINGS_MANAGE,
  SETTINGS_VIEW,
  USERS_MANAGE,
} from 'constants/permissions'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { useEffect } from 'react'
import { setPageTitle } from 'utilities/PageTitle'
import { componentLoader } from 'utilities/PreloadLinks'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'

const ReactLazyPreload = (importStatement) => {
  const Component = lazy(() => componentLoader(importStatement))
  Component.preload = importStatement
  return Component
}

const PrivateRoute = ({ component: Component, permissions = [], ...rest }) => {
  const can = useCurrentUserCan()

  useEffect(() => {
    if (rest.title) {
      setPageTitle(`${rest.title}`)
    } else {
      setPageTitle('Trybe')
    }
  }, [rest.title])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!chain(permissions).every((permission) => can(permission))) {
          return <NotAuthorized />
        }

        return <Component {...props} />
      }}
    />
  )
}

export const useCheckPermissions = () => {
  const can = useCurrentUserCan()

  return (permissions: string[]) => permissions?.every((permission) => can(permission))
}

interface RouteInterface {
  path: string
  name: string
  route: React.ComponentType<any>
  exact?: boolean
  permissions?: string[]
  importFrom: () => Promise<{ default: React.ComponentType<any> }>
}

const FeatureFlagWrapper = ({ children, flag, active = true }) => {
  const featureFlags = useActiveFeatureFlags()

  if (active ? !featureFlags.includes(flag) : featureFlags.includes(flag)) {
    return null
  }

  return children
}

const routes: RouteInterface[] = [
  {
    path: '/onboarding',
    name: 'Onboarding',
    route: PrivateRoute,
    exact: true,
    importFrom: () => import('screens/Onboarding').then((file) => ({ default: file.Onboarding })),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/NotificationsList').then((file) => ({
        default: file.NotificationsList,
      })),
  },
  {
    path: '/offering-availability',
    name: 'Offering availability',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_MANAGE],
    importFrom: () => import('./screens/OfferingAvailabilityCalendar'),
  },
  {
    path: '/practitioner-availability',
    name: 'Practitioner availability',
    route: PrivateRoute,
    exact: true,
    permissions: [ROTAS_VIEW],
    importFrom: () => import('./screens/PractitionerAvailabilityCalendar'),
  },
  {
    path: '/calendar',
    name: 'Calendar',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./components/Calendar'),
  },
  {
    path: '/calendar/schedule',
    name: 'Schedule',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./components/Calendar'),
  },
  {
    path: '/reports',
    name: 'Reports',
    route: PrivateRoute,
    permissions: [REPORTS_VIEW],
    importFrom: () => import('./screens/Reports'),
  },
  {
    path: '/',
    exact: true,
    name: 'Home',
    route: PrivateRoute,
    permissions: [REPORTS_VIEW],
    importFrom: () => import('./screens/Dashboards/Home'),
  },
  {
    path: '/dashboards',
    name: 'Home',
    route: PrivateRoute,
    permissions: [REPORTS_VIEW],
    importFrom: () => import('./screens/Dashboards'),
  },
  {
    path: '/admin/feature-flags',
    name: 'Feature flags',
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/FeatureFlags').then((file) => ({ default: file.FeatureFlags })),
  },
  {
    path: '/admin/sites',
    name: 'Sites',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/ManageSites').then((file) => ({ default: file.ManageSites })),
  },
  {
    path: '/admin/sites/:siteId',
    name: 'Sites',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/ManageSites/Details').then((file) => ({ default: file.SiteDetail })),
  },
  {
    path: '/admin/sites/:siteId/adyen-setup',
    name: 'Sites',
    route: PrivateRoute,
    importFrom: () => import('./screens/AdyenSetup').then((file) => ({ default: file.AdyenSetup })),
  },
  {
    path: '/admin/users',
    name: 'Users',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/ManageUsers').then((file) => ({ default: file.ManageUsers })),
  },
  {
    path: '/admin/users/:userId',
    name: 'Users',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/ManageUsers/Details').then((file) => ({ default: file.UserDetail })),
  },
  {
    path: '/admin/deposit-exposure',
    name: 'Sites',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/DepositExposure').then((file) => ({ default: file.DepositExposure })),
  },
  {
    path: '/settings/inventory/categories',
    name: 'Stock categories',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/InventoryCategories').then((file) => ({
        default: file.InventoryCategories,
      })),
  },
  {
    path: '/settings/inventory/brands',
    name: 'Stock brands',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/InventoryBrands').then((file) => ({
        default: file.InventoryBrands,
      })),
  },
  {
    path: '/settings/inventory/suppliers',
    name: 'Suppliers',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/InventorySuppliers').then((file) => ({
        default: file.InventorySuppliers,
      })),
  },
  {
    path: '/settings/inventory/suppliers/:supplierId',
    name: 'Supplier settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/InventorySuppliers/Edit').then((file) => ({
        default: file.EditInventorySupplierSettings,
      })),
  },
  {
    path: '/settings/resources/equipment/:equipmentId',
    name: 'Equipment settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Equipment/EditEquipmentSettings').then(
        (file) => ({ default: file.EditEquipmentSettings })
      ),
  },
  {
    path: '/settings/resources/rooms/:roomId',
    name: 'Room settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Rooms/EditRoomSettings').then((file) => ({
        default: file.EditRoomSettings,
      })),
  },
  {
    path: '/bookable-areas/:bookableAreaId/*',
    name: 'Bookable Area Settings',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./components/SettingsDirectory/Resources/BookableAreas/Edit'),
  },
  {
    path: '/settings/resources/practitioners/:practitionerId',
    name: 'Practitioner settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Practitioners/Edit').then((file) => ({
        default: file.EditPractitionerSettings,
      })),
  },
  {
    path: '/settings/offerings/package-types/:packageTypeId',
    name: 'Package type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/Packages/Edit').then((file) => ({
        default: file.EditPackageSettings,
      })),
  },
  {
    path: '/settings/offerings/course-types/:courseTypeId',
    name: 'Course type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/Courses/Edit').then((file) => ({
        default: file.EditCourseTypeSettings,
      })),
  },
  {
    path: '/settings/offerings/session-types/:sessionTypeId',
    name: 'Session type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/SessionTypes/Edit').then((file) => ({
        default: file.EditSessionTypeSettings,
      })),
  },
  {
    path: '/settings/offerings/appointment-types/:appointmentTypeId',
    name: 'Appointment type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/AppointmentTypes/Edit').then((file) => ({
        default: file.EditAppointmentTypeSettings,
      })),
  },
  {
    path: '/settings/offerings/area-booking-types/:areaBookingTypeId',
    name: 'Booking type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/AreaBookingTypes/Edit').then((file) => ({
        default: file.EditAreaBookingTypeSettings,
      })),
  },
  {
    path: '/table-reservation-types/:reservationTypeId/*',
    name: 'Table reservation type settings',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./components/SettingsDirectory/Offerings/TableReservationTypes/Edit'),
  },
  {
    path: '/room-reservation-types/:reservationTypeId/*',
    name: 'Room reservation type settings',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./components/SettingsDirectory/Offerings/RoomReservationTypes/Edit'),
  },
  {
    path: '/my-account',
    name: 'My Account',
    route: PrivateRoute,
    importFrom: () => import('./screens/MyAccount'),
  },
  {
    path: '/settings/offerings/retail-products/:retailProductId',
    name: 'Retail product settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/RetailProducts/Edit').then((file) => ({
        default: file.EditRetailProductSettings,
      })),
  },
  {
    path: '/settings/offerings/voucher-types/:voucherTypeId',
    name: 'Voucher type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/VoucherTypes/Edit').then((file) => ({
        default: file.EditVoucherTypeSettings,
      })),
  },
  {
    path: '/settings/external-vouchers',
    name: 'Vouchers integration',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/ExternalVouchers').then((file) => ({
        default: file.Vouchers,
      })),
  },
  {
    path: '/settings/clients/intake-forms',
    name: 'Intake forms',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/IntakeFormBuilder').then((file) => ({
        default: file.IntakeFormBuilder,
      })),
  },
  {
    path: '/settings/clients/intake-forms/:sectionId',
    name: 'Edit intake form section',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/IntakeFormSectionBuilder').then((file) => ({
        default: file.IntakeFormSectionBuilder,
      })),
  },
  {
    path: '/settings/clients/intake-forms/:sectionId/questions/:questionId',
    name: 'Edit intake form question',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/IntakeFormQuestionBuilder').then((file) => ({
        default: file.IntakeFormQuestionBuilder,
      })),
  },
  {
    path: '/external-vouchers/:voucherId/settings',
    name: 'Vouchers integration settings',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./screens/EditExternalVoucherSettings'),
  },
  {
    path: '/settings/orders/discount-types-and-promo-codes/:discountTypeId',
    name: 'Discount type settings',
    route: PrivateRoute,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Orders/DiscountTypesAndPromoCodes/Edit').then(
        (file) => ({ default: file.EditTypeOrCodeSettings })
      ),
  },
  {
    path: '/orders',
    name: 'Orders',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/OrderList'),
  },
  {
    path: '/orders/enquiries',
    name: 'Enquiries',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/EnquiryList/EnquiryList'),
  },
  {
    path: '/beta/orders',
    name: 'Orders',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/OrderList/OrderListNew'),
  },
  {
    path: '/orders/:orderId?',
    name: 'Order details',
    route: PrivateRoute,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/OrderDetails'),
  },
  {
    path: '/visits',
    name: 'Visits',
    exact: true,
    route: PrivateRoute,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/Visits/List'),
  },
  {
    path: '/visits/:visitId',
    name: 'Visit details',
    exact: true,
    route: PrivateRoute,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/Visits/Details'),
  },
  {
    path: '/visits/:visitId/messages',
    name: 'Visit details',
    exact: true,
    route: PrivateRoute,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/Visits/Details'),
  },
  {
    path: '/visits/:visitId/audit',
    name: 'Visit details',
    exact: true,
    route: PrivateRoute,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () => import('./screens/Visits/Details'),
  },
  {
    path: '/inventory/stock-takes/:stockTakeId',
    name: 'Stocktake',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/Inventory/StockTake/Detail').then((file) => ({
        default: file.StockTakeDetail,
      })),
  },
  {
    path: '/inventory/products/:productId',
    name: 'Product details',
    route: PrivateRoute,
    permissions: [INVENTORY_VIEW],
    importFrom: () => import('./screens/Inventory/sections/ProductDetails'),
  },
  {
    path: '/inventory',
    name: 'Inventory',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/Inventory').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={false}>
            <file.default {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/stock-items',
    name: 'Stock items',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/InventoryV2/Stock').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={true}>
            <file.Stock {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/stock-items/:productId',
    name: 'Stock item details',
    route: PrivateRoute,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/InventoryV2/Stock/StockDetails').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={true}>
            <file.EditStockDetails {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/stock-orders',
    name: 'Stock orders',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/InventoryV2/StockOrders').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={true}>
            <file.StockOrders {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/stock-orders/:orderId',
    name: 'Stock order',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/InventoryV2/StockOrders/StockOrderDetails').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={true}>
            <file.StockOrderDetails {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/stock-takes-v2',
    name: 'Stocktakes',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () =>
      import('./screens/InventoryV2/StockTakes').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={true}>
            <file.StockTakes {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/inventory/orders/:orderId',
    name: 'Inventory order',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () => import('./screens/Inventory/sections/OrderDetails'),
  },
  {
    path: '/inventory/*',
    name: 'Inventory',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./screens/Inventory').then((file) => ({
        default: (props) => (
          <FeatureFlagWrapper flag="new_inventory" active={false}>
            <file.default {...props} />
          </FeatureFlagWrapper>
        ),
      })),
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () =>
      import('./screens/VoucherCodesList/VoucherCodesList').then((file) => ({
        default: file.VoucherCodesList,
      })),
  },
  {
    path: '/vouchers/:voucherCodeId',
    name: 'Voucher code',
    route: PrivateRoute,
    exact: true,
    permissions: [RESERVATIONS_VIEW],
    importFrom: () =>
      import('./screens/VoucherCodeDetails/VoucherCodeDetails').then((file) => ({
        default: file.VoucherCodeDetails,
      })),
  },
  {
    path: '/settings/billing',
    name: 'Billing',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/System/Billing').then((file) => ({
        default: file.Billing,
      })),
  },
  {
    path: '/settings/data-import',
    name: 'Importer',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/System/Importer').then((file) => ({
        default: file.Importer,
      })),
  },
  {
    path: '/settings/data-import/:importId',
    name: 'Importer',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/System/Importer/Detail').then((file) => ({
        default: file.ImporterDetail,
      })),
  },
  {
    path: '/settings/clients/membership',
    name: 'Membership settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/Membership').then((file) => ({
        default: file.Membership,
      })),
  },
  {
    path: '/settings/email-branding',
    name: 'Email branding',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/EmailBranding').then((file) => ({
        default: file.EmailBranding,
      })),
  },
  {
    path: '/settings/resources/bookable-areas',
    name: 'Bookable areas',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/BookableAreas').then((file) => ({
        default: file.BookableAreas,
      })),
  },
  {
    path: '/settings/offerings/area-booking-types',
    name: 'Area booking types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/AreaBookingTypes').then((file) => ({
        default: file.AreaBookingTypes,
      })),
  },
  {
    path: '/settings/offerings/retail-products',
    name: 'Retail products',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/RetailProducts').then((file) => ({
        default: file.RetailProducts,
      })),
  },
  {
    path: '/settings/offerings/appointment-types',
    name: 'Appointment types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/AppointmentTypes').then((file) => ({
        default: file.AppointmentTypes,
      })),
  },
  {
    path: '/settings/offerings/session-types',
    name: 'Session types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/SessionTypes').then((file) => ({
        default: file.SessionTypes,
      })),
  },
  {
    path: '/settings/offerings/package-types',
    name: 'Package types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/Packages').then((file) => ({
        default: file.Packages,
      })),
  },
  {
    path: '/settings/offerings/courses',
    name: 'Courses',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/Courses').then((file) => ({
        default: file.Courses,
      })),
  },
  {
    path: '/settings/offerings/vouchers',
    name: 'Vouchers',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Offerings/VoucherTypes').then((file) => ({
        default: file.VoucherTypes,
      })),
  },
  {
    path: '/settings/orders/sales-channels',
    name: 'Sales channels',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Orders/SalesChannels').then((file) => ({
        default: file.SalesChannels,
      })),
  },
  {
    path: '/settings/orders/discount-types-and-promo-codes',
    name: 'Discount types and promo codes',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Orders/DiscountTypesAndPromoCodes').then((file) => ({
        default: file.DiscountTypesAndPromoCodes,
      })),
  },
  {
    path: '/settings/orders/order-labels',
    name: 'Order labels',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Orders/OrderLabels').then((file) => ({
        default: file.OrderLabels,
      })),
  },
  {
    path: '/settings/table-reservation-types',
    name: 'Table reservation types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./components/SettingsDirectory/Offerings/TableReservationTypes'),
  },
  {
    path: '/settings/orders/service-charge',
    name: 'Service charge',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Orders/ServiceCharge').then((file) => ({
        default: file.ServiceCharge,
      })),
  },
  {
    path: '/settings/room-reservation-types',
    name: 'Room reservation types',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () => import('./components/SettingsDirectory/Offerings/RoomReservationTypes'),
  },
  {
    path: '/settings/shop',
    name: 'Shop settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/Shop').then((file) => ({
        default: file.ShopSettings,
      })),
  },
  {
    path: '/settings/shop/*',
    name: 'Shop settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/Shop').then((file) => ({
        default: file.ShopSettings,
      })),
  },
  {
    path: '/settings/visit-types',
    name: 'Visit types',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/VisitTypes').then((file) => ({
        default: file.VisitTypes,
      })),
  },
  {
    path: '/settings/comms-locales',
    name: 'Communication languages',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/CommsLocales').then((file) => ({
        default: file.CommsLocales,
      })),
  },
  {
    path: '/settings/visit-types/:visitTypeId',
    name: 'Visit type settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/VisitTypes/Details').then((file) => ({
        default: file.VisitTypeDetails,
      })),
  },
  {
    path: '/settings',
    name: 'Settings',
    route: PrivateRoute,
    exact: true,
    permissions: [INVENTORY_VIEW],
    importFrom: () => import('./components/SettingsDirectory'),
  },
  {
    path: '/clients/:clientId',
    name: 'Customer details',
    route: PrivateRoute,
    importFrom: () => import('./components/CustomerDirectory/ClientDetail'),
  },
  {
    path: '/clients',
    name: 'Customers',
    route: PrivateRoute,
    permissions: [CUSTOMERS_VIEW],
    importFrom: () =>
      import('./components/ClientList').then((file) => ({ default: file.ClientList })),
  },
  {
    path: '/beta/clients',
    name: 'Clients',
    route: PrivateRoute,
    permissions: [CUSTOMERS_VIEW],
    importFrom: () =>
      import('./components/ClientList/ClientListV2').then((file) => ({ default: file.ClientList })),
  },
  {
    path: '/memberships/:membershipId',
    name: 'Membership',
    route: PrivateRoute,
    permissions: [CUSTOMERS_VIEW],
    importFrom: () =>
      import('./components/MembershipDetails').then((file) => ({
        default: file.MembershipDetails,
      })),
  },
  {
    path: '/memberships',
    name: 'Memberships',
    route: PrivateRoute,
    permissions: [CUSTOMERS_VIEW],
    importFrom: () =>
      import('./components/MembershipList').then((file) => ({ default: file.MembershipList })),
  },
  {
    path: '/settings/legal',
    name: 'Legal settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/Legal').then((file) => ({
        default: file.Legal,
      })),
  },
  {
    path: '/settings/site/opening-hours',
    name: 'Opening hours',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/OpeningHours').then((file) => ({
        default: file.OpeningHours,
      })),
  },
  {
    path: '/settings/site/payment-types',
    name: 'Payment types',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/PaymentTypes').then((file) => ({
        default: file.PaymentTypes,
      })),
  },
  {
    path: '/settings/site/appointment-enquiries',
    name: 'Appointment enquiries',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Site/AppointmentEnquiries').then((file) => ({
        default: file.AppointmentEnquiries,
      })),
  },
  {
    path: '/settings/organisation/revenue-centres',
    name: 'Revenue centres',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Organisation/RevenueCentres').then((file) => ({
        default: file.RevenueCentres,
      })),
  },
  {
    path: '/settings/integrations',
    name: 'Integrations',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations').then((file) => ({
        default: file.Integrations,
      })),
  },
  {
    path: '/settings/integrations/avvio',
    name: 'Avvio settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Avvio').then((file) => ({
        default: file.Avvio,
      })),
  },
  {
    path: '/settings/integrations/resdiary',
    name: 'ResDiary integrations',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/ResDiary').then((file) => ({
        default: file.ResDiary,
      })),
  },
  {
    path: '/settings/integrations/resdiary/:configId',
    name: 'ResDiary settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/ResDiary/Settings').then((file) => ({
        default: file.Settings,
      })),
  },
  {
    path: '/settings/integrations/fiskaltrust',
    name: 'Fiskaltrust settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Fiskaltrust').then((file) => ({
        default: file.Fiskaltrust,
      })),
  },
  {
    path: '/settings/integrations/giftpro',
    name: 'GiftPro settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/GiftPro').then((file) => ({
        default: file.GiftPro,
      })),
  },
  {
    path: '/settings/integrations/for-sight',
    name: 'ForSight settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/ForSight').then((file) => ({
        default: file.ForSight,
      })),
  },
  {
    path: '/settings/integrations/hconnect',
    name: 'HConnect settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/HConnect').then((file) => ({
        default: file.HConnect,
      })),
  },
  {
    path: '/settings/integrations/guestline',
    name: 'Guestline settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Guestline').then((file) => ({
        default: file.Guestline,
      })),
  },
  {
    path: '/settings/integrations/hotsoft',
    name: 'HotSoft settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/HotSoft').then((file) => ({
        default: file.HotSoft,
      })),
  },
  {
    path: '/settings/integrations/mews',
    name: 'Mews settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Mews').then((file) => ({
        default: file.Mews,
      })),
  },
  {
    path: '/settings/integrations/opera',
    name: 'Opera settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/OperaFias').then((file) => ({
        default: file.OperaFias,
      })),
  },
  {
    path: '/settings/integrations/google-analytics',
    name: 'Google Analytics settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/GoogleAnalytics').then((file) => ({
        default: file.GoogleAnalytics,
      })),
  },
  {
    path: '/settings/integrations/google-tag-manager',
    name: 'Google Tag Manager settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/GoogleTagManager').then((file) => ({
        default: file.GoogleTagManager,
      })),
  },
  {
    path: '/settings/integrations/gantner',
    name: 'Gantner settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Gantner').then((file) => ({
        default: file.Gantner,
      })),
  },
  {
    path: '/settings/integrations/gantner/devices/:deviceId',
    name: 'Ganter device settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Gantner/EditDevice').then(
        (file) => ({ default: file.GantnerEditDevice })
      ),
  },
  {
    path: '/settings/integrations/mobile-passes',
    name: 'Mobile passes settings',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/MobilePasses').then((file) => ({
        default: file.MobilePasses,
      })),
  },
  {
    path: '/settings/integrations/sso',
    name: 'Single Sign-on',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/SingleSignOn').then((file) => ({
        default: file.SingleSignOn,
      })),
  },
  {
    path: '/settings/integrations/payments',
    name: 'Payments',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Payments').then((file) => ({
        default: file.Payments,
      })),
  },
  {
    path: '/settings/integrations/escpos',
    name: 'Receipt printing',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/EscPosConnectors').then((file) => ({
        default: file.EscPosConnectors,
      })),
  },
  {
    path: '/settings/integrations/webhooks',
    name: 'Webhooks',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Webhooks').then((file) => ({
        default: file.Webhooks,
      })),
  },
  {
    path: '/settings/integrations/webhooks/:configId',
    name: 'Webhook config',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Integrations/Webhooks/Settings').then((file) => ({
        default: file.Settings,
      })),
  },
  {
    path: '/settings/ota-availability',
    name: 'OTA Availability',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/OtaAvailability').then((file) => ({
        default: file.OtaAvailability,
      })),
  },
  {
    path: '/settings/resources/blocked-time-presets',
    name: 'Blocked time presets',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/BlockedTimePresets').then((file) => ({
        default: file.BlockedTimePresets,
      })),
  },
  {
    path: '/settings/site/zones',
    name: 'Zones',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Zones').then((file) => ({ default: file.Zones })),
  },
  {
    path: '/settings/resources/equipment',
    name: 'Equipment',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Equipment').then((file) => ({
        default: file.Equipment,
      })),
  },
  {
    path: '/settings/users/:userId',
    name: 'User details',
    route: PrivateRoute,
    permissions: [USERS_MANAGE],
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Users/DetailsDirectory').then((file) => ({
        default: file.UserDetails,
      })),
  },
  {
    path: '/settings/users',
    name: 'Users',
    exact: true,
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Users').then((file) => ({ default: file.Users })),
  },
  {
    path: '/settings/resources/rooms',
    name: 'Rooms',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Rooms').then((file) => ({
        default: file.Rooms,
      })),
  },
  {
    path: '/settings/resources/practitioners',
    name: 'Practitioners',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Resources/Practitioners').then((file) => ({
        default: file.Practitioners,
      })),
  },
  {
    path: '/settings/site/categories',
    name: 'Categories',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Categories').then((file) => ({
        default: file.Categories,
      })),
  },
  {
    path: '/settings/site/voucher-delivery',
    name: 'Voucher delivery',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Site/VoucherDelivery').then((file) => ({
        default: file.VoucherDelivery,
      })),
  },
  {
    path: '/settings/site/appointment-enquiries',
    name: 'Appointment enquiries',
    route: PrivateRoute,
    exact: true,
    permissions: [SETTINGS_VIEW],
    importFrom: () =>
      import('./components/SettingsDirectory/Site/Categories').then((file) => ({
        default: file.Categories,
      })),
  },
  {
    path: '/settings/clients/membership-types/:membershipTypeId',
    name: 'Edit membership type',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/Membership/Edit').then((file) => ({
        default: file.MembershipTypeEdit,
      })),
  },
  {
    path: '/settings/clients/membership-types/:membershipTypeId/credits/:creditRuleId',
    name: 'Edit credit rule',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/Membership/Edit/EditCreditRule').then(
        (file) => ({ default: file.EditCreditRule })
      ),
  },
  {
    path: '/settings/marketing-preferences',
    name: 'Marketing preferences',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/MarketingPreferences').then((file) => ({
        default: file.MarketingPreferences,
      })),
  },
  {
    path: '/settings/clients/customer-labels',
    name: 'Customer labels',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/SettingsDirectory/Clients/ClientLabels').then((file) => ({
        default: file.ClientLabels,
      })),
  },
  {
    path: '/settings/orders/emails',
    name: 'Email template settings',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Emails').then((file) => ({
        default: file.EmailsSettings,
      })),
  },
  {
    path: '/settings/orders/emails/templates/:messageFragmentId',
    name: 'Edit message template',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Emails/Edit/EditMessageFragment').then((file) => ({
        default: file.EditMessageFragment,
      })),
  },
  {
    path: '/settings/sms-notifications',
    name: 'SMS notifications',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/SmsNotifications').then((file) => ({
        default: file.SmsNotifications,
      })),
  },
  {
    path: '/settings/sms-notifications/templates',
    name: 'SMS notifications',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/SmsNotifications').then((file) => ({
        default: file.SmsNotifications,
      })),
  },
  {
    path: '/settings/sms-notifications/templates/:smsFragmentId',
    name: 'Edit SMS template',
    route: PrivateRoute,
    exact: true,
    importFrom: () =>
      import('./components/SettingsDirectory/Marketing/SmsNotifications/SmsTemplates/Edit').then(
        (file) => ({ default: file.EditSmsTemplate })
      ),
  },
  {
    path: '/emails/campaigns/:campaignId/details',
    name: 'Campaign details',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/Campaign/components/Details').then((file) => ({
        default: file.Details,
      })),
  },
  {
    path: '/emails/campaigns/:campaignId/content',
    name: 'Campaign content',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/Campaign/components/Content').then((file) => ({
        default: file.Content,
      })),
  },
  {
    path: '/emails/campaigns/:campaignId/send',
    name: 'Send campaign',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/Campaign/components/Send').then((file) => ({
        default: file.Send,
      })),
  },
  {
    path: '/emails/campaigns/:campaignId',
    name: 'Edit campaign',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/Campaign').then((file) => ({
        default: file.CampaignHome,
      })),
  },
  {
    path: '/emails/campaigns',
    name: 'Email campaigns',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/CampaignsList').then((file) => ({
        default: file.CampaignsList,
      })),
  },
  {
    path: '/emails/lists/:emailListId/details',
    name: 'Email list details',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/EmailList/components/Details').then((file) => ({
        default: file.Details,
      })),
  },
  {
    path: '/emails/lists/:emailListId/subscribers',
    name: 'Email list subscribers',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/EmailList/components/SubscribersList').then(
        (file) => ({ default: file.SubscribersList })
      ),
  },
  {
    path: '/emails/lists/:emailListId',
    name: 'Email list details',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/EmailList').then((file) => ({
        default: file.EmailListHome,
      })),
  },
  {
    path: '/emails/lists',
    name: 'Email lists',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/components/EmailLists').then((file) => ({
        default: file.EmailLists,
      })),
  },
  {
    path: '/emails',
    name: 'Emails',
    route: PrivateRoute,
    importFrom: () =>
      import('./components/Emails/EmailsHome').then((file) => ({ default: file.EmailsHome })),
  },
  {
    path: '/debug/intake-forms-list',
    name: 'Email lists',
    route: PrivateRoute,
    importFrom: () =>
      import('./screens/IntakeFormsSheet/IntakeFormsSheet').then((file) => ({
        default: file.IntakeFormsSheetInner,
      })),
  },
]

const routesToExport = routes.map((route) => {
  const { importFrom, ...rest } = route

  if (importFrom) {
    return {
      ...rest,
      component: ReactLazyPreload(importFrom),
    }
  }
  return rest
})

export { routesToExport as routes, PrivateRoute }
