import { createContext, useContext, useMemo } from 'react'
import { useCurrentOrder } from 'contexts/Order'
import { isArray } from 'lodash'
import { useSelector } from 'react-redux'
import { selectGuest } from 'selectors/GuestSelectors'

export const GuestContext = createContext()

export const GuestProvider = ({ id, guest: object, children }) => {
  const guest = useSelector(selectGuest(id)) || {}

  return <GuestContext.Provider value={object ?? guest}>{children}</GuestContext.Provider>
}

export const useGuest = () => useContext(GuestContext)

export const GuestsContext = createContext()

export const GuestsProvider = ({ ids = [], guests: objects, children }) => {
  const { order: { guests: orderGuests = [] } = {} } = useCurrentOrder()
  const idsArray = isArray(ids) ? ids : ids.split(',')

  const guests = useMemo(() => {
    return objects ?? orderGuests.filter((orderGuest) => idsArray.indexOf(orderGuest.id) >= 0)
  }, [idsArray, orderGuests, objects])

  return <GuestsContext.Provider value={guests}>{children}</GuestsContext.Provider>
}

export const useGuests = () => useContext(GuestsContext)
