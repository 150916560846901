import { useQuery, useMutation, useQueryClient, UseQueryResult } from 'react-query'
import { UserSiteRolesApi } from '@trybeapp/sdk.auth'
import { useUser } from 'hooks/UseUser'

const api = new UserSiteRolesApi()

export const useUserSiteRoles = (userId, params = {}) => {
  return useQuery(
    ['userSiteRoles', userId, params],
    async () => await api.userSiteRolesIndex(userId, params)
  )
}

export const useAssignSiteRoleToUser = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ([userId, assignSiteRole]: [string, { site_id: string; role_id: string }]) =>
      await api.userSiteRoleAssign(userId, assignSiteRole),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('userSiteRoles')
      },
    }
  )
}

export const useRemoveSiteRoleToUser = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ([userId, removeSiteRole]: [string, { site_id: string; role_id: string }]) =>
      await api.userSiteRoleRemove(userId, removeSiteRole),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('userSiteRoles')
      },
    }
  )
}

export const useCurrentUserSitePermissions = (): UseQueryResult<any, any> => {
  const { sub: userId } = useUser()

  return useQuery(['currentUserSiteRoles'], async () => await api.userSiteRolesIndex(userId), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
}
