import { useEffect, useMemo } from 'react'
import { Modal } from '@trybeapp/ui.modal'
import { Formik, useField, useFormikContext } from 'formik'
import { Button } from '@trybeapp/ui.button'
import { string, object, date as yupDate } from 'yup'
import {
  ALL_STAGES,
  TIMES_STAGE,
  useAddAreaBookingOrderItemOverlay,
  useOrderItem,
  useStage,
} from '../contexts'
import { Row, Col } from '@trybeapp/ui.grid'
import { ErrorProvider } from 'contexts/Error'
import { ErrorAlert } from 'components/ErrorAlert'
import { Box } from '@trybeapp/ui.box'
import { Input } from '@trybeapp/ui.input'
import { Select } from '@trybeapp/ui.select'
import { ConnectedField } from 'components/ConnectedField'
import { FormikBookableAreaSelect } from 'components/fields/BookableAreaSelect'
import { FormikDateInput } from 'components/DateInput'
import { Alert } from '@trybeapp/ui.alert'
import { Hint } from 'ui/components/Hint'
import {
  useAllActiveBookingOrderItemDates,
  useAllActivePackageOrderItemDates,
  useOrderId,
} from 'screens/OrderDetails/components/Overview/hooks'
import moment from 'moment'
import { DateTime } from 'luxon'
import { useIsEditMode } from '../hooks'
import { SpinnerOverlay } from 'components/SpinnerOverlay'
import { AreaBookingSlotSelection } from 'components/AreaBookingSlotSelection'
import SectionHeading from 'components/SectionHeading'
import { trackEvent } from 'components/Segment'
import {
  EVENT_ADD_APPOINTMENT_STEP_COMPLETED,
  EVENT_APPOINTMENT_CREATED,
  EVENT_APPOINTMENT_UPDATED,
  EVENT_EDIT_APPOINTMENT_STEP_COMPLETED,
  EVENT_EDIT_APPOINTMENT_CANCELLED,
  EVENT_ADD_APPOINTMENT_CANCELLED,
} from 'constants/trackableEvents'
import { useAddOrderItem, useEditOrderItem } from 'api/OrderItems'
import { useQueryParam } from 'use-query-params'
import { useAreaBookingTypes, useGetAreaBookingType } from 'api/AreaBookingTypes'
import { map } from 'lodash'
import { useAreaBookingSlots } from 'api/OfferingAvailability'
import { withFormikSelect } from 'components/FormikSelect'

export const TimesStage = () => {
  const { modal } = useAddAreaBookingOrderItemOverlay()
  const orderId = useOrderId()
  const isEditMode = useIsEditMode()
  const {
    orderItem: {
      id: itemId,
      guest_ids: guestIds,
      type_id: typeId,
      duration,
      date,
      start_time: time = '',
      area_ids: areaIds = [],
    },
  } = useOrderItem()

  const {
    mutate: mutateNewOrderItem,
    isLoading: isLoadingNewOrderItem,
    error: newOrderItemError,
  } = useAddOrderItem()

  const {
    mutate: mutateExistingOrderItem,
    isLoading: isLoadingExistingOrderItem,
    error: existingOrderItemError,
  } = useEditOrderItem()

  const initialValues = {
    date,
    time: time ? DateTime.fromJSDate(time).toFormat('HH:mm') : '',
    duration,
    area_ids: areaIds,
    guest_ids: guestIds,
    offering_id: typeId,
    offering_type: 'area_booking',
  }

  const validationSchema = object({
    // TODO: add duration, area IDs
    date: yupDate().required('You must select a date'),
    time: string().required('You must select a time'),
  })

  const handleOnSubmit = (values) => {
    const { date, time, area_ids: areaIds, ...orderItem } = values

    const formattedDate = DateTime.fromJSDate(date).startOf('day').toISODate()

    const timeParts = time.split(':')
    const formattedTime = DateTime.fromJSDate(date)
      .set({ hour: timeParts[0], minute: timeParts[1] })
      .toISO({ suppressMilliseconds: true })

    const item = {
      ...orderItem,
      date: formattedDate,
      time: formattedTime,
      area_ids: areaIds,
    }

    if (isEditMode) {
      mutateExistingOrderItem(
        [
          orderId,
          itemId,
          item,
          {
            skipAvailabilityChecks: true,
          },
        ],
        { onSuccess: () => modal.hide() }
      )
      trackEvent(EVENT_APPOINTMENT_UPDATED, { orderId, orderItemId: itemId })
      trackEvent(EVENT_EDIT_APPOINTMENT_STEP_COMPLETED, {
        step: TIMES_STAGE,
        orderId,
        orderItemId: itemId,
      })
    } else {
      mutateNewOrderItem(
        [
          orderId,
          item,
          {
            skipAvailabilityChecks: true,
          },
        ],
        { onSuccess: () => modal.hide() }
      )

      trackEvent(EVENT_APPOINTMENT_CREATED, { orderId })
      trackEvent(EVENT_ADD_APPOINTMENT_STEP_COMPLETED, {
        step: TIMES_STAGE,
        orderId,
      })
    }
  }

  const closeModal = () => {
    modal.hide()

    if (isEditMode) {
      trackEvent(EVENT_EDIT_APPOINTMENT_CANCELLED, {
        step: TIMES_STAGE,
        orderId,
        orderItemId: itemId,
      })
    } else {
      trackEvent(EVENT_ADD_APPOINTMENT_CANCELLED, {
        step: TIMES_STAGE,
        orderId,
      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      <>
        <Modal.Header backgroundColor="light.800">Details</Modal.Header>
        <Modal.Content overflow="scroll">
          <ErrorProvider error={newOrderItemError || existingOrderItemError}>
            <ErrorAlert />
          </ErrorProvider>
          <Row minHeight={500} width={900}>
            <Col col={1 / 3}>
              <Box height={1}>
                <AreaBookingItemForm />
              </Box>
            </Col>
            <Col col={2 / 3}>
              <Box position="relative" width={1} height={1} borderLeft={1} borderColor="light.500">
                <Box height={1} position="absolute" top={0} bottom={0} left={0} right={0} pl="xxl">
                  <SlotSection />
                </Box>
              </Box>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Footer>
          <Box width={1} display="flex">
            <SubmitButton loading={isLoadingNewOrderItem || isLoadingExistingOrderItem} />
            <BackButton />
            <Box ml="auto">
              <Button variant="ghost" onClick={closeModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal.Footer>
      </>
    </Formik>
  )
}

const SlotSection = () => {
  const [{ value: currentDate }] = useField('date')

  if (!currentDate) {
    return (
      <Box my="sm">
        <SectionHeading>Time suggestions</SectionHeading>
        <Hint>Select a date to see applicable slots.</Hint>
      </Box>
    )
  }

  return <SlotSelection />
}

const useSlotParams = () => {
  const orderId = useOrderId()
  const {
    values: { date: currentDate, duration: selectedDuration },
  } = useFormikContext()

  const {
    orderItem: { id: itemId },
  } = useOrderItem()

  return useMemo(() => {
    if (currentDate) {
      const dateObject = DateTime.fromJSDate(currentDate).startOf('day')

      const params = {
        basketId: orderId,
        dateTimeFrom: dateObject.toISO({ suppressMilliseconds: true }),
        dateTimeTo: dateObject
          .endOf('day')
          .set({ millisecond: 0 })
          .toISO({ suppressMilliseconds: true }),
      }

      if (selectedDuration) {
        params.duration = selectedDuration
      }

      if (itemId) {
        params.excludeBasketItems = itemId
      }

      return params
    }

    return {}
  }, [orderId, currentDate, itemId, selectedDuration])
}

const withEditingExistingItemCheck =
  (Component) =>
  ({ ...props }) => {
    const {
      orderItem: { type_id: typeId },
    } = useOrderItem()
    const { data: { data: bookingTypes } = {}, isSuccess } = useAreaBookingTypes({
      perPage: -1,
    })

    const bookingTypeIds = useMemo(() => {
      return map(bookingTypes, 'id')
    }, [bookingTypes])

    const unavailable = typeId && isSuccess && !bookingTypeIds.includes(typeId)

    if (!unavailable) return <Component {...props} />

    return (
      <Box my="sm">
        <SectionHeading>Time suggestions</SectionHeading>
        <Alert variant="error" mb="sm">
          <Box>
            It looks like the booking type you are editing is no longer available. Update the times
            manually using the form to the left.
          </Box>
        </Alert>
      </Box>
    )
  }

const SlotSelection = withEditingExistingItemCheck(() => {
  const {
    orderItem: { type_id: areaBookingTypeId },
  } = useOrderItem()

  const { data: { data: bookingType } = {} } = useGetAreaBookingType(areaBookingTypeId, {
    enabled: !!areaBookingTypeId,
    refetchOnMount: false,
  })

  const { setFieldValue } = useFormikContext()
  const {
    values: { date: currentDate, time, area_ids: areaIds },
  } = useFormikContext()
  const {
    data: { data: slots = [], meta: { basket_booking_slots: basketSlots = [] } = {} } = {},
    isSuccess,
    isLoading,
  } = useAreaBookingSlots(areaBookingTypeId, useSlotParams())

  const availableSlots = useMemo(() => {
    return slots.filter(({ quantity_available: available }) => available > 0)
  }, [slots])

  if (!currentDate) {
    return (
      <Box my="sm">
        <SectionHeading>Time suggestions</SectionHeading>
        <Hint>Select a date to get applicable slots</Hint>
      </Box>
    )
  }

  if (isSuccess && availableSlots.length === 0) {
    return (
      <Box my="sm">
        <SectionHeading>Time suggestions</SectionHeading>
        <Hint>No slots available for the selected date</Hint>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box position="relative" minHeight={200}>
        <SpinnerOverlay backgroundColor="light.900" />
      </Box>
    )
  }

  return (
    <Box my="md">
      <SectionHeading>Time suggestions</SectionHeading>
      <AreaBookingSlotSelection
        bookingType={bookingType}
        basketSlots={basketSlots}
        time={time}
        areaIds={areaIds}
        options={availableSlots}
        onTimeChange={(value) => {
          setFieldValue('time', value ? DateTime.fromJSDate(value).toFormat('HH:mm') : '')
        }}
        onDurationChange={(value) => setFieldValue('duration', value)}
        onAreaIdsChange={(value) => {
          setFieldValue('area_ids', value)
        }}
      />
    </Box>
  )
})

const SubmitButton = ({ loading }) => {
  const isEditMode = useIsEditMode()
  const { submitForm } = useFormikContext()

  return (
    <Button variant="primary" onClick={submitForm} loading={loading}>
      {isEditMode ? 'Edit' : 'Add'}
    </Button>
  )
}

const AreaBookingItemForm = () => {
  const {
    orderItem: { type_id: areaBookingTypeId },
  } = useOrderItem()

  const { data: { data: bookingType = {} } = {} } = useGetAreaBookingType(areaBookingTypeId, {
    enabled: !!areaBookingTypeId,
    refetchOnMount: false,
  })

  const isEditMode = useIsEditMode()

  return (
    <>
      <Alert variant="info">
        You are {isEditMode ? 'editing' : 'adding'} <strong>{bookingType.name}</strong>
      </Alert>
      <DateField />
      <TimeField />
      <DurationField />
      <AreasField />
    </>
  )
}

const DateField = () => {
  return (
    <Box my="md">
      <ConnectedField label="Date" component={FormikDateInput} name="date" />
      <SuggestedDates />
    </Box>
  )
}

const TimeField = () => {
  return (
    <Box my="md">
      <ConnectedField label="Time" component={Input} name="time" type="time" step={300} />
    </Box>
  )
}

const DurationField = () => {
  const {
    orderItem: { type_id: areaBookingTypeId },
  } = useOrderItem()

  const { data: { data: bookingType = {} } = {} } = useGetAreaBookingType(areaBookingTypeId, {
    enabled: !!areaBookingTypeId,
    refetchOnMount: false,
  })

  const durationInterval = bookingType.duration_interval ?? 15
  const minDuration = bookingType.min_duration || durationInterval
  const maxDuration = bookingType.max_duration || 12 * 60

  const durationOptions = useMemo(
    () => generateDurationOptions(minDuration, maxDuration, durationInterval),
    [minDuration, maxDuration, durationInterval]
  )

  return (
    <Box my="md">
      <ConnectedField
        label="Duration"
        component={DurationSelect}
        isClearable={true}
        options={durationOptions}
        name="duration"
      />
    </Box>
  )
}

const DurationSelect = withFormikSelect(({ ...props }) => {
  return <Select {...props} />
})

export const generateDurationOptions = (minDuration, maxDuration, durationInterval) => {
  const options = []

  for (let duration = minDuration; duration <= maxDuration; duration += durationInterval) {
    let label

    if (duration < 60) {
      label = `${duration} mins`
    } else {
      const hours = Math.floor(duration / 60)
      const mins = duration % 60

      label = `${hours}h ${mins}m`
    }

    options.push({
      value: duration,
      label,
    })
  }

  return options
}

const AreasField = () => {
  return (
    <Box my="md">
      <ConnectedField
        label="Areas"
        component={FormikBookableAreaSelect}
        isMultiple={true}
        name="area_ids"
      />
    </Box>
  )
}

export const SuggestedDates = () => {
  const bookingDates = useAllActiveBookingOrderItemDates()
  const packageDates = useAllActivePackageOrderItemDates()
  const [date] = useQueryParam('date')
  const {
    setFieldValue,
    values: { date: currentDate },
  } = useFormikContext()

  useEffect(() => {
    if (!currentDate) {
      if (bookingDates.length > 0) {
        setFieldValue('date', moment(bookingDates[0]).toDate())
      } else if (packageDates.length > 0) {
        setFieldValue('date', moment(packageDates[0]).toDate())
      } else if (date) {
        setFieldValue('date', date)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, date, bookingDates.length, packageDates.length])

  if (bookingDates.length > 0) {
    return (
      <>
        <Box my="sm">
          <Hint>Select a date from other items</Hint>
        </Box>
        <Row mb="md">
          {bookingDates.map((date) => (
            <Col col="auto" key={date}>
              <Button
                size="xs"
                variant="primary-info"
                onClick={() => setFieldValue('date', moment(date).toDate())}
              >
                {moment(date).format('DD/MM/YYYY')}
              </Button>
            </Col>
          ))}
        </Row>
      </>
    )
  }

  return null
}

const BackButton = () => {
  const { setStage, stage } = useStage()
  const previousStageIndex = ALL_STAGES.findIndex((currentStage) => stage === currentStage) - 1
  const handleOnClick = () => setStage(ALL_STAGES[previousStageIndex])

  return (
    <Button variant="ghost" mx="sm" onClick={handleOnClick}>
      Back
    </Button>
  )
}
