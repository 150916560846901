import VendorDatePicker, { setDefaultLocale } from 'react-datepicker'
import { Input } from './Input'
import { ForwardedRef, forwardRef } from 'react'
import { createEvent } from 'utilities/createEvent'

setDefaultLocale('en-GB')

interface DatePickerProps {
  ref?: ForwardedRef<any>
  value: Date
  onChange: (date: Date, event: React.SyntheticEvent) => void
  onMonthChange?: (date: Date) => void
  onCalendarClose?: () => void
  valueComponent?: any
  hideLabel?: boolean
  disabled?: boolean
  inline?: boolean
  monthsShown?: number
  minDate?: Date
  excludeDates?: Date[]
  dayClassName?: (date: Date) => string
}

export const DatePicker: React.FC<DatePickerProps> = forwardRef(
  ({ value, onChange, hideLabel = false, valueComponent = null, ...props }, ref) => {
    const CustomInput: any = forwardRef(({ value, onClick }: any, ref) => (
      <Input label="" name="" onFocus={onClick} value={value} onChange={() => null} {...props} />
    ))

    return (
      <VendorDatePicker
        selected={value}
        onChange={onChange}
        locale="en-GB"
        dateFormat="dd/MM/yyyy"
        showPopperArrow={false}
        customInput={valueComponent ? valueComponent : <CustomInput />}
        ref={ref}
        {...props}
      />
    )
  }
)

export const FormikDatePicker = forwardRef(
  ({ onChange: parentOnChange, ...props }: any, ref: any) => {
    const handleChange = (value) => {
      parentOnChange(createEvent({ value: value || '', name: props.name }))
    }

    return <DatePicker name={props.name} onChange={handleChange} ref={ref} {...props} />
  }
)
