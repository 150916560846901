import classNames from 'classnames'
import React, { useMemo } from 'react'
import { ButtonSizes } from './Button'

interface GlobalHeaderButtonProps {
  label: string
  hideLabel?: boolean
  leadingIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  trailingIcon?: JSX.Element
  onClick?: (event: any) => void
  className?: string
  size?: ButtonSizes

  as?: React.ElementType
}

export const GlobalHeaderButton = React.forwardRef<any, any>(
  (
    {
      as: As = 'button',
      label,
      hideLabel = false,
      leadingIcon = null,
      trailingIcon = null,
      onClick = null,
      className = '',
      size = 'sm',
      ...props
    }: GlobalHeaderButtonProps,
    ref
  ) => {
    const handleClick = (e) => {
      if (onClick) {
        onClick(e)
      }
    }

    const hasLabel = useMemo(() => {
      return !!label && !hideLabel
    }, [label, hideLabel])

    const resolvedLeadingIcon = useMemo(() => {
      if (!leadingIcon) return null

      let className

      switch (size) {
        case 'xs':
          className = (hasLabel ? 'mr-1 -ml-1' : '') + ' h-4 w-4'
          break

        case 'sm':
        default:
          className = (hasLabel ? 'mr-1 -ml-1' : '') + ' h-5 w-5'
      }

      return leadingIcon({
        className: `group-hover:text-grape ${className}`,
        'aria-hidden': true,
      })
    }, [leadingIcon, hasLabel, size])

    let classes = [
      'flex-shrink-0',
      'rounded-full',
      'bg-white',
      'text-gray-500',
      'hover:text-gray-600',
      'group',
      'focus:outline-none',
      'focus:ring-2',
      'focus:ring-offset-2',
      'focus:ring-violet',
      'text-sm',
      'p-1',
      'font-medium',
      'flex-1',
      'text-left',
    ]

    const labelClasses = {
      'sr-only': hideLabel,
      'flex-1': true,
    }

    return (
      <As
        type={As === 'button' ? 'button' : null}
        onClick={(e) => handleClick(e)}
        className={classNames(className, classes)}
        {...props}
        title={hideLabel ? label : undefined}
        ref={ref}
      >
        <div className="flex w-full">
          {leadingIcon && resolvedLeadingIcon}
          <span className={classNames(labelClasses)}>{label}</span>
          <span className="flex-shrink-0">{trailingIcon}</span>
        </div>
      </As>
    )
  }
)
