import styled, { css } from '@xstyled/styled-components'
import { Text } from 'components/Text'
import { Box } from '@trybeapp/ui.box'
import { Spinner } from '@trybeapp/ui.spinner'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'

export const SelectPill = ({ selected, disabled, loading, onClick, children }) => {
  const color = disabled ? 'nude.300' : selected ? 'nude.900' : 'nude.700'

  const handleOnClick = (event) => {
    if (disabled) {
      return
    }

    onClick(event)
  }

  return (
    <StyledBox
      borderRadius="sm"
      selected={selected}
      disabled={disabled}
      loading={loading}
      backgroundColor={selected ? 'light.500' : 'light.700'}
      p="sm"
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      display="flex"
      alignItems="center"
      onClick={handleOnClick}
      color={color}
      borderColor="light.500"
      borderStyle="solid"
      borderWidth={selected ? 'sm' : 'sm'}
      mb="sm"
    >
      {!loading && (
        <FontAwesomeIcon
          icon={selected ? 'check-square' : ['far', 'square']}
          fontSize="body2"
          color="inherit"
          mr="sm"
          fixedWidth
        />
      )}

      {loading && (
        <Box display="inline-block" mr="sm">
          <Spinner variant="info" size="xs" />
        </Box>
      )}

      <Text color="inherit" variant="body2" alignItems="center">
        {children}
      </Text>
    </StyledBox>
  )
}

const StyledBox = styled(Box)(
  ({ selected = false, disabled = false }) => css`
    &:hover {
      ${disabled
        ? css`
            background-color: light.700;
          `
        : selected
        ? css`
            background-color: light.200;
          `
        : css`
            background-color: light.500;
            border-width: xs;
          `}
    }
  `
)

export default SelectPill
