import { ComponentType } from 'react'
import { ErrorMessage as BaseErrorMessage, ErrorMessageProps } from 'formik'

export const ErrorMessage: ComponentType<ErrorMessageProps> = ({ name }) => (
  <BaseErrorMessage
    name={name}
    render={(msg) => <TailwindErrorMessage>{msg}</TailwindErrorMessage>}
  />
)

export const TailwindErrorMessage = ({ children }) => (
  <p className="mt-2 text-sm text-red-700">{children}</p>
)
