import { useState, useEffect } from 'react'
import { Button } from '@trybeapp/ui.button'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { Text } from '@trybeapp/ui.text'
import { withUserHasPermission } from 'hooks/UseCurrentUserCan'

/**
 * @deprecated Use a Button instead
 */
export const DeleteButton = withUserHasPermission(
  ({
    onDelete,
    confirmation = 'Are you sure?',
    showIcon = true,
    icon = 'trash',
    variant = 'secondary-danger',
    size = 'md',
    text = '',
    children,
    ...props
  }) => {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showConfirmationTimeout, setShowConfirmationTimeout] = useState()

    const onConfirm = (e) => {
      e.preventDefault()
      setShowConfirmation(true)

      const timeout = setTimeout(() => {
        setShowConfirmation(false)
      }, 3000)

      setShowConfirmationTimeout(timeout)
    }

    useEffect(() => {
      return () => {
        if (showConfirmationTimeout) {
          clearTimeout(showConfirmationTimeout)
        }
      }
    }, [showConfirmationTimeout])

    useEffect(() => {
      return () => {
        if (showConfirmation) {
          setShowConfirmation(false)
        }
      }
    }, [showConfirmation])

    const getVariant = () => (showConfirmation ? 'primary-danger' : variant)

    const handleOnDelete = (e) => {
      e.preventDefault()
      onDelete()
    }

    return (
      <Button
        variant={getVariant()}
        onClick={showConfirmation ? handleOnDelete : onConfirm}
        size={size}
        {...props}
      >
        {showIcon && !showConfirmation && (
          <FontAwesomeIcon
            icon={icon}
            mr={text || showConfirmation || children ? 'sm' : undefined}
          />
        )}
        {!showConfirmation && text}
        {!showConfirmation && children}
        {showConfirmation ? (
          <Text
            fontSize="inherit"
            as="span"
            color="light.900"
            fontWeight="inherit"
            display="inline"
          >
            {confirmation}
          </Text>
        ) : (
          ''
        )}
      </Button>
    )
  }
)

export default DeleteButton
