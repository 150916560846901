import { CustomerCreditsApi } from '@trybeapp/sdk.customer'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMutation, useQuery, UseQueryOptions, useQueryClient, UseQueryResult } from 'react-query'

const customerCreditsApi = new CustomerCreditsApi()

export const useCustomerCredits = (
  customerId: string,
  params = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(
    ['customerCredits', { customerId }, params],
    async () => await customerCreditsApi.listCustomerCredits(customerId, params),
    options
  )
}

export const useCreateCustomerCredit = (customerId: string) => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation<any>(
    (params: any) =>
      customerCreditsApi.issueCustomerCredit(customerId, {
        ...params,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customerCredits'])
      },
    }
  )
}

export const useRevokeCustomerCredit = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation<any>(
    async (creditId) => customerCreditsApi.revokeCustomerCredit(customerId, creditId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customerCredits'])
      },
    }
  )
}
