import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ClipboardCopyIcon } from '@heroicons/react/solid'

export interface CopyableCodeProps {
  // The code to display and which will be copied to the clipboard
  code: string
}

export const CopyableCode: React.FC<CopyableCodeProps> = ({ code }) => {
  const [copied, setCopied] = useState(false)
  const [showCopyHover, setShowCopyHover] = useState(false)

  const handleCopied = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <CopyToClipboard text={code} onCopy={() => handleCopied()}>
      <div
        className="align-middle relative p-1 inline-block text-xs rounded-md bg-gray-100 border border-gray-200 text-gray-900 font-mono truncate cursor-pointer overflow-hidden whitespace-nowrap max-w-full"
        onMouseEnter={() => setShowCopyHover(true)}
        onMouseLeave={() => setShowCopyHover(false)}
      >
        {(showCopyHover || copied) && (
          <div className="inset-0 absolute bg-gray-200 flex items-center justify-center text-gray-900">
            <div className="flex items-center space-x-1 font-bold">
              <ClipboardCopyIcon className="w-4 h-4" />
              <span>{copied ? 'Copied!' : 'Copy'}</span>
            </div>
          </div>
        )}
        {code}
      </div>
    </CopyToClipboard>
  )
}
