import { CustomersApi } from '@trybeapp/sdk'
import { UseQueryOptions, useQuery } from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new CustomersApi(apiConfiguration)

export const useCustomerMemberships = (customerId: string, options: UseQueryOptions = {}) =>
  useQuery<unknown, unknown, any>(
    ['memberships', customerId],
    async () =>
      await api.getCustomerMemberships({
        customerId: customerId,
      }),
    options
  )
