import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { OfferingsApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new OfferingsApi()

export const useOfferingSearch = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  options.cacheTime = 60 * 60 * 1000 // 1 hour
  options.staleTime = 5 * 60 * 1000 // 5 minutes

  return useQuery(
    ['offeringSearch', siteId, params],
    () => api.offeringSearch(siteId, params),
    options
  )
}

const fetchOfferingSearch = async (siteId, params) => {
  const page = params.page ?? 1
  const response = await api.offeringSearch(siteId, params)

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useInfiniteOfferings = (params = {}, options: UseInfiniteQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useInfiniteQuery(
    ['offeringsSearch', { siteId, ...params }],
    ({ pageParam }) => fetchOfferingSearch(siteId, { ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      cacheTime: 60 * 60 * 1000, // 1 hour
      staleTime: 5 * 60 * 1000, // 5 minutes
      ...options,
    }
  )
}

export const useGetMostPopularOfferings = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  options.cacheTime = 24 * 60 * 60 * 1000 // 24 hours
  options.staleTime = 24 * 60 * 60 * 1000 // 24 hours

  return useQuery(
    ['mostPopularOfferings', siteId, params],
    () => api.getMostPopularOfferings(siteId, params),
    options
  )
}
