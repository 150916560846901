import { createSelector } from 'reselect'

/**
 * @deprecated
 */
export const selectGuestsById = (state) => state.resources.GuestsReducer.byId

/**
 * @deprecated
 */
export const selectGuest = (guestId) => createSelector([selectGuestsById], (byId) => byId[guestId])
