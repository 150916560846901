import { Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { Button } from 'ui/components/Button'
import { Spinner } from 'ui/components/Spinner'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { ExclamationCircleIcon, UserIcon } from '@heroicons/react/outline'
import { ClientMetadata } from 'components/CustomerDirectory/ClientDetail'
import { useBarcodeSearch as useClientBarcodeSearch } from 'api/Customers'
import { useBarcodeSearch as useProductBarcodeSearch } from 'api/Products'
import { useAddOrder } from 'api/Orders'
import { useAddOrderItem } from 'api/OrderItems'
import { useHistory } from 'react-router-dom'
import { useCheckIn } from 'api/CheckIns'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMembershipsForCustomer } from 'api/Memberships'
import { MembershipRow } from 'components/CustomerDirectory/Memberships/Memberships'
import { ApiErrorNotification, useNotificationContext } from 'ui/components/Notification'

const LookingUpBarcode = ({ code }) => (
  <div className="flex items-start">
    <div className="flex items-center space-x-2 text-sm">
      <Spinner margin="" />
      <div>
        Looking for a client or product with code <span className="font-medium">{code}</span>
      </div>
    </div>
  </div>
)

export const FoundClient = ({ client, onHide, withAutoCheckIn = true }) => {
  const siteId = useCurrentSiteId()
  const { isLoading: isLoadingMemberships, data: { data: memberships = [] } = {} } =
    useMembershipsForCustomer(client?.id, {
      enabled: client?.id !== undefined,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    })

  const { isLoading: isCheckingIn, isSuccess: hasCheckedIn, mutate: checkIn } = useCheckIn(siteId)

  useEffect(() => {
    if (client?.id && withAutoCheckIn) {
      checkIn([
        client.id,
        {
          method_id: 'scanned',
        },
      ])
    }
  }, [client, checkIn])

  const membershipsToDisplay = useMemo(() => {
    return [...memberships].splice(0, 3)
  }, [memberships])

  return (
    <>
      <div className="flex items-start">
        <div className="flex flex-1 items-start space-x-4 -m-4 mb-0 p-4 bg-gray-50 border-b">
          <div
            className="w-[75px] h-[75px] bg-gray-200 rounded-full relative flex items-center justify-center bg-cover bg-center"
            style={client.avatar ? { backgroundImage: `url(${client.avatar?.url})` } : {}}
          >
            {!client.avatar && <UserIcon className="w-10 h-10 text-gray-400" />}
          </div>

          <div>
            <div className="font-semibold text-xl">{client.full_name}</div>
            <ClientMetadata client={client} />
          </div>
        </div>
      </div>
      <ul className="flex space-x-2 text-xs font-medium">
        {client.labels.map((label) => (
          <li
            className="rounded-b-lg py-0.5 px-1 lg:py-1 lg:px-2 text-white"
            style={{ backgroundColor: '#' + label.colour }}
            key={label.id}
          >
            {label.value}
          </li>
        ))}
      </ul>

      <div className="mt-3 text-sm">
        {isLoadingMemberships && <div className="bg-gray-200 animate-pulse w-full h-8" />}

        {!isLoadingMemberships && memberships.length === 0 && (
          <div className="text-gray-500 text-center py-4 rounded-lg">No memberships</div>
        )}

        {!isLoadingMemberships && memberships.length > 0 && (
          <>
            <ul className="divide-y divide-gray-100">
              {membershipsToDisplay.map((membership) => (
                <li key={`toast_${membership.id}`} className="py-2">
                  <MembershipRow membership={membership} withStatusDetail={false} />
                </li>
              ))}
            </ul>
            {memberships.length > 3 && (
              <div className="text-gray-500 text-xs font-medium mb-4 ml-1 border-t pt-1 border-gray-100">
                Showing 3 of {memberships.length - 3} memberships
              </div>
            )}
          </>
        )}
      </div>
      <div className="mt-2 flex justify-items-stretch space-x-2">
        <Link to={`/clients/${client.id}`} onClick={() => onHide()}>
          <Button label="Open client profile" onClick={() => {}} />
        </Link>

        <div className="flex text-sm items-center space-x-1 text-gray-500 ">
          {isCheckingIn && (
            <>
              <Spinner height="h-4" />
              <span>Checking in...</span>
            </>
          )}

          {hasCheckedIn && (
            <>
              <CheckIcon className="w-5 h-5" />
              <span>Checked in</span>
            </>
          )}
        </div>
      </div>
    </>
  )
}

const FoundProduct = ({ product, onHide }) => {
  const { mutateAsync: createOrder } = useAddOrder()
  const { mutateAsync: addOrderItem } = useAddOrderItem()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const notification = useNotificationContext()

  const handleNewOrder = async () => {
    setLoading(true)

    const { data: order } = await createOrder({})

    await addOrderItem(
      [
        order.id,
        {
          offering_type: 'product',
          offering_id: product.id,
        },
      ],
      {
        onError: (error: any) => {
          notification.notify(<ApiErrorNotification error={error} />)
          setLoading(false)
        },
      }
    )

    history.push(`/orders/${order.id}`)
    setLoading(false)
    onHide()
  }

  return (
    <>
      <div className="flex items-start">
        <div className="flex flex-1 items-start space-x-4">
          <div>
            <div className="font-semibold text-xl">{product.name}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-items-stretch space-x-2 mt-2">
        <Button label="Create order with item" onClick={handleNewOrder} loading={loading} />
      </div>
    </>
  )
}

const BarcodeNotFound = ({ code }) => (
  <div className="flex items-start">
    <div className="flex items-center space-x-2 text-sm">
      <ExclamationCircleIcon className="h-5 w-5" />
      <div>
        Couldn't find a client or product with code <span className="font-medium">{code}</span>
      </div>
    </div>
  </div>
)

export const ScannedBarcodeToast = ({ code, withAutoClientCheckIn = true }) => {
  const { isLoading: isLookingUpCustomer, data: { data: customer } = {} } = useClientBarcodeSearch(
    code,
    {
      enabled: code !== null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      cacheTime: 0,
    }
  )

  const { isLoading: isLookingUpProduct, data: { data: product } = {} } = useProductBarcodeSearch(
    code,
    {
      enabled: code !== null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      cacheTime: 0,
    }
  )

  const isLoading = isLookingUpCustomer || isLookingUpProduct

  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (hide) {
      setShow(false)
    }
  }, [hide])

  useEffect(() => {
    if (!hide) {
      setShow(true)
    }
  }, [hide])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm relative w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden p-4 style-reset">
        <div>
          {isLoading && <LookingUpBarcode code={code} />}
          {!isLoading && !customer && !product && <BarcodeNotFound code={code} />}
          {!isLoading && customer && (
            <FoundClient
              client={customer}
              onHide={() => setHide(true)}
              withAutoCheckIn={withAutoClientCheckIn}
            />
          )}

          {!isLoading && product && <FoundProduct product={product} onHide={() => setHide(true)} />}

          <div className="m-4 absolute top-0 right-0">
            <button
              className="bg-white rounded-xl inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet"
              onClick={() => {
                setHide(true)
              }}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  )
}
