import { RESERVATIONS_VIEW } from 'constants/permissions'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const VoucherCodes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'VoucherCodes' */ '../screens/VoucherCodesList/VoucherCodesList'
  ).then((file) => {
    return { default: file.VoucherCodesList }
  })
)

export const useRoutes = () => {
  return [
    {
      path: '/vouchers',
      name: 'Vouchers',
      component: VoucherCodes,
      exact: true,
      permissions: [RESERVATIONS_VIEW],
    },
  ]
}
