import { UseQueryOptions, useQuery, UseQueryResult } from 'react-query'
import { CouponCodesApi } from '@trybeapp/sdk.shop'

const api = new CouponCodesApi()

export const useGetApplicableOfferingsForCouponCode = (
  code?: string,
  options?: UseQueryOptions
): UseQueryResult<any> => {
  return useQuery(
    ['couponCodeApplicableOfferings', code],
    () => api.getCouponOfferingDiscounts(code),
    options
  )
}
