import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query'
import { MembershipRatesApi } from '@trybeapp/sdk.customer'
import { MembershipRatesApi as MembershipRatesApiTs } from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new MembershipRatesApi()
const tsApi = new MembershipRatesApiTs(apiConfiguration)

export const useMembershipRates = (params, options) => {
  return useQuery(['membershipRates', { params }], () => tsApi.listMembershipRates(params), {
    ...options,
  })
}

export const useMembershipRate = (membershipRateId, options) => {
  return useQuery(
    ['membershipRate', membershipRateId],
    async () => (await api.getMembershipRate(membershipRateId)).data,
    {
      ...options,
    }
  )
}

export const useInfiniteMembershipRates = (params = {}, options = {}) =>
  useInfiniteQuery(['membershipRates', { params }], fetchMembershipRates, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage
    },
    ...options,
  })

const fetchMembershipRates = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await tsApi.listMembershipRates({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useCreateMembershipRate = (membershipTypeId, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (membershipRate) =>
      await api.createMembershipRate({
        ...membershipRate,
        membership_type_id: membershipTypeId,
      }),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('membershipRates')
        if (options.onSuccess) {
          options.onSuccess(result)
        }
      },
    }
  )
}

export const useUpdateMembershipRate = (membershipRateId, options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (membershipRate) => await api.updateMembershipRate(membershipRateId, membershipRate),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries('membershipRates')
        queryClient.invalidateQueries(['membershipRate', membershipRateId])
        if (options.onSuccess) {
          options.onSuccess(result)
        }
      },
    }
  )
}

export const useDeleteMembershipRate = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation((membershipRateId) => api.archiveMembershipRate(membershipRateId), {
    onSuccess: (_, membershipRateId) => {
      queryClient.invalidateQueries('membershipRates')
      queryClient.invalidateQueries(['membershipRate', membershipRateId])
    },
    ...options,
  })
}
