import { BookingSummaryProvider, useBookingSummary } from 'contexts/BookingSummary'
import { GuestProvider } from 'contexts/Guest'
import { PractitionerProvider } from 'contexts/Practitioner'
import { RoomProvider } from 'contexts/Room'
import { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectBookingOrderItem } from 'selectors/OrdersSelectors'

export const BookingOrderItemContext = createContext()

export const BookingOrderItemProvider = ({ id, children }) => {
  const value = useSelector(selectBookingOrderItem(id)) || {}
  const { booking_summary: bookingSummaryId } = value

  return (
    <BookingOrderItemContext.Provider value={value}>
      <BookingSummaryProvider id={bookingSummaryId}>
        <WithResources>{children}</WithResources>
      </BookingSummaryProvider>
    </BookingOrderItemContext.Provider>
  )
}

const WithResources = ({ children }) => {
  const { practitioner: practitionerId, room: roomId, guest: guestId } = useBookingSummary() || {}

  return (
    <GuestProvider id={guestId}>
      <PractitionerProvider id={practitionerId}>
        <RoomProvider id={roomId}>{children}</RoomProvider>
      </PractitionerProvider>
    </GuestProvider>
  )
}

export const withBookingOrderItem =
  (Component) =>
  ({ id, ...props }) => (
    <BookingOrderItemProvider id={id}>
      <Component {...props} />
    </BookingOrderItemProvider>
  )

export const useBookingOrderItem = () => useContext(BookingOrderItemContext)
