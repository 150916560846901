import { RESERVATIONS_VIEW } from 'constants/permissions'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const OrderList = ReactLazyPreload(
  () => import(/* webpackChunkName: 'OrderList' */ '../screens/OrderList')
)

export const useRoutes = () => {
  return [
    {
      path: '/orders',
      name: 'Orders',
      component: OrderList,
      exact: true,
      permissions: [RESERVATIONS_VIEW],
    },
  ]
}
