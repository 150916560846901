import { forwardRef } from 'react'
import { system } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'
import { Input as VendorInput } from '@trybeapp/ui.input'
import VendorDateInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import { Box } from '@trybeapp/ui.box'
import { createEvent } from '@trybeapp/ui.utilities'
import { DateTime, SystemZone } from 'luxon'

/**
 * @deprecated
 */
export const Wrapper = styled(Box)(
  () => css`
    ${system}
    &&&&& {
      color: nude.900;
      .DayPickerInput {
        width: 100%;
      }
    }
  `
)

/**
 * @deprecated Use ui/components/Form/DatePicker instead
 */
export const DateInput = forwardRef(
  (
    {
      selected,
      dateFormat = 'D MMMM YYYY',
      onChange,
      onDayChange,
      dayPickerProps,
      name,
      inputProps,
      defaultStartOfDay = true,
      ...props
    },
    ref
  ) => {
    const Input = forwardRef(({ value, ...props }, ref) => {
      return (
        <VendorInput
          ref={ref}
          value={value && moment(value, 'MM-DD-YYYY').format(dateFormat)}
          autoComplete="off"
          name={name}
          handleReset={() => {
            typeof onDayChange === 'function' && onDayChange(undefined)
            typeof onChange === 'function' && onChange(undefined)
          }}
          {...props}
          {...inputProps}
        />
      )
    })

    const localOnDayChange = (value) => {
      if (defaultStartOfDay) {
        value = value
          ? DateTime.fromJSDate(value, { zone: new SystemZone() }).startOf('day').toJSDate()
          : undefined
      }

      typeof onDayChange === 'function' && onDayChange(value)
      typeof onChange === 'function' && onChange(value)
    }

    return (
      <Wrapper>
        <VendorDateInput
          component={Input}
          name={name}
          selectedDay={selected}
          onDayChange={localOnDayChange}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={'Select a date'}
          dayPickerProps={{
            localeUtils: MomentLocaleUtils,
            ...dayPickerProps,
          }}
          ref={ref}
          {...props}
        />
      </Wrapper>
    )
  }
)

/**
 * @deprecated
 */
export const FormikDateInput = forwardRef(({ onChange: parentOnChange, ...props }, ref) => {
  const handleOnChange = (value) => parentOnChange(createEvent({ value, name: props.name }))
  return <DateInput onChange={handleOnChange} {...props} ref={ref} />
})
