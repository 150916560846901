import { createContext, useContext, useState } from 'react'
import { useModalState } from '@trybeapp/ui.modal'

export const AddCourseOrderItemOverlayModalContext = createContext()

export const AddCourseOrderItemOverlayModalProvider = ({ children }) => {
  const modal = useModalState()
  const [courseType, setCourseType] = useState()

  const values = {
    modal,
    courseType,
    setCourseType,
  }

  return (
    <OrderItemProvider>
      <AddCourseOrderItemOverlayModalContext.Provider value={values}>
        {children}
      </AddCourseOrderItemOverlayModalContext.Provider>
    </OrderItemProvider>
  )
}

export const useAddCourseOrderItemOverlay = () => useContext(AddCourseOrderItemOverlayModalContext)

export const OrderItemContext = createContext()

export const OrderItemProvider = ({ children }) => {
  const [orderItem, setOrderItem] = useState({})

  return (
    <OrderItemContext.Provider value={{ orderItem, setOrderItem }}>
      {children}
    </OrderItemContext.Provider>
  )
}

export const useOrderItem = () => useContext(OrderItemContext)
