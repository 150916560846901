import { productActionTypes as actions } from '@trybeapp/ducks.inventory'
import { LISTEN } from './actionTypes'

const INITIAL_STATE = {
  copying: false,
  copied: false,
  error: null,
  listen: false,
}

export const CopyInventoryProductOverlayReducer = (state = INITIAL_STATE, action) => {
  if (action.type !== LISTEN && !state.listen) {
    return state
  }

  switch (action.type) {
    case LISTEN:
      if (action.value) {
        return {
          ...state,
          listen: action.value,
        }
      }

      return { ...INITIAL_STATE }

    case actions.COPY_PRODUCT:
      return {
        ...state,
        error: null,
        copied: false,
        copying: true,
      }

    case actions.COPY_PRODUCT_ERROR:
      return {
        ...state,
        error: action.message,
        copying: false,
      }

    case actions.COPY_PRODUCT_SUCCESS:
      return {
        ...state,
        copied: true,
        copying: false,
        copiedId: action.result,
      }

    default:
      return state
  }
}
