import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useUser } from 'hooks/UseUser'
import { useEffect } from 'react'

export const Stonly = () => {
  const { sub: userId } = useUser()
  const siteId = useCurrentSiteId()

  useEffect(() => {
    if (import.meta.env.VITE_APP_STONLY_ID) {
      const stonlyId = document.createElement('script')
      stonlyId.innerText = `var STONLY_WID = "${import.meta.env.VITE_APP_STONLY_ID}";`
      document.body.appendChild(stonlyId)

      const script = document.createElement('script')
      script.innerText = `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
        w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).queue=[],(y=t.createElement(o)).async=!0,
        (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
        4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
        (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
        }(window,document,"script","https://stonly.com/js/widget/v2/");`
      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    if (window.StonlyWidget && userId && siteId) {
      const script = document.createElement('script')
      script.innerHTML = `
        StonlyWidget('identify', '${userId}', {
          'site-id': '${siteId}',
        });
      `

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [userId, siteId])

  return null
}
