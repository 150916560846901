import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { CourseTypesApi } from '@trybeapp/sdk.shop'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new CourseTypesApi()

export const useCourseTypes = (params = {}, options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['courseTypes', params],
    async () => api.courseTypesIndex({ siteId, ...params }),
    options
  )
}

export const useInfiniteCourseTypes = (params = {}) => {
  const siteId = useCurrentSiteId()

  return useInfiniteQuery(['inifiniteCourseTypes', { siteId, ...params }], fetchCourseTypes, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
  })
}

const fetchCourseTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await api.courseTypesIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetCourseType = (courseTypeId: string, options = {}) =>
  useQuery(['courseType', { courseTypeId }], () => api.courseTypeShow(courseTypeId), options)

export const useCourseTypeAvailabilityRules = (
  courseTypeId: string,
  params: any = {},
  options?: UseQueryOptions
) =>
  useQuery<unknown, unknown, any>(
    ['courseTypeAvailabilityRules', { courseTypeId, params }],
    async () => {
      const response = await api.courseTypeAvailabilityRulesIndex(courseTypeId, params)

      response.data.forEach((availabilityRule) => {
        availabilityRule.date_from = availabilityRule.date_from
          ? sdkDateToSystemDateTime(availabilityRule.date_from).toJSDate()
          : null

        availabilityRule.date_to = availabilityRule.date_to
          ? sdkDateToSystemDateTime(availabilityRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedCourseTypeAvailabilityRules = (
  courseTypeId,
  params = {},
  options?: UseQueryOptions
) => {
  const [page] = usePageQueryParam()

  return useCourseTypeAvailabilityRules(courseTypeId, { page, params }, options)
}

export const useCreateCourseTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    ([courseTypeId, values]: any) =>
      api.courseTypeAvailabilityRuleStore(courseTypeId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypeAvailabilityRules'])
      },
    }
  )
}

export const useUpdateCourseTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([courseTypeId, ruleId, values]: any) =>
      api.courseTypeAvailabilityRuleUpdate(courseTypeId, ruleId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypeAvailabilityRules'])
      },
    }
  )
}

export const useDeleteCourseTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([courseTypeId, ruleId]: any) => api.courseTypeAvailabilityRuleDestroy(courseTypeId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypeAvailabilityRules'])
      },
    }
  )
}

export const useCourseTypePriceRules = (
  courseTypeId: string,
  params: any = {},
  options?: UseQueryOptions
) =>
  useQuery<unknown, unknown, any>(
    ['courseTypePriceRules', { courseTypeId, params }],
    async () => {
      const response = await api.courseTypePriceRulesIndex(courseTypeId, params)

      response.data.forEach((priceRule) => {
        priceRule.date_from = priceRule.date_from
          ? sdkDateToSystemDateTime(priceRule.date_from).toJSDate()
          : null

        priceRule.date_to = priceRule.date_to
          ? sdkDateToSystemDateTime(priceRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedCourseTypePriceRules = (
  courseTypeId,
  params = {},
  options?: UseQueryOptions
) => {
  const [page] = usePageQueryParam()

  return useCourseTypePriceRules(courseTypeId, { page, params }, options)
}

export const useCreateCourseTypePriceRule = (courseTypeId: string) => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (values: any) =>
      api.courseTypePriceRuleStore(courseTypeId, {
        weekdays: values.weekdays,
        is_available: values.is_available,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
        max_duration: values.max_duration,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypePriceRules'])
      },
    }
  )
}

export const useUpdateCourseTypePriceRule = (courseTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, values }: any) =>
      api.courseTypePriceRuleUpdate(courseTypeId, id, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        max_duration: values.max_duration,
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypePriceRules'])
      },
    }
  )
}

export const useDeleteCourseTypePriceRule = (courseTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation((id: string) => api.courseTypePriceRuleDestroy(courseTypeId, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['courseTypePriceRules'])
    },
  })
}

export const useCreateCourseType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation((courseType: any) => api.courseTypeStore({ ...courseType, siteId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['courseTypes'])
    },
  })
}

export const useCopyCourseType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, courseType] = args
      return await api.courseTypeCopy(copyFromId, { courseType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypes'])
      },
    }
  )
}

export const useUpdateCourseType = (courseTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, any>(
    (courseType) => api.courseTypeUpdate(courseTypeId, courseType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['courseTypes'])
        queryClient.invalidateQueries(['courseType', { courseTypeId }])
      },
    }
  )
}

export const useDeleteCourseType = () => {
  const queryClient = useQueryClient()

  return useMutation((courseTypeId: string) => api.courseTypeDestroy(courseTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['courseTypes'])
    },
  })
}

export const useRestoreCourseType = () => {
  const queryClient = useQueryClient()

  return useMutation((courseId: any) => api.courseTypeRestore(courseId), {
    onSuccess: () => {
      queryClient.invalidateQueries('courseTypes')
    },
  })
}
