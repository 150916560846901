import { createContext, Fragment, useState, useContext, useEffect, useMemo } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { Spinner } from './Spinner'
import { createPortal } from 'react-dom'

export const NotificationContext = createContext({
  notify: (_: JSX.Element) => void 0,
  notifyApiError: (_: Response, string?) => void 0,
  clearAllNotifications: () => void 0,
  removeNotification: (_: number) => void 0,

  /** @deprecated */
  toast: (_: JSX.Element, __: { position: string }) => void 0,
})

interface NotificationStore {
  [index: string]: any
}

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationStore>({})

  const clearAllNotifications = () => setNotifications({})

  const notify = (notification: JSX.Element) => {
    const newNotifications = { ...notifications }
    const key = new Date().getTime()
    newNotifications[key] = notification

    setNotifications(newNotifications)

    return key
  }

  /** Creates a notification from a TS SDK error response */
  const notifyApiError = (response: Response, description?: string) => {
    return response
      .json()
      .then((error) => notify(<ApiErrorNotification error={{ body: error }} />))
      .catch(() =>
        notify(
          <Notification variant="danger" title="An error occurred" description={description} />
        )
      )
  }

  const removeNotification = (key: number) => {
    const newNotifications = { ...notifications }
    delete newNotifications[key]

    setNotifications(newNotifications)
  }

  /**
   * This is here for compatibility to allow direct swapping
   * from @trybeapp/ui.toast to new notifications.
   * @deprecated Use notifications.notify() instead
   */
  const toast = (element: JSX.Element, position = {}) => {
    return notify(element)
  }

  return (
    <NotificationContext.Provider
      value={{ notify, notifyApiError, clearAllNotifications, removeNotification, toast }}
    >
      <>
        {children}

        {createPortal(
          <NotificationArea>
            {Object.keys(notifications).map((key) => (
              <Fragment key={key}>{notifications[key]}</Fragment>
            ))}
          </NotificationArea>,
          document.getElementsByTagName('body')[0]
        )}
      </>
    </NotificationContext.Provider>
  )
}

export const NotificationArea = ({ children }) => (
  <div
    aria-live="assertive"
    className="fixed inset-0 flex pt-20 sm:pt-6 px-4 py-6 pointer-events-none sm:p-6 style-reset z-60"
  >
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">{children}</div>
  </div>
)

interface NotificationProps {
  title: string
  description: string
  variant: 'success' | 'info' | 'danger' | 'warning' | 'pending'
  autoDismiss?: boolean
  listMessages?: string[]
  hideClose?: boolean
}

interface ErrorNotificationProps {
  error?: {
    body?: ApiError
  }
  defaultDescription?: string
}

interface ApiError {
  message: string
  errors?: { [key: string]: string }
}

export const ApiErrorNotification: React.FC<ErrorNotificationProps> = ({
  error,
  defaultDescription = 'An error occurred, please try again.',
  ...props
}) => {
  const { message: title = null, errors = {} } = error.body ?? {}
  const messages: string[] = Object.values(errors)

  const description =
    messages.length > 0 ? 'Please fix the errors below and try again.' : defaultDescription

  return (
    <Notification
      variant="danger"
      title={title || 'An error occurred'}
      listMessages={messages}
      description={description}
      {...props}
    />
  )
}

export const Notification: React.FC<NotificationProps> = ({
  title,
  description,
  variant,
  autoDismiss = false,
  listMessages = [],
  hideClose = false,
}) => {
  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)

  const [textColor, backgroundColor] = useMemo(() => {
    switch (variant) {
      default:
      case 'info':
        return ['text-blue-400', 'bg-blue-200']
      case 'success':
        return ['text-green-400', 'bg-green-200']
      case 'warning':
        return ['text-yellow-400', 'bg-yellow-200']
      case 'danger':
        return ['text-red-400', 'bg-red-200']
    }
  }, [variant])

  const icon = useMemo(() => {
    switch (variant) {
      default:
      case 'info':
        return InformationCircleIcon
      case 'pending':
        return () => <Spinner variant="info" />
      case 'success':
        return CheckCircleIcon
      case 'danger':
        return XCircleIcon
      case 'warning':
        return ExclamationCircleIcon
    }
  }, [variant])

  useEffect(() => {
    if (hide) {
      setShow(false)
    }
  }, [hide])

  useEffect(() => {
    if (!hide) {
      setShow(true)
    }

    if (autoDismiss) {
      setTimeout(() => {
        setHide(true)
      }, 6000)
    }
  }, [autoDismiss, hide])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-xl pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            {icon && (
              <div className="flex-shrink-0">
                {icon({
                  className: `h-6 w-6 ${textColor}`,
                  'aria-hidden': true,
                })}
              </div>
            )}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900 break-words">{title}</p>
              {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
              {listMessages.length > 0 && (
                <ul className="list-disc pl-5 space-y-1 mt-2">
                  {listMessages.map((message, i) => (
                    <li className="text-sm text-gray-500" key={i}>
                      {message}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              {hideClose === false && (
                <button
                  className="bg-white rounded-xl inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet"
                  onClick={() => {
                    setHide(true)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>

        {autoDismiss && (
          <div className="mt-1 w-full h-1">
            <div className={`h-1 ${backgroundColor} animate-dismiss-indicator`} />
          </div>
        )}
      </div>
    </Transition>
  )
}

/**
 * @deprecated
 */
export const Toast = ({ variant, children }) => {
  let newVariant = variant

  // variant 'error' is now called 'danger'
  if (variant === 'error') {
    newVariant = 'danger'
  }

  // The title is in the children as an element called Toast.Title.
  // We need to move this to the title prop
  // Everything else in the children is the description
  const [titleComponent, ...description] = children
  const title = titleComponent.props.children

  return (
    <Notification
      variant={newVariant}
      title={title.toString()}
      description={description.toString()}
      autoDismiss
    />
  )
}

/** @deprecated */
Toast.Title = ({ children }) => children

export const useNotificationContext = () => useContext(NotificationContext)

/**
 * This is here for compatibility to allow direct swapping
 * from @trybeapp/ui.toast to new notifications.
 * @deprecated Use useNotificationContext instead
 */
export const useToast = () => {
  const context = useContext(NotificationContext)

  return context.toast
}
