import {
  useMutation,
  useQueryClient,
  useQuery,
  MutationOptions,
  UseQueryOptions,
  UseMutationResult,
  UseQueryResult,
} from 'react-query'
import {
  AddMembershipChargePayment,
  MembershipChargeResponse,
  MembershipsApi as MembershipsApiTs,
} from '@trybeapp/sdk'
import { MembershipsApi } from '@trybeapp/sdk.customer'
import { BasketApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { DateTime } from 'luxon'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new MembershipsApi()
const basketApi = new BasketApi()
const membershipsApiTs = new MembershipsApiTs(apiConfiguration)

export const useMembershipsForCustomer = (customerId: string, options: UseQueryOptions = {}) =>
  useQuery<unknown, unknown, any>(
    ['memberships', customerId],
    async () =>
      await api.listMemberships({
        customerId: customerId,
      }),
    options
  )

export const useMemberships = (params: any = {}, options: UseQueryOptions = {}) => {
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  params.siteId = useCurrentSiteId()
  params.createdAtFrom = params.createdAtFrom ? toIsoDate(params.createdAtFrom) : null
  params.createdAtTo = params.createdAtTo ? toIsoDate(params.createdAtTo) : null
  params.endDateFrom = params.endDateFrom ? toIsoDate(params.endDateFrom) : null
  params.endDateTo = params.endDateTo ? toIsoDate(params.endDateTo) : null
  params.nextBillingDateFrom = params.nextBillingDateFrom
    ? DateTime.fromJSDate(params.nextBillingDateFrom).toISODate()
    : null
  params.nextBillingDateTo = params.nextBillingDateTo
    ? DateTime.fromJSDate(params.nextBillingDateTo).toISODate()
    : null

  return useQuery<any>(['memberships', { ...params }], () => api.listMemberships(params), options)
}

export const useExportMemberships = (): UseMutationResult<any> => {
  const siteId = useCurrentSiteId()

  return useMutation(['membershipExport'], (params: any = {}) => {
    params.siteId = siteId
    params.createdAtFrom = params.createdAtFrom ? toIsoDate(params.createdAtFrom) : null
    params.createdAtTo = params.createdAtTo ? toIsoDate(params.createdAtTo) : null
    params.endDateFrom = params.endDateFrom ? toIsoDate(params.endDateFrom) : null
    params.endDateTo = params.endDateTo ? toIsoDate(params.endDateTo) : null
    params.nextBillingDateFrom = params.nextBillingDateFrom
      ? toIsoDate(params.nextBillingDateFrom)
      : null
    params.nextBillingDateTo = params.nextBillingDateTo ? toIsoDate(params.nextBillingDateTo) : null

    return api.queueListMemberships(params)
  })
}

export const useCustomerMembership = (membershipId: string, options = {}) =>
  useQuery(['membership', membershipId], () => api.getMembership(membershipId), options)

export const useMembership = (
  membershipId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery<unknown, unknown, any>(
    ['membershipTs', membershipId],
    () => membershipsApiTs.getMembership({ membershipId }),
    options
  )

export const useConfirmMembership = () => {
  const queryClient = useQueryClient()

  return useMutation((membershipId) => api.confirmMembership(membershipId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['memberships'])
    },
  })
}

export const useCreateCustomerMembership = (customerId: string, options: any = {}) => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async (membership: any) =>
      basketApi.createMembershipOrder({
        customer_id: customerId,
        site_id: siteId,
        ...membership,
      }),
    {
      ...options,
      onSuccess: (result) => {
        queryClient.invalidateQueries(['memberships'])
        if (options.onSuccess) {
          options.onSuccess(result)
        }
      },
    }
  )
}

export const useCancelMembership = (membershipId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (passedMembershipId = null) =>
      await api.cancelMembership(membershipId ?? passedMembershipId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['memberships'])
      },
    }
  )
}

export const useRequestMandate = (membershipId: string) =>
  useMutation(() => api.requestMandate(membershipId))

export const useRequestMandateForMembership = () =>
  useMutation((membershipId: string) => api.requestMandate(membershipId))

export const useRetryPaymentForMembership = () =>
  useMutation((membershipId: string) => api.retryPayment(membershipId))

export const useUpdateMembership = (membershipId: string, options = {}) => {
  const queryClient = useQueryClient()

  return useMutation<any, any, any>(
    (updateMembershipRequest) => api.updateMembership(membershipId, { updateMembershipRequest }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['memberships'])
      },
    }
  )
}

export const useAddMembershipPayment = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ membershipId, paymentMethodId }: any) =>
      api.addManualPayment(membershipId, {
        addManualPaymentRequest: {
          processor_type_id: paymentMethodId,
        },
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        // Invalidate the list of memberships because we've updated the next billing date of this one.
        queryClient.invalidateQueries(['memberships'])

        if (options.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      },
    }
  )
}

export const useAddMembershipChargePayment = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([membershipId, chargeId, addMembershipChargePayment]: [
      string,
      string,
      AddMembershipChargePayment,
    ]) => {
      return membershipsApiTs.actionAddMembershipChargePayment({
        membershipId,
        chargeId,
        addMembershipChargePayment,
      })
    },
    {
      onSuccess: (response: MembershipChargeResponse) => {
        queryClient.invalidateQueries([
          'chargesForMembership',
          { membershipId: response.data.membership.id },
        ])
      },
    }
  )
}

export const useArchiveMembership = () => {
  const queryClient = useQueryClient()

  return useMutation((membershipId) => api.archiveMembership(membershipId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['memberships'])
    },
  })
}

export const useNextBillingDetails = (
  membershipId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery<unknown, unknown, any>(
    ['membershipNextBillingDetails', membershipId],
    () => api.nextBillingDetails(membershipId),
    options
  )

export const useSubsequentBillingDetails = (
  membershipId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery<unknown, unknown, any>(
    ['membershipSubsequentBillingDetails', membershipId],
    () => api.subsequentBillingDetails(membershipId),
    options
  )
