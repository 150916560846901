import { endsWith } from 'lodash'

export const getPageTitle = () => {
  return document.title
}

export const setPageTitle = (title) => {
  if (!title) {
    document.title = 'Trybe'
  } else if (!endsWith(title, 'Trybe')) {
    document.title = `${title} | Trybe`
  } else {
    document.title = title
  }
}
