import { stockTakeActionTypes } from '@trybeapp/ducks.inventory'
import { PAGINATION_META } from 'constants/paginationMeta'
import { without } from 'lodash'
import { LISTEN } from './actionTypes'

export const INITIAL_STATE = {
  selectedIds: [],
  deleting: false,
  deletingId: null,
  creating: false,
  created: false,
  createdId: null,
  loaded: false,
  loading: false,
  error: null,
  meta: { ...PAGINATION_META },
}

export const StockTakeSectionReducer = (state = INITIAL_STATE, action) => {
  if (action.type !== LISTEN && !state.listen) {
    return state
  }

  switch (action.type) {
    case LISTEN:
      if (action.value) {
        return {
          ...state,
          listen: action.value,
        }
      }

      return { ...INITIAL_STATE }
    case stockTakeActionTypes.FETCH_STOCK_TAKES:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case stockTakeActionTypes.FETCH_STOCK_TAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        selectedIds: action.result,
        meta: action.meta,
      }

    case stockTakeActionTypes.FETCH_STOCK_TAKES_ERROR:
      return {
        ...state,
        error: action.message,
        loading: false,
      }

    case stockTakeActionTypes.DELETE_STOCK_TAKE:
      return {
        ...state,
        deleting: true,
        deletingId: action.stockTakeId,
        deleted: false,
        error: null,
      }

    case stockTakeActionTypes.DELETE_STOCK_TAKE_SUCCESS:
      return {
        ...state,
        selectedIds: without(state.selectedIds, action.stockTakeId),
        deleting: false,
        deleted: true,
        deletingId: null,
      }

    case stockTakeActionTypes.DELETE_STOCK_TAKE_ERROR:
      return {
        ...state,
        error: action.message,
        deleting: false,
        deletingId: null,
      }

    case stockTakeActionTypes.CREATE_STOCK_TAKE:
      return {
        ...state,
        creating: true,
        created: false,
        error: null,
        createdId: null,
      }

    case stockTakeActionTypes.CREATE_STOCK_TAKE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
        createdId: action.result,
      }

    case stockTakeActionTypes.CREATE_STOCK_TAKE_ERROR:
      return {
        ...state,
        error: action.message,
        creating: false,
      }

    default:
      return state
  }
}
