import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Redirect, matchPath, useLocation } from 'react-router-dom'
import { routes } from 'routes'
import { useTrybeApiSetup } from 'utilities/TrybeApiClient'
import 'utilities/fontAwesomeLibrary'
import 'utilities/momentLocale'
import { useUser } from 'hooks/UseUser'
import { OrganisationConfigProvider } from 'contexts/OrganisationConfig'
import { SpinnerOverlay } from 'components/SpinnerOverlay'
import { SiteConfigProvider } from 'contexts/SiteConfig'
import { Stonly } from 'components/Stonly'
import { FreshChat } from 'components/FreshChat'
import {
  init,
  reactRouterV5Instrumentation,
  withErrorBoundary,
  replayIntegration,
  withProfiler,
} from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'
import { ErrorFallback } from 'components/ErrorFallback'
import { Segment } from 'components/Segment'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useCurrentUserSitePermissions } from 'api/UserSiteRoles'
import { HTML5Backend as Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { PusherProvider } from '@harelpls/use-pusher'
import { useShopPusherConfig } from 'utilities/Pusher/Config'
import { NotificationProvider } from 'ui/components/Notification'
import SidebarLayout from '../../layouts/SidebarLayout/SidebarLayout'
import { GlobalBarcodeListenerProvider } from 'components/BarcodeListener/GlobalContext'
import 'react-phone-number-input/style.css'
import { oidcConfiguration } from 'constants/oidcConfiguration'
import { Authenticating, NotAuthorized } from 'components/Authentication'
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context'
import { PromptForUpdate } from 'components/PromptForUpdate'
import { GantnerUsageNotification } from 'components/GantnerUsageNotification'
import { NotFound404 } from 'screens/NotFound404'
import * as Sentry from '@sentry/browser'
import { Log } from 'oidc-client-ts'

const App = () => {
  const { default_organisation_id: organisationId } = useUser()
  useTrybeApiSetup()

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <OrganisationConfigProvider organisationId={organisationId}>
          <SiteConfigProvider>
            <DndProvider backend={Backend}>
              <IfLoadedPermissions>
                <WithPusher>
                  <Router>
                    <NotificationProvider>
                      <GantnerUsageNotification />
                      <PromptForUpdate />
                      <GlobalBarcodeListenerProvider>
                        <SidebarLayout>
                          <Switch>
                            {routes.map((route, index) => (
                              <route.route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                permissions={route.permissions}
                                title={route.name}
                                component={(props) => <route.component {...props} />}
                              />
                            ))}
                            <DefaultRedirect />
                          </Switch>
                        </SidebarLayout>
                        <Segment />
                        <FreshChat />
                        <Stonly />
                      </GlobalBarcodeListenerProvider>
                    </NotificationProvider>
                  </Router>
                  {import.meta.env.VITE_APP_ENABLE_REACT_QUERY_DEV_TOOLS && (
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                  )}
                </WithPusher>
              </IfLoadedPermissions>
            </DndProvider>
          </SiteConfigProvider>
        </OrganisationConfigProvider>
      </QueryClientProvider>
    </>
  )
}

const IfLoadedPermissions = ({ children }) => {
  const { isSuccess, isError, error } = useCurrentUserSitePermissions()

  if (isError && error.status === 403) return <NotAuthorized />

  return !isSuccess ? <SpinnerOverlay /> : children
}

const DefaultRedirect = () => {
  const { pathname } = useLocation()
  const to = '/calendar'

  // If this is a /beta route, let's try redirecting to the non-beta version.
  if (pathname.startsWith('/beta')) {
    return <Redirect to={pathname.replace('/beta', '')} />
  }

  return <NotFound404 />
}

const history = createBrowserHistory()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, if any request returns a 401, 403 or 404, don't retry.
      // Any other status code will retry 2 more times.
      retry: (failureCount, error: any) => {
        if ([401, 403, 404].includes(error.status)) {
          return false
        }

        return failureCount < 3
      },
    },
  },
})

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_PROJECT_ENVIRONMENT,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [
      import.meta.env.VITE_APP_PROJECT_ENVIRONMENT === 'staging' ? replayIntegration() : null,
      new Integrations.BrowserTracing({
        routingInstrumentation() {
          return reactRouterV5Instrumentation(history, routes as any, matchPath)
        },
      }),
    ].filter(Boolean),
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1,
    tracesSampleRate: 0.01,
    beforeSend(event) {
      if (event.exception) {
        const exceptionValues = event.exception.values || []
        for (const exception of exceptionValues) {
          if (exception.stacktrace) {
            const frames = exception.stacktrace.frames || []
            for (const frame of frames) {
              // Check if the frame's filename includes the Freshchat domain
              if (frame.filename && frame.filename.includes('https://wchat.freshchat.com/')) {
                return null // Ignore this error
              }
            }
          }
        }
      }

      return event
    },
    ignoreErrors: [
      /^Loading (CSS )?chunk \d* failed\./,
      /^Failed to fetch dynamically imported module/,
      /^Illegal invocation$/,
      /ResizeObserver loop completed with undelivered notifications/,
      /ResizeObserver loop limit exceeded$/,
      /Failed to update a ServiceWorker for scope/,
      /\.try\.be\/service-worker\.js load failed/,
      /Load failed/,
      /InvalidStateError: Failed to update a ServiceWorker for scope/,
      /Importing a module script failed./,
      /Unexpected end of JSON input/,
      /Cannot update a null\/nonexistent service worker registration/,
    ],
  })
}

const WithPusher = ({ children }) => {
  const pusherConfig = useShopPusherConfig()

  return <PusherProvider {...pusherConfig}>{children}</PusherProvider>
}

const withAuth = (Component) => (props) => {
  return (
    <AuthProvider
      {...oidcConfiguration}
      onSigninCallback={(user: any) => {
        window.history.replaceState({}, document.title, user.state || window.location.pathname)
      }}
    >
      <Component {...props} />
    </AuthProvider>
  )
}

const useUserManagerEvents = () => {
  const auth = useAuth()

  Log.setLevel(Log.INFO)
  Log.setLogger(console)

  useEffect(() => {
    return import.meta.env.NODE_ENV !== 'production' && auth.events.addUserLoaded((user) => {})
  }, [auth.events])

  useEffect(() => {
    return import.meta.env.NODE_ENV !== 'production' && auth.events.addUserUnloaded(() => {})
  }, [auth.events])

  useEffect(() => {
    return import.meta.env.NODE_ENV !== 'production' && auth.events.addUserSignedIn(() => {})
  }, [auth.events])

  useEffect(() => {
    return import.meta.env.NODE_ENV !== 'production' && auth.events.addUserSignedOut(() => {})
  }, [auth.events])

  useEffect(() => {
    return import.meta.env.NODE_ENV !== 'production' && auth.events.addUserSessionChanged(() => {})
  }, [auth.events])
}

const AuthWrapper = () => {
  const auth = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)
  const [hasAddedEvent, setHasAddedEvent] = useState(false)

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect({ state: window.location.href })
      setHasTriedSignin(true)
    }
  }, [auth, hasTriedSignin])

  // https://github.com/authts/react-oidc-context/issues/390#issue-1249428231
  useEffect(() => {
    if (!hasAddedEvent) {
      auth.events.addAccessTokenExpiring(() => {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Starting silent renew',
        })

        auth.startSilentRenew()
      })

      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'Added accessTokenExpiring event callback',
      })

      setHasAddedEvent(true)

      auth.events.addAccessTokenExpired(() => {
        Sentry.captureException('Access token expired')
      })

      auth.events.addSilentRenewError((error) => {
        Sentry.captureException(error, { extra: { category: 'auth', event: 'silentRenewError' } })

        if (auth.user?.expired) {
          auth.removeUser()
          auth.signoutRedirect()
        }
      })
    }
  }, [])

  useUserManagerEvents()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div></div>
  }

  if (auth.isLoading) {
    return <Authenticating />
  }

  if (auth.error && (!auth.user || auth.user.expired)) {
    return <NotAuthorized />
  }

  if (auth.isAuthenticated) {
    return <App />
  }

  return null
}

export default withProfiler(
  withErrorBoundary(withAuth(AuthWrapper), {
    fallback: ({ error, ...props }) => <ErrorFallback error={error} {...props} />,
  })
)
