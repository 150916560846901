import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { RoomsApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'

const api = new RoomsApi()

export const useAllRooms = (
  siteId?: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const currentSiteId = useCurrentSiteId()
  const params = {
    siteId: siteId ?? currentSiteId,
    perPage: -1,
  }

  // With these defaults, rooms will always be loaded from the cache initially,
  // and refreshed in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useRooms(params, options)
}

export const usePagedRooms = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useRooms({ page, ...params }, options)
}

export const useRooms = (params: any = {}, options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(['rooms', siteId, params], () => api.roomsIndex({ ...params, siteId }), options)
}

export const useInfiniteRooms = (
  params: any = {},
  options: UseInfiniteQueryOptions = {}
): UseInfiniteQueryResult => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useInfiniteQuery(
    ['infiniteRooms', { params }],
    async (queryOptions) => {
      const { queryKey, pageParam: page = 1 } = queryOptions as any
      const { params } = queryKey[1]
      const response = await api.roomsIndex({ page, ...params })

      return {
        data: response.data,
        page,
        nextPage: response.meta.last_page > page ? page + 1 : undefined,
        total: response.meta.total,
      }
    },
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextPage
      },
      ...options,
    }
  )
}

export const useGetRoom = (roomId, options: UseQueryOptions = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  const queryClient = useQueryClient()
  const allRoomsKey = ['rooms', { siteId, perPage: -1 }]

  // Initialise the data from cache if we have it already
  options.initialData = () => {
    const { data: rooms = [] }: any = queryClient.getQueryData(allRoomsKey) ?? {}

    const room = rooms.find((room) => room.id === roomId)

    return room ? { data: room } : undefined
  }

  // Tell the query when the cached data was last fetched so it can determine
  // whether it's stale.
  options.initialDataUpdatedAt = () => queryClient.getQueryState(allRoomsKey)?.dataUpdatedAt

  // With these defaults, rooms will always be loaded from the cache initially,
  // and refreshed in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(['rooms', roomId], () => api.roomShow(roomId), options)
}

export const useOrderedRooms = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['rooms', siteId, params],
    async () => await api.getOrderedRooms(siteId, params),
    options
  )
}

export const useUpdateOrderedRooms = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (roomIds: any) =>
      api.updateRoomOrder({
        room_ids: roomIds,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rooms'])
      },
    }
  )
}

export const useCreateRoom = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation((room: any) => api.roomStore({ ...room, site_id: siteId }), {
    onSuccess: () => {
      queryClient.invalidateQueries('rooms')
    },
  })
}

export const useUpdateRoom = () => {
  const queryClient = useQueryClient()

  return useMutation(([roomId, room]: any) => api.roomUpdate(roomId, room), {
    onSuccess: () => {
      queryClient.invalidateQueries('rooms')
    },
  })
}

export const useCopyRoom = () => {
  const queryClient = useQueryClient()

  return useMutation(({ copyFromId, room }: any) => api.roomCopy(copyFromId, { room }), {
    onSuccess: () => {
      queryClient.invalidateQueries('rooms')
    },
  })
}

export const useDeleteRoom = () => {
  const queryClient = useQueryClient()

  return useMutation((roomId: string) => api.roomDestroy(roomId), {
    onSuccess: () => {
      queryClient.invalidateQueries('rooms')
    },
  })
}
