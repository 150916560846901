import { brandActionTypes } from '@trybeapp/ducks.inventory'
import { without } from 'lodash'
import { PAGINATION_META } from 'constants/paginationMeta'
import { RESET_STATE } from '../../actionTypes'

export const INITIAL_STATE = {
  brandDeleted: false,
  brandsFetched: false,
  deleteError: null,
  paginationMeta: { ...PAGINATION_META },
  selectedIds: [],
}

export const InventoryBrandsSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case brandActionTypes.FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        brandsFetched: true,
        paginationMeta: action.meta,
        selectedIds: action.result,
      }

    case brandActionTypes.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        brandDeleted: true,
        selectedIds: without(state.selectedIds, action.brandId),
      }

    case brandActionTypes.DELETE_BRAND_ERROR:
      return {
        ...state,
        deleteError: action.message,
      }

    case RESET_STATE:
      return {
        brandDeleted: false,
        deleteError: null,
        paginationMeta: { ...PAGINATION_META },
        ...state,
      }

    default:
      return state
  }
}
