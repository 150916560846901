import { useDeleteCustomer } from 'api/Customers'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'

export const DeleteConfirmation = ({ clientId, onClose }) => {
  const { isLoading, mutate } = useDeleteCustomer(clientId)
  const notification = useNotificationContext()

  const handleDelete = () => {
    mutate(null, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Client deleted"
            description="All the data associated with this client has been anonymised."
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()

        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <ConfirmationModal
      isOpen={clientId !== null}
      title="Delete customer"
      onClose={() => onClose()}
      confirmButtonLabel="Delete"
      onConfirm={handleDelete}
      confirming={isLoading}
      variant="danger"
    >
      <p className="text-sm font-medium text-gray-700">
        Are you sure you want to delete this customer?
      </p>
      <p className="text-sm text-gray-500">
        All their personal data will be anonymised, and this cannot be undone.
      </p>
    </ConfirmationModal>
  )
}
