import { actionTypes as actions } from '@trybeapp/ducks.orders'

export const INITIAL_STATE = {
  complete: false,
  error: false,
  loading: false,
  selectedIds: [],
  meta: {},
}

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.FETCH_ORDERS:
      return {
        ...state,
        loading: true,
        error: false,
      }

    case actions.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        complete: true,
        selectedIds: [...action.orders.map((order) => order.id)],
        meta: action.meta,
      }

    case actions.FETCH_ORDERS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        complete: true,
      }
    default:
      return state
  }
}

export default reducer
