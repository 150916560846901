import { createContext, useState, useReducer } from 'react'

import { useModalState } from '@trybeapp/ui.modal'

export const ModalContext = createContext()

const ACTION_TYPE_SUDO = 'SUDO'
const ACTION_TYPE_SUDO_SUCCESS = 'SUDO_SUCCESS'
const ACTION_TYPE_SUDO_ERROR = 'SUDO_ERROR'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE_SUDO:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ACTION_TYPE_SUDO_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ACTION_TYPE_SUDO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    default:
      throw new Error()
  }
}

const INITIAL_STATE = {
  error: null,
}

export const ModalProvider = ({ children }) => {
  const modal = useModalState()
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [onSudo, setOnSudo] = useState()
  const onSudoError = (error) => dispatch({ type: ACTION_TYPE_SUDO_ERROR, payload: { error } })
  const onSudoSuccess = () => {
    dispatch({ type: ACTION_TYPE_SUDO_SUCCESS })
    modal.hide()
    onSudo()
  }

  return (
    <ModalContext.Provider value={{ modal, ...state, onSudoError, setOnSudo, onSudoSuccess }}>
      {children}
    </ModalContext.Provider>
  )
}
