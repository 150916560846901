import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { UsersApi } from '@trybeapp/sdk.auth'
import { useUser as useCurrentUser } from 'hooks/UseUser'

const api = new UsersApi()

export const useCurrentUserOptions = (options: UseQueryOptions = {}): UseQueryResult<any> => {
  const { sub: currentUserId } = useCurrentUser()
  options.cacheTime = Infinity
  options.refetchOnWindowFocus = false

  return useQuery(['userOptions', currentUserId], async () => await api.userOptionsIndex(), options)
}

export const useUpdateCurrentUserOptions = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()

  return useMutation(
    (userOptions) => {
      return api.userOptionsUpdate(userOptions)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userOptions')
      },
    }
  )
}
