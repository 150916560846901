import { forwardRef } from 'react'
import { Field as FormikField } from 'formik'
import { Field } from 'ui/components/Form/Field'

export const ConnectedField = forwardRef(({ component, name, children, ...props }, ref) => {
  if (!children && !component) {
    return null
  }

  if (props.autoComplete === 'off') {
    props.autoComplete = `off_${Math.random()}`
  }

  return (
    <FormikField name={name} value={props.value} type={props.type}>
      {({ field, meta }) => (
        <Field
          ref={ref}
          name={name}
          {...meta}
          {...field}
          connected
          {...props}
          component={component}
        >
          {children}
        </Field>
      )}
    </FormikField>
  )
})
