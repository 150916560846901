import { createContext, useContext, useEffect, useState } from 'react'
import { useModalState } from '@trybeapp/ui.modal'
import { isEmpty } from 'lodash'

export const AddProductOrderItemOverlayModalContext = createContext()

export const AddProductOrderItemOverlayModalProvider = ({ children }) => {
  const modal = useModalState()
  const [scannedProduct, setNewProduct] = useState({})

  return (
    <NewItemProvider newItem={scannedProduct}>
      <AddProductOrderItemOverlayModalContext.Provider value={{ modal, setNewProduct }}>
        {children}
      </AddProductOrderItemOverlayModalContext.Provider>
    </NewItemProvider>
  )
}

export const withAddProductOrderItemOverlayModalProvider =
  (Component) =>
  ({ id, ...props }) => (
    <AddProductOrderItemOverlayModalProvider>
      <Component {...props} />
    </AddProductOrderItemOverlayModalProvider>
  )

export const useAddProductOrderItemOverlay = () =>
  useContext(AddProductOrderItemOverlayModalContext)

export const NewItemContext = createContext()

export const NewItemProvider = ({ newItem: scannedItem, children }) => {
  const [newItem, setNewItem] = useState(scannedItem ?? {})

  useEffect(() => {
    // If the `scannedItem` prop was changed, set the newItem to it
    if (!isEmpty(scannedItem)) {
      setNewItem(scannedItem)
    }
  }, [scannedItem])

  return (
    <NewItemContext.Provider value={{ newItem, setNewItem }}>{children}</NewItemContext.Provider>
  )
}

export const useNewItem = () => useContext(NewItemContext)
