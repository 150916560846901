import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { VoucherTypesApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new VoucherTypesApi()

export const useVoucherTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(['voucherTypes', params], () => api.voucherTypesIndex(params), options)
}

export const useVoucherTypesForPageParam = (params: any = {}) => {
  const [page] = usePageQueryParam()
  params.page = page

  return useVoucherTypes(params)
}

export const useInfiniteVoucherTypes = (params = {}) =>
  useInfiniteQuery(['inifiniteVoucherTypes', { params }], fetchVoucherTypes, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
  })

const fetchVoucherTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await api.voucherTypesIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetVoucherType = (voucherTypeId: string, options = {}) =>
  useQuery(['voucherType', { voucherTypeId }], () => api.voucherTypeShow(voucherTypeId), options)

export const useOrderedVoucherTypes = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['voucherTypes', siteId, params],
    async () => await api.getOrderedVoucherTypes(siteId, params),
    options
  )
}

export const useUpdateOrderedVoucherTypes = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (voucherTypeIds: any) =>
      api.updateVoucherTypeOrder({
        voucher_type_ids: voucherTypeIds,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherTypes'])
      },
    }
  )
}

export const useVoucherAvailabilityRules = (voucherTypeId: string) =>
  useQuery(['voucherTypeAvailabilityRules', { voucherTypeId }], async () => {
    const response = await api.voucherTypeAvailabilityRulesIndex(voucherTypeId)

    response.data.forEach((availabilityRule) => {
      availabilityRule.date_from = availabilityRule.date_from
        ? sdkDateToSystemDateTime(availabilityRule.date_from).toJSDate()
        : null

      availabilityRule.date_to = availabilityRule.date_to
        ? sdkDateToSystemDateTime(availabilityRule.date_to).toJSDate()
        : null
    })

    return response
  })

export const useCreateVoucherAvailabilityRule = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    ([voucherTypeId, values]: any) =>
      api.voucherTypeAvailabilityRuleStore(voucherTypeId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherTypeAvailabilityRules'])
      },
    }
  )
}

export const useUpdateVoucherAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([voucherTypeId, ruleId, values]: any) =>
      api.voucherTypeAvailabilityRuleUpdate(voucherTypeId, ruleId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherTypeAvailabilityRules'])
      },
    }
  )
}

export const useDeleteVoucherAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([voucherTypeId, ruleId]: any) => api.voucherTypeAvailabilityRuleDestroy(voucherTypeId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherTypeAvailabilityRules'])
      },
    }
  )
}

export const useCreateVoucherType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (voucherType: any) => api.voucherTypeStore({ ...voucherType, site_id: siteId }),
    {
      onSuccess: (result: any) => {
        queryClient.invalidateQueries(['voucherTypes'])
      },
    }
  )
}

export const useCopyVoucherType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, voucherType] = args

      return await api.voucherTypeCopy(copyFromId, { voucherType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherTypes'])
      },
    }
  )
}

export const useUpdateVoucherType = (voucherTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation((voucherType) => api.voucherTypeUpdate(voucherTypeId, voucherType), {
    onSuccess: () => {
      queryClient.invalidateQueries(['voucherTypes'])
      queryClient.invalidateQueries(['voucherType', { voucherTypeId }])
    },
  })
}

export const useDeleteVoucherType = () => {
  const queryClient = useQueryClient()

  return useMutation((voucherTypeId: string) => api.voucherTypeDestroy(voucherTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['voucherTypes'])
    },
  })
}

export const useRestoreVoucherType = () => {
  const queryClient = useQueryClient()

  return useMutation((voucherTypeId: any) => api.voucherTypeRestore(voucherTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('voucherTypes')
    },
  })
}
