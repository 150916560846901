import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@trybeapp/ui.box'
import { Button } from '@trybeapp/ui.button'
import { Tag } from '@trybeapp/ui.tag'
import { Text } from '@trybeapp/ui.text'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { actions as orderActions } from '@trybeapp/ducks.orders'
import { STATUS_PENDING_CONFIRMATION, STATUS_CANCELLED } from 'constants/orderStatuses'
import { TYPE_APPOINTMENT } from 'constants/offeringTypes'
import { Col, Row } from '@trybeapp/ui.grid'
import { CostColumn, DateColumn, StartTimeColumn, StatusColumn, TypeColumn } from '../Columns'
import { Card } from '../../../components/Card'
import { HeadingsRow } from './HeadingsRow'
import { useDeleteOrderItem } from 'api/OrderItems'
import { useOrderIsLocked, useOrderIsSettled } from '../hooks'
import { map } from 'lodash'
import { sentenceJoin } from 'utilities/StringUtils'
import { ItemActions } from './ItemActions'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon } from '@heroicons/react/outline'
import { useOrderDetails } from 'screens/OrderDetails/contexts'

export const AppointmentItemRow = ({ item }) => {
  const { orderId } = useParams()
  const { id: itemId, status: itemStatus, type_name: name = '', type_product_code: code } = item
  const canConfirm = itemStatus === STATUS_PENDING_CONFIRMATION
  const itemIsCancelled = itemStatus === STATUS_CANCELLED
  const canEdit = !itemIsCancelled && !useOrderIsSettled() && !useOrderIsLocked()
  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <Col col={0.7} mb="lg">
            <Box display="flex" alignItems="center">
              {code && (
                <Text as="span" variant="body3" color="nude.700" mr="xs">
                  <strong>[{code}]</strong>
                </Text>
              )}
              <Text as="span" variant="body3" color="nude.700">
                <strong>{name}</strong>
              </Text>
            </Box>
          </Col>
        </Row>
        <HeadingsRow />
        <Row>
          <StatusColumn item={item} />
          <DateColumn item={item} />
          <StartTimeColumn item={item} />
          <TypeColumn item={item}>
            <BookingOrderItemResourceSummary item={item} />
            <AppointmentItemErrors item={item} />
          </TypeColumn>
          <CostColumn item={item} />
          <Col col={0.1}>
            <ItemActions item={item} handleDelete={handleDelete} isDeleting={isDeleting}>
              {canEdit && <EditItemButton item={item} />}
            </ItemActions>
            {canConfirm && (
              <Box>
                <ConfirmBasketItemButton item={item} />
              </Box>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export const BookingOrderItemResourceSummary = ({ item }) => {
  const { item_type: itemType = '', booking_summary: bookingSummary = {} } = item

  const resourceString = useMemo(() => {
    const practitioners = bookingSummary.practitioners || []
    const room = bookingSummary.room || {}
    const strings = []

    if (practitioners.length > 0) {
      strings.push(`with ${sentenceJoin(map(practitioners, 'name'), ',', '&')}`)
    }

    if (room?.name) {
      strings.push(`in ${room.name}`)
    }

    if (strings.length === 0) {
      return ''
    }

    const sentence = strings.join(' ')

    return sentence.charAt(0).toUpperCase() + sentence.slice(1)
  }, [bookingSummary.practitioners, bookingSummary.room])

  if (itemType !== TYPE_APPOINTMENT || !resourceString) return null

  return (
    <Text variant="meta1" color="nude.500" lineHeight={1.2}>
      {resourceString}
    </Text>
  )
}

const useAppointmentItemErrors = (item) => {
  const { booking_summary: bookingSummary = {}, guests } = item

  const practitioners = bookingSummary.practitioners || []
  const room = bookingSummary.room
  const numGuests = guests.length || 1

  const [errors, setErrors] = useState([])

  useEffect(() => {
    const errorsSet = new Set(errors)

    if (!errorsSet.has('Practitioner') && practitioners.length < numGuests) {
      errorsSet.add('Practitioner')
    }

    if (practitioners.length >= numGuests && errorsSet.has('Practitioner')) {
      errorsSet.delete('Practitioner')
    }

    if (!errorsSet.has('Room') && !room) {
      errorsSet.add('Room')
    }

    if (room && errorsSet.has('Room')) {
      errorsSet.delete('Room')
    }

    setErrors(Array.from(errorsSet))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitioners, room])

  return errors
}

const AppointmentItemErrors = ({ item }) => {
  const { item_type: itemType = '' } = item
  const errors = useAppointmentItemErrors(item)

  if (itemType !== TYPE_APPOINTMENT || errors.length === 0) return null

  return (
    <Box mt="0.2rem">
      <Tag variant="danger">
        <FontAwesomeIcon icon="exclamation-circle" />
        {errors.join(', ')}
      </Tag>
    </Box>
  )
}

const EditItemButton = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('appointmentWizard.editingAppointment', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}

const ConfirmBasketItemButton = ({ item }) => {
  const dispatch = useDispatch()
  const { id: itemId } = item
  const { orderId } = useParams()

  const handleOnClick = () => dispatch(orderActions.confirmBookingOrderItem(orderId, itemId))

  return (
    <Button variant="secondary-success" mr="sm" size="sm" onClick={handleOnClick}>
      <FontAwesomeIcon icon="check-circle" mr="sm" />
      Confirm
    </Button>
  )
}
