import { ReactLazyPreload } from 'utilities/PreloadLinks'

const EmailsHome = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'EmailsHome' */ '../components/Emails/EmailsHome').then((file) => {
    return { default: file.EmailsHome }
  })
)

const CampaignsList = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'CampaignsList' */ '../components/Emails/components/CampaignsList'
  ).then((file) => {
    return { default: file.CampaignsList }
  })
)

const EmailLists = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'EmailLists' */ '../components/Emails/components/EmailLists').then(
    (file) => {
      return { default: file.EmailLists }
    }
  )
)

export const EmailsDirectoryRoute = () => {
  return [
    {
      path: '/emails',
      name: 'Emails',
      component: EmailsHome,
      exact: true,
    },
  ]
}

export const useRoutes = () => {
  return [
    {
      path: '/emails/campaigns',
      name: 'Campaigns',
      component: CampaignsList,
      exact: true,
    },
    {
      path: '/emails/lists',
      name: 'Email lists',
      component: EmailLists,
      exact: true,
    },
  ]
}
