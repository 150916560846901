import { Select } from '@trybeapp/ui.select'
import { createEvent } from '@trybeapp/ui.utilities'
import { forwardRef } from 'react'

/**
 * @deprecated
 */
export const withFormikSelect = (Component) =>
  forwardRef(({ onChange: parentOnChange, name, ...props }, ref) => (
    <Component
      ref={ref}
      name={name}
      onChange={(value, event) => parentOnChange(createEvent({ name, value }))}
      {...props}
    />
  ))

/**
 * @deprecated
 */
export const FormikSelect = forwardRef(({ onChange: parentOnChange, name, ...props }, ref) => (
  <Select
    onChange={(value, event) => parentOnChange(createEvent({ name, value }))}
    {...props}
    ref={ref}
  />
))
