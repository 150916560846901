import { forwardRef, cloneElement, createRef } from 'react'
import { Label } from 'ui/components/Form/Label'
import classNames from 'classnames'

const TYPES = {
  Checkbox: 'checkbox',
  Radio: 'radio',
  Input: 'text',
  RadioTab: 'radio',
  Select: 'text',
  Toggle: 'checkbox',
}

const getBaseType = (type) => TYPES[type] || type

export const Field = forwardRef(
  (
    {
      name,
      helperText,
      checked,
      label,
      labelFor,
      labelInfo,
      passLabel = false,
      children,
      touched,
      error,
      component: Component,
      required = false,
      type = 'text',
      warning,
      onChange,
      onClick,
      componentProps,
      connected,
      ...rest
    }: any,
    ref: any
  ) => {
    const baseType = getBaseType(type || Component.displayName)
    const isCheckbox = baseType === 'checkbox'
    const isRadio = baseType === 'radio'
    const isCheckable = isRadio || isCheckbox
    const variant = getVariant({ connected, touched, warning, error })
    const Children = children && cloneElement(children, { variant: variant })
    const hintText = variant ? error || warning : helperText
    const inputRef = ref || createRef()

    const handleClick = (e) => {
      e.stopPropagation()
      onClick && onClick(e)

      if (isCheckbox) {
        e.target.checked = !e.target.checked
      }

      if (isCheckbox) {
        onChange && onChange(e)
      }
    }

    if (passLabel) {
      rest.label = label
    }

    const InputComponent = (
      <Component
        type={type}
        name={name}
        variant={variant}
        touched={touched}
        required={required}
        checked={checked}
        onClick={handleClick}
        onChange={onChange}
        ref={inputRef}
        {...componentProps}
        {...rest}
      />
    )

    return (
      <FieldWrapper variant={variant} checked={checked} {...rest}>
        {label && (
          <Label htmlFor={labelFor ?? name} required={required}>
            {isCheckable && InputComponent}
            {passLabel === false && label}
            {labelInfo && (
              <div className="mt-2">
                <TextMuted>{labelInfo}</TextMuted>
              </div>
            )}
          </Label>
        )}
        {!label && isCheckable && InputComponent}
        <div className={label ? 'mt-0.5' : ''}>
          {Children}
          {!isCheckable && InputComponent}
          {hintText && <Hint variant={variant}>{hintText}</Hint>}
        </div>
      </FieldWrapper>
    )
  }
)

const FieldWrapper = ({ children }) => {
  return <div>{children}</div>
}

const TextMuted = ({ children }) => {
  return <span className="text-gray-400 mt-2">{children}</span>
}

export const Hint = ({ variant = undefined, children }) => {
  const classes = classNames('text-sm px-1 mt-0.5', {
    'text-green-600': variant === 'success',
    'text-yellow-600': variant === 'warning',
    'text-blue-600': variant === 'info',
    'text-red-600': variant === 'danger',
    'text-gray-500': !variant,
  })

  return <div className={classes}>{children}</div>
}

const isPristine = (connected, touched) => connected && !touched

export const getVariant = ({ connected, error, touched, warning }) => {
  if (isPristine(connected, touched)) {
    return undefined
  }

  if (error) {
    return 'danger'
  }

  if (warning) {
    return 'warning'
  }

  return undefined
}

export const LoadingField = () => (
  <div className="flex flex-col gap-y-1">
    <div className="h-5 w-16 bg-gray-200 animate-pulse" />
    <div className="h-8 md:w-2/3 bg-gray-200 animate-pulse" />
  </div>
)
