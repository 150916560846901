interface HintProps {
  children: string
  variant?: 'error' | 'warning'
}

export const Hint: React.FC<HintProps> = ({ children, variant }) => (
  <span
    className={`text-sm ${
      variant === 'error'
        ? 'text-red-500'
        : variant === 'warning'
        ? 'text-yellow-500'
        : 'text-gray-500'
    }`}
  >
    {children}
  </span>
)
