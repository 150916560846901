export const oidcConfiguration = {
  client_id: 'Trybe', // oidc client configuration, the same as oidc client library used internally https://github.com/IdentityModel/oidc-client-js
  redirect_uri: import.meta.env.VITE_APP_OAUTH_REDIRECT_URI,
  response_type: 'code',
  post_logout_redirect_uri: import.meta.env.VITE_APP_OAUTH_POST_LOGOUT_REDIRECT_URI,
  scope: 'openid profile email organisations sso',
  authority: import.meta.env.VITE_APP_OAUTH_AUTHORITY,
  silent_redirect_uri: import.meta.env.VITE_APP_OAUTH_SILENT_REDIRECT_URI,
  automaticSilentRenew: false,
  loadUserInfo: false,
}
