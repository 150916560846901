import { useCurrentSiteId, useCurrentSite } from 'contexts/SiteConfig'
import { ForwardedRef, forwardRef, useMemo } from 'react'
import { createEvent } from 'utilities/createEvent'
import { Select } from './Select'
import { usePagedVoucherDeliveryOptions } from 'api/VoucherDeliveryOptions'
import { formatMoney } from 'ui/components/Money'
import { VoucherDeliveryOption } from '@trybeapp/sdk'

export const VoucherDeliveryOptionSelect = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const siteId = useCurrentSiteId()
  const { currency } = useCurrentSite()

  const { data: { data: deliveryOptions = [] } = {} } = usePagedVoucherDeliveryOptions(
    { perPage: -1 },
    { refetchOnWindowFocus: false }
  )

  const options = deliveryOptions.map((deliveryOption: VoucherDeliveryOption, key) => ({
    value: deliveryOption.id,
    name: deliveryOption.name + ' - ' + formatMoney({ amount: deliveryOption.price, currency }),
  }))

  const value = useMemo(() => {
    const selectedIds = [props.value]

    const selectedOptions = options.filter((option) => selectedIds.includes(option.value))

    return selectedOptions[0]
  }, [options, props.value])

  return <Select options={options} selected={value} ref={ref} {...props} />
})

export const FormikVoucherDeliveryOptionSelect = forwardRef<any, any>(
  ({ onChange: parentOnChange, name, ...props }, ref) => {
    const onChange = (value) => {
      const updatedValue = value.value
      parentOnChange(createEvent({ name, value: updatedValue }))
    }

    return <VoucherDeliveryOptionSelect name={name} onChange={onChange} {...props} />
  }
)
