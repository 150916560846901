import { createContext, useContext } from 'react'

type SidebarContextProps = {
  minimised?: boolean
  setMinimised?: (open: boolean) => void
  previousPages?: any[]
  title?: string
  setTitle?: (title: string) => void
  setPreviousPages?: (pages: any[]) => void
  lightTheme?: boolean
}

export const SidebarContext = createContext<SidebarContextProps>({})

export const useSidebar = () => useContext(SidebarContext)
