import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CustomerMarketingPreferencesApi } from '@trybeapp/sdk.customer'

const api = new CustomerMarketingPreferencesApi()

export const useCustomerMarketingPreferences = (customerId: string, params = {}) =>
  useQuery(['customerMarketingPreferences', { params }], async () =>
    api.listCustomerMarketingPreferences(customerId, params)
  )

export const useOptIn = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (preferenceId) => api.marketingPreferenceOptIn(customerId, preferenceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customerMarketingPreferences')
      },
    }
  )
}

export const useOptOut = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (preferenceId) => api.marketingPreferenceOptOut(customerId, preferenceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customerMarketingPreferences')
      },
    }
  )
}
