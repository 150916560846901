import { RefreshIcon } from '@heroicons/react/solid'
import { useRetryPaymentForMembership, useSubsequentBillingDetails } from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'

export const RetryPaymentModal = ({ isOpen, onClose, membership }) => {
  const { isLoading, mutate } = useRetryPaymentForMembership()
  const notification = useNotificationContext()

  const {
    data: {
      data: { date: newNextBillingDate } = {
        date: null,
      },
    } = {},
  } = useSubsequentBillingDetails(membership?.id, {
    enabled: membership?.id !== undefined,
  })

  const handleRetry = () => {
    mutate(membership?.id, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Charge created successfully"
            description={`We have created a new charge for this membership.`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  if (!isOpen) return null

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Retry payment"
      onClose={onClose}
      onConfirm={handleRetry}
      confirming={isLoading}
      confirmButtonIcon={RefreshIcon}
      confirmButtonLabel="Retry payment"
      variant="info"
    >
      <p className="text-sm text-gray-500 mb-2">
        We'll attempt to charge&nbsp;
        <strong className="font-medium text-gray-700">
          {membership.customer.first_name ?? 'the customer'}
        </strong>
        &nbsp;again.
      </p>
      <p className="text-sm text-gray-500 mb-2">
        The next billing date will be incremented to&nbsp;
        <time dateTime={newNextBillingDate}>
          <strong className="font-medium text-gray-700">
            {DateTime.fromJSDate(newNextBillingDate).toLocaleString(DateTime.DATE_FULL)}
          </strong>
        </time>
        , and the membership will remain in a&nbsp;
        <strong className="font-medium text-gray-700">Needs attention</strong>
        &nbsp;state until the payment is successful. This can take up to 3 working days.
      </p>
      <p className="text-sm text-gray-500 mb-2">
        If successful, the membership status will change to&nbsp;
        <strong className="font-medium text-gray-700">Active</strong>.
      </p>
      <p className="text-sm text-gray-500">
        If it fails again, no changes will be made to the membership status, and the next billing
        date will be reverted back to&nbsp;
        <strong className="font-medium text-gray-700">
          {DateTime.fromJSDate(membership.next_billing_date).toLocaleString(DateTime.DATE_MED)}
        </strong>
        .
      </p>
      <p className="text-xs text-gray-500 mt-3">
        You can subscribe to email notifications to be instantly notified when a membership payment
        fails, including from retries such as this one.
      </p>
      <p className="text-xs text-gray-500 mt-2">
        <span className="hover:underline">
          <Link to="/my-account/notifications">Click here to view your notification settings.</Link>
        </span>
      </p>
    </ConfirmationModal>
  )
}
