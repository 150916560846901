import { useCustomerCredits, useRevokeCustomerCredit } from 'api/CustomerCredits'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Badge } from 'ui/components/Badge'
import { IssueCreditModal } from './IssueCreditModal'
import { Dropdown, DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import { XIcon, EyeIcon, CheckIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { ConfirmationModal } from 'ui/Modal'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { useGetCustomer } from 'api/Customers'
import { Section } from '../Shared/Section'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { ApplicableOfferingsForCouponModal } from './ApplicableOfferingsForCouponModal'
import { EmptyState } from 'ui/components/EmptyState'
import { TicketIcon } from '@heroicons/react/outline'

const Credit = ({ credit, onRevoke, onApplicableOfferingsForCode }) => {
  const issued = DateTime.fromJSDate(credit.issued_at).toLocaleString(DateTime.DATE_FULL)
  const doesntExpire = credit.expires_at === null
  const isExpired = !doesntExpire && credit.expires_at < Date.now()
  const isRedeemed = !!credit.redeemed_at
  const isRevoked = !!credit.revoked_at
  let secondaryText = ''
  let badge = <Badge variant="success" label="Redeemable" />

  switch (true) {
    case isRevoked:
      secondaryText = `Revoked ${DateTime.fromJSDate(credit.revoked_at).toLocaleString(
        DateTime.DATE_FULL
      )}`
      badge = <Badge variant="danger" label="Revoked" />
      break
    case isRedeemed:
      secondaryText = `Redeemed ${DateTime.fromJSDate(credit.redeemed_at).toLocaleString(
        DateTime.DATE_FULL
      )}`
      badge = <Badge variant="info" label="Redeemed" />
      break
    case isExpired:
      secondaryText = `Expired ${DateTime.fromJSDate(credit.expires_at).toLocaleString(
        DateTime.DATE_FULL
      )}`
      badge = <Badge variant="warning" label="Expired" />
      break
    case doesntExpire:
      secondaryText = `Doesn't expire`
      break
    default:
      secondaryText = `Expires ${DateTime.fromJSDate(credit.expires_at).toLocaleString(
        DateTime.DATE_FULL
      )}`
  }

  return (
    <li className="text-sm p-3">
      <div className="flex-1 flex items-center justify-between">
        <div>
          <div className="font-medium text-gray-700">
            {credit.coupon_name}
            {badge && <div className="inline-block ml-2">{badge}</div>}
          </div>
          <div className="text-gray-500">
            Issued {issued} &middot; {secondaryText}
          </div>
        </div>

        {!isRevoked && !isExpired && (
          <div className="flex-shrink-0">
            <Dropdown threeDots>
              <DropdownItem
                label="Applicable Offerings"
                onClick={() => onApplicableOfferingsForCode(credit.coupon_code)}
                icon={EyeIcon}
              />
              <DropdownDivider />
              <DropdownItem label="Revoke" onClick={() => onRevoke(credit.id)} icon={XIcon} />
            </Dropdown>
          </div>
        )}
      </div>
    </li>
  )
}

export const Credits = () => {
  const [redeemed, setRedeemed] = useState(false)
  const [expired, setExpired] = useState(false)
  const [revoked, setRevoked] = useState(false)
  const { clientId } = useParams()
  const notification = useNotificationContext()
  const [showingRevokeConfirmation, setShowingRevokeConfirmation] = useState(null)
  const [showingApplicableOfferingsForCode, setShowingApplicableOfferingsForCode] = useState(null)
  const [page, setPage] = useState(1)

  const {
    data: { data: credits = [], meta } = {},
    isLoading,
    isError,
    isSuccess,
  } = useCustomerCredits(
    clientId,
    {
      page,
      redeemed,
      expired,
      revoked,
    },
    {
      refetchOnMount: false,
    }
  )
  const { isLoading: isRevoking, mutate } = useRevokeCustomerCredit(clientId)
  const { isLoading: isLoadingClient, data: { data: client = {} } = {} } = useGetCustomer(clientId)

  const revokeCredit = () => {
    mutate(showingRevokeConfirmation, {
      onSuccess: () => {
        setShowingRevokeConfirmation(null)
        notification.notify(
          <Notification
            title="Credit revoked"
            description={`The credit was successfully revoked`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: () => {
        setShowingRevokeConfirmation(null)
        notification.notify(
          <Notification
            title="Couldn't revoke credit"
            description={`An error occurred when revoking this credit`}
            variant="danger"
            autoDismiss
          />
        )
      },
    })
  }

  const showAll = () => {
    setRedeemed(null)
    setExpired(null)
    setRevoked(null)
    setPage(1)
  }

  const onlyShowActive = () => {
    setRedeemed(false)
    setExpired(false)
    setRevoked(false)
    setPage(1)
  }

  const onlyShowExpired = () => {
    setRedeemed(null)
    setExpired(true)
    setRevoked(null)
    setPage(1)
  }

  const onlyShowRevoked = () => {
    setRedeemed(null)
    setExpired(null)
    setRevoked(true)
    setPage(1)
  }

  const onlyShowRedeemed = () => {
    setRedeemed(true)
    setExpired(null)
    setRevoked(null)
    setPage(1)
  }

  const isShowingAll = redeemed === null && expired === null && revoked === null
  const isOnlyShowingActive = redeemed === false && expired === false && revoked === false
  const isOnlyShowingExpired = redeemed === false && expired === true && revoked === false
  const isOnlyShowingRevoked = redeemed === false && expired === false && revoked === true
  const isOnlyShowingRedeemed = redeemed === true && expired === false && revoked === false

  return (
    <Section
      title="Credits"
      button={
        !isLoadingClient &&
        client.deleted_at === null && (
          <div className="flex items-center space-x-2">
            <Dropdown
              buttonLabel="Show"
              buttonHideLabel
              buttonLeadingIcon={EyeIcon}
              buttonVariant="ghost"
            >
              <DropdownItem
                label="Show all"
                icon={isShowingAll ? CheckIcon : () => {}}
                onClick={() => showAll()}
                hideOnClick={false}
              />
              <DropdownItem
                label="Only show active"
                icon={isOnlyShowingActive ? CheckIcon : () => {}}
                onClick={() => onlyShowActive()}
                hideOnClick={false}
              />
              <DropdownItem
                label="Only show expired"
                icon={isOnlyShowingExpired ? CheckIcon : () => {}}
                onClick={() => onlyShowExpired()}
                hideOnClick={false}
              />
              <DropdownItem
                label="Only show revoked"
                icon={isOnlyShowingRevoked ? CheckIcon : () => {}}
                onClick={() => onlyShowRevoked()}
                hideOnClick={false}
              />
              <DropdownItem
                label="Only show redeemed"
                icon={isOnlyShowingRedeemed ? CheckIcon : () => {}}
                onClick={() => onlyShowRedeemed()}
                hideOnClick={false}
              />
            </Dropdown>
            <IssueCreditModal clientId={clientId} />
          </div>
        )
      }
    >
      {isSuccess && credits.length > 0 && (
        <PaginatedMiniList
          isLoading={isLoading}
          isError={isError}
          meta={meta}
          onPageChange={setPage}
          borderless
          emptyMessage="This client has no credits"
          rows={credits.map((credit) => (
            <Credit
              key={credit.id}
              credit={credit}
              onRevoke={(id) => setShowingRevokeConfirmation(id)}
              onApplicableOfferingsForCode={(code) => setShowingApplicableOfferingsForCode(code)}
            />
          ))}
        />
      )}

      {isSuccess && credits.length === 0 && (
        <EmptyState
          title={`${isShowingAll ? 'This client has no credits' : 'No credits found'}`}
          icon={TicketIcon}
        />
      )}

      <ApplicableOfferingsForCouponModal
        code={showingApplicableOfferingsForCode}
        isOpen={showingApplicableOfferingsForCode !== null}
        hide={() => setShowingApplicableOfferingsForCode(null)}
      />

      <ConfirmationModal
        isOpen={!!showingRevokeConfirmation}
        title="Revoke credit"
        onClose={() => setShowingRevokeConfirmation(null)}
        confirmButtonLabel="Revoke"
        confirming={isRevoking}
        onConfirm={revokeCredit}
        variant="danger"
      >
        <p className="text-sm text-gray-600">Are you sure you want to revoke this credit?</p>
      </ConfirmationModal>
    </Section>
  )
}
