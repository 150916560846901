import { formatMoney } from 'ui/components/Money'
import { Modal, ModalButtons } from 'ui/Modal'
import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import { Button } from 'ui/components/Button'
import {
  useAddMembershipChargePayment,
  useAddMembershipPayment,
  useNextBillingDetails,
  useSubsequentBillingDetails,
} from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { classNames } from 'ui/utilities'
import { useCustomPaymentTypes } from 'api/CustomPaymentTypes'
import { useActiveFeatureFlags, useCurrentSiteId, useCurrentSite } from 'contexts/SiteConfig'
import { DateTime } from 'luxon'
import { useBillingPeriod } from './Memberships'
import { useGetSite } from 'api/Sites'

export const PaymentModal = ({ membership, onClose, isOpen }) => {
  const billingPeriod = useBillingPeriod(membership?.next_charge)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const { isLoading, mutate } = useAddMembershipPayment()
  const { isLoading: isAddingPaymentForCharge, mutate: addPaymentForCharge } =
    useAddMembershipChargePayment()
  const { name } = useCurrentSite()
  const siteId = useCurrentSiteId()
  const notifications = useNotificationContext()
  const featureFlags = useActiveFeatureFlags()

  const membershipOrderMatchesSelectedSite = membership?.site_id === siteId

  const { data: { data: site = {} } = {} } = useGetSite(membership?.site_id, {
    enabled: membership?.site_id !== undefined,
  })

  const {
    data: {
      data: { amount, currency } = {
        amount: 0,
        currency: 'GBP',
      },
    } = {},
  } = useNextBillingDetails(membership?.id, {
    enabled:
      membership?.id !== undefined &&
      !featureFlags.includes('membership_revenue_schedule') &&
      membershipOrderMatchesSelectedSite,
  })

  const {
    data: {
      data: { date: newNextBillingDate } = {
        date: null,
      },
    } = {},
  } = useSubsequentBillingDetails(membership?.id, {
    enabled:
      membership?.id !== undefined &&
      !featureFlags.includes('membership_revenue_schedule') &&
      membershipOrderMatchesSelectedSite,
  })

  if (membership === null) {
    return null
  }

  const formattedAmount = formatMoney({
    amount: featureFlags.includes('membership_revenue_schedule')
      ? membership?.next_charge?.amount
      : amount,
    currency: featureFlags.includes('membership_revenue_schedule')
      ? membership?.next_charge?.currency
      : currency,
  })

  const handleSubmit = () => {
    if (featureFlags.includes('membership_revenue_schedule')) {
      addPaymentForCharge(
        [
          membership.id,
          membership.next_charge.id,
          {
            payment_type_id: paymentMethod,
          },
        ],
        {
          onSuccess: () => {
            onClose()
            notifications.notify(
              <Notification
                title="Payment recorded"
                description="The payment was successfully recorded"
                variant="success"
                autoDismiss
              />
            )
          },
          onError: (e: any) => {
            notifications.notify(<ApiErrorNotification error={e} />)
          },
        }
      )
    } else {
      mutate(
        { membershipId: membership.id, paymentMethodId: paymentMethod },
        {
          onSuccess: () => {
            onClose()
            notifications.notify(
              <Notification
                title="Payment recorded"
                description="The payment was successfully recorded"
                variant="success"
                autoDismiss
              />
            )
          },
          onError: (e: any) => {
            notifications.notify(<ApiErrorNotification error={e} />)
          },
        }
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Record payment" styleReset>
      {membership !== null && (
        <div className="space-y-4 text-sm text-gray-500">
          {!membershipOrderMatchesSelectedSite && (
            <div>
              This membership was not created on this site, please switch to{' '}
              <strong> {site?.name} </strong>
              to record payment.
            </div>
          )}

          {membershipOrderMatchesSelectedSite && (
            <>
              <p>
                You're recording a payment of{' '}
                <strong className="font-medium text-gray-700">{formattedAmount}</strong> for this{' '}
                {membership.type.name} membership on {name}.
              </p>
              {featureFlags.includes('membership_revenue_schedule') && (
                <p>
                  This payment will be made towards the next charge, which is for the billing period{' '}
                  {billingPeriod}.
                </p>
              )}
              <p className="font-medium text-gray-700">How is this payment being made?</p>
              <PaymentButtons
                value={paymentMethod}
                onChange={setPaymentMethod}
                membership={membership}
              />
              {!featureFlags.includes('membership_revenue_schedule') && (
                <p>
                  By clicking{' '}
                  <strong className="font-medium text-gray-700">Record {formattedAmount}</strong>,
                  the next billing date will{' '}
                  {newNextBillingDate && (
                    <>
                      be incremented to{' '}
                      <time dateTime={newNextBillingDate}>
                        <strong className="inline-block font-medium text-gray-700">
                          {DateTime.fromJSDate(newNextBillingDate).toLocaleString(
                            DateTime.DATE_FULL
                          )}
                        </strong>
                      </time>
                    </>
                  )}
                  {newNextBillingDate === null && (
                    <>
                      be set to <span className="inline-block italic">N/A</span>
                    </>
                  )}
                  <> and any due credits will be issued.</>
                </p>
              )}
              <ModalButtons>
                <Button
                  variant="primary"
                  label={`Record ${formattedAmount}`}
                  onClick={handleSubmit}
                  loading={isLoading || isAddingPaymentForCharge}
                  disabled={paymentMethod === null}
                />
                <Button label="Cancel" onClick={onClose} />
              </ModalButtons>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}

const PaymentButtons = ({ onChange, value, membership }) => {
  const { isLoading, data: { data: paymentTypes = [] } = {} } = useCustomPaymentTypes(
    membership?.site_id
  )

  if (isLoading) {
    return (
      <div className="animate-pulse border border-gray-100 rounded-md divide-y divide-gray-100">
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
        <div className="p-4">
          <div className="h-4 bg-gray-200" />
        </div>
      </div>
    )
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className="sr-only">Payment method</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {paymentTypes.map(({ id, name }, index) => (
          <RadioGroup.Option
            key={id}
            value={id}
            className={({ checked }) =>
              classNames(
                index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                index === paymentTypes.length - 1 ? 'rounded-b-md' : '',
                checked ? 'bg-violet-50 border-violet-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-violet border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-violet' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label as="span" className={'text-gray-900 block text-sm font-medium'}>
                    {name}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
