import { createContext, useContext } from 'react'

export const OrganisationConfigContext = createContext()

export const OrganisationConfigProvider = ({ organisationId, children }) => {
  return (
    <OrganisationConfigContext.Provider value={organisationId}>
      {children}
    </OrganisationConfigContext.Provider>
  )
}

export const useOrganisationId = () => useContext(OrganisationConfigContext)
