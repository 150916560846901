import classNames from 'classnames'
import { useMemo } from 'react'

interface SpinnerProps {
  variant?: 'success' | 'info' | 'danger' | 'warning'
  height?: string
  width?: string
  margin?: string
}

export const Spinner: React.FC<SpinnerProps> = ({
  variant,
  height = 'h-5',
  width = 'w-5',
  margin = 'my-2',
}) => {
  const textColor = useMemo(() => {
    switch (variant) {
      default:
        return ''
      case 'info':
        return 'text-blue-400'
      case 'success':
        return 'text-green-400'
      case 'warning':
        return 'text-yellow-400'
      case 'danger':
        return 'text-red-400'
    }
  }, [variant])

  return (
    <span className={textColor}>
      <span className="sr-only">Loading...</span>
      <svg
        className={classNames('animate-rotate stroke-current mx-auto', height, width, margin)}
        viewBox="0 0 50 50"
      >
        <circle
          strokeLinecap="round"
          className="animate-spinner-path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </span>
  )
}
