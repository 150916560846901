import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { CustomersApi } from '@trybeapp/sdk.customer'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { CustomersApi as CustomersApiTs } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new CustomersApi()
const tsApi = new CustomersApiTs(apiConfiguration)

export const useCustomers = (params = {}, options: UseQueryOptions = {}): UseQueryResult<any> =>
  useQuery(['customers', { params }], async () => api.listCustomers(params), options)

export const useCustomerSearch = (): UseMutationResult<any> => {
  return useMutation((query: string) => {
    return api.listCustomers({
      query,
    })
  })
}

export const useExportCustomers = (): UseMutationResult<any> => {
  const siteId = useCurrentSiteId()

  return useMutation(['Export'], (params: any) => {
    params.siteId = siteId

    return api.queueListCustomers(params)
  })
}

export const useGetCustomer = (customerId: string, options = {}) =>
  useQuery(['customers', customerId], () => api.getCustomer(customerId), options)

export const useUpdateCustomer: any = (options: UseMutationOptions = {}) => {
  const queryClient = useQueryClient()

  return useMutation(([customerId, customer]: any) => api.updateCustomer(customerId, customer), {
    ...options,
    onSuccess: (result, [customerId]) => {
      queryClient.invalidateQueries('customers')
      queryClient.invalidateQueries(['auditLogs', { subjectId: customerId }])
      if (options.onSuccess) {
        options.onSuccess(result, null, null)
      }
    },
  })
}

export const useCreateCustomer = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation((customer: any) =>
    api.createCustomer(
      {
        createCustomerRequest: {
          ...customer,
          site_id: siteId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['customers'])
        },
      }
    )
  )
}

export const useDeleteCustomer = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(() => api.deleteCustomer(customerId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['customers', customerId])
    },
  })
}

export const useResetCustomerPassword = (customerId: string) =>
  useMutation(() => api.resetCustomerPassword(customerId))

export const useTriggerBilling = (customerId: string) =>
  useMutation(() => api.triggerCustomerBilling(customerId))

export const useResendEmailVerification = (customerId: string) =>
  useMutation(() => api.resendEmailVerification(customerId))

export const useGetCustomerAddresses = (
  customerId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(
    ['customerAddresses', { customerId }],
    () => api.listCustomerAddresses(customerId),
    options
  )
}

export const useCreateNewCustomerAddress = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: any) =>
      api.createCustomerAddress(customerId, {
        createCustomerAddressRequest: values,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['customerAddresses']),
    }
  )
}

export const useDeleteCustomerAddress = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation((addressId: string) => api.deleteCustomerAddress(customerId, addressId), {
    onSuccess: () => queryClient.invalidateQueries(['customerAddresses']),
  })
}

export const useUpdateCustomerAddress = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ([addressId, vals]: [string, any]) =>
      api.updateCustomerAddress(customerId, addressId, {
        updateCustomerAddressRequest: vals,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['customerAddresses', { customerId }]),
    }
  )
}

export const useBarcodeSearch = (code: string, options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery<any, any, any>(
    ['getCustomerByBarcode', { code, siteId }],
    () => api.getCustomerByBarcode(code, siteId),
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
}

export const useAssociatedBarcodesForClient = (clientId: string, options: UseQueryOptions = {}) =>
  useQuery<any, any, any>(
    ['listAssociatedBarcodes', { clientId }],
    () => api.listAssociatedBarcodes(clientId),
    {
      ...options,
      refetchOnWindowFocus: false,
    }
  )

export const useAssociateBarcode = () => {
  const queryClient = useQueryClient()

  return useMutation<any, any, any>(
    ([clientId, barcode]) =>
      api.associateBarcode(clientId, {
        associateBarcodeRequest: {
          barcode,
        },
      }),
    {
      onSuccess: (_, [clientId]) => {
        queryClient.invalidateQueries(['listAssociatedBarcodes', { clientId }])
      },
    }
  )
}

export const useDisassociateBarcode = () => {
  const queryClient = useQueryClient()

  return useMutation<any, any, any>(
    ([clientId, barcodeId]) => api.disassociateBarcode(clientId, barcodeId),
    {
      onSuccess: (_, [clientId]) => {
        queryClient.invalidateQueries(['listAssociatedBarcodes', { clientId }])
      },
    }
  )
}

export const useLockCustomer = () => {
  const queryClient = useQueryClient()

  return useMutation((customerId: string) => api.lockCustomer(customerId), {
    onSuccess: (_, customerId) => queryClient.invalidateQueries(['customers', customerId]),
  })
}

export const useUnlockCustomer = () => {
  const queryClient = useQueryClient()

  return useMutation((customerId: string) => api.unlockCustomer(customerId), {
    onSuccess: (_, customerId) => queryClient.invalidateQueries(['customers', customerId]),
  })
}

export const useGetCustomerStats = (
  customerId: string,
  options: UseQueryOptions = {}
): UseQueryResult => {
  options.staleTime = options.staleTime ?? 60 * 1000

  return useQuery(
    ['customerStats', customerId],
    () => tsApi.getCustomerStats({ customerId }),
    options
  )
}
