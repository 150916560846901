import { useHistory } from 'react-router-dom'
import { Box } from '@trybeapp/ui.box'
import { Button } from '@trybeapp/ui.button'
import { Text } from '@trybeapp/ui.text'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { STATUS_CANCELLED } from 'constants/orderStatuses'
import { Col, Row } from '@trybeapp/ui.grid'
import { Card } from '../../../components/Card'
import { Money } from '@trybeapp/ui.money'
import { StatusTag } from '../Overview'
import { useOrder } from 'contexts/Order'
import { CostColumn } from '../Columns'

export const MembershipItemRow = ({ item }) => {
  const { status } = useOrder()
  const cancelled = status === STATUS_CANCELLED
  const {
    type_name: name = '',
    total_cost: totalCost,
    discount_amount: discountAmount,
    item_configuration: details = {},
  } = item

  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <Col col={0.7} mb="lg">
            <Text as="span" variant="body3" color="nude.700">
              <strong>
                {name} {details.membership_type_name ? `- ${details.membership_type_name}` : ''}
              </strong>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col col={0.13} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Status
            </Text>
          </Col>
          <Col col={0.27} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Joining fee
            </Text>
          </Col>
          <Col col={0.3} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Pro-rata
            </Text>
          </Col>
          <Col col={0.15} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Total
            </Text>
          </Col>
          <Col col={0.15} mb="xs"></Col>
        </Row>
        <Row>
          <Col col={0.13}>
            <StatusTag status={status} />
          </Col>
          <Col col={0.27}>
            <Money
              currency={details.joining_fee?.currency ?? 'gbp'}
              amount={(details.joining_fee?.amount ?? 0) / 100}
              variant="body2"
              color="nude.900"
            />
          </Col>
          <Col col={0.3}>
            <Money
              currency={details.joining_fee?.currency ?? 'gbp'}
              amount={(totalCost + discountAmount - (details.joining_fee?.amount ?? 0)) / 100}
              variant="body2"
              color="nude.900"
            />
          </Col>
          <CostColumn item={item} />
          <Col col={0.15}>
            <Box display="flex" alignItems="center">
              {!cancelled && <GoToMembership />}
            </Box>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const GoToMembership = () => {
  const order = useOrder()

  const history = useHistory()
  const handleOnClick = () => {
    history.push(`/clients/${order.customer_id}/memberships`)
  }

  return (
    <Button variant="ghost" size="xs" onClick={handleOnClick} mr="sm">
      <FontAwesomeIcon mr={1} icon="edit" />
      View membership
    </Button>
  )
}
