import { orderItemActionTypes } from '@trybeapp/ducks.inventory'
import { LISTEN } from './actionTypes'

const INITIAL_STATE = {
  creating: false,
  created: false,
  updating: false,
  updated: false,
  error: null,
  listen: false,
}

export const InventoryOrderItemOverlayReducer = (state = INITIAL_STATE, action) => {
  if (action.type !== LISTEN && !state.listen) {
    return state
  }

  switch (action.type) {
    case LISTEN:
      if (action.value) {
        return {
          ...state,
          listen: action.value,
        }
      }

      return { ...INITIAL_STATE }

    case orderItemActionTypes.CREATE_ORDER_ITEM:
      return {
        ...state,
        error: null,
        created: false,
        creating: true,
      }

    case orderItemActionTypes.CREATE_ORDER_ITEM_ERROR:
      return {
        ...state,
        error: action.message,
        creating: false,
      }

    case orderItemActionTypes.CREATE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        created: true,
        creating: false,
        createdId: action.result,
      }

    case orderItemActionTypes.UPDATE_ORDER_ITEM:
      return {
        ...state,
        error: null,
        updated: false,
        updating: true,
      }

    case orderItemActionTypes.UPDATE_ORDER_ITEM_ERROR:
      return {
        ...state,
        error: action.message,
        updating: false,
      }

    case orderItemActionTypes.UPDATE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        updated: true,
        updating: false,
      }

    default:
      return state
  }
}
