import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from 'react-query'
import { NotificationConfigApi } from '@trybeapp/sdk.shop'
import { NotificationsApi } from '@trybeapp/sdk.auth'

import { useCurrentSiteId } from 'contexts/SiteConfig'

const notificationsApi = new NotificationsApi()

export const useGetGroupedNotifications = (options?: UseQueryOptions): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  options.refetchOnMount = options.refetchOnMount ?? false
  options.refetchOnReconnect = options.refetchOnReconnect ?? false
  options.refetchOnWindowFocus = options.refetchOnWindowFocus ?? false

  return useQuery(
    ['groupedNotifications', siteId],
    async () => await notificationsApi.groupedNotificationsIndex(siteId),
    options
  )
}

export const useBulkMarkNotificationsAsRead = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async (notificationIds) =>
      await notificationsApi.notificationsBulkMarkAsRead(siteId, notificationIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications')
        queryClient.invalidateQueries('groupedNotifications')
      },
    }
  )
}

interface UserNotificationParamters {
  page?: number
  read?: number
  type?: string[]
}

export const useGetUserNotifications = (
  params: UserNotificationParamters = {
    page: 1,
  },
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['notifications', siteId, params],
    async () => await notificationsApi.notificationsIndex(siteId, params),
    options
  )
}

export const useMarkNotificationAsRead = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (notificationId) => await notificationsApi.notificationRead(notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications')
        queryClient.invalidateQueries('groupedNotifications')
      },
    }
  )
}

export const useMarkNotificationAsUnread = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (notificationId) => await notificationsApi.notificationUnread(notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications')
        queryClient.invalidateQueries('groupedNotifications')
      },
    }
  )
}

export const useAcknowledgeNotification = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (notificationId) => await notificationsApi.notificationAcknowledge(notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notifications')
        queryClient.invalidateQueries('groupedNotifications')
      },
    }
  )
}

const api = new NotificationConfigApi()

export const useGetShopNotificationsConfig = (options?: UseQueryOptions): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['userNotificationSubscriptions', siteId],
    async () => await api.myNotificationConfigIndex({ siteId }),
    options
  )
}

export const useSubscribeToEndOfDaySummary = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(async () => await api.subscribeEndOfDaySummary({ siteId }), {
    onSuccess: () => {
      queryClient.invalidateQueries('userNotificationSubscriptions')
    },
  })
}

export const useUnsubscribeToEndOfDaySummary = (): UseMutationResult<any, any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(async () => await api.unsubscribeEndOfDaySummary({ siteId }), {
    onSuccess: () => {
      queryClient.invalidateQueries('userNotificationSubscriptions')
    },
  })
}
