import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { BookableAreasApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new BookableAreasApi()

export const useBookableAreas = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(['bookableAreas', params], () => api.bookableAreasIndex(params), options)
}

export const useGetBookableArea = (bookableAreaId: string, options = {}) =>
  useQuery(
    ['bookableAreas', { bookableAreaId }],
    () => api.bookableAreaShow(bookableAreaId),
    options
  )

export const useOrderedAreas = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['areas', siteId, params],
    async () => await api.getOrderedAreas(siteId, params),
    options
  )
}

export const useUpdateOrderedAreas = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (areaIds: any) =>
      api.updateAreaOrder({
        area_ids: areaIds,
        site_id: siteId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookableAreas'])
      },
    }
  )
}

export const useCreateBookableArea = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (bookableArea: any) => api.bookableAreaStore({ ...bookableArea, site_id: siteId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookableAreas'])
      },
    }
  )
}

export const useUpdateBookableArea = () => {
  const queryClient = useQueryClient()

  return useMutation((bookableArea: any) => api.bookableAreaUpdate(bookableArea.id, bookableArea), {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookableAreas'])
    },
  })
}

export const useDeleteBookableArea = () => {
  const queryClient = useQueryClient()

  return useMutation((bookableAreaId: string) => api.bookableAreaDestroy(bookableAreaId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookableAreas'])
    },
  })
}
