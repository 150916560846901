import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  useQueryClient,
  useMutation,
} from 'react-query'
import { PaymentMethodsApi } from '@trybeapp/sdk.customer'

const api = new PaymentMethodsApi()

export const usePaymentMethodsForCustomer = (
  customerId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(
    ['customerPaymentMethods', { customerId }],
    () => api.listPaymentMethods({ customerId }),
    options
  )
}

const fetchPaymentMethods = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listPaymentMethods({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useInfinitePaymentMethods = (params = {}, options = {}) =>
  useInfiniteQuery(['paymentMethods', { params }], fetchPaymentMethods, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
    ...options,
  })

export const useDeletePaymentMethod = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ([paymentMethodId]: [string]) => await api.deletePaymentMethod(paymentMethodId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customerPaymentMethods'])
      },
    }
  )
}
