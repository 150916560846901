import { useEffect, useMemo } from 'react'
import styled, { css } from '@xstyled/styled-components'
import { intersection, isEmpty, uniq } from 'lodash'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import { Label } from '@trybeapp/ui.label'
import { Button } from '@trybeapp/ui.button'
import { Money } from '@trybeapp/ui.money'
import { Alert } from '@trybeapp/ui.alert'
import { Row, Col } from '@trybeapp/ui.grid'
import { Box } from '@trybeapp/ui.box'
import { Text } from 'components/Text'
import { trackEvent } from 'components/Segment'
import { EVENT_AREA_BOOKING_SLOT_SELECTED } from 'constants/trackableEvents'
import { useOrderId } from 'screens/OrderDetails/components/Overview/hooks'
import { useGetBookableArea } from 'api/BookableAreas'
import {
  useCurrentAreaIds,
  useCurrentSlot,
  useCurrentTime,
  useInvalidTime,
  useAllSlots,
  useSlotsByHour,
  useCurrentDuration,
} from './hooks'
import { withSlotsByHour, withAreaBookingSlotForm, useAreaBookingForm } from './contexts'
import { minsToFriendlyDuration } from 'utilities/DateUtils/dateUtils'
import { sentenceJoin } from 'utilities/StringUtils'

export const AreaBookingSlotSelection = withAreaBookingSlotForm(
  withSlotsByHour(({ includePrice }) => {
    const invalidTime = useInvalidTime()

    return (
      <>
        {invalidTime ? (
          <Alert variant="warning" fontSize="body2">
            You have selected an invalid time.
            <Text color="inherit" fontSize="body3">
              Use the form on the left hand side to manually configure this booking.
            </Text>
          </Alert>
        ) : (
          <>
            <TimeSelection includePrice={includePrice} />
            <AreaSelection />
          </>
        )}
      </>
    )
  })
)

const TimeSelection = ({ includePrice }) => {
  const currentTime = useCurrentTime()
  const slotsByHour = useSlotsByHour()
  const { onTimeChange, onAreaIdsChange, onDurationChange } = useAreaBookingForm()

  const handleClear = () => {
    onTimeChange('')
    onDurationChange('')
    onAreaIdsChange([])
  }

  if (currentTime) {
    return (
      <Box mb="md">
        <Label>Select a time slot</Label>
        <CondensedRow alignItems="center">
          <CondensedCol col="auto" px="sm">
            <SlotPill selected={true}>{currentTime}</SlotPill>
          </CondensedCol>
          <CondensedCol px="sm">
            <Button variant="ghost" onClick={handleClear} size="sm">
              Clear
            </Button>
          </CondensedCol>
        </CondensedRow>
      </Box>
    )
  }

  return (
    <Box mb="sm">
      <SuggestedSlots />
      <DurationFilter />
      <Label>Select a time slot</Label>
      {Object.keys(slotsByHour).map((hour) => (
        <HourRow key={hour} hour={hour} includePrice={includePrice} />
      ))}
    </Box>
  )
}

const SuggestedSlots = () => {
  const { basketSlots = [] } = useAreaBookingForm()
  const allSlots = useAllSlots()

  const suggestedSlots = useMemo(() => {
    return basketSlots
      .map((basketSlot) => {
        const foundSlot = allSlots.find((slot) => {
          return (
            basketSlot.start_time.getTime() === slot.start_time.getTime() &&
            basketSlot.duration === slot.duration
          )
        })

        basketSlot.availabilitySlot = foundSlot

        return basketSlot
      })
      .filter((basketSlot) => !!basketSlot.availabilitySlot)
  }, [allSlots, basketSlots])

  if (suggestedSlots.length === 0) {
    return null
  }

  return (
    <Box mb="lg">
      <Label>Select a slot from other items</Label>
      <Row>
        {suggestedSlots.map((slot, i) => (
          <CondensedCol col={1 / 4} key={i}>
            <BasketSlot slot={slot} />
          </CondensedCol>
        ))}
      </Row>
    </Box>
  )
}

const DurationFilter = () => {
  const { durationFilter, setDurationFilter } = useAreaBookingForm()
  const slots = useAllSlots()
  const durations = uniq(slots.map((slot) => parseInt(slot.duration))).sort(
    (durationA, durationB) => durationA - durationB
  )

  if (durations.length < 2) {
    return null
  }

  const toggleFilter = (duration) => {
    if (duration === durationFilter) {
      setDurationFilter(null)
    } else {
      setDurationFilter(duration)
    }
  }

  return (
    <Box mb="lg">
      <Label>Show slots with duration</Label>
      {durations.map((duration) => (
        <SlotPill
          key={duration}
          display="inline-block"
          mr="sm"
          selected={duration === durationFilter}
          onClick={() => toggleFilter(duration)}
        >
          {minsToFriendlyDuration(duration)}
        </SlotPill>
      ))}
    </Box>
  )
}

const AreaSelection = () => {
  const currentSlot = useCurrentSlot()
  const { onAreaIdsChange } = useAreaBookingForm()
  const currentAreaIds = useCurrentAreaIds()
  const { area_ids: areaIds = [] } = currentSlot

  useEffect(() => {
    if (currentSlot?.area_ids?.length === 1 && currentAreaIds.length === 0) {
      onAreaIdsChange(currentSlot.area_ids)
    }
  }, [currentSlot, onAreaIdsChange, currentAreaIds])

  if (isEmpty(currentSlot)) return null

  return (
    <Box mb="md">
      <Label>Select areas</Label>
      <InvalidAreaAlert />
      <CondensedRow>
        {areaIds.map((areaId) => (
          <CondensedCol col={1 / 4} key={areaId}>
            <AreaPill areaId={areaId} />
          </CondensedCol>
        ))}
      </CondensedRow>
    </Box>
  )
}

const AreaPill = ({ areaId }) => {
  const currentAreaIds = useCurrentAreaIds()
  const { data: { data: { name } = {} } = {} } = useGetBookableArea(areaId)
  const { onAreaIdsChange } = useAreaBookingForm()

  const handleSlotClick = () => {
    if (currentAreaIds.includes(areaId)) {
      onAreaIdsChange(currentAreaIds.filter((currentId) => currentId !== areaId))
    } else {
      onAreaIdsChange([...currentAreaIds, areaId])
    }
  }

  return (
    <SlotPill selected={currentAreaIds.includes(areaId)} onClick={handleSlotClick}>
      {name}
    </SlotPill>
  )
}

const useSlotsForHour = (hour) => {
  const slots = useSlotsByHour()

  return slots[hour] ?? []
}

const HourRow = ({ hour, includePrice = true }) => {
  const { durationFilter } = useAreaBookingForm()
  const slots = useSlotsForHour(hour)
  const {
    bookingType: { start_time_interval: interval },
  } = useAreaBookingForm()

  const dateObject = DateTime.fromISO(hour)
  const label = `${dateObject.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${dateObject
    .plus({ hour: 1 })
    .toLocaleString(DateTime.TIME_24_SIMPLE)}`

  const columnWidth = useMemo(() => {
    switch (true) {
      case interval >= 15:
        return 1 / 4
      default:
        return 1 / 8
    }
  }, [interval])

  const filteredSlots = useMemo(() => {
    return slots.filter((slot) => !durationFilter || slot.duration === durationFilter)
  }, [slots, durationFilter])

  return (
    <CondensedRow mb="md">
      <CondensedCol col={1}>
        <Label fontSize="body3" color="nude.700">
          {label}
        </Label>
      </CondensedCol>
      {filteredSlots.map((slot, index) => (
        <CondensedCol col={columnWidth} key={index}>
          <Slot slot={slot} includePrice={includePrice} />
        </CondensedCol>
      ))}
    </CondensedRow>
  )
}

const Slot = ({ slot, includePrice = true }) => {
  const currentTime = useCurrentTime()
  const currentDuration = useCurrentDuration()
  const orderId = useOrderId()
  const { onTimeChange, onDurationChange } = useAreaBookingForm()

  const durationText = useMemo(() => {
    return minsToFriendlyDuration(slot.duration)
  }, [slot.duration])

  const endTime = useMemo(() => {
    return DateTime.fromJSDate(slot.start_time).plus({ minute: slot.duration })
  }, [slot.start_time, slot.duration])

  const handleSlotClick = (slot) => {
    const { start_time: startTime, duration } = slot

    onTimeChange(startTime)
    onDurationChange(duration)

    trackEvent(EVENT_AREA_BOOKING_SLOT_SELECTED, {
      orderId,
      startTime: DateTime.fromJSDate(startTime).toISO({
        suppressMilliseconds: true,
      }),
    })
  }

  return (
    <SlotPill
      selected={
        DateTime.fromJSDate(slot.start_time).toFormat('HH:mm') === currentTime &&
        slot.duration === currentDuration
      }
      onClick={() => handleSlotClick(slot)}
    >
      <>
        {DateTime.fromJSDate(slot.start_time).toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
        {endTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
        <Box>{durationText}</Box>
        <Box>{`${slot.quantity_available} available`}</Box>
        {includePrice && (
          <Box>
            <Money
              color="inherit"
              variant="meta2"
              my="xs"
              textAlign="center"
              lineHeight={1.2}
              as="span"
              amount={slot.price / 100}
              currency={slot.currency}
            />
          </Box>
        )}
      </>
    </SlotPill>
  )
}

const BasketSlot = ({ slot }) => {
  const currentTime = useCurrentTime()
  const currentDuration = useCurrentDuration()
  const orderId = useOrderId()
  const { onTimeChange, onDurationChange, onAreaIdsChange } = useAreaBookingForm()

  const durationText = useMemo(() => {
    return minsToFriendlyDuration(slot.duration)
  }, [slot.duration])

  const endTime = useMemo(() => {
    return DateTime.fromJSDate(slot.start_time).plus({ minute: slot.duration })
  }, [slot.start_time, slot.duration])

  const areaNames = useMemo(() => {
    return sentenceJoin(
      slot.area_ids.map((areaId) => {
        const area = slot.availabilitySlot.areas.find((area) => area.id === areaId)
        return area?.name
      })
    )
  }, [slot.area_ids, slot.availabilitySlot.areas])

  const areasRemainingCapacity = useMemo(() => {
    return slot.area_ids.reduce((totalCapacity, areaId) => {
      const area = slot.availabilitySlot.areas.find((area) => area.id === areaId)

      return area ? totalCapacity + area.remaining_capacity : totalCapacity
    }, 0)
  }, [slot.area_ids, slot.availabilitySlot.areas])

  const handleSlotClick = (slot) => {
    const { start_time: startTime, duration, area_ids: areaIds } = slot

    onTimeChange(startTime)
    onDurationChange(duration)
    onAreaIdsChange(areaIds)

    trackEvent(EVENT_AREA_BOOKING_SLOT_SELECTED, {
      orderId,
      startTime: DateTime.fromJSDate(startTime).toISO({
        suppressMilliseconds: true,
      }),
    })
  }

  return (
    <SlotPill
      selected={
        DateTime.fromJSDate(slot.start_time).toFormat('HH:mm') === currentTime &&
        slot.duration === currentDuration
      }
      onClick={() => handleSlotClick(slot)}
      disabled={areasRemainingCapacity < 1}
    >
      <>
        {DateTime.fromJSDate(slot.start_time).toLocaleString(DateTime.TIME_24_SIMPLE)} -{' '}
        {endTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
        <Box>{durationText}</Box>
        <Box>{areaNames}</Box>
        <Box>{`${areasRemainingCapacity} ${pluralize('space', areasRemainingCapacity)}`}</Box>
      </>
    </SlotPill>
  )
}

const SlotPill = ({ selected, disabled, subtle, onClick, children, ...props }) => {
  const handleOnClick = (event) => {
    if (disabled) {
      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  return (
    <StyledSlotPill
      borderRadius="sm"
      selected={selected}
      subtle={subtle}
      disabled={disabled}
      p="sm"
      style={{ cursor: 'pointer' }}
      display="flex"
      alignItems="center"
      onClick={handleOnClick}
      mb="sm"
      transition="fast"
      {...props}
    >
      <Text
        as="div"
        color="inherit"
        variant="body3"
        textAlign="center"
        lineHeight={1.2}
        alignItems="center"
        mx="auto"
      >
        {children}
      </Text>
    </StyledSlotPill>
  )
}

const StyledSlotPill = styled(Box)(
  ({ selected = false, disabled = false, subtle = false }) => css`
    background-color: ${selected
      ? 'info.500'
      : subtle
      ? 'light.500'
      : disabled
      ? 'nude.100'
      : 'info.100'};

    color: ${selected ? 'light.900' : subtle ? 'nude.600' : disabled ? 'nude.600' : 'info.900'};

    &:hover {
      ${disabled
        ? css`
            background-color: info.100;
          `
        : selected
        ? css`
            background-color: info.200;
          `
        : css`
            background-color: info.200;
            color: light.900;
            border-width: xs;
          `}
    }
  `
)

const InvalidAreaAlert = () => {
  const currentAreaIds = useCurrentAreaIds()
  const { area_ids: areaIds = [] } = useCurrentSlot()

  if (currentAreaIds && intersection(areaIds, currentAreaIds).length !== currentAreaIds.length) {
    return (
      <Alert variant="warning" p="sm" fontSize="body2" mb="sm">
        <Text color="inherit" fontSize="body3">
          You have selected an area that isn't available at this time.
          {areaIds.length > 0 && ' Select from the valid areas below.'}
        </Text>
      </Alert>
    )
  }

  return null
}

const CondensedRow = ({ children, ...props }) => {
  return (
    <Row mx="-sm" {...props}>
      {children}
    </Row>
  )
}
const CondensedCol = ({ children, ...props }) => {
  return (
    <Col px="sm" {...props}>
      {children}
    </Col>
  )
}
