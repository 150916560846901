import { useContext } from 'react'
import { ModalContext } from './contexts'
import cookie from 'react-cookies'
import moment from 'moment'

export const useSudo = (click) => {
  const sudoCookie = cookie.load('sudo')
  const { setOnSudo, modal } = useContext(ModalContext)

  if (!sudoCookie) {
    return () => {
      setOnSudo(() => () => {
        cookie.save('sudo', true, {
          expires: moment().add(15, 'minutes').toDate(),
        })
        click()
      })
      modal.show()
    }
  } else {
    return () => click()
  }
}
