import { INVENTORY_VIEW } from 'constants/permissions'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { ReactLazyPreload } from 'utilities/PreloadLinks'

const Products = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryProducts' */ '../screens/Inventory/sections/Products/Products'
  ).then((file) => {
    return { default: file.ProductsSection }
  })
)

const Stock = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'InventoryStock' */ '../screens/InventoryV2/Stock').then((file) => {
    return { default: file.Stock }
  })
)

const Brands = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryBrands' */ '../screens/Inventory/sections/Brands/Brands'
  ).then((file) => {
    return { default: file.BrandsSection }
  })
)

const Categories = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryCategories' */ '../screens/Inventory/sections/Categories/Categories'
  ).then((file) => {
    return { default: file.CategoriesSection }
  })
)

const Suppliers = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventorySuppliers' */ '../screens/Inventory/sections/Suppliers/Suppliers'
  ).then((file) => {
    return { default: file.SuppliersSection }
  })
)

const Orders = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryOrders' */ '../screens/Inventory/sections/Orders/Orders'
  ).then((file) => {
    return { default: file.OrdersSection }
  })
)

const StockOrders = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'InventoryStockOrders' */ '../screens/InventoryV2/StockOrders').then(
    (file) => {
      return { default: file.StockOrders }
    }
  )
)

const StockTakes = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'InventoryStockTakes' */ '../screens/Inventory/sections/StockTakes/StockTakes'
  ).then((file) => {
    return { default: file.StockTakesSection }
  })
)

export const useRoutes = () => {
  const featureFlags = useActiveFeatureFlags()

  return [
    {
      path: '/inventory/products',
      name: 'Products',
      component: Products,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/brands',
      name: 'Brands',
      component: Brands,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/categories',
      name: 'Categories',
      component: Categories,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/suppliers',
      name: 'Suppliers',
      component: Suppliers,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/orders',
      name: 'Orders',
      component: Orders,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/stock-takes',
      name: 'Stocktakes',
      component: StockTakes,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/stock-items',
      name: 'Stock items',
      component: Stock,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: !featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/stock-orders',
      name: 'Stock orders',
      component: StockOrders,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: !featureFlags.includes('new_inventory'),
    },
    {
      path: '/inventory/stock-takes-v2',
      name: 'Stocktakes',
      component: StockTakes,
      exact: true,
      permissions: [INVENTORY_VIEW],
      hide: !featureFlags.includes('new_inventory'),
    },
  ]
}
