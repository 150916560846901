import { combineReducers } from 'redux'
import { AddCalendarEventOverlayReducer } from 'components/AddCalendarEventOverlay/reducer'
import { InventoryBrandOverlayReducer } from 'components/InventoryBrandOverlay/reducer'
import { InventoryCategoryOverlayReducer } from 'components/InventoryCategoryOverlay/reducer'
import { InventoryOrderOverlayReducer } from 'components/InventoryOrderOverlay/reducer'
import { InventoryOrderItemOverlayReducer } from 'components/InventoryOrderItemOverlay/reducer'
import { InventorySupplierOverlayReducer } from 'components/InventorySupplierOverlay/reducer'
import { AddInventoryStockTakeOverlayReducer } from 'components/AddInventoryStockTakeOverlay/reducer'
import { CopyInventoryProductOverlayReducer } from 'components/CopyInventoryProductOverlay/reducer'
import { InventoryBrandsSectionReducer } from 'screens/Inventory/sections/Brands/reducer'
import { InventoryCategoriesSectionReducer } from 'screens/Inventory/sections/Categories/reducer'
import { InventoryOrdersSectionReducer } from 'screens/Inventory/sections/Orders/reducer'
import { InventorySuppliersSectionReducer } from 'screens/Inventory/sections/Suppliers/reducer'
import { InventoryOrderDetailsReducer } from 'screens/Inventory/sections/OrderDetails/reducer'
import { StockTakeSectionReducer } from 'screens/Inventory/sections/StockTakes/reducer'
import { PractitionerScheduledAvailabilityReducer } from '@trybeapp/ducks.practitioner-scheduled-availability'
import { SessionTypesReducer } from '@trybeapp/ducks.sessions'
import BookingCalendarReducer from 'components/BookingCalendar/reducer'
import OrderList from 'screens/OrderList/reducer'
import PractitionerSummariesReducer from '@trybeapp/ducks.practitioner-summaries'
import {
  PackagesReducer as PackageTypesReducer,
  PackageItemChoicesReducer,
  PackageItemChoiceOptionsReducer,
} from '@trybeapp/ducks.packages'
import {
  BookingOrderItemsReducer,
  BookingSummariesReducer,
  OrdersReducer,
  PackageItemsReducer,
  PackageOrderItemsReducer,
  PurchaseOrderItemsReducer,
} from '@trybeapp/ducks.orders'
import { GuestsReducer } from '@trybeapp/ducks.guests'
import {
  BrandsReducer as Brands,
  CategoriesReducer as Categories,
  OrdersReducer as Orders,
  OrderItemsReducer as OrderItems,
  ProductsReducer as Products,
  SupplierProductsReducer as SupplierProducts,
  SuppliersReducer as Suppliers,
  StockAdjustmentsReducer as StockAdjustments,
  StockTakesReducer as StockTakes,
  StockTakeItemsReducer as StockTakeItems,
} from '@trybeapp/ducks.inventory'

export default combineReducers({
  OrderList,

  ui: combineReducers({
    Inventory: combineReducers({
      OrderDetails: InventoryOrderDetailsReducer,
      BrandsSection: InventoryBrandsSectionReducer,
      CategoriesSection: InventoryCategoriesSectionReducer,
      OrdersSection: InventoryOrdersSectionReducer,
      SuppliersSection: InventorySuppliersSectionReducer,
      StockTakeSection: StockTakeSectionReducer,
    }),
  }),

  components: combineReducers({
    AddCalendarEventOverlayReducer,
    BookingCalendarReducer,
    Inventory: combineReducers({
      AddStockTakeOverlay: AddInventoryStockTakeOverlayReducer,
      BrandOverlay: InventoryBrandOverlayReducer,
      CategoryOverlay: InventoryCategoryOverlayReducer,
      OrderOverlay: InventoryOrderOverlayReducer,
      OrderItemOverlay: InventoryOrderItemOverlayReducer,
      SupplierOverlay: InventorySupplierOverlayReducer,
      CopyProductOverlay: CopyInventoryProductOverlayReducer,
    }),
  }),

  resources: combineReducers({
    BookingOrderItemsReducer,
    BookingSummariesReducer,
    GuestsReducer,
    OrdersReducer,
    PackageItemChoiceOptionsReducer,
    PackageItemChoicesReducer,
    PackageItemsReducer,
    PackageOrderItemsReducer,
    PackageTypesReducer,
    PractitionerScheduledAvailabilityReducer,
    PractitionerSummariesReducer,
    PurchaseOrderItemsReducer,
    SessionTypesReducer,

    Inventory: combineReducers({
      Brands,
      Categories,
      Orders,
      OrderItems,
      Products,
      SupplierProducts,
      Suppliers,
      StockAdjustments,
      StockTakes,
      StockTakeItems,
    }),
  }),
})
