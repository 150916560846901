import { Toast, useToast } from 'ui/components/Notification'
import { useError } from 'contexts/Error'
import { isEmpty } from 'lodash'
import { useCallback, useEffect } from 'react'
import { Box } from '@trybeapp/ui.box'
import { Text } from 'components/Text'

/**
 * @deprecated Use ui/components/Notification instead
 */
export const ErrorToast = ({ defaultMessage = 'Something went wrong!', title = 'Error' }) => {
  const error = useError()
  const toast = useToast()

  useEffect(() => {
    if (!isEmpty(error) || error instanceof Error) {
      let displayError = error

      if (displayError instanceof Error === false && displayError.body instanceof Object === true) {
        displayError = error.body
      }

      const { message = defaultMessage } = displayError

      toast(
        <Toast variant="error">
          <Toast.Title>{title}</Toast.Title>
          {message}
        </Toast>,
        { position: 'top-right' }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, title])

  return null
}

/**
 * @deprecated Use ui/components/Notification instead
 */
export const useErrorToast = () => {
  const toast = useToast()

  return useCallback((error) => {
    const { message = 'Something went wrong', errors = {} } = error.body
    const messages = Object.values(errors)

    toast(
      <Toast variant="error">
        <Toast.Title>Something went wrong</Toast.Title>
        {message}
        {messages.length > 0 && (
          <Box mt="sm">
            {messages.map((message, i) => (
              <Text as="span" fontSize="meta1" color="inherit" key={i}>
                {message}
              </Text>
            ))}
          </Box>
        )}
      </Toast>,
      { position: 'top-right' }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default ErrorToast
