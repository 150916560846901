import { createContext, useContext, useState } from 'react'
import { useNotificationContext } from 'ui/components/Notification'
import { BarcodeListener } from '.'
import { ScannedBarcodeToast } from './ScannedBarcodeToast'

/**
 * We've got a global barcode listener which is included in the App.js file.
 * This looks out for scanned barcodes and shows a toast in the corner
 * when it detects one that's been scanned.
 *
 * There are certain scenarios when we _don't_ want the global barcode listener
 * to be listening. In those cases, we can use this context to disable it.
 * Don't forget to set it back to active after you're done.
 *
 * Usage example:
 *
 * ```
 * const { setActive } = useGlobalBarcodeListenerContext()
 *
 * useEffect(() => {
 *  setActive(false)
 *
 *  return () => {
 *   setActive(true)
 * })
 * ```
 *
 * If you want to listen to barcodes but you don't want to invoke the default behaviour,
 * for example if you're associating a barcode to a client and you don't want to trigger
 * the toast when a barcode is scanned, you can change the callback using `setCallback`.
 * Don't forget to set it back to the global listener when you're done.
 *
 * Usage example:
 *
 * ```
 * const { setCallback, reset } = useGlobalBarcodeListenerContext()
 *
 * useEffect(() => {
 *   setCallback(doSomethingElseWithBarcode)
 *
 *   return () => {
 *     reset()
 *   }
 * })
 *
 */
export const GlobalBarcodeListenerContext = createContext({
  active: true,
  setActive: (_: boolean) => {},
  prefixes: [],
  suffixes: [],
})

export const GlobalBarcodeListenerProvider = ({ children }) => {
  const [active, setActive] = useState(true)
  const notification = useNotificationContext()
  const prefixes = []
  const suffixes = []

  const handleBarcodeScan = (code: string) => {
    notification.notify(<ScannedBarcodeToast key={code + '' + Date.now()} code={code} />)
  }

  return (
    <GlobalBarcodeListenerContext.Provider value={{ active, setActive, prefixes, suffixes }}>
      {children}
      <BarcodeListener
        active={active}
        prefixCodes={prefixes}
        suffixCodes={suffixes}
        onScan={handleBarcodeScan}
      />
    </GlobalBarcodeListenerContext.Provider>
  )
}

export const useGlobalBarcodeListenerContext = () => useContext(GlobalBarcodeListenerContext)
