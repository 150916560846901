/**
 * An integer of the smallest unit of the currency.
 * ie, if the currency is GPB, 100 represents £1.00
 */
type BaseCurrencyAmount = number

interface MoneyProps {
  amount?: BaseCurrencyAmount
  currency: string
  locale?: string
  showDecimals?: boolean
  showMoreDecimalPlaces?: boolean
}

export const Money: React.FC<MoneyProps> = ({
  amount = 0,
  locale,
  currency = 'gbp',
  showDecimals = true,
  showMoreDecimalPlaces = false,
}: MoneyProps) => {
  return <>{formatMoney({ amount, locale, currency, showDecimals, showMoreDecimalPlaces })}</>
}

export const formatMoney: (props: MoneyProps) => string = ({
  amount = 0,
  locale,
  currency = 'gbp',
  showDecimals = true,
  showMoreDecimalPlaces = false,
}: MoneyProps) => {
  const formattedCurrency = currency.toUpperCase()

  amount = baseAmountToCurrencyAmount(amount, formattedCurrency)

  if (locale === undefined) {
    locale = navigator.language ?? 'en-GB'
  }

  let formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: formattedCurrency,
    maximumFractionDigits: showMoreDecimalPlaces ? 5 : 2,
  }).format(amount)

  if (showDecimals === false) {
    const decimalSeparator = new Intl.NumberFormat(locale).format(1.11).match(/[^1]/)[0]

    // Match on the decimal separator followed by one or more digits and white space
    const decimalsRegex = new RegExp(`\\${decimalSeparator}\\d+(\\s)?`)

    // Replace the decimal separator, decimals and the white space with just the white space
    formatted = formatted.replace(decimalsRegex, '$1')
  }

  return formatted
}

export const baseAmountToCurrencyAmount = (amount: BaseCurrencyAmount, currency) => {
  const formattedCurrency = currency.toUpperCase()

  switch (formattedCurrency) {
    default:
    case 'GBP':
      return amount / 100
  }
}

export const amountToCurrencyBaseAmount = (amount, currency): BaseCurrencyAmount => {
  const formattedCurrency = currency.toUpperCase()

  const decimalAmount = amount.replace(',', '.')

  switch (formattedCurrency) {
    default:
    case 'GBP':
      return Math.round(decimalAmount * 100)
  }
}

export const getCurrencySymbol = (currency: string) => {
  const formattedCurrency = currency.toUpperCase()

  return (
    {
      GBP: '£',
      EUR: '€',
      USD: '$',
      AUD: '$',
    }[formattedCurrency] ?? formattedCurrency
  )
}
