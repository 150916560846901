import { Row, Col } from '@trybeapp/ui.grid'
import { Text } from 'components/Text'

export const HeadingsRow = ({ slim = false, ...props }) => (
  <Row {...props}>
    <Col col={0.13} mb="xs">
      <Text
        variant="meta2"
        color="nude.400"
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="sm"
      >
        Status
      </Text>
    </Col>
    <Col col={0.15} mb="xs">
      <Text
        variant="meta2"
        color="nude.400"
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="sm"
      >
        Date
      </Text>
    </Col>
    <Col col={0.12} mb="xs">
      <Text
        variant="meta2"
        color="nude.400"
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="sm"
      >
        Time
      </Text>
    </Col>
    <Col col={0.3} mb="xs">
      <Text
        variant="meta2"
        color="nude.400"
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="sm"
      >
        Service
      </Text>
    </Col>
    {slim === false && (
      <>
        <Col col={0.15} mb="xs">
          <Text
            variant="meta2"
            color="nude.400"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="sm"
          >
            Total
          </Text>
        </Col>
      </>
    )}
  </Row>
)
