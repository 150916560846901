import { PlusIcon } from '@heroicons/react/solid'
import { Button } from 'ui/components/Button'
import { useParams } from 'react-router-dom'
import { useCreateCustomerNote, useCustomerNotes, useDeleteCustomerNote } from 'api/CustomerNotes'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { useState } from 'react'
import { EmptyState } from 'ui/components/EmptyState'
import { PencilAltIcon } from '@heroicons/react/outline'
import { Avatar } from 'ui/components/Avatar'
import { DateTime } from 'luxon'
import { Transition } from '@headlessui/react'
import { ConfirmationModal } from 'ui/Modal'
import { Section } from '../Shared/Section'
import { Icon } from 'ui/components/Logo'
import { useUser } from 'hooks/UseUser'

export const Note = ({ note, isAuthor, onDelete }) => (
  <li className="p-3 text-sm">
    <div className="rounded-xl text-gray-700 bg-gray-100 p-3 px-4">{note.note}</div>
    <div className="flex space-x-2 items-center text-xs py-2 px-2">
      <div className="flex-shrink-0">
        {note.author && (
          <Avatar
            width={24}
            height={24}
            firstName={note.author.first_name}
            lastName={note.author.last_name}
          />
        )}

        {!note.author && (
          <div className="h-[40px] w-[40px] rounded-full bg-gray-300 p-[10px] pt-[11px]">
            <Icon width={8} variant="bright" />
          </div>
        )}
      </div>
      <div className="flex-1">
        {note.author && (
          <div className="font-medium">
            {note.author.first_name} {note.author.last_name}
          </div>
        )}
        {!note.author && <div className="font-medium italic">System</div>}
        <time
          dateTime={note.created_at}
          className="text-gray-500"
          title={DateTime.fromJSDate(note.created_at).toLocaleString(DateTime.DATETIME_FULL)}
        >
          {DateTime.fromJSDate(note.created_at).toRelative()}
        </time>
      </div>
      {isAuthor && onDelete && (
        <button
          className="block font-medium text-red-700 hover:text-red-600"
          onClick={() => onDelete(note.id)}
        >
          Delete
        </button>
      )}
    </div>
  </li>
)

const Textarea = ({ onClose }) => {
  const notification = useNotificationContext()
  const { clientId } = useParams()
  const { isLoading: isAdding, mutate: createNote } = useCreateCustomerNote(clientId)
  const user = useUser()
  const [note, setNote] = useState('')

  const handleCancel = () => {
    setNote('')
    onClose()
  }

  const handleSubmit = () => {
    createNote(
      { note },
      {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Note added"
              description="Your note was successfully added"
              variant="success"
              autoDismiss
            />
          )

          setNote('')
          onClose()
        },
        onError: () => {
          notification.notify(
            <Notification
              title="Couldn't add note"
              description="Sorry, there was an error when adding your note."
              variant="danger"
              autoDismiss
            />
          )
        },
      }
    )
  }

  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form action="#" className="relative">
          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-gray-300 focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-violet">
            <label htmlFor="note" className="sr-only">
              Add new note
            </label>
            <textarea
              rows={3}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              name="note"
              id="note"
              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
              placeholder="Add new note..."
            />

            <div className="py-2" aria-hidden="true">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
            <div className="flex-shrink-0 space-x-2">
              <Button label="Cancel" onClick={handleCancel} />
              <Button
                disabled={note.length === 0}
                loading={isAdding}
                variant="primary"
                label="Post"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export const Notes = () => {
  const { clientId } = useParams()
  const { isLoading, data: { data: notes = [] } = {} } = useCustomerNotes(clientId)
  const { isLoading: isDeletingNote, mutate: deleteNote } = useDeleteCustomerNote(clientId)
  const [showingDeletePrompt, setShowingDeletePrompt] = useState(null)
  const [addingNewNote, setAddingNewNote] = useState(false)
  const user = useUser()
  const notification = useNotificationContext()

  const handleDeleteNote = () => {
    deleteNote(showingDeletePrompt, {
      onSuccess: () => {
        setShowingDeletePrompt(null)
        notification.notify(
          <Notification
            title="Note deleted"
            description="Your note was successfully deleted"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: () => {
        setShowingDeletePrompt(null)
        notification.notify(
          <Notification
            title="Couldn't delete note"
            description="Sorry, there was an error when deleting this note."
            variant="danger"
            autoDismiss
          />
        )
      },
    })
  }

  return (
    <Section
      title="Notes"
      button={<Button label="New" leadingIcon={PlusIcon} onClick={() => setAddingNewNote(true)} />}
    >
      {isLoading && <div className="bg-gray-200 animate-pulse h-16" />}

      {!isLoading && notes.length === 0 && (
        <EmptyState title="This client has no notes" icon={PencilAltIcon} />
      )}

      {!isLoading && notes.length > 0 && (
        <ul className="-space-y-2">
          {notes.map((note) => (
            <Note
              key={note.id}
              note={note}
              isAuthor={note.author?.id === user.sub}
              onDelete={setShowingDeletePrompt}
            />
          ))}
        </ul>
      )}

      <Transition
        show={addingNewNote}
        enter="transition duration-100 ease-out"
        enterFrom="transform -translate-y-10 opacity-0"
        enterTo="transform translate-y-0 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform translate-y-0 opacity-100"
        leaveTo="transform -translate-y-5 opacity-0"
      >
        <div className="p-3">
          <Textarea onClose={() => setAddingNewNote(false)} />
        </div>
      </Transition>

      <ConfirmationModal
        isOpen={showingDeletePrompt !== null}
        title="Delete note"
        onClose={() => setShowingDeletePrompt(null)}
        onConfirm={handleDeleteNote}
        variant="danger"
        confirmButtonLabel="Delete note"
        confirming={isDeletingNote}
      >
        <p className="text-sm text-gray-500">Are you sure you want to delete this note?</p>
      </ConfirmationModal>
    </Section>
  )
}
