import React from 'react'
import { useState } from 'react'
import { AddressAutofill } from '@mapbox/search-js-react'
import classNames from 'classnames'
import { ReactChild } from '@mapbox/search-js-react/node_modules/@types/react/index'
import { useCurrentSite } from 'contexts/SiteConfig'

interface AddressLookUpProps {
  onChange: Function
  value?: string
  showAddressDetails: boolean
}

export const addressDetailsClasses =
  'py-3 focus:ring-violet focus:ring-2 focus:ring-offset-2 shadow-sm sm:text-sm border-gray-300 focus:border-gray-300 dark:bg-gray-700 dark:border-transparent dark:text-white disabled:bg-gray-100 disabled:cursor-not-allowed text-gray-900'

export const AddressLookUp: React.FC<AddressLookUpProps> = ({ onChange, showAddressDetails }) => {
  const mapboxToken = import.meta.env.VITE_MAPBOX_PUBLIC_KEY
  const [address, setAddress] = useState(null)
  const site = useCurrentSite()

  function handleAddressChange(mapboxAddressResponse) {
    setAddress(mapboxAddressResponse)
    onChange(mapboxAddressResponse.features[0].properties)
  }

  //TO DO: update the return type of this componenet once Search-js-react is updated, along with our react version
  function returnInputAsMapboxChild(): ReactChild {
    return (
      <input
        name="address"
        placeholder="Address"
        type="text"
        className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}
      />
    ) as ReactChild
  }

  return (
    <div>
      <form>
        <AddressAutofill
          accessToken={mapboxToken}
          options={{
            language: 'en',
            country: `${site.country_code ?? 'GB'}`,
          }}
          onRetrieve={(value) => handleAddressChange(value)}
        >
          {returnInputAsMapboxChild()}
        </AddressAutofill>
        {address !== null && showAddressDetails && (
          <div className="grid grid-cols-2 grid-rows-2 mt-4">
            <input
              name="town"
              placeholder="Town"
              type="text"
              className={classNames(addressDetailsClasses)}
            />
            <input
              name="county"
              placeholder="County"
              type="text"
              className={classNames(addressDetailsClasses)}
            />
            <input
              name="postcode"
              placeholder="Postcode"
              type="text"
              className={classNames(addressDetailsClasses)}
            />
            <input
              name="country"
              placeholder="Country"
              type="text"
              className={classNames(addressDetailsClasses)}
            />
          </div>
        )}
      </form>
    </div>
  )
}
