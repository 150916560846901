import { Info, DateTime } from 'luxon'
import { Button } from '../Button'
import { ForwardedRef, forwardRef } from 'react'
import { createEvent } from 'utilities/createEvent'
import classNames from 'classnames'

const months = Info.months('short')

type DateObj = {
  day: string
  month: string
  year: string
}
interface DateOfBirthInputProps {
  value: DateObj
  onChange: (date: DateObj) => void
  hideLabel?: boolean
  label: string
  name: string
  className?: string
  showClearButton?: boolean
}

export const DateOfBirth: React.FC<DateOfBirthInputProps> = forwardRef(
  ({ value, onChange, ...props }: DateOfBirthInputProps, ref: ForwardedRef<any>) => (
    <fieldset className={classNames('p-0 flex space-x-8 items-center', props.className)}>
      <div className="bg-white rounded-xl shadow-sm -space-y-px flex-1">
        <div className="flex -space-x-px">
          <div className="w-1/2 flex-1 min-w-0">
            <label htmlFor="dob_date" className="sr-only">
              Date
            </label>
            <input
              className="px-3 h-full focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-violet relative block w-full rounded-none rounded-l-md bg-transparent focus:z-10 sm:text-sm border border-gray-300"
              placeholder="Date"
              name="dob_date"
              type="number"
              min="1"
              max="31"
              value={value?.day ?? ''}
              onChange={(e) =>
                onChange({
                  day: e.target.value ?? '',
                  month: value?.month,
                  year: value?.year,
                })
              }
            />
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="dob_month" className="sr-only">
              Month
            </label>
            <select
              className="focus:ring-violet focus:outline-none focus:ring-2 focus:ring-offset-2 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
              placeholder="Month"
              name="dob_month"
              value={value?.month || ''}
              onChange={(e) =>
                onChange({
                  day: value?.day,
                  month: e.target.value ?? '',
                  year: value?.year,
                })
              }
            >
              <option disabled value="">
                Month
              </option>
              <option disabled>---</option>
              {months.map((month, index) => (
                <option key={`month_${index}`} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="dob_year" className="sr-only">
              Year
            </label>
            <input
              className="px-3 h-full focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-violet relative block w-full rounded-none rounded-r-md bg-transparent focus:z-10 sm:text-sm border border-gray-300"
              placeholder="Year"
              name="dob_year"
              type="number"
              min="1900"
              max={DateTime.now().year}
              value={value?.year ?? ''}
              onChange={(e) =>
                onChange({
                  day: value?.day,
                  month: value?.month,
                  year: e.target.value ?? '',
                })
              }
            />
          </div>
        </div>
      </div>

      {props.showClearButton !== false && (
        <Button label="Clear" variant="ghost" onClick={() => onChange(null)} />
      )}
    </fieldset>
  )
)

export const FormikDateOfBirth = forwardRef(
  ({ onChange: parentOnChange, value, ...props }: any, ref: any) => {
    const handleOnChange = (value) => {
      const month = value.month
        ? new Number(value.month).valueOf() < 10
          ? `0${value.month}`
          : value.month
        : ''

      parentOnChange(
        createEvent({
          value: `${value.year ?? ''}-${month}-${value.day ?? ''}`,
          name: props.name,
        })
      )
    }

    const valueParts = (value || '').split('-')
    const valueObject = {
      year: valueParts[0] ?? '',
      month: valueParts[1] ? Number(valueParts[1]).valueOf() : '',
      day: valueParts[2] ?? '',
    }

    return (
      <DateOfBirth
        name={props.name}
        value={valueObject}
        onChange={handleOnChange}
        ref={ref}
        {...props}
      />
    )
  }
)
