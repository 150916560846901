import { DateTime } from 'luxon'
import { useInfiniteAuditLogs } from 'api/AuditLog'
import { Button } from 'ui/components/Button'
import { EmptyState } from 'ui/components/EmptyState'
import { BookOpenIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Modal } from 'ui/Modal'

export const AuditLogs = ({ subjectId, showExpanded = false, ignoreVerbose = false }) => {
  const { isLoading, isSuccess, hasNextPage, fetchNextPage, data } = useInfiniteAuditLogs(subjectId)

  const [viewDetails, setViewDetails] = useState(null)

  if (isLoading) {
    return <LoadingSkeleton />
  }

  return (
    <div>
      <ul className="pl-2">
        {data.pages[0].total === 0 && <EmptyState icon={BookOpenIcon} title="No audit logs" />}
        {ignoreVerbose
          ? data.pages.map((page, pageIndex) =>
              page.data
                .filter((log) => !(log.friendly_changes.length === 0 && log.type === 'updated'))
                .map((auditLog, index) => (
                  <Activity
                    onViewDetails={() => setViewDetails(auditLog)}
                    key={auditLog.id}
                    log={auditLog}
                    showExpanded={showExpanded}
                  />
                ))
            )
          : data.pages.map((page, pageIndex) =>
              page.data.map((auditLog, index) => (
                <Activity
                  onViewDetails={() => setViewDetails(auditLog)}
                  key={auditLog.id}
                  log={auditLog}
                  showExpanded={showExpanded}
                />
              ))
            )}
      </ul>
      {hasNextPage && (
        <div className="text-center">
          <Button label="Load older entries" variant="ghost" onClick={() => fetchNextPage()} />
        </div>
      )}

      <AuditLogDetailModal viewDetails={viewDetails} onClose={() => setViewDetails(null)} />
    </div>
  )
}

const AuditLogDetailModal = ({ viewDetails, onClose }) => {
  return (
    <Modal title="Audit log details" isOpen={viewDetails !== null} onClose={onClose} styleReset>
      <div className="space-y-4 text-gray-700">
        <div className="text-sm">{viewDetails?.description}</div>
        <ul className="m-0 space-y-2 text-sm list-disc pl-4 text-gray-700">
          {(viewDetails?.friendly_changes ?? []).map((change) => (
            <li key={change}>{change}</li>
          ))}
        </ul>

        {viewDetails?.friendly_changes.length === 0 && (
          <div className="text-sm text-gray-500">No further details about this activity</div>
        )}

        <div className="pt-4">
          <CauserSummary causer={viewDetails?.causer} createdAt={viewDetails?.created_at} />
        </div>
      </div>
    </Modal>
  )
}

const CauserSummary = ({
  causer,
  createdAt,
  withViewDetailsButton = false,
  onViewDetails = null,
}) => {
  const causerName = causer?.full_name ?? 'Trybe'

  return (
    <div className="flex space-x-2 items-center text-xs">
      <div className="flex-1">
        <div className="font-medium">{causerName}</div>
        <time
          dateTime={createdAt}
          className="text-gray-500"
          title={DateTime.fromJSDate(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
        >
          {DateTime.fromJSDate(createdAt).toRelative()}
        </time>
        {withViewDetailsButton && (
          <>
            {' · '}
            <button onClick={onViewDetails} className="text-violet hover:underline font-medium">
              View details
            </button>
          </>
        )}
      </div>
    </div>
  )
}

interface ActivityProps {
  // The audit log
  log: any
  // When the "view details" button is clicked
  onViewDetails: () => void
  // Whether to show all the changes. If false, only the description is shown
  // and the user can click "View Details" to see the rest.
  showExpanded: boolean
}

const Activity: React.FC<ActivityProps> = ({ log, onViewDetails, showExpanded }) => {
  return (
    <li className="text-gray-700 p-3 text-sm border-l-2 border-gray-200 ml-4 pl-0">
      <div className="-ml-3 space-y-2 border border-violet-100 rounded-md p-3 bg-white">
        <div>{log.description}</div>
        {showExpanded && (
          <ul className="list-disc pl-4 space-y-1">
            {log.friendly_changes.map((field) => (
              <li key={field} className="text-gray-500">
                {field}
              </li>
            ))}
          </ul>
        )}

        <CauserSummary
          causer={log.causer}
          onViewDetails={onViewDetails}
          withViewDetailsButton={!showExpanded}
          createdAt={log.created_at}
        />
      </div>
    </li>
  )
}

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col space-y-2 animate-pulse">
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
      <div className="h-12 bg-gray-200" />
    </div>
  )
}
