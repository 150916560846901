import { ConfirmationModal } from 'ui/Modal'
import { useParams } from 'react-router-dom'
import { useUpdateCustomer } from 'api/Customers'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'

export const RemovePhotoModal = ({ isOpen, onClose }) => {
  const { clientId } = useParams()
  const notification = useNotificationContext()

  const { isLoading, mutate } = useUpdateCustomer()

  const handleRemove = () => {
    mutate(
      [
        clientId,
        {
          avatar_id: null,
        },
      ],
      {
        onSuccess: () => {
          onClose()
          notification.notify(
            <Notification
              title="Avatar removed"
              description="The profile picture for this client has been removed."
              variant="success"
              autoDismiss
            />
          )
        },
        onError: (e: any) => {
          notification.notify(<ApiErrorNotification error={e} />)
        },
      }
    )
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Remove photo"
      onClose={onClose}
      confirmButtonLabel="Remove"
      onConfirm={handleRemove}
      confirming={isLoading}
      variant="danger"
    >
      <p className="text-sm text-gray-500">Are you sure you want to remove this photo?</p>
    </ConfirmationModal>
  )
}
