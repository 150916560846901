import {
  CreateDiscountForOrderItemRequest,
  CreateDiscountForOrderRequest,
  DeleteItemDiscountRequest,
  DeleteOrderDiscountRequest,
  DeletePromoCodeFromOrderRequest,
  OrdersApi,
} from '@trybeapp/sdk'
import { useMutation, useQueryClient } from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new OrdersApi(apiConfiguration)

export const useApplyOrderDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ orderId, applyDiscountToOrder }: CreateDiscountForOrderRequest) => {
      return await api.createDiscountForOrder({
        orderId,
        applyDiscountToOrder,
      })
    },
    {
      onSuccess: (_, { orderId }) => {
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderDiscounts', orderId])
      },
    }
  )
}

export const useRemoveOrderDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ orderId, discountId }: DeleteOrderDiscountRequest) => {
      return await api.deleteOrderDiscount({
        orderId,
        discountId,
      })
    },
    {
      onSuccess: (_, { orderId }) => {
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderDiscounts', orderId])
      },
    }
  )
}

export const useApplyOrderItemDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ orderId, itemId, applyDiscountToOrderItem }: CreateDiscountForOrderItemRequest) => {
      return await api.createDiscountForOrderItem({
        orderId,
        itemId,
        applyDiscountToOrderItem,
      })
    },
    {
      onSuccess: (_, { orderId }) => {
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useRemoveOrderItemDiscount = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ orderId, itemId, discountId }: DeleteItemDiscountRequest) => {
      return await api.deleteItemDiscount({
        orderId,
        itemId,
        discountId,
      })
    },
    {
      onSuccess: (_, { orderId }) => {
        queryClient.invalidateQueries(['orders', orderId])
      },
    }
  )
}

export const useRemovePromoCode = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ orderId, discountTypeId }: DeletePromoCodeFromOrderRequest) => {
      return await api.deletePromoCodeFromOrder({
        orderId,
        discountTypeId,
      })
    },
    {
      onSuccess: (_, { orderId, discountTypeId }) => {
        queryClient.invalidateQueries(['orders', orderId])
        queryClient.invalidateQueries(['orderDiscounts', orderId])
        queryClient.invalidateQueries(['discountTypes', discountTypeId])
      },
    }
  )
}
