import { th } from '@xstyled/system'
import { VARIANTS } from '@trybeapp/ui.utilities'

const TAG_VARIANTS = {
  blue: 'colors.sub.blue',
  dark: 'colors.dark.500',
  default: 'colors.nude.100',
  green: 'colors.sub.green',
  orange: 'colors.sub.orange',
  pink: 'colors.sub.pink',
  primary: 'colors.primary.500',
  purple: 'colors.sub.purple',
  red: 'colors.sub.red',
  secondary: 'colors.secondary.500',
  turquoize: 'colors.sub.turquoize',
  yellow: 'colors.sub.yellow',
  danger: 'colors.danger.100',
  ...VARIANTS,
}

/**
 * @deprecated
 */
export const getVariantsTheme = (theme) => {
  return Object.entries(TAG_VARIANTS).reduce((acc, [variant, color]) => {
    acc[variant] = { 'background-color': th(color)({ theme }) }
    return acc
  }, {})
}

/**
 * @deprecated
 */
export const getTags = (theme) => {
  const { colors, fontSizes, fontWeights, space, toRem } = theme

  const sizes = {
    xs: toRem(18),
    sm: toRem(19),
    md: toRem(21),
    lg: toRem(27),
    xl: toRem(36),
  }

  const variants = getVariantsTheme(theme)
  return {
    default: {
      'font-size': fontSizes.meta2,
      'font-weight': fontWeights.regular,
      color: colors.light[900],
    },
    variants: {
      ...variants,
      danger: {
        ...variants.danger,
        color: colors.danger[500],
      },
      'secondary-danger': {
        ...variants.danger,
        'background-color': colors.danger[500],

        color: colors.light[900],
      },
      success: {
        'background-color': colors.success[100],
        color: colors.success[500],
      },
      info: {
        'background-color': colors.info[100],
        color: colors.info[500],
      },
      warning: {
        'background-color': colors.warning[100],
        color: colors.warning[700],
      },
      default: {
        'background-color': colors.light[500],
        color: colors.nude[800],
      },
    },
    sizes: {
      xs: {
        padding: `0 ${space.xs}`,
        height: sizes.xs,
      },
      sm: {
        padding: `0 ${space.sm}`,
        height: sizes.sm,
      },
      md: {
        padding: `0 ${space.md}`,
        height: sizes.md,
      },
      lg: {
        padding: `0 ${space.lg}`,
        height: sizes.lg,
        'font-size': fontSizes.meta1,
      },
      xl: {
        padding: `0 ${space.xl}`,
        height: sizes.xl,
        'font-size': fontSizes.meta1,
      },
    },
    shape: {
      sm: {
        width: sizes.sm,
        height: sizes.sm,
      },
      md: {
        width: sizes.md,
        height: sizes.md,
      },
      lg: {
        width: sizes.lg,
        height: sizes.lg,
      },
      xl: {
        width: sizes.xl,
        height: sizes.xl,
      },
    },
  }
}
