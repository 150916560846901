import { ChangeEvent } from 'react'

interface CheckboxProps {
  /** The current state of the checkbox */
  checked?: boolean
  /** Fired when the input is changed */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown
  /** If the checkbox should be disabled */
  disabled?: boolean
  /** The ID of the checkbox */
  id: string
  /** The input name of the checkbox */
  name: string
  /** The ID of the element which describes what this checkbox is for */
  describedBy: string
  /** Whether the checkbox is in a loading state */
  loading?: boolean
  /** The value of the checkbox */
  value?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onChange = () => {},
  disabled = false,
  loading = false,
  value = '',
  id,
  name,
  describedBy,
}) => (
  <div className="relative">
    {loading && (
      <div className="absolute inset-0 flex items-center cursor-not-allowed">
        <span className="sr-only">Loading...</span>
        <svg className="animate-rotate stroke-current h-5 w-5 text-grape-500" viewBox="0 0 50 50">
          <circle
            strokeLinecap="round"
            className="animate-spinner-path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    )}
    <input
      id={id}
      aria-describedby={describedBy}
      name={name}
      value={value}
      type="checkbox"
      className={`${
        loading ? 'opacity-0' : ''
      } focus:ring-2 focus:ring-grape-500 hover:border-grape-400 hover:text-grape-700 h-4 w-4 relative -top-px text-grape-500 border-grape-300 rounded cursor-pointer disabled:opacity-50`}
      disabled={disabled || loading}
      onChange={onChange}
      checked={checked}
    />
  </div>
)
