import { useMutation } from 'react-query'
import { CustomerLabelsApi } from '@trybeapp/sdk.customer'

const api = new CustomerLabelsApi()

export const useAttachCustomerLabel = (customerId: string) => {
  return useMutation((labels) =>
    api.attachCustomerLabels(customerId, {
      attachDetatchCustomerLabelsRequest: {
        labels: [labels],
      },
    })
  )
}

export const useDetatchCustomerLabel = (customerId: string) => {
  return useMutation((labels) =>
    api.detatchCustomerLabels(customerId, {
      attachDetatchCustomerLabelsRequest: {
        labels: [labels],
      },
    })
  )
}
