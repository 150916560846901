import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { useCurrentOrder } from 'contexts/Order'
import { Checkbox } from 'ui/components/Form/Checkbox'
import pluralize from 'pluralize'

export const GuestsSelector = ({ onChange, value = [], max, disabled = false }) => {
  const { order: { guests: orderGuests = [] } = {} } = useCurrentOrder()
  const [guestIds, setGuestIds] = useState(value)
  const [allSelected, setAllSelected] = useState(false)
  const [originalValue] = useState([...value])
  max = max || undefined

  useEffect(() => {
    setGuestIds(value)
  }, [value])

  const handleChange = (e) => {
    const guestId = e.target.value
    const isChecked = e.target.checked
    let updatedIds

    if (isChecked || !guestIds.includes(guestId)) {
      updatedIds = [...guestIds, guestId]
    } else if (!isChecked && guestIds.includes(guestId)) {
      updatedIds = guestIds.filter((id) => id !== guestId)
    }

    setGuestIds(updatedIds)
    onChange(updatedIds)
    updateAllSelected()
  }

  const isOptionDisabled = (guest) => {
    return disabled || (max !== undefined && !guestIds.includes(guest.id) && guestIds.length >= max)
  }

  const updateAllSelected = () => {
    if (guestIds.length > 0 && guestIds.length === orderGuests.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }

  const toggleSelectAll = () => {
    let updatedIds

    if (allSelected) {
      updatedIds = []
      setAllSelected(false)
    } else {
      updatedIds = map(orderGuests, 'id')
      setAllSelected(true)
    }

    setGuestIds(updatedIds)
    onChange(updatedIds)
  }

  return (
    <div className="flex flex-col gap-2">
      {orderGuests.length > 2 && max === undefined && (
        <div className="text-sm text-gray-500">
          {guestIds.length} {pluralize('guest', guestIds.length)} selected
        </div>
      )}
      {orderGuests.length > 2 && (
        <div>
          <label
            id="select_all_guests_label"
            className={classNames(
              'flex gap-2 items-center border border-gray-200 shadow-sm hover:bg-violet-50 text-gray-700 p-2 rounded-md cursor-pointer text-sm font-medium',
              { 'bg-gray-50': allSelected }
            )}
          >
            <Checkbox
              id="select_all_guests"
              name="select_all_guests"
              onChange={toggleSelectAll}
              checked={allSelected}
              describedBy="select_all_guests_label"
            />
            <div>Select all</div>
          </label>
        </div>
      )}
      {orderGuests.map((guest) => (
        <div
          key={guest.id}
          className={classNames({
            'opacity-50': isOptionDisabled(guest),
          })}
        >
          <label
            id={`guest_${guest.id}_description`}
            className={classNames(
              'flex gap-2 items-center border border-gray-300 shadow-sm hover:bg-violet-50 text-gray-700 p-2 rounded-md cursor-pointer',
              { 'bg-violet-50': guestIds.includes(guest.id) }
            )}
          >
            <Checkbox
              id={guest.id}
              name={`guest_${guest.id}`}
              onChange={handleChange}
              checked={guestIds.includes(guest.id)}
              describedBy={`guest_${guest.id}_description`}
              disabled={isOptionDisabled(guest)}
              value={guest.id}
            />
            <div>{guest.name}</div>
            {originalValue.includes(guest.id) && !guestIds.includes(guest.id) && (
              <div className="text-xs text-gray-500">Previously selected</div>
            )}
          </label>
        </div>
      ))}
    </div>
  )
}
