import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Text } from '@trybeapp/ui.text'
import { STATUS_CANCELLED, STATUS_CONFIRMED, STATUS_SETTLED } from 'constants/orderStatuses'
import { Col, Row } from '@trybeapp/ui.grid'
import { Card } from '../../../components/Card'
import { StatusTag } from '../Overview'
import { useDeleteOrderItem } from 'api/OrderItems'
import { CostColumn } from '../Columns'
import { ItemActions } from './ItemActions'
import { Modal, ModalButtons } from 'ui/Modal'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { ConnectedField } from 'components/ConnectedField'
import { Alert } from 'ui/components/Alert'
import { DateTime } from 'luxon'
import { Input } from 'ui/components/Form/Input'
import { Button } from 'ui/components/Button'
import { useSendVoucherRecipientEmail } from 'api/VoucherPurchases'
import { DropdownItem } from 'ui/components/Dropdown'
import { MailIcon, PencilAltIcon, ChevronDoubleLeftIcon } from '@heroicons/react/solid'
import { EditVoucherCustomMessageOverlay } from '../../Items/EditVoucherCustomMessageOverlay/EditIVoucherCustomMessageOverlay'
import { EditVoucherDeliveryMethodOverlay } from '../../Items/EditVoucherDeliveryMethodOverlay/EditVoucherDeliveryMethodOverlay'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useCurrentOrder } from 'contexts/Order'
import { VoucherDeliveryDetailsModal } from './VoucherDeliveryDetailsModal'

export const VoucherItemRow = ({ item }) => {
  const { orderId } = useParams()
  const {
    id: itemId,
    type_name: name = '',
    quantity,
    item_configuration,
    purchasable_details,
  } = item
  const status = item.status
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [customMessageModalOpen, setCustomMessageModalOpen] = useState(false)
  const [voucherDeliveryDetailsModalOpen, setVoucherDeliveryDetailsModalOpen] = useState(false)
  const [editDeliveryMethodModalOpen, setEditDeliveryMethodModalOpen] = useState(false)
  const { order: { status: orderStatus } = {} } = useCurrentOrder()
  const isCancelled = status === STATUS_CANCELLED
  const isConfirmed = status === STATUS_CONFIRMED
  const hasPhysicalDelivery = item.item_configuration.delivery_method_type === 'physical'
  const orderIsSettled = orderStatus === STATUS_SETTLED

  const customMessageLabel =
    item.item_configuration?.custom_message !== null &&
    item.item_configuration?.custom_message !== ''
      ? 'Edit message'
      : 'Add message'

  const featureFlags = useActiveFeatureFlags()

  const validTo = useMemo(() => {
    if (!purchasable_details.valid_to_date) return null

    return DateTime.fromISO(purchasable_details.valid_to_date).toLocaleString(DateTime.DATE_MED)
  }, [purchasable_details.valid_to_date])

  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  const generateSubtitle = (item) => {
    if (!orderIsSettled && item.item_configuration.delivery_method_type === 'email') {
      return 'Order must be checked out to send voucher'
    }

    if (!orderIsSettled && item.item_configuration.delivery_method_type === 'physical') {
      return 'Cannot manually send vouchers set to physical delivery'
    }
    return null
  }

  const GetDeliveryStatusMessage = (item) => {
    if (!item_configuration.delivery_date) return null

    const deliveryDate = DateTime.fromISO(item.item_configuration.delivery_date)
    const today = DateTime.now()

    if (deliveryDate < today) {
      return 'Delivered ' + deliveryDate.toLocaleString(DateTime.DATE_MED)
    }
    return 'Delivery ' + deliveryDate.toLocaleString(DateTime.DATE_MED)
  }

  const GenerateDeliveryInstructions = (item) => {
    if (item.item_configuration['delivery_method_type'] === 'physical') {
      return (
        <div className="flex">
          <Text
            variant="meta2"
            color="nude.400"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="sm"
          >
            Physical delivery -{' '}
            <button
              className="hover:underline"
              onClick={() => setVoucherDeliveryDetailsModalOpen(true)}
            >
              <Text
                variant="meta2"
                color="nude.400"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="sm"
              >
                see details
              </Text>
            </button>
          </Text>
        </div>
      )
    }

    if (
      item.item_configuration['delivery_method_type'] === 'email' &&
      item.item_configuration['delivery_date'] !== null
    ) {
      return (
        <div>
          Digital delivery -{' '}
          <button
            className="hover:underline"
            onClick={() => setVoucherDeliveryDetailsModalOpen(true)}
          >
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              see details
            </Text>
          </button>
        </div>
      )
    }

    return ''
  }

  return (
    <Card className="my-2">
      <Card.Body>
        {item_configuration.delivery_date !== null && (
          <div className="flex flex-row-reverse">
            <div className="text-gray-500 text-sm">{GetDeliveryStatusMessage(item)}</div>
          </div>
        )}
        <Row>
          <Col col={0.7} mb="lg">
            <Text as="span" variant="body3" color="nude.700">
              <strong>{name}</strong>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col col={0.13} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Status
            </Text>
          </Col>
          <Col col={0.27} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Quantity
            </Text>
          </Col>
          <Col col={0.3} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Voucher
            </Text>
          </Col>
          <Col col={0.15} mb="xs">
            <Text
              variant="meta2"
              color="nude.400"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="sm"
            >
              Total
            </Text>
          </Col>
          <Col col={0.1} mb="xs"></Col>
        </Row>
        <Row>
          <Col col={0.13}>
            <StatusTag status={status} />
          </Col>
          <Col col={0.27}>
            <Text variant="body2" color="nude.900" lineHeight={1.2}>
              {quantity}
            </Text>
          </Col>
          <Col col={0.3}>
            <Text variant="body2" color="nude.900" lineHeight={1.2}>
              {name}
            </Text>
            {purchasable_details.valid_to_date !== null && (
              <Text variant="meta1" color="nude.500" lineHeight={1.2}>
                Exp: {validTo}
              </Text>
            )}
          </Col>
          <CostColumn item={item} />
          <Col col={0.1}>
            {!isCancelled && (
              <ItemActions item={item} handleDelete={handleDelete} isDeleting={isDeleting}>
                {isConfirmed && !hasPhysicalDelivery && (
                  <>
                    <DropdownItem
                      label="Send"
                      disabled={!orderIsSettled}
                      subtitle={generateSubtitle(item)}
                      onClick={() => setEmailModalOpen(true)}
                      icon={MailIcon}
                    />
                  </>
                )}
                {featureFlags.includes('voucher_delivery_method') && (
                  <DropdownItem
                    label={'Edit delivery details'}
                    onClick={() => setEditDeliveryMethodModalOpen(true)}
                    icon={ChevronDoubleLeftIcon}
                    disabled={orderIsSettled}
                    subtitle={
                      orderIsSettled && 'Cannot edit delivery details once the order is settled'
                    }
                  />
                )}
                <DropdownItem
                  label={customMessageLabel}
                  onClick={() => setCustomMessageModalOpen(true)}
                  icon={PencilAltIcon}
                />
              </ItemActions>
            )}
            <EmailModal
              isOpen={emailModalOpen}
              onClose={() => setEmailModalOpen(false)}
              item={item}
            />
            <EditVoucherCustomMessageOverlay
              item={item}
              onClose={() => setCustomMessageModalOpen(false)}
              isOpen={customMessageModalOpen}
            />
            <EditVoucherDeliveryMethodOverlay
              item={item}
              onClose={() => setEditDeliveryMethodModalOpen(false)}
              isOpen={editDeliveryMethodModalOpen}
            />
            <VoucherDeliveryDetailsModal
              item={item}
              onClose={() => setVoucherDeliveryDetailsModalOpen(false)}
              isOpen={voucherDeliveryDetailsModalOpen}
            />
          </Col>
        </Row>
        {featureFlags.includes('voucher_delivery_method') && (
          <Row>
            <Col col={1}>
              <Text
                variant="meta2"
                color="nude.400"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="sm"
                className="mt-4"
              >
                {GenerateDeliveryInstructions(item)}
              </Text>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  )
}

const EmailModal = ({ isOpen, onClose, item }) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Send voucher email" styleReset>
    <EmailModalForm item={item} onClose={onClose} />
  </Modal>
)

const EmailModalForm = ({ item, onClose }) => {
  const { mutate, isLoading } = useSendVoucherRecipientEmail()
  const notification = useNotificationContext()
  const { orderId } = useParams()

  const initialValues = {
    recipient_name: item.item_configuration?.recipient_name ?? '',
    recipient_email: item.item_configuration?.recipient_email ?? '',
  }

  const validationSchema = object({
    recipient_name: string().required('Name is required'),
    recipient_email: string().required().email('Must be a valid email'),
  })

  const handleSend = (values) => {
    mutate([item.purchasable_details.id, values, item, orderId], {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Email sent"
            description="The voucher was sent to the recipient"
            variant="success"
            autoDismiss
          />
        )
        onClose()
      },
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSend}>
      <Form>
        <div className="flex flex-col space-y-2">
          {item.purchasable_details.email_sent_at && (
            <Alert variant="info" title="">
              {`An email was sent on ${DateTime.fromISO(
                item.purchasable_details.email_sent_at
              ).toLocaleString({
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
              })}
            `}
            </Alert>
          )}
          <div className="w-3/4 flex-1 min-w-0">
            <ConnectedField
              label="Recipient name"
              name="recipient_name"
              component={Input}
              type="text"
              placeholder="Full name"
              autoFocus
            />
          </div>
          <div className="w-3/4 flex-1 min-w-0">
            <ConnectedField
              label="Recipient email"
              name="recipient_email"
              component={Input}
              type="email"
              placeholder="Email"
            />
          </div>
        </div>
        <ModalButtons>
          <Button variant="primary" type="submit" label="Send" loading={isLoading} />
          <Button label="Cancel" onClick={onClose} />
        </ModalButtons>
      </Form>
    </Formik>
  )
}
