import { useMutation, useQueryClient } from 'react-query'
import { VoucherPurchasesApi } from '@trybeapp/sdk.shop'

const api = new VoucherPurchasesApi()

export const usePreviewVoucherRecipientEmail = () =>
  useMutation(([voucherPurchaseId, values]: any) =>
    api.voucherRecipientEmailPreview(voucherPurchaseId, values)
  )

export const useSendVoucherRecipientEmail = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    ([voucherPurchaseId, values]: any) => {
      return api.voucherRecipientEmailSend(voucherPurchaseId, values)
    },
    {
      onSuccess: (_, args) => {
        if (args[2].item_configuration.delivery_date !== null) {
          queryClient.invalidateQueries(['orders', args[3]])
        }
      },
    }
  )
}
