import { SupportedLocale } from '@trybeapp/sdk'
import { useSupportedLocales } from 'api/Meta'
import React, { useMemo } from 'react'
import {
  MultiSelectProps,
  Select,
  SelectOption,
  SingleSelectProps,
} from 'ui/components/Form/Select'

type SingleLocaleSelectProps = Omit<SingleSelectProps, 'options'> & {
  selected?: SelectOption<string> // Selected is now optional
  selectedByCode?: string
}

type MultiLocaleSelectProps = Omit<MultiSelectProps, 'options'> & {
  selected?: SelectOption<string>[] // Selected is now optional
  selectedByCode?: string[]
}

type LocaleSelectProps = SingleLocaleSelectProps | MultiLocaleSelectProps

export const localeToOption = (locale: SupportedLocale): SelectOption<string> => ({
  name: (
    <div className="flex space-x-1 items-center">
      <span>{locale.emoji}</span>
      <span>{locale.name}</span>
    </div>
  ),
  value: locale.code,
  altText: locale.name,
})

/**
 * The LocaleSelect component works just like a Select box,
 * but the options are the platform supported locales.
 */
export const LocaleSelect: React.FC<LocaleSelectProps> = (props) => {
  const { isLoading, isSuccess, data } = useSupportedLocales()

  const options = useMemo(() => {
    if (!isSuccess) {
      return []
    }

    return data?.data?.map(localeToOption)
  }, [isSuccess, data])

  // Use selectedCodes/selectedCode, or just props.selected if it's not set
  const selected = useMemo(() => {
    if (props.selectedByCode && Array.isArray(props.selectedByCode)) {
      return options.filter((option) => props.selectedByCode.includes(option.value))
    }

    if (props.selectedByCode) {
      return options.find((option) => option.value === props.selectedByCode)
    }

    return props.selected
  }, [props.selected, props.selectedByCode, options])

  if (isLoading) {
    return <div className="rounded-md bg-gray-200 animate-pulse h-6" />
  }

  return <Select {...props} selected={selected} options={options} />
}
