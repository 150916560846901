import { SHOW_MODAL } from './actionTypes'

const INITIAL_UI_STATE = {
  showModal: false,
}

export const AddCalendarEventOverlayReducer = (state = INITIAL_UI_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.show,
        slot: action.slot,
      }

    default:
      return state
  }
}
