import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './rootReducer'
import sagas from './rootSaga'
import { createReduxEnhancer } from '@sentry/react'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
const sentryReduxEnhancer = createReduxEnhancer({})

export function configureStore(initialState = {}) {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  )

  sagaMiddleware.run(sagas)

  return store
}
