import { Input } from 'ui/components/Form/Input'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'

const ConnectedField = BaseConnectedField as any

export const AddressForm = () => (
  <div className="grid grid-cols-2 gap-4 gap-x-6">
    <div className="col-span-2">
      <ConnectedField
        component={Input}
        label="Name"
        name="name"
        description="Enter a name to distinguish this address, ie Home or Office"
      />
    </div>
    <div className="col-span-2">
      <ConnectedField component={Input} label="Address line 1" name="address_line_1" />
    </div>
    <div className="col-span-2">
      <ConnectedField component={Input} label="Address line 2" name="address_line_2" />
    </div>
    <ConnectedField component={Input} label="City" name="city" />
    <ConnectedField component={Input} label="County" name="county" />
    <ConnectedField component={Input} label="Country" name="country" />
    <ConnectedField component={Input} label="Postcode" name="postcode" />
  </div>
)
