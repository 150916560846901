import { useVisitIntakeForms } from 'api/VisitIntakeForm'
import { Section } from '../Shared/Section'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { PaginatedMiniList } from 'ui/components/PaginatedMiniList'
import { useState } from 'react'
import { ChevronRightIcon, PencilAltIcon } from '@heroicons/react/outline'
import { ViewAnswersButton } from 'screens/OrderDetails/components/IntakeForms/IntakeForms'
import { Spinner } from 'ui/components/Spinner'
import { EmptyState } from 'ui/components/EmptyState'

const Form = ({ form }) => (
  <li className="text-sm">
    <ViewAnswersButton
      visitIntakeFormId={form.id}
      completedAt={form.completed_at}
      buttonComponent={({ onClick, isLoading }) => (
        <button
          className="w-full p-3 hover:bg-gray-50 flex items-center justify-between text-left"
          onClick={onClick}
        >
          <div className="flex-1 space-y-1">
            <div className="font-medium">For order {form.order_ref}</div>
            <div className="text-gray-500">
              Filled in{' '}
              {DateTime.fromJSDate(form.completed_at).toLocaleString(DateTime.DATETIME_FULL)}
            </div>
          </div>
          <div className="flex-shrink-0 text-gray-500">
            {isLoading && <Spinner />}

            {!isLoading && <ChevronRightIcon className="w-6 h-6" />}
          </div>
        </button>
      )}
    />
  </li>
)

export const IntakeForms = () => {
  const { clientId } = useParams()
  const [page, setPage] = useState(1)

  const {
    isLoading,
    isError,
    isSuccess,
    data: { data: forms = [], meta } = {},
  } = useVisitIntakeForms({
    customerId: clientId,
    page,
  })

  return (
    <Section title="Intake forms">
      {isSuccess && forms.length > 0 && (
        <PaginatedMiniList
          isLoading={isLoading}
          isError={isError}
          meta={meta}
          onPageChange={setPage}
          borderless
          rows={forms.map((form) => (
            <Form form={form} key={form.id} />
          ))}
        />
      )}

      {isSuccess && forms.length === 0 && (
        <EmptyState icon={PencilAltIcon} title="This client hasn't filled any intake forms yet" />
      )}
    </Section>
  )
}
