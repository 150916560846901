import { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectBookingSummary } from 'selectors/BookingSummarySelectors'

export const BookingSummaryContext = createContext()

export const BookingSummaryProvider = ({ id, children }) => {
  const value = useSelector(selectBookingSummary(id)) || {}

  return <BookingSummaryContext.Provider value={value}>{children}</BookingSummaryContext.Provider>
}

export const withBookingSummary =
  (Component) =>
  ({ id, ...props }) => (
    <BookingSummaryProvider id={id}>
      <Component {...props} />
    </BookingSummaryProvider>
  )

export const useBookingSummary = () => useContext(BookingSummaryContext)
