import { createContext, useState, useEffect, useMemo } from 'react'
import { selectSite } from 'selectors/SiteSelectors'
import { useUser } from 'hooks/UseUser'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { Settings } from 'luxon'
import { useCurrentUserOptions as useCurrentUserOptionsApi } from 'api/UserOptions'
import { useFlagsForSite, useGetShopSite } from 'api/Sites'

export const SiteConfigContext = createContext()

export const SiteConfigProvider = ({ children }) => {
  const { default_site_id: siteId, site_ids: validSiteIds = [] } = useUser()

  const siteInStorage = sessionStorage.getItem('activeSiteId')
  const defaultSiteId = validSiteIds.includes(siteInStorage) ? siteInStorage : siteId

  const [activeSiteId, setActiveSiteId] = useState(defaultSiteId || siteId || '')

  const value = useProvideSiteConfig(activeSiteId)

  // Load user options so we have them in the query cache for later
  useCurrentUserOptions()

  useEffect(() => {
    sessionStorage.setItem('activeSiteId', activeSiteId)
  }, [activeSiteId])

  useEffect(() => {
    if (!activeSiteId && siteId) {
      setActiveSiteId(siteId)
    }
  }, [activeSiteId, siteId])

  return (
    <SiteConfigContext.Provider value={{ ...value, activeSiteId, setActiveSiteId }}>
      {children}
    </SiteConfigContext.Provider>
  )
}

const useProvideSiteConfig = (siteId) => {
  const { data: { data: site = {} } = {} } = useGetShopSite(siteId)

  useEffect(() => {
    Settings.defaultZone = site?.timezone ?? 'Europe/London'
  }, [site])

  return {
    siteId,
    site,
  }
}

export const useCurrentSiteId = () => {
  const { activeSiteId } = useSiteConfig()

  return activeSiteId
}

export const useCurrentSite = () => {
  const { activeSiteId } = useSiteConfig()
  const site = selectSite(activeSiteId) || {}

  return site
}

export const useActiveFeatureFlags = () => {
  const currentSiteId = useCurrentSiteId()
  const { data: { data: featureFlags = [] } = {}, isSuccess } = useFlagsForSite(currentSiteId, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return useMemo(() => featureFlags, [currentSiteId, isSuccess])
}

export const useCurrentUserOptions = () => {
  const { data: { data: userOptions = {} } = {} } = useCurrentUserOptionsApi()

  return userOptions
}
