import { Modal, ModalButtons } from 'ui/Modal'
import { FormikPhoneInput, Input } from 'ui/components/Form/Input'
import { Button } from 'ui/components/Button'
import { Form, Formik } from 'formik'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { object, string, date } from 'yup'
import { useHistory, Link } from 'react-router-dom'
import { ErrorMessage } from 'ui/components/Form/ErrorMessage'
import { useCreateVisit, useUpdateVisit } from 'api/Visits'
import { useInfiniteVisitTypes } from 'api/VisitTypes'
import { DateRangePicker } from 'ui/components/Form/DateRangePicker'
import { FormikSelect } from 'ui/components/Form/Select'
import { Toggle } from 'ui/components/Form/Toggle'
import { Field } from 'ui/components/Form/Field'
import React, { useRef } from 'react'
import { Visit } from '@trybeapp/sdk'

const ConnectedField = BaseConnectedField as any

interface AddVisitOverlayProps {
  visit?: Visit
  onCancel: () => void
  isOpen?: boolean
}

export const AddVisitOverlay: React.FC<AddVisitOverlayProps> = ({
  visit,
  onCancel,
  isOpen = false,
}) => {
  const notification = useNotificationContext()
  const history = useHistory()
  const saveButtonRef = useRef(null)
  const { mutate, isLoading: isCreating } = useCreateVisit()
  const { mutate: update, isLoading: isUpdating } = useUpdateVisit()

  const {
    hasNextPage,
    fetchNextPage,
    data: visitTypes,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteVisitTypes({}, { enabled: isOpen })

  const visitTypeOptions =
    visitTypes?.pages?.flatMap((page) =>
      page.data.map((visitType) => {
        return {
          value: visitType.id,
          name: visitType.name,
        }
      })
    ) ?? []

  const initialValues = {
    arrival_date: visit?.arrival_date ?? '',
    departure_date: visit?.departure_date ?? '',
    visit_type_id: visit?.visit_type?.id ?? '',
    first_name: visit?.first_name ?? '',
    last_name: visit?.last_name ?? '',
    email: visit?.email ?? '',
    phone: visit?.phone ?? '',
    notify_on_create: visit?.notify_on_create ?? true,
  }

  const validationSchema = object({
    first_name: string().required('First name is required'),
    last_name: string().required('Last name is required'),
    email: string().email('Must be a valid email address'),
    phone: string(),
    arrival_date: date().required('Dates are required'),
    departure_date: date().required('Dates are required'),
  })

  const handleSubmit = (values) => {
    const newValues = { ...values }

    const departureInPast = values.departure_date && values.departure_date < new Date()

    if (departureInPast) {
      delete newValues.notify_on_create
    }
    if (newValues.phone === '') {
      delete newValues.phone
    }
    if (newValues.email === '') {
      delete newValues.email
    }

    if (visit?.id === undefined) {
      mutate(newValues, {
        onSuccess: ({ data }: any) => {
          history.push(`/visits/${data.id}`)
          notification.notify(
            <Notification
              title="Visit created"
              description="The visit was successfully created"
              variant="success"
              autoDismiss
            />
          )
          onCancel()
        },
        onError: (error: any) => {
          notification.notifyApiError(error)
        },
      })
    } else {
      update([visit?.id, values], {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Visit updates"
              description="The visit was updated"
              variant="success"
              autoDismiss
            />
          )
          onCancel()
        },
        onError: (error: any) => {
          notification.notifyApiError(error)
        },
      })
    }
  }

  return (
    <Modal
      title={
        visitTypeOptions?.length === 0
          ? `You haven't set up any visit types`
          : `${visit?.id ? 'Update' : 'Create'} visit`
      }
      isOpen={isOpen}
      onClose={onCancel}
      size="sm"
      initialFocus={saveButtonRef}
      styleReset
    >
      {visitTypeOptions?.length === 0 ? (
        <div className="py-4">
          <p className="text-gray-700 text-sm">
            You'll need to configure a visit type before you can create a visit.{' '}
            <Link to="/settings/visit-types" onClick={onCancel} className="text-violet">
              Go to visit types
            </Link>
          </p>
        </div>
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            const departureInPast = values.departure_date && values.departure_date < new Date()
            return (
              <Form>
                <div className="flex flex-col gap-y-4">
                  {visit?.id === undefined && (
                    <p className="text-sm text-gray-600">
                      Provide some basic details about the visit.
                    </p>
                  )}
                  <div>
                    <Field
                      label="Arrival and departure dates"
                      component={DateRangePicker}
                      dateFormat="YYYY-MM-DD"
                      value={[values.arrival_date, values.departure_date]}
                      onChange={(range) => {
                        setFieldValue('arrival_date', range[0] ?? null)
                        setFieldValue('departure_date', range[1] ?? null)
                      }}
                    />
                    <ErrorMessage name="arrival_date" />
                  </div>
                  <ConnectedField
                    component={FormikSelect}
                    name="visit_type_id"
                    label="Visit type"
                    isLoadingMore={isFetchingNextPage}
                    loadMore={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    options={visitTypeOptions}
                    selected={visitTypeOptions.find(
                      (option) => option.value === values.visit_type_id
                    )}
                    disabled={isLoading}
                  />

                  {visit?.id !== undefined && (
                    <p className="text-sm text-gray-600">
                      Any changes made below will also be reflected in your order details.
                    </p>
                  )}
                  <ConnectedField component={Input} name="first_name" label="First name" />
                  <ConnectedField component={Input} name="last_name" label="Last name" />
                  <ConnectedField component={Input} name="email" label="Email" />
                  <ConnectedField component={FormikPhoneInput} name="phone" label="Phone" />

                  {visit?.id === undefined && values.departure_date && (
                    <Toggle
                      label="Notify customer about this visit"
                      disabled={departureInPast}
                      description={
                        departureInPast
                          ? ["The departure date is in the past, so we can't send a notification."]
                          : [
                              "We'll send the customer an email containing a link to manage their visit.",
                            ]
                      }
                      value={!departureInPast && values.notify_on_create}
                      onChange={() => setFieldValue('notify_on_create', !values.notify_on_create)}
                    />
                  )}
                </div>

                <ModalButtons>
                  <Button
                    variant="primary"
                    type="submit"
                    label={`${visit?.id !== undefined ? 'Update' : 'Create'} visit`}
                    loading={isCreating || isUpdating}
                    ref={saveButtonRef}
                  />
                  <Button label="Cancel" onClick={onCancel} />
                </ModalButtons>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}
