import { Dropdown, DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import {
  PencilIcon,
  MailIcon,
  XIcon,
  CashIcon,
  ArchiveIcon,
  RefreshIcon,
  UsersIcon,
  ExternalLinkIcon,
  BookOpenIcon,
} from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'

export const MembershipDropdown = ({
  membership,
  onEdit,
  onRequestMandate,
  onAddPayment,
  onCancel,
  onRetryPayment,
  onArchive,
  onManageMembers,
  isLead,
  canRecordManualPayment,
  onOpenAuditLog,
}) => {
  const featureFlags = useActiveFeatureFlags()

  /**
   * We can request a new mandate if we don't have one, its revoked,
   * or the setup is unpaid and we dont have one.
   */
  const canRequestMandate =
    isLead &&
    ['needs_attention', 'active'].includes(membership.status) &&
    !membership.type.offline_payments

  const leadMember = membership.members.find((m) => m.is_lead)

  const canRetryPayment = Boolean(
    membership.status === 'needs_attention' && membership.attention_reason === 'payment_failed'
  )

  const { push } = useHistory()

  const goToOrder = () => {
    push('/orders/' + membership.basket_id + '/payments')
  }

  return (
    <Dropdown threeDots>
      {isLead && (
        <DropdownItem
          label="Edit"
          disabled={membership.status === 'inactive' && membership.status !== 'expired'}
          subtitle={
            membership.status === 'inactive' || membership.status === 'expired'
              ? "This membership can't be edited"
              : ''
          }
          onClick={() => onEdit(membership.id)}
          icon={PencilIcon}
        />
      )}

      {
        <>
          <DropdownItem
            label="Manage members"
            onClick={() => onManageMembers(membership.id)}
            icon={UsersIcon}
          />
          {!isLead && (
            <DropdownItem
              label="Go to lead member"
              icon={ExternalLinkIcon}
              inAppLink
              href={`/clients/${leadMember.customer_id}`}
            />
          )}
        </>
      }

      {canRetryPayment && isLead && (
        <DropdownItem
          label="Retry payment"
          onClick={() => onRetryPayment(membership.id)}
          icon={RefreshIcon}
        />
      )}

      {membership.status === 'needs_attention' &&
        membership.attention_reason === 'setup_unpaid' &&
        isLead && (
          <DropdownItem onClick={() => goToOrder()} label="Pay setup fee" icon={CashIcon} />
        )}

      {canRecordManualPayment &&
        isLead &&
        (!featureFlags.includes('membership_revenue_schedule') ||
          membership.next_charge !== null) && (
          <DropdownItem
            onClick={() => onAddPayment(membership.id)}
            label="Record payment"
            icon={CashIcon}
          />
        )}

      {canRequestMandate && (
        <DropdownItem
          label="Request payment method"
          onClick={() => onRequestMandate(membership.id)}
          icon={MailIcon}
        />
      )}

      <DropdownItem label="See audit log" onClick={onOpenAuditLog} icon={BookOpenIcon} />

      {isLead && <DropdownDivider />}

      {membership.status !== 'inactive' && membership.status !== 'expired' && isLead && (
        <DropdownItem label="Cancel" onClick={() => onCancel(membership.id)} icon={XIcon} />
      )}

      {(membership.status === 'inactive' || membership.status === 'expired') && isLead && (
        <DropdownItem label="Archive" onClick={() => onArchive(membership.id)} icon={ArchiveIcon} />
      )}
    </Dropdown>
  )
}
