interface H1Props {
  /** The contents of the H1 */
  children: any
  /** Whether the text should be truncated or wrap */
  truncate?: boolean
  /** Margin to apply. This should be a Tailwind string */
  margin?: string
}

export const H1: React.FC<H1Props> = ({ children, truncate = true, margin = 'my-4' }) => (
  <h1 className={`text-2xl font-bold text-gray-800 ${margin} ${truncate ? ' truncate' : ''}`}>
    {children}
  </h1>
)
