import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { ProductsApi, CheckAvailabilityApi } from '@trybeapp/sdk.shop'
import { ProductsApi as ProductsApiTs } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new ProductsApi()
const tsApi = new ProductsApiTs(apiConfiguration)
const availabilityApi = new CheckAvailabilityApi()

export const useGetProducts = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(
    ['products', siteId, params],
    async () => await api.productsIndex(params),
    options
  )
}

export const usePagedProducts = (params = {}, options?: UseQueryOptions): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useGetProducts({ page, ...params }, options)
}

export const useInfiniteProducts = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['products', { params }], fetchProducts, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
  })
}

const fetchProducts = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.productsIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetProduct = (productId: string, options?: UseQueryOptions) => {
  return useQuery<unknown, unknown, any>(
    ['product', productId],
    async () => await api.productShow(productId),
    options
  )
}

export const useBarcodeSearch = (code: string, options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery<any, any, any>(
    ['getProductByBarcode', { code }],
    () =>
      tsApi.actionSearchProductsByBarcode({
        code,
        siteId,
      }),
    {
      ...options,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
}

export const useCreateProduct = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    async (args: any) => {
      const [product] = args
      return await api.productStore({ ...product, site_id: siteId })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['products'])
      },
    }
  )
}

export const useUpdateProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [productId, product] = args
      return await api.productUpdate(productId, product)
    },
    {
      onSuccess: (result, args) => {
        queryClient.invalidateQueries(['product', args[0]])
        queryClient.invalidateQueries(['products'])
      },
    }
  )
}

export const useCopyProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, product] = args

      return await api.productCopy(copyFromId, { product })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['products'])
      },
    }
  )
}

export const useDeleteProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [productId] = args
      return await api.productDestroy(productId)
    },
    {
      onSuccess: (result, args) => {
        queryClient.invalidateQueries(['products'])
      },
    }
  )
}

export const useProductPriceRules = (productId: string, params: any = {}) =>
  useQuery(['productPriceRules', productId, { params }], () =>
    api.productPriceRulesIndex(productId, params)
  )

export const useCreateProductPriceRule = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(
    ([productId, priceRule]: any) => {
      const vals = {
        ...priceRule,
        date_from: toIsoDate(priceRule.date_from),
        date_to: toIsoDate(priceRule.date_to),
      }

      return api.productPriceRuleStore(productId, vals)
    },
    {
      onSuccess: (_, args) => {
        queryClient.invalidateQueries(['products', args[0]])
        queryClient.invalidateQueries(['productPriceRules'])
      },
    }
  )
}

export const useEditProductPriceRule = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(
    ([productId, priceRuleId, priceRule]: any) => {
      const vals = {
        ...priceRule,
        date_from: toIsoDate(priceRule.date_from),
        date_to: toIsoDate(priceRule.date_to),
      }

      return api.productPriceRuleUpdate(productId, priceRuleId, vals)
    },
    {
      onSuccess: (result, args) => {
        queryClient.invalidateQueries(['products', args[0]])
        queryClient.invalidateQueries(['productPriceRules'])
      },
    }
  )
}

export const useDeleteProductPriceRule = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()

  return useMutation(
    async ([productId, priceRuleId]: any) => {
      return await api.productPriceRuleDestroy(productId, priceRuleId)
    },
    {
      onSuccess: (result, args) => {
        queryClient.invalidateQueries(['products', args[0]])
        queryClient.invalidateQueries(['productPriceRules'])
      },
    }
  )
}

export const useProductAvailability = (productId, options?: UseQueryOptions) => {
  return useQuery(
    ['productAvailability', productId],
    async () => await availabilityApi.getProductAvailability(productId),
    options
  )
}

export const useSuggestDescriptionForProduct = (productId: string) =>
  useMutation((description?: string) =>
    tsApi.actionSuggestDescriptionForProduct({
      productId,
      suggestDescription3: { description },
    })
  )
