import { TrashIcon, CogIcon, QrcodeIcon, LockClosedIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Dropdown, DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import { CheckInButton } from '../ClientDetail'
import { DeleteConfirmation } from '../DeleteConfirmation'
import { ManageBarcodeModal } from '../ManageBarcodeModal'
import { LockConfirmation } from '../LockConfirmation'
import { SETTINGS_MANAGE } from 'constants/permissions'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'

export const ClientHeader = ({ isLoading, client }) => {
  const can = useCurrentUserCan()
  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(null)
  const [showingManageBarcode, setShowingManageBarcode] = useState(null)
  const [showingLockConfirmation, setShowingLockConfirmation] = useState([null, null])

  return (
    <div>
      {!isLoading && client.deleted_at === null && (
        <div className="flex items-center space-x-2">
          <CheckInButton client={client} />

          <Dropdown buttonLabel="Settings" buttonLeadingIcon={CogIcon}>
            <DropdownItem
              icon={QrcodeIcon}
              label="Manage passes"
              onClick={() => setShowingManageBarcode(client.id)}
            />
            <DropdownItem
              icon={LockClosedIcon}
              label={client.locked_at ? 'Unlock' : 'Lock'}
              disabled={!can(SETTINGS_MANAGE)}
              subtitle={
                !can(SETTINGS_MANAGE)
                  ? `You don't have permission to ${client.locked_at ? 'unlock' : 'lock'} clients`
                  : null
              }
              onClick={() =>
                setShowingLockConfirmation([client.id, client.locked_at ? 'unlocking' : 'locking'])
              }
            />
            <DropdownDivider />
            <DropdownItem
              icon={TrashIcon}
              label="Delete"
              disabled={client.locked_at}
              onClick={() => setShowingDeleteConfirmation(client.id)}
            />
          </Dropdown>
        </div>
      )}

      <ManageBarcodeModal
        isOpen={showingManageBarcode !== null}
        onClose={() => setShowingManageBarcode(null)}
      />

      <DeleteConfirmation
        clientId={showingDeleteConfirmation}
        onClose={() => setShowingDeleteConfirmation(null)}
      />

      <LockConfirmation
        clientId={showingLockConfirmation[0]}
        onClose={() => setShowingLockConfirmation([null, null])}
        state={showingLockConfirmation[1]}
      />
    </div>
  )
}
