import { useApplyOrderItemDiscount } from 'api/OrderDiscounts'
import { useGetOrder } from 'api/Orders'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { useCurrentSite } from 'contexts/SiteConfig'
import { Form, Formik, useFormikContext } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { FormikDiscountTypeSelect } from 'ui/components/Form/DiscountTypeSelect'
import { FormikCurrencyInput, Input } from 'ui/components/Form/Input'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { number, object } from 'yup'

const ConnectedField = BaseConnectedField as any

export const AddOrderItemDiscountOverlay = ({ item, isOpen = false, onClose }) => {
  const { orderId } = useParams<{ orderId: string }>()
  const { data: { data: order = {} } = {} } = useGetOrder(orderId)
  const notification = useNotificationContext()

  const initialValues = {
    discount_type_id: '',
    discount_type_code: '',
    applicable_for: 'app_only',
    amount_type: '',
    discount_amount: '',
    is_custom_amount: false,
  }

  const validationSchema = object({
    discount_amount: number().when('amount_type', {
      is: 'monetary',
      then: number()
        .required('You must enter an amount for the discount.')
        .min(1, 'This amount cannot be zero.')
        .max(
          order.outstanding_payment_amount,
          "You can't discount more than the remaining cost of the order."
        ),
      otherwise: number().when('amount_type', {
        is: 'percentage',
        then: number()
          .required('You must enter an amount for the discount.')
          .min(1, 'Amount cannot be zero')
          .max(100, "You can't discount more than the total cost of the order."),
      }),
    }),
  })

  const { mutate: applyOrderItemDiscount } = useApplyOrderItemDiscount()

  const handleSubmit = (vals) => {
    const newVals = { ...vals }

    applyOrderItemDiscount(
      { orderId: order.id, itemId: item.id, applyDiscountToOrderItem: newVals },
      {
        onSuccess: () => {
          notification.notify(
            <Notification
              title="Discount applied"
              description="The discount was applied successfully"
              variant="success"
              autoDismiss
            />
          )
          onClose()
        },
        onError: (e) => {
          notification.notify(<ApiErrorNotification error={e} />)
        },
      }
    )
  }

  return (
    <Modal title="Apply discount to item" isOpen={isOpen} onClose={onClose} styleReset>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <ApplyDiscountToItem onClose={onClose} />
      </Formik>
    </Modal>
  )
}

const ApplyDiscountToItem = ({ onClose }) => {
  const { currency } = useCurrentSite()
  const { values, setFieldValue } = useFormikContext<any>()
  const [discountType, setDiscountType] = useState(null)

  const isMonetary = discountType?.amount_type === 'monetary'
  const canEdit = discountType?.is_custom_amount === true

  return (
    <Form>
      <div className="mt-4">
        <ConnectedField
          name="discount_type_code"
          type="select"
          component={FormikDiscountTypeSelect}
          label="Discount type"
          onChange={(event) => {
            const discountType = event.target.discountType
            setDiscountType(discountType)

            if (event.target.name === 'discount_type_code') {
              setFieldValue('discount_type_code', event.target.value)
            }

            if (event.target.name === 'amount_type') {
              setFieldValue('amount_type', event.target.value)
            }

            if (event.target.name === 'discount_amount') {
              setFieldValue('discount_amount', event.target.value)
            }
          }}
        />
      </div>

      {values.discount_type_code !== '' && (
        <div className="mt-4">
          <ConnectedField
            name="discount_amount"
            label="Discount amount"
            component={isMonetary ? FormikCurrencyInput : Input}
            currency={isMonetary && currency}
            disabled={!canEdit}
          />
        </div>
      )}

      <ModalButtons>
        <Button label="Apply discount" variant="primary" type="submit" />
        <Button label="Cancel" onClick={onClose} />
      </ModalButtons>
    </Form>
  )
}
