import { css } from '@xstyled/styled-components'

export const getAside = (theme) => {
  const { colors } = theme

  return {
    default: {
      'border-color': colors.light[500],
      'border-style': 'solid',
      width: '300px',
      'min-height': css`calc(100vh - 55px)`,
    },
  }
}
