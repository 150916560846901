import { useMemo } from 'react'
import { Modal } from '@trybeapp/ui.modal'
import { Formik, useFormikContext, ErrorMessage } from 'formik'
import { Button } from '@trybeapp/ui.button'
import { string, object, array } from 'yup'
import {
  TIMES_STAGE,
  TYPE_SELECTION_STAGE,
  useAddAreaBookingOrderItemOverlay,
  useOrderItem,
  useStage,
} from '../contexts'
import { Hint } from 'ui/components/Hint'
import { useCurrentOrder } from 'contexts/Order'
import { Text } from 'components/Text'
import { Box } from '@trybeapp/ui.box'
import { useIsEditMode } from '../hooks'
import { useOrderId } from 'screens/OrderDetails/components/Overview/hooks'
import { STATUS_CONFIRMED } from 'constants/orderStatuses'
import DeleteButton from 'components/DeleteButton'
import { Card } from '@trybeapp/ui.card'
import { FormikGuestSelection } from 'components/GuestSelection'
import { useDeleteOrderItem } from 'api/OrderItems'
import { useAreaBookingTypes, useGetAreaBookingType } from 'api/AreaBookingTypes'
import { SelectPill } from '../SelectPill'
import { SpinnerOverlay } from 'components/SpinnerOverlay'
import {
  EVENT_ADD_AREA_BOOKING_STEP_COMPLETED,
  EVENT_EDIT_AREA_BOOKING_STEP_COMPLETED,
} from 'constants/trackableEvents'
import { trackEvent } from 'components/Segment'
import { OfferingSearch } from 'components/OfferingSearch'
import { TYPE_AREA_BOOKING } from 'constants/offeringTypes'

export const DefaultStage = () => {
  const { modal } = useAddAreaBookingOrderItemOverlay()
  const { setStage } = useStage()
  const orderId = useOrderId()
  const { orderItem, setOrderItem } = useOrderItem()
  const { guest_ids: guestIds = [], type_id: newOfferingId = '' } = orderItem
  const { order: { guests: orderGuestIds = [] } = {} } = useCurrentOrder()
  const hasGuests = useMemo(() => guestIds.length > 0, [guestIds.length])
  const isEditMode = useIsEditMode()

  const initialGuestIds = useMemo(() => {
    if (guestIds) {
      return guestIds
    }

    return orderGuestIds.length === 1 ? [orderGuestIds[0]] : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(guestIds), orderGuestIds])

  const initialValues = {
    guest_ids: initialGuestIds,
    type_id: newOfferingId,
    offering_type: 'area_booking',
  }

  const validationSchema = useMemo(
    () =>
      object({
        type_id: string().required('You must select a booking type'),
        guest_ids: hasGuests ? array().required('You must select a guest') : undefined,
      }),
    [hasGuests]
  )

  const handleOnSubmit = (values) => {
    setOrderItem({ ...orderItem, ...values })
    setStage(TIMES_STAGE)

    if (isEditMode) {
      trackEvent(EVENT_EDIT_AREA_BOOKING_STEP_COMPLETED, {
        step: TYPE_SELECTION_STAGE,
        orderId,
        orderItemId: orderItem.id,
      })
    } else {
      trackEvent(EVENT_ADD_AREA_BOOKING_STEP_COMPLETED, {
        step: TYPE_SELECTION_STAGE,
        orderId,
      })
    }
  }

  const closeModal = () => {
    modal.hide()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      <>
        <Modal.Header backgroundColor="light.800">
          {isEditMode ? 'Edit' : 'Add'} area booking
        </Modal.Header>
        <Modal.Content overflow="scroll" backgroundColor="pageBackground">
          <Card overflow="visible">
            <Card.Body>
              <Fields />
            </Card.Body>
          </Card>
        </Modal.Content>
        <Modal.Footer>
          <Box width={1} display="flex">
            <SubmitButton />
            <Box ml="auto">
              <DeleteBookingButton />
              <Button variant="ghost" onClick={closeModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal.Footer>
      </>
    </Formik>
  )
}

const Fields = () => {
  const orderId = useOrderId()

  return (
    <>
      <FormikGuestSelection orderId={orderId} name="guest_ids" addingItemText="an area booking" />
      <BookingTypeSelection />
    </>
  )
}

const SubmitButton = ({ loading }) => {
  const { submitForm } = useFormikContext()

  return (
    <Button variant="primary" onClick={submitForm} loading={loading}>
      Next
    </Button>
  )
}

const DeleteBookingButton = () => {
  const exists = useIsEditMode()
  const {
    orderItem: { id: itemId, status },
  } = useOrderItem()
  const orderId = useOrderId()

  const { mutate, isLoading } = useDeleteOrderItem()
  const handleOnDelete = () => {
    mutate([orderId, itemId])
  }

  if (!exists) return null

  return (
    <DeleteButton
      mx="sm"
      size="sm"
      variant="secondary-danger"
      loading={isLoading}
      onDelete={handleOnDelete}
      text={status === STATUS_CONFIRMED ? 'Cancel' : 'Delete'}
    />
  )
}

const BookingTypeSelection = () => {
  const { submitForm, values, setFieldValue } = useFormikContext()
  const { data: { data: bookingTypes } = {}, isLoading, isSuccess } = useAreaBookingTypes()

  const areaBookingTypeId = values.type_id

  // We always fetch the area booking type so that its in cache for the next stage
  const { data: { data: bookingType = {} } = {} } = useGetAreaBookingType(areaBookingTypeId, {
    enabled: !!areaBookingTypeId,
  })

  const handleClick = (bookingType) => {
    if (areaBookingTypeId === bookingType.id) {
      setFieldValue('type_id', '')
    } else {
      setFieldValue('type_id', bookingType.id)
    }
  }

  // The selected area booking type is not in the first 15 results
  const typeNotVisible =
    areaBookingTypeId &&
    Array.isArray(bookingTypes) &&
    bookingTypes.find((bookingType) => bookingType.id === areaBookingTypeId) === undefined

  return (
    <Box mb="md">
      <Text mb="sm">Select a booking type:</Text>

      <div className="mb-2">
        <OfferingSearch
          offeringType={TYPE_AREA_BOOKING}
          onSelect={(offering) => {
            setFieldValue('type_id', offering.id, true)
            // HACK: We have to submit in a timeout because the formik context is not yet updated
            setTimeout(() => submitForm())
          }}
        />
      </div>

      {isLoading && <SpinnerOverlay />}

      {typeNotVisible && (
        <SelectPill selected={true} onClick={() => handleClick('')}>
          {bookingType.name}
        </SelectPill>
      )}

      {isSuccess &&
        bookingTypes.map((bookingType) => (
          <SelectPill
            key={bookingType.id}
            selected={bookingType.id === areaBookingTypeId}
            onClick={() => handleClick(bookingType)}
          >
            {bookingType.name}
          </SelectPill>
        ))}

      <ErrorMessage name="type_id">{(error) => <Hint variant="error">{error}</Hint>}</ErrorMessage>
    </Box>
  )
}
