import { Text } from '@trybeapp/ui.text'

/**
 * @deprecated
 */
export const SectionHeading = ({ children, ...props }) => {
  return (
    <Text variant="h5" color="primary.500" fontWeight="bold" mb="xl" {...props}>
      {children}
    </Text>
  )
}

export default SectionHeading
