import { useGetAppointmentType } from 'api/AppointmentTypes'
import { useGetAreaBookingType } from 'api/AreaBookingTypes'
import { useGetCourseType } from 'api/CourseTypes'
import { useGetPackage } from 'api/Packages'
import { useGetProduct } from 'api/Products'
import { useGetRoomReservationType } from 'api/RoomReservationTypes'
import { useSessionType } from 'api/SessionTypes'
import { useGetTableReservationType } from 'api/TableReservationTypes'
import { useGetVoucherType } from 'api/VoucherTypes'
import {
  TYPE_APPOINTMENT,
  TYPE_AREA_BOOKING,
  TYPE_COURSE,
  TYPE_PACKAGE,
  TYPE_PRODUCT,
  TYPE_ROOM_RESERVATION,
  TYPE_SESSION,
  TYPE_TABLE_RESERVATION,
  TYPE_VOUCHER,
} from 'constants/offeringTypes'

enum OfferingType {
  Appointment = TYPE_APPOINTMENT,
  AreaBooking = TYPE_AREA_BOOKING,
  Course = TYPE_COURSE,
  Package = TYPE_PACKAGE,
  Product = TYPE_PRODUCT,
  RoomReservation = TYPE_ROOM_RESERVATION,
  Session = TYPE_SESSION,
  TableReservation = TYPE_TABLE_RESERVATION,
  Voucher = TYPE_VOUCHER,
}

export const useOffering = (offeringId: string, offeringType: OfferingType) => {
  let query

  switch (offeringType) {
    default:
    case OfferingType.Appointment:
      query = useGetAppointmentType
      break
    case OfferingType.AreaBooking:
      query = useGetAreaBookingType
      break
    case OfferingType.Course:
      query = useGetCourseType
      break
    case OfferingType.Package:
      query = useGetPackage
      break
    case OfferingType.Product:
      query = useGetProduct
      break
    case OfferingType.TableReservation:
      query = useGetTableReservationType
      break
    case OfferingType.RoomReservation:
      query = useGetRoomReservationType
      break
    case OfferingType.Session:
      query = useSessionType
      break
    case OfferingType.Voucher:
      query = useGetVoucherType
      break
  }

  const queryResult = query(offeringId)

  const { data: { data: offering } = {} } = queryResult

  return { offering, ...queryResult }
}
