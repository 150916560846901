import { useState } from 'react'
import { Dropdown, DropdownItem } from 'ui/components/Dropdown'
import { CashIcon, ReceiptTaxIcon, TrashIcon, UsersIcon } from '@heroicons/react/outline'
import { TrashIcon as TrashIconSolid } from '@heroicons/react/solid'
import { EditItemPriceOverlay } from '../../Items/EditItemPriceOverlay'
import { ConfirmationModal } from 'ui/Modal'
import { useCurrentUserCan } from 'hooks/UseCurrentUserCan'
import { RESERVATIONS_MANAGE, SETTINGS_MANAGE } from 'constants/permissions'
import { useCurrentOrder } from 'contexts/Order'
import { EditItemGuestsOverlay } from '../../Items/EditItemGuestsOverlay'
import pluralize from 'pluralize'
import { useOrderIsSettled } from '../hooks'
import { AddOrderItemDiscountOverlay } from '../../Discounts/AddOrderItemDiscountOverlay'
export const ItemActions = ({
  item,
  handleDelete,
  isDeleting,
  canChangePrice = true,
  children,
}) => {
  const [showingAddDiscountOverlay, setShowingAddDiscountOverlay] = useState(false)
  const [editItemPriceOverlayVisible, setEditItemPriceOverlayVisible] = useState(false)
  const [editItemGuestsOverlayVisible, setEditItemGuestsOverlayVisible] = useState(false)
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
  const orderIsCheckedOut = useOrderIsSettled()
  const can = useCurrentUserCan()
  const { discounts = [] } = item
  const userCanOverride = can(SETTINGS_MANAGE)
  const discountable = item.is_modifiable || can(RESERVATIONS_MANAGE)
  const { order } = useCurrentOrder()

  return (
    <div className="text-right">
      <Dropdown threeDots>
        {children}
        {(!orderIsCheckedOut || userCanOverride) && item.guests?.length > 0 && (
          <DropdownItem
            label={`Change ${pluralize('guest', item.guests.length)}`}
            onClick={() => setEditItemGuestsOverlayVisible(true)}
            icon={UsersIcon}
          />
        )}
        {canChangePrice &&
          !orderIsCheckedOut &&
          discounts.length === 0 &&
          !order.applied_promo_code &&
          discountable && (
            <>
              <DropdownItem
                label="Add discount"
                icon={ReceiptTaxIcon}
                onClick={() => setShowingAddDiscountOverlay(true)}
              />
            </>
          )}
        {canChangePrice && !orderIsCheckedOut && (
          <DropdownItem
            label="Change price"
            onClick={() => setEditItemPriceOverlayVisible(true)}
            icon={CashIcon}
          />
        )}
        {(!orderIsCheckedOut || userCanOverride) && (
          <DropdownItem
            label="Remove item"
            onClick={() => setDeleteConfirmationVisible(true)}
            icon={TrashIcon}
          />
        )}
      </Dropdown>
      <AddOrderItemDiscountOverlay
        item={item}
        isOpen={showingAddDiscountOverlay}
        onClose={() => setShowingAddDiscountOverlay(false)}
      />
      <EditItemPriceOverlay
        onCancel={() => setEditItemPriceOverlayVisible(false)}
        isOpen={editItemPriceOverlayVisible}
        item={item}
      />
      <EditItemGuestsOverlay
        onCancel={() => setEditItemGuestsOverlayVisible(false)}
        isOpen={editItemGuestsOverlayVisible}
        item={item}
      />
      <ConfirmationModal
        title="Remove this item?"
        confirmButtonLabel="Yes, remove"
        isOpen={deleteConfirmationVisible}
        onClose={() => setDeleteConfirmationVisible(false)}
        setIsOpen={setDeleteConfirmationVisible}
        confirming={isDeleting}
        confirmButtonIcon={TrashIconSolid}
        onConfirm={handleDelete}
      >
        <p className="text-sm text-gray-500">
          Are you sure you want to remove this item from the order?
        </p>
      </ConfirmationModal>
    </div>
  )
}
