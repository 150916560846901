import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Text } from '@trybeapp/ui.text'
import { STATUS_CANCELLED, STATUS_RESERVED } from 'constants/orderStatuses'
import { TYPE_APPOINTMENT } from 'constants/offeringTypes'
import { upperCaseFirst } from 'upper-case-first'
import { Card } from '../../../components/Card'
import { useDeleteOrderItem } from 'api/OrderItems'
import { useOrderIsLocked, useOrderIsSettled } from '../hooks'
import { map } from 'lodash'
import { sentenceJoin } from 'utilities/StringUtils'
import { ItemActions } from './ItemActions'
import { DropdownItem } from 'ui/components/Dropdown'
import { PencilIcon } from '@heroicons/react/outline'
import { useOrderDetails } from 'screens/OrderDetails/contexts'
import { StatusTag } from '../Overview'

export const TableReservationItemRow = ({ item }) => {
  const { orderId } = useParams()
  const { id: itemId, status: itemStatus, type_name: name = '', type_product_code: code } = item
  const itemIsCancelled = itemStatus === STATUS_CANCELLED
  const canEdit = !itemIsCancelled && !useOrderIsSettled() && !useOrderIsLocked()
  const { mutate, isLoading: isDeleting } = useDeleteOrderItem()
  const handleDelete = () => {
    mutate([orderId, itemId])
  }

  return (
    <Card className="my-2">
      <Card.Body>
        <div className="text-nude-700 text-sm font-bold mb-2">
          {name} {code && <>[{code}]</>}
        </div>
        <table className="table-fixed">
          <thead>
            <tr className="text-nude-400 uppercase font-semibold text-xs tracking-wider text-left">
              <th className="w-[13%]">Status</th>
              <th className="w-[29%]">Date and time</th>
              <th className="w-[31%]">Service</th>
              <th className="w-[27%]">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-nude-900">
              <td>
                <Status item={item} />
              </td>
              <td>
                <Times item={item} />
              </td>
              <td>{item.type_name}</td>
              <td>-</td>
              <td className="text-right">
                <ItemActions
                  item={item}
                  handleDelete={handleDelete}
                  isDeleting={isDeleting}
                  canChangePrice={false}
                >
                  {canEdit && <EditItemButton item={item} />}
                </ItemActions>
              </td>
            </tr>
          </tbody>
        </table>
      </Card.Body>
    </Card>
  )
}

export const Status = ({ item }) => {
  const { status: itemStatus } = item
  let displayStatus, variant

  switch (itemStatus) {
    case STATUS_RESERVED:
      displayStatus = 'Pending'
      variant = 'warning'
      break

    case 'received':
      displayStatus = 'Received'
      variant = 'info'
      break

    default:
      displayStatus = itemStatus
  }

  return <StatusTag status={displayStatus} />
}

const Times = ({ item }) => {
  const { booking_summary: bookingSummary } = item
  const startTime = DateTime.fromJSDate(bookingSummary.start_time).toLocaleString(
    DateTime.TIME_SIMPLE
  )
  const timeTo = DateTime.fromJSDate(bookingSummary.end_time).toLocaleString(DateTime.TIME_SIMPLE)
  const date = DateTime.fromJSDate(bookingSummary.start_time).toLocaleString(DateTime.DATE_SHORT)

  return (
    <>
      {date} {startTime}-{timeTo}
    </>
  )
}

export const BookingOrderItemResourceSummary = ({ item }) => {
  const { item_type: itemType = '', booking_summary: bookingSummary = {} } = item

  const resourceString = useMemo(() => {
    const practitioners = bookingSummary.practitioners || []
    const room = bookingSummary.room || {}

    const strings = []

    if (practitioners.length > 0) {
      strings.push(`with ${sentenceJoin(map(practitioners, 'name'), ',', '&')}`)
    }

    if (room?.name) {
      strings.push(`in ${room.name}`)
    }

    return upperCaseFirst(strings.join(' ') ?? '')
  }, [bookingSummary.practitioners, bookingSummary.room])

  if (itemType !== TYPE_APPOINTMENT || !resourceString) return null

  return (
    <Text variant="meta1" color="nude.500" lineHeight={1.2}>
      {resourceString}
    </Text>
  )
}

const useAppointmentItemErrors = (item) => {
  const { booking_summary: bookingSummary = {}, guests } = item

  const practitioners = bookingSummary.practitioners || []
  const room = bookingSummary.room
  const numGuests = guests.length || 1

  const [errors, setErrors] = useState([])

  useEffect(() => {
    const errorsSet = new Set(errors)

    if (!errorsSet.has('Practitioner') && practitioners.length < numGuests) {
      errorsSet.add('Practitioner')
    }

    if (practitioners.length >= numGuests && errorsSet.has('Practitioner')) {
      errorsSet.delete('Practitioner')
    }

    if (!errorsSet.has('Room') && !room) {
      errorsSet.add('Room')
    }

    if (room && errorsSet.has('Room')) {
      errorsSet.delete('Room')
    }

    setErrors(Array.from(errorsSet))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitioners, room])

  return errors
}

const EditItemButton = ({ item }) => {
  const { setValue } = useOrderDetails()

  const handleOnClick = () => {
    setValue('tableReservationWizard.editingTableReservation', item)
  }

  return <DropdownItem label="Edit" onClick={handleOnClick} icon={PencilIcon} />
}
