import { H2 } from 'ui/components/Text/H2'

export const Section = ({ title, button = null, children }) => (
  <div>
    <div className="flex justify-between items-center border-b border-violet-200 p-3">
      <H2>{title}</H2>
      {button}
    </div>

    {children}
  </div>
)
