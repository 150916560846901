import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faAngleDown,
  faCartPlus,
  faBan,
  faBriefcase,
  faBoxOpen,
  faCompressAlt,
  faExpandAlt,
  faCalendarCheck,
  faChartBar,
  faChartPie,
  faClock,
  faCalendarPlus,
  faChalkboardTeacher,
  faEye,
  faEnvelope,
  faTags,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCheckCircle,
  faCircleNotch,
  faCog,
  faCogs,
  faComments,
  faShoppingCart,
  faTruckLoading,
  faDirections,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faHotel,
  faInbox,
  faInfoCircle,
  faLink,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMinus,
  faMoneyBill,
  faGripLines,
  faUpload,
  faPaperPlane,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRandom,
  faPrint,
  faSearch,
  faSignOutAlt,
  faFilter,
  faStickyNote,
  faTachometerAlt,
  faTag,
  faTicketAlt,
  faTimesCircle,
  faTimes,
  faTrash,
  faUserClock,
  faPersonBooth,
  faMousePointer,
  faUndo,
  faUserNurse,
  faUserFriends,
  faUsers,
  faUsersCog,
  faUserTag,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import { faSave, faCopy, faSquare, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

library.add(
  faAngleLeft,
  faAngleRight,
  faChevronLeft,
  faChevronRight,
  faAngleDown,
  faCartPlus,
  faBan,
  faBriefcase,
  faBoxOpen,
  faCompressAlt,
  faExpandAlt,
  faCalendarCheck,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faChartPie,
  faCalendarAlt,
  faClock,
  faCalendarPlus,
  faChalkboardTeacher,
  faEye,
  faEnvelope,
  faTags,
  faCheck,
  faCheckSquare,
  faCheckCircle,
  faCircleNotch,
  faCog,
  faCogs,
  faComments,
  faShoppingCart,
  faTruckLoading,
  faCopy,
  faMinus,
  faSquare,
  faDirections,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faFileAlt,
  faHotel,
  faInbox,
  faInfoCircle,
  faLink,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMoneyBill,
  faGripLines,
  faUpload,
  faPaperPlane,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRandom,
  faPrint,
  faSave,
  faSearch,
  faSignOutAlt,
  faFilter,
  faStickyNote,
  faTachometerAlt,
  faTag,
  faTicketAlt,
  faTimesCircle,
  faTimes,
  faTrash,
  faUserClock,
  faPersonBooth,
  faMousePointer,
  faUndo,
  faUserNurse,
  faUserFriends,
  faUsers,
  faUsersCog,
  faUserTag,
  faWindowClose
)
