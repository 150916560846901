import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from 'react-query'
import { CustomerNotesApi } from '@trybeapp/sdk.customer'

const api = new CustomerNotesApi()

export const useCustomerNotes = (
  customerId: string,
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 15 minutes.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 15 * 60 * 1000 // 15 minutes

  return useQuery(['customerNotes', { customerId }], () => api.listNotes(customerId), options)
}

export const useCreateCustomerNote = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (vals: any) => api.createNote(customerId, { createCustomerNoteRequest: vals }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['customerNotes', { customerId }])
      },
    }
  )
}

export const useUpdateCustomerNote = (customerId: string, noteId: string) => {
  const queryClient = useQueryClient()

  return useMutation((vals) => api.updateNote(customerId, noteId, vals), {
    onSuccess: () => {
      queryClient.invalidateQueries(['customerNotes', { customerId }])
    },
  })
}

export const useDeleteCustomerNote = (customerId: string) => {
  const queryClient = useQueryClient()

  return useMutation((noteId: string) => api.deleteNote(customerId, noteId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['customerNotes', { customerId }])
    },
  })
}
