import { createContext, useContext, useState } from 'react'
import { chain } from 'lodash'
import { DateTime } from 'luxon'

export const SlotsByHourContext = createContext()

export const SlotsByHourProvider = ({ slots, children }) => {
  const groupedSlots = chain(slots)
    .groupBy(({ start_time: startTime }) => DateTime.fromJSDate(startTime).startOf('hour').toISO())
    .value()

  return (
    <SlotsByHourContext.Provider value={{ slots, groupedSlots }}>
      {children}
    </SlotsByHourContext.Provider>
  )
}

export const withSlotsByHour =
  (Component) =>
  ({ options, ...props }) => (
    <SlotsByHourProvider slots={options}>
      <Component {...props} />
    </SlotsByHourProvider>
  )

export const useSlotsByHourContext = () => useContext(SlotsByHourContext)

export const AreaBookingSlotForm = createContext()

export const AreaBookingSlotFormProvider = ({
  bookingType,
  basketSlots,
  onTimeChange,
  onDurationChange,
  onAreaIdsChange,
  onPriceChange,
  time,
  duration,
  areaIds = [],
  children,
}) => {
  const [durationFilter, setDurationFilter] = useState(null)

  return (
    <AreaBookingSlotForm.Provider
      value={{
        bookingType,
        basketSlots,
        time,
        duration,
        areaIds,
        onTimeChange,
        onDurationChange,
        onAreaIdsChange,
        onPriceChange,
        durationFilter,
        setDurationFilter,
      }}
    >
      {children}
    </AreaBookingSlotForm.Provider>
  )
}

export const withAreaBookingSlotForm =
  (Component) =>
  ({
    bookingType,
    basketSlots,
    onTimeChange,
    onDurationChange,
    onAreaIdsChange,
    onPriceChange,
    time,
    duration,
    areaIds = [],
    ...props
  }) => (
    <AreaBookingSlotFormProvider
      bookingType={bookingType}
      basketSlots={basketSlots}
      onTimeChange={onTimeChange}
      onDurationChange={onDurationChange}
      onAreaIdsChange={onAreaIdsChange}
      onPriceChange={onPriceChange}
      time={time}
      duration={duration}
      areaIds={areaIds}
    >
      <Component {...props} />
    </AreaBookingSlotFormProvider>
  )

export const useAreaBookingForm = () => useContext(AreaBookingSlotForm)
