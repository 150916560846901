import { PusherProvider } from '@harelpls/use-pusher'
import { useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

export const useShopPusherConfig = () => {
  const { user } = useAuth()
  const token = user?.access_token

  return useMemo(() => {
    if (!token) return null

    return {
      clientKey: import.meta.env.VITE_APP_PUSHER_KEY,
      cluster: 'eu',
      authEndpoint: `${import.meta.env.VITE_APP_BASE_API_URL}/shop/broadcasting/auth`,
      auth: {
        headers: { Authorization: `Bearer ${token}` },
      },
    }
  }, [token])
}

export const useCustomerPusherConfig = () => {
  const { user } = useAuth()
  const token = user?.access_token

  return useMemo(() => {
    if (!token) return null

    return {
      clientKey: import.meta.env.VITE_APP_PUSHER_KEY,
      cluster: 'eu',
      authEndpoint: `${import.meta.env.VITE_APP_BASE_API_URL}/customers/broadcasting/auth`,
      auth: {
        headers: { Authorization: `Bearer ${token}` },
      },
    }
  }, [token])
}

export const useInventoryPusherConfig = () => {
  const { user } = useAuth()
  const token = user?.access_token

  return useMemo(() => {
    if (!token) return null

    return {
      clientKey: import.meta.env.VITE_APP_PUSHER_KEY,
      cluster: 'eu',
      authEndpoint: `${import.meta.env.VITE_APP_BASE_API_URL}/inventory/broadcasting/auth`,
      auth: {
        headers: { Authorization: `Bearer ${token}` },
      },
    }
  }, [token])
}

export const withCustomerPusher =
  (Component) =>
  ({ ...props }) => {
    const pusherConfig = useCustomerPusherConfig()

    return (
      <PusherProvider {...pusherConfig}>
        <Component {...props} />
      </PusherProvider>
    )
  }

export const withInventoryPusher =
  (Component) =>
  ({ ...props }) => {
    const pusherConfig = useInventoryPusherConfig()

    return (
      <PusherProvider {...pusherConfig}>
        <Component {...props} />
      </PusherProvider>
    )
  }
