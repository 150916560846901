import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import {
  CreateVoucherDeliveryOptionRequest,
  VoucherDeliveryOptionApi as DevliveryOptionsApiTs,
  ShowVoucherDeliveryOptionResponse,
  UpdateVoucherDeliveryOptionRequest,
} from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new DevliveryOptionsApiTs(apiConfiguration)

export const usePagedVoucherDeliveryOptions = (params: any = {}, options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['voucherDeliveryOptions', siteId, params],
    () => api.listVoucherDeliveryOptions({ siteId, ...params }),
    options
  )
}

export const useCreateVoucherDeliveryOption = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (voucherDeliveryOption: CreateVoucherDeliveryOptionRequest) =>
      api.createVoucherDeliveryOption({
        createVoucherDeliveryOptionRequest: voucherDeliveryOption,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherDeliveryOptions'])
      },
    }
  )
}

export const useUpdateVoucherDeliveryOption = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([voucherDeliveryOptionId, voucherDeliveryOption]: [
      string,
      UpdateVoucherDeliveryOptionRequest,
    ]) =>
      api.updateVoucherDeliveryOption({
        voucherDeliveryOptionId,
        updateVoucherDeliveryOptionRequest: voucherDeliveryOption,
      }),
    {
      onSuccess: (_, [voucherDeliveryOptionId]) => {
        queryClient.invalidateQueries(['voucherDeliveryOptions'])
      },
    }
  )
}

export const useUpdateVoucherDeliveryOptionOrder = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (voucherDeliveryOptionIds: string[]) =>
      api.updateVoucherDeliveryOptionOrder({
        updateVoucherDeliveryOptionOrderRequest: {
          voucher_delivery_option_ids: voucherDeliveryOptionIds,
          site_id: siteId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherDeliveryOptions'])
      },
    }
  )
}

export const useOrderedVoucherDeliveryOptions = (
  params: any = {},
  options: UseQueryOptions = {}
) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['voucherDeliveryOptions', siteId, params],
    () => api.listSortedVoucherDeliveryOptions({ siteId, ...params }),
    options
  )
}

export const useGetVoucherDeliveryOption = (
  voucherDeliveryOptionId: string,
  options?: UseQueryOptions<ShowVoucherDeliveryOptionResponse>
) =>
  useQuery(
    ['voucherDeliveryOption', { voucherDeliveryOptionId }],
    () => api.getVoucherDeliveryOption({ voucherDeliveryOptionId }),
    options
  )

export const useRestoreVoucherDeliveryOption = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (voucherDeliveryOptionId: string) =>
      api.restoreVoucherDeliveryOption({
        voucherDeliveryOptionId: voucherDeliveryOptionId,
      }),
    {
      onSuccess: (_, voucherDeliveryOptionId) => {
        queryClient.invalidateQueries(['voucherDeliveryOptions', voucherDeliveryOptionId])
      },
    }
  )
}

export const useDeleteVoucherDeliveryOption = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (voucherDeliveryOptionId: string) =>
      api.deleteVoucherDeliveryOption({
        voucherDeliveryOptionId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voucherDeliveryOptions'])
      },
    }
  )
}
