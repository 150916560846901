// absolute path to moment in node_modules
import moment from '../../node_modules/moment'

export const {
  fn,
  min,
  max,
  now,
  utc,
  unix,
  months,
  isDate,
  locale,
  invalid,
  duration,
  isMoment,
  weekdays,
  parseZone,
  localeData,
  isDuration,
  monthsShort,
  weekdaysMin,
  defineLocale,
  updateLocale,
  locales,
  weekdaysShort,
  normalizeUnits,
  relativeTimeRounding,
  relativeTimeThreshold,
  calendarFormat,
} = moment

export default moment
