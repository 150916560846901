import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { MembershipsApi } from '@trybeapp/sdk.customer'

const api = new MembershipsApi()

export const useMembershipMembers = (membershipId: string, options?: UseQueryOptions) =>
  useQuery<any, any, any>(
    ['membershipMembers', { membershipId }],
    () => api.listMembershipMembers(membershipId),
    options
  )

export const useAddMembershipMember = (membershipId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (customerId: string) =>
      api.addMembershipMember(membershipId, {
        addMembershipMemberRequest: {
          customer_id: customerId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['membershipMembers', { membershipId }])
      },
    }
  )
}

export const useRemoveMembershipMember = (membershipId: string) => {
  const queryClient = useQueryClient()

  return useMutation((customerId: string) => api.removeMembershipMember(membershipId, customerId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['membershipMembers', { membershipId }])
    },
  })
}
