import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { useEditOrderItem } from 'api/OrderItems'
import { Formik } from 'formik'
import { useFormikContext } from 'formik'
import { useCurrentOrder } from 'contexts/Order'
import { RadioCards } from 'ui/components/Form/RadioCards/RadioCards'
import { ConnectedField } from 'components/ConnectedField'
import { FormikVoucherDeliveryOptionSelect } from 'ui/components/Form/VoucherDeliveryOptionSelect'
import { CustomDeliveryDateSelect } from 'ui/components/Form/CustomDeliveryDateSelect'
import { DateTime } from 'luxon'
import { AddressLookUp } from 'ui/components/Form/AddressLookUp'
import classNames from 'classnames'
import { addressDetailsClasses } from 'ui/components/Form/AddressLookUp'
import { object, string, date as yupDate } from 'yup'

interface IEditVoucherDeliveryMethodOverlayProps {
  item: any
  isOpen: boolean
  onClose: Function
}

export const EditVoucherDeliveryMethodOverlay: React.FC<IEditVoucherDeliveryMethodOverlayProps> = ({
  item,
  isOpen = false,
  onClose,
}) => {
  const { orderId } = useParams()
  const notification = useNotificationContext()
  const { mutate, isLoading } = useEditOrderItem()

  const handleSave = (vals) => {
    mutate([orderId, item.id, { ...item, item_configuration: vals }], {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Custom message updated"
            description="The custom message has been updated successfully."
            variant="success"
            autoDismiss
          />
        )
        onClose()
      },
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })
  }

  const validationSchema = object({
    delivery_date: yupDate(),
    address_1ine1: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('Address line 1 is required'),
    }),
    address_1ine2: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string(),
    }),
    county: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('County is required'),
    }),
    country: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('Country is required'),
    }),
    town: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('town is required'),
    }),
    postcode: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('Postcode is required'),
    }),
    delivery_option_id: string().when('item_configuration.delivery_method_type', {
      is: 'physical',
      then: string().required('Delivery option is required'),
    }),
  })
    .default({})
    .nullable()

  const deliveryOptions = [
    {
      id: 'physical',
      title: 'Mail',
      description: 'We will send the voucher to the recipient by mail',
    },
    {
      id: 'email',
      title: 'Email',
      description: 'We will send the voucher to the recipient by email',
    },
  ]

  const initialValues = {
    delivery_method_type: item?.item_configuration?.delivery_method_type || 'physical',
    delivery_date: item?.item_configuration?.delivery_date || '',
    address_line1: item?.item_configuration?.address_line1 || '',
    address_line2: item?.item_configuration?.address_line2 || '',
    town: item?.item_configuration?.town || '',
    county: item?.item_configuration?.county || '',
    country: item?.item_configuration?.country || 'United Kingdom',
    postcode: item?.item_configuration?.postcode || '',
    delivery_option_id: item?.item_configuration?.delivery_option_id || '',
    custom_message: item?.item_configuration?.custom_message,
    predetermined_code: item?.item_configuration?.predetermined_code || '',
    recipient_email: item?.item_configuration?.recipient_email || '',
    recipient_name: item?.item_configuration?.recipient_name || '',
  }

  return (
    <Modal
      title={'Edit delivery method'}
      isOpen={isOpen}
      onClose={() => onClose()}
      styleReset
      size="lg"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSave}
        validationSchema={validationSchema}
      >
        <ChooseDeliveryMethodStep
          deliveryOptions={deliveryOptions}
          item={item}
          onClose={onClose}
          onSave={handleSave}
          isLoading={isLoading}
        />
      </Formik>
    </Modal>
  )
}

const ChooseDeliveryMethodStep = ({ deliveryOptions, item, onSave, onClose, isLoading }) => {
  const { order: { currency } = {} } = useCurrentOrder()
  const { values, setFieldValue, errors, touched } = useFormikContext()
  const [wasPreviousEmailDeliveryType, setWasPreviousEmailDeliveryType] = useState(
    item.item_configuration.delivery_method_type === 'email'
  )

  const selectedDeliveryMethod = useMemo(() => {
    return deliveryOptions.find((option) => option.id === values.delivery_method_type)
  }, [values, deliveryOptions])

  const handleDeliveryMethodChange = (value) => {
    setFieldValue('delivery_method_type', value.id)
    if (value.id === 'email') {
      setFieldValue('address_line1', '')
      setFieldValue('address_line2', '')
      setFieldValue('town', '')
      setFieldValue('county', '')
      setFieldValue('postcode', '')
      setFieldValue('delivery_option_id', '')
    } else {
      setFieldValue('delivery_date', '')
      setWasPreviousEmailDeliveryType(true)
    }
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        <RadioCards
          options={deliveryOptions}
          value={selectedDeliveryMethod}
          onChange={(val) => {
            handleDeliveryMethodChange(val)
          }}
        />{' '}
      </div>
      {selectedDeliveryMethod?.id === 'physical' && (
        <PhysicalDeliveryDetails
          item={item}
          wasPreviousEmailDeliveryType={wasPreviousEmailDeliveryType}
        />
      )}
      {selectedDeliveryMethod?.id === 'email' && (
        <div className="my-4">
          <EmailDeliveryDetails />
        </div>
      )}
      <ModalButtons>
        <Button
          variant="primary"
          onClick={() => onSave(values)}
          label="Update"
          loading={isLoading}
        />
        <Button onClick={onClose} label="Cancel" />
      </ModalButtons>
    </>
  )
}

const PhysicalDeliveryDetails = ({ wasPreviousEmailDeliveryType }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext()
  const [showManaulInput, setShowManualInputs] = useState(!wasPreviousEmailDeliveryType)
  const [showAddressLookup, setShowAddressLookup] = useState(wasPreviousEmailDeliveryType)
  const [showManualInputTooltip, setShowManualInputTooltip] = useState(wasPreviousEmailDeliveryType)

  const handleAddressChange = (addressProperties) => {
    setFieldValue('address_line1', addressProperties.address_line1)
    setFieldValue('address_1ine2', addressProperties.address_line2)
    setFieldValue('town', addressProperties.address_level3)
    setFieldValue('county', addressProperties.address_level3)
    setFieldValue('country', addressProperties.country)
    setFieldValue('postcode', addressProperties.postcode)
    setShowManualInputs(true)
    setShowAddressLookup(false)
    setShowManualInputTooltip(false)
  }

  return (
    <div>
      <div className="py-2">
        <>
          {showAddressLookup && (
            <AddressLookUp
              onChange={(addressProperties) => handleAddressChange(addressProperties)}
              showAddressDetails
            />
          )}
          {showManualInputTooltip && (
            <p
              className="mt-2 text-gray-500 text-sm underline"
              onClick={() => {
                setShowManualInputs(true)
                setShowAddressLookup(false)
                setShowManualInputTooltip(false)
              }}
            >
              Enter an address manually
            </p>
          )}
        </>

        {showManaulInput && (
          <>
            <input
              name="address_line1"
              placeholder="Address line 1"
              type="text"
              value={values.address_line1}
              onChange={(event) => setFieldValue('address_line1', event.target.value)}
              className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}
            />
            <input
              name="address_line2"
              placeholder="Address line 2"
              type="text"
              value={values.address_line2}
              onChange={(event) => setFieldValue('address_line2', event.target.value)}
              className={classNames(addressDetailsClasses, 'w-full block my-2 rounded-md')}
            />
            <div className="grid md:grid-cols-2 grid-rows-2 sm:grid-cols-1">
              <input
                name="address_line3"
                placeholder="Town"
                type="text"
                value={values.town}
                onChange={(event) => setFieldValue('town', event.target.value)}
                className={classNames(addressDetailsClasses)}
              />
              <input
                name="county"
                placeholder="County"
                type="text"
                value={values.county}
                className={classNames(addressDetailsClasses)}
                onChange={(event) => setFieldValue('county', event.target.value)}
              />
              <input
                name="postcode"
                placeholder="Postcode"
                type="text"
                value={values.postcode}
                className={classNames(addressDetailsClasses)}
                onChange={(event) => setFieldValue('postcode', event.target.value)}
              />
              <input
                name="country"
                placeholder="Country"
                type="text"
                disabled
                value={values.country}
                className={classNames(addressDetailsClasses)}
              />
            </div>
          </>
        )}

        <div className="md:w-2/3 mt-4">
          <ConnectedField
            name="delivery_option_id"
            label="Delivery option"
            component={FormikVoucherDeliveryOptionSelect}
            onChange={(event) => setFieldValue('delivery_option_id', event.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

const EmailDeliveryDetails = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext()

  const handleSendImmediatelyChange = (value) => {
    if (value && values.delivery_date !== '') {
      setFieldValue('delivery_date', '')
    } else {
      setFieldValue('delivery_date', DateTime.now().plus({ days: 1 }).toJSDate())
    }
  }

  return (
    <div>
      <CustomDeliveryDateSelect
        dateValue={
          values.delivery_date !== ''
            ? new Date(values.delivery_date)
            : DateTime.now().plus({ days: 1 }).toJSDate()
        }
        onChange={(date) => {
          setFieldValue('delivery_date', date)
        }}
        minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
        onSendImmediatelyChange={handleSendImmediatelyChange}
        sendImmediately={values.delivery_date === ''}
      />
    </div>
  )
}
