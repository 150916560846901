import { lighten } from 'polished'
import { createTheme } from '@trybeapp/ui.core'
import { getAside } from 'components/Aside/theme'
import { getCharts } from 'components/Charts/theme'
import { trybeTheme as baseTheme } from '@trybeapp/ui.core'
import { getTags } from 'components/Tag/theme'

/**
 * @deprecated
 */
const trybeTheme = createTheme({ defaultFontSize: 15, ...baseTheme })

trybeTheme.space.xs = trybeTheme.toRem(3)

const colors = trybeTheme.colors
colors.teal = {
  100: '#e6fffa',
  200: '#b2f5ea',
  300: '#81e6d9',
  400: '#4fd1c5',
  500: '#38b2ac',
  600: '#319795',
  700: '#2c7a7b',
  800: '#285e61',
  900: '#234e52',
}

trybeTheme.colors = {
  ...colors,

  pageBackground: lighten(0.81, colors.primary[900]),
  light: {
    ...colors.light,
    800: '#fcfcfc',
  },
  success: {
    ...colors.success,
    50: lighten(0.1, colors.success[100]),
  },
  danger: {
    ...colors.danger,
    50: lighten(0.02, colors.danger[100]),
  },
  darkGray1: '#121921',
  darkGray2: '#1c2733',
  darkGray3: '#212d3c',
  darkGray4: '#2f4256',
  darkGray5: '#3e5671',

  gray1: '#576b81',
  gray2: '#687f99',
  gray3: '#8194aa',
  gray4: '#9aa9bb',
  gray5: '#b2becb',

  lightGray1: '#beccdb',
  lightGray2: '#cdd7e3',
  lightGray3: '#d8e1ea',
  lightGray4: '#eaeff4',
  lightGray5: '#f6f8fa',

  blue1: '#0e5a8a',
  blue2: '#106ba3',
  blue3: '#137cbd',
  blue4: '#2b95d6',
  blue5: '#48aff0',

  green1: '#0a6640',
  green2: '#0d8050',
  green3: '#0f9960',
  green4: '#15b371',
  green5: '#3dcc91',

  orange1: '#a66321',
  orange2: '#bf7326',
  orange3: '#d9822b',
  orange4: '#f29d49',
  orange5: '#ffb366',

  red1: '#a82a2a',
  red2: '#c23030',
  red3: '#db3737',
  red4: '#f55656',
  red5: '#ff7373',

  vermilion1: '#9e2b0e',
  vermilion2: '#b83211',
  vermilion3: '#d13913',
  vermilion4: '#eb532d',
  vermilion5: '#ff6e4a',

  rose1: '#a82255',
  rose2: '#c22762',
  rose3: '#db2c6f',
  rose4: '#f5498b',
  rose5: '#ff66a1',

  violet1: '#5c255c',
  violet2: '#752f75',
  violet3: '#8f398f',
  violet4: '#a854a8',
  violet5: '#c274c2',

  indigo1: '#5642a6',
  indigo2: '#634dbf',
  indigo3: '#7157d9',
  indigo4: '#9179f2',
  indigo5: '#ad99ff',

  cobalt1: '#1f4b99',
  cobalt2: '#2458b3',
  cobalt3: '#2965cc',
  cobalt4: '#4580e6',
  cobalt5: '#669eff',

  turquoise1: '#008075',
  turquoise2: '#00998c',
  turquoise3: '#00b3a4',
  turquoise4: '#14ccbd',
  turquoise5: '#2ee6d6',

  forest1: '#1d7324',
  forest2: '#238c2c',
  forest3: '#29a634',
  forest4: '#43bf4d',
  forest5: '#62d96b',

  lime1: '#728c23',
  lime2: '#87a629',
  lime3: '#9bbf30',
  lime4: '#b6d94c',
  lime5: '#d1f26d',

  gold1: '#a67908',
  gold2: '#bf8c0a',
  gold3: '#d99e0b',
  gold4: '#f2b824',
  gold5: '#ffc940',

  sepia1: '#63411e',
  sepia2: '#7d5125',
  sepia3: '#96622d',
  sepia4: '#b07b46',
  sepia5: '#c99765',
}
trybeTheme.buttons.outline = {
  ...trybeTheme.buttons.primary,
  color: colors.primary[900],
  'background-color': 'transparent',
  'border-color': 'transparent',
}

trybeTheme.shadows = {
  ...trybeTheme.shadows,
  xs: '1px 2px 4px 0 rgba(0,0,0,0.01)',
}

trybeTheme.buttons.primary = {
  ...trybeTheme.buttons.primary,
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.secondary = {
  ...trybeTheme.buttons.secondary,
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.tertiary = {
  ...trybeTheme.buttons.tertiary,
  color: colors.nude[700],
  'background-color': colors.light[500],
  'border-color': colors.light[200],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.disabled = {
  ...trybeTheme.buttons.disabled,
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.ghost = {
  ...trybeTheme.buttons.ghost,
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.hover.tertiary = {
  ...trybeTheme.buttons.hover.tertiary,
  'background-color': colors.light[200],
  'border-color': colors.nude[200],
}

trybeTheme.buttons.focus.tertiary = {
  ...trybeTheme.buttons.focus.tertiary,
  'background-color': colors.nude[200],
}

trybeTheme.buttons['primary-danger'] = {
  ...trybeTheme.buttons['primary-danger'],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['primary-warning'] = {
  ...trybeTheme.buttons['primary-warning'],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['primary-info'] = {
  ...trybeTheme.buttons['primary-info'],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['success-info'] = {
  ...trybeTheme.buttons['success-info'],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['secondary-danger'] = {
  ...trybeTheme.buttons['secondary-danger'],
  'background-color': colors.danger[100],
  'border-color': colors.danger[100],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['primary-success'] = {
  ...trybeTheme.buttons['primary-success'],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['secondary-success'] = {
  ...trybeTheme.buttons['secondary-success'],
  color: colors.success[700],
  'background-color': colors.success[100],
  'border-color': colors.success[100],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['secondary-info'] = {
  ...trybeTheme.buttons['secondary-info'],
  color: colors.info[700],
  'background-color': colors.info[100],
  'border-color': colors.info[100],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons['secondary-warning'] = {
  ...trybeTheme.buttons['secondary-warning'],
  color: colors.warning[700],
  'background-color': colors.warning[100],
  'border-color': colors.warning[100],
  'text-transform': 'none',
  'letter-spacing': 'normal',
}

trybeTheme.buttons.hover.outline = {
  'box-shadow': 'none',
  'background-color': colors.primary[200],
}
trybeTheme.buttons.focus.outline = {
  'box-shadow': 'none',
  'background-color': colors.primary[200],
}

trybeTheme.buttons.primary = {
  ...trybeTheme.buttons.primary,
  'background-color': colors.primary[100],
  'border-color': colors.primary[100],
  color: colors.primary[900],
}

trybeTheme.buttons.hover.primary = {
  ...trybeTheme.buttons.hover.primary,
  'background-color': colors.primary[100],
  'border-color': colors.primary[200],
}

trybeTheme.buttons.focus.primary = {
  ...trybeTheme.buttons.focus.primary,
  'background-color': colors.primary[200],
  'border-color': colors.primary[200],
  color: colors.light[900],
}

trybeTheme.components = {
  datepicker: {
    '.DayPicker-Day--selected': {
      'background-color': colors.primary[900],
    },
    '.DayPicker-Day': {
      '&:hover': {
        'background-color': colors.primary[300],
      },
    },
    '.DayPicker-Month': {
      width: '100%',
      margin: 0,
    },
    '.DayPicker-NavButton': {
      right: 0,
      top: 0,
    },
    '.DayPicker-Day--outside': {
      color: colors.primary[500],
    },
  },
  secondaryNavbar: {
    'background-color': colors.light[900],
    'border-bottom': `1px solid ${colors.light[500]}`,
    width: '100%',
    height: '55px',
  },
  page: {
    'background-color': trybeTheme.colors.pageBackground,
    // 'background-color': colors.light[700],
  },
  sidebar: {
    'background-color': colors.primary[900],
    'border-right': `1px solid ${colors.nude[200]}`,
  },
  viewAppointments: {
    hourHeight: 150,
    dayRowHeight: 50,
    todayBackground: colors.primary[300],
    todayBorder: colors.primary[400],
    appointmentBackground: colors.primary[250],
    appointmentBackgroundHover: colors.primary[300],
    appointmentPadding: 0.5,
  },
}

trybeTheme.modals.backdrop = {
  ...trybeTheme.modals.backdrop,
  'z-index': '50',
}

trybeTheme.modals.default = {
  ...trybeTheme.modals.default,
  overflow: 'visible',
  'z-index': '50',
}

trybeTheme.modals.header = {
  ...trybeTheme.modals.header,
  backgroundColor: colors.light[700],
  color: colors.nude[900],
}

trybeTheme.components.aside = getAside(trybeTheme)
trybeTheme.charts = getCharts(trybeTheme)
trybeTheme.fields.select.default.color = colors.nude[800]

trybeTheme.fields.label = {
  color: colors.nude[700],
  'font-size': trybeTheme.toEm(12),
  'font-weight': trybeTheme.weights.bold,
  'line-height': '1.4rem',
  'min-height': '1.3rem',
}
trybeTheme.fields.checkbox.default = {
  ...trybeTheme.fields.checkbox.default,
}

trybeTheme.fields.hint['margin-top'] = `${trybeTheme.space.xs} !important`

trybeTheme.tags = getTags(trybeTheme)
export default trybeTheme
