import {
  GantnerApi,
  ShowNotificationPreferences,
  UpdateNotificationPreferences,
} from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new GantnerApi(apiConfiguration)

export const useGetNotificationPreferences = (options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery<ShowNotificationPreferences>(
    ['gantnerUsageNotificationPreferences', { siteId }],
    () =>
      api.getGantnerUsageNotificationPreferences({
        siteId,
      }),
    {
      cacheTime: Infinity,
      staleTime: 60 * 60 * 1000, // 60 minutes
      ...options,
    }
  )
}

export const useUpdateNotificationPreferences = () => {
  const siteId = useCurrentSiteId()
  const queryClient = useQueryClient()

  return useMutation(
    (vals: UpdateNotificationPreferences) =>
      api.updateGantnerUsageNotificationPreferences({
        siteId,
        updateNotificationPreferences: vals,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['gantnerUsageNotificationPreferences', { siteId }])
      },
    }
  )
}
