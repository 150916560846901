import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { AppointmentTypesApi } from '@trybeapp/sdk.shop'
import { AppointmentTypesApi as AppointmentTypesApiTs } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new AppointmentTypesApi()
const tsApi = new AppointmentTypesApiTs(apiConfiguration)

export const useAppointmentTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(
    ['appointmentTypes', { params }],
    async () => api.appointmentTypesIndex(params),
    options
  )
}

export const usePagedAppointmentTypes = (
  params = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useAppointmentTypes({ page, ...params }, options)
}

export const useInfiniteAppointmentTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['appointmentTypes', { params }], fetchAppointmentTypes, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
  })
}

const fetchAppointmentTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.appointmentTypesIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetAppointmentType = (appointmentTypeId, options?: UseQueryOptions) =>
  useQuery<unknown, unknown, any>(
    ['appointmentTypes', appointmentTypeId],
    async () => api.appointmentTypeShow(appointmentTypeId),
    options
  )

export const useCreateAppointmentType = () => {
  const queryClient = useQueryClient()

  return useMutation((appointmentType: any) => api.appointmentTypeStore(appointmentType), {
    onSuccess: () => {
      queryClient.invalidateQueries(['appointmentTypes'])
    },
  })
}

export const useCopyAppointmentType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, appointmentType] = args

      return await api.appointmentTypeCopy(copyFromId, { appointmentType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypes'])
      },
    }
  )
}

export const useUpdateAppointmentType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([appointmentTypeId, appointmentType]: any) =>
      api.appointmentTypeUpdate(appointmentTypeId, appointmentType),
    {
      onSuccess: (result: any) => {
        queryClient.invalidateQueries(['appointmentTypes', result.data.id])
      },
    }
  )
}

export const useRestoreAppointmentType = () => {
  const queryClient = useQueryClient()

  return useMutation((appointmentTypeId: any) => api.appointmentTypeRestore(appointmentTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('appointmentTypes')
    },
  })
}

export const useDeleteAppointmentType = () => {
  const queryClient = useQueryClient()

  return useMutation((appointmentTypeId: string) => api.appointmentTypeDestroy(appointmentTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['appointmentTypes'])
    },
  })
}

export const useAppointmentTypeAvailabilityRules = (
  appointmentTypeId,
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['appointmentTypeAvailabilityRules', { appointmentTypeId, params }],
    async () => {
      const response = await api.appointmentTypeAvailabilityRulesIndex(appointmentTypeId, params)

      response.data.forEach((availabilityRule) => {
        availabilityRule.date_from = availabilityRule.date_from
          ? sdkDateToSystemDateTime(availabilityRule.date_from).toJSDate()
          : null

        availabilityRule.date_to = availabilityRule.date_to
          ? sdkDateToSystemDateTime(availabilityRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedAppointmentTypeAvailabilityRules = (
  appointmentTypeId,
  params = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useAppointmentTypeAvailabilityRules(appointmentTypeId, { page, params }, options)
}

export const useCreateAppointmentTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([appointmentTypeId, values]: any) =>
      api.appointmentTypeAvailabilityRuleStore(appointmentTypeId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypeAvailabilityRules'])
      },
    }
  )
}

export const useUpdateAppointmentTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([appointmentTypeId, ruleId, values]: any) =>
      api.appointmentTypeAvailabilityRuleUpdate(appointmentTypeId, ruleId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypeAvailabilityRules'])
      },
    }
  )
}

export const useDeleteAppointmentTypeAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([appointmentTypeId, ruleId]: any) =>
      api.appointmentTypeAvailabilityRuleDestroy(appointmentTypeId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypeAvailabilityRules'])
      },
    }
  )
}

export const useAppointmentTypePriceRules = (
  appointmentTypeId: string,
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> =>
  useQuery(
    ['appointmentTypePriceRules', appointmentTypeId, { params }],
    async () => {
      const response = await api.appointmentTypePriceRulesIndex(appointmentTypeId, params)

      response.data.forEach((priceRule) => {
        priceRule.date_from = priceRule.date_from
          ? sdkDateToSystemDateTime(priceRule.date_from).toJSDate()
          : null

        priceRule.date_to = priceRule.date_to
          ? sdkDateToSystemDateTime(priceRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedAppointmentTypePriceRules = (
  appointmentTypeId: string,
  params = {},
  options: UseQueryOptions = {}
) => {
  const [page] = usePageQueryParam()

  return useAppointmentTypePriceRules(appointmentTypeId, { page, params }, options)
}

export const useCreateAppointmentTypePriceRule = (appointmentTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: any) =>
      api.appointmentTypePriceRuleStore(appointmentTypeId, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypePriceRules', appointmentTypeId])
        queryClient.invalidateQueries(['appointmentTypes', appointmentTypeId])
      },
    }
  )
}

export const useUpdateAppointmentTypePriceRule = (appointmentTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, values }: any) =>
      api.appointmentTypePriceRuleUpdate(appointmentTypeId, id, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['appointmentTypePriceRules', appointmentTypeId])
        queryClient.invalidateQueries(['appointmentTypes', appointmentTypeId])
      },
    }
  )
}

export const useDeleteAppointmentTypePriceRule = (appointmentTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation((id: string) => api.appointmentTypePriceRuleDestroy(appointmentTypeId, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['appointmentTypePriceRules', appointmentTypeId])
      queryClient.invalidateQueries(['appointmentTypes', appointmentTypeId])
    },
  })
}

export const useSuggestDescriptionForAppointmentType = (appointmentTypeId: string) =>
  useMutation((description?: string) =>
    tsApi.actionSuggestDescriptionForAppointmentType({
      appointmentTypeId,
      suggestDescription: { description },
    })
  )
