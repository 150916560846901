import { useSupportedLocales } from 'api/Meta'
import { useMemo } from 'react'
import { Spinner } from '../Spinner'

// The ISO country code for the locale, for example `en`.
type LocaleCode = string

interface LocaleDescriptorProps {
  children: LocaleCode
}

/**
 * A component that outputs the emoji and country name
 * for the given locale code.
 */
export const LocaleDescriptor: React.FC<LocaleDescriptorProps> = (props) => {
  const { isLoading, data } = useSupportedLocales()

  const foundLocale = useMemo(
    () => data?.data?.find((locale) => locale.code === props.children),
    [props.children, data]
  )

  if (isLoading) {
    return <Spinner />
  }

  if (!foundLocale) {
    return null
  }

  return (
    <div>
      {foundLocale.emoji} {foundLocale.name}
    </div>
  )
}
