import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import { Input } from 'ui/components/Form/Input'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import {
  useNotificationContext,
  Notification,
  ApiErrorNotification,
} from 'ui/components/Notification'
import { useInfiniteMembershipTypes } from 'api/MembershipTypes'
import { useActiveFeatureFlags } from 'contexts/SiteConfig'
import { useInfiniteMembershipRates } from 'api/MembershipRates'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { useCreateCustomerMembership } from 'api/Memberships'
import { DateTime, Duration } from 'luxon'
import { date, object, string } from 'yup'
import { useMembershipCreditRules } from 'api/MembershipCreditRule'
import { PlusIcon } from '@heroicons/react/solid'
import { Select } from 'ui/components/Form/Select'
import { Badge } from 'ui/components/Badge'
import { Money } from 'ui/components/Money'
import pluralize from 'pluralize'
import { ErrorMessage } from 'ui/components/Form/ErrorMessage'
import { useInfinitePaymentMethods } from 'api/PaymentMethods'
import { PaymentMethodIcon } from 'ui/components/PaymentMethodIcon'
import { Hint } from 'ui/components/Form/Field'
import useDebounce from 'hooks/Debounce'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const ConnectedField = BaseConnectedField as any

const defaultStartDate = DateTime.local()

const calculateEndDateFromInterval = (startDateStr: string, interval?: string): string => {
  if (interval === null) {
    // This means there should be no end date, but because the values goes in
    // a HTML input, we need to return a string.
    return ''
  }

  const startDate = DateTime.fromJSDate(new Date(startDateStr))
  const duration = Duration.fromISO(interval)

  return startDate.plus(duration).minus({ days: 1 }).toISODate()
}

const initialValues = {
  membership_type_id: '',
  membership_rate_id: '',
  payment_method_id: '',
  start_date: defaultStartDate.toISODate(),
  end_date: '',
}

export const CreateMembershipModal = ({ clientId }) => {
  const [show, setShow] = useState(false)
  const { site } = useSiteConfig()
  const { brand_id: brandId } = site ?? {}
  const notification = useNotificationContext()
  const featureFlags = useActiveFeatureFlags()
  const siteMembershipFeatureFlag = featureFlags.includes('toggle_site_membership_types')
  const [selectedMembershipType, setSelectedMembershipType] = useState(null)
  const [selectedMembershipRate, setSelectedMembershipRate] = useState(null)

  const [membershipTypeSearch, setMembershipTypeSearch] = useState('')
  const debouncedMembershipTypeSearch = useDebounce(membershipTypeSearch, 500)

  const [membershipRateSearch, setMembershipRateSearch] = useState('')
  const debouncedMembershipRateSearch = useDebounce(membershipRateSearch, 500)

  const validationSchema = object({
    membership_type_id: string().required().label('Membership type'),
    membership_rate_id: string().nullable().required().label('Rate'),
    start_date: date()
      .required()
      .label('Start date')
      .test('is-valid-date', "Start date can't be before the pre-sale start date", (value) => {
        const minStart = selectedMembershipType?.type?.minimum_start_date

        if (!minStart) {
          return true
        }

        return DateTime.fromJSDate(value).toISODate() >= DateTime.fromJSDate(minStart).toISODate()
      }),
    end_date: date().nullable().label('End date'),
  })

  const {
    hasNextPage: hasNextPageTypes,
    fetchNextPage: fetchNextPageTypes,
    data: dataTypes,
    isFetchingNextPage: isFetchingNextPageTypes,
    isLoading: isLoadingTypes,
  } = useInfiniteMembershipTypes(
    {
      brandId,
      query: debouncedMembershipTypeSearch,
      siteId: siteMembershipFeatureFlag ? site.id : null,
    },
    {
      enabled: show,
    }
  )

  const {
    hasNextPage: hasNextPageRates,
    fetchNextPage: fetchNextPageRates,
    data: dataRates,
    isFetchingNextPage: isFetchingNextPageRates,
    isLoading: isLoadingRates,
  } = useInfiniteMembershipRates(
    {
      membershipTypeId: selectedMembershipType?.value,
      query: debouncedMembershipRateSearch,
    },
    {
      enabled: !!selectedMembershipType && !!brandId,
    }
  )

  const {
    fetchNextPage: fetchNextPagePaymentMethods,
    data: dataPaymentMethods,
    isFetchingNextPage: isFetchingNextPagePaymentMethods,
    isLoading: isLoadingPaymentMethods,
  } = useInfinitePaymentMethods({
    customerId: clientId,
    membership_payment_method: true,
  })

  const { data: { data: creditRules = [], meta: creditRulesMeta = {} } = {} } =
    useMembershipCreditRules(
      {
        membershipTypeId: selectedMembershipType?.value,
        issueOnSignup: true,
      },
      {
        enabled: !!selectedMembershipType && !!brandId,
      }
    )

  const { isLoading: isUpdating, mutate } = useCreateCustomerMembership(clientId)

  const create = (vals) =>
    mutate(vals, {
      onSuccess: () => {
        const creditMessage =
          creditRulesMeta.total > 0
            ? `\n\nWe're issuing ${creditRulesMeta.total} ${pluralize(
                'credit',
                creditRulesMeta.total
              )} now - they'll appear in the Credits section shortly.`
            : ''

        setShow(false)
        setSelectedMembershipType(null)

        notification.notify(
          <Notification
            title="Membership created"
            description={`The membership was successfully created.${creditMessage}`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (error) => {
        notification.notify(<ApiErrorNotification error={error} />)
      },
    })

  const membershipTypesOptions = (dataTypes?.pages ?? [])
    .map((page) =>
      (page?.data ?? []).map((type) => ({
        type,
        value: type.id,
        name: (
          <div className="flex space-x-1">
            <span>{type.name}</span>
            <>
              {type._private && !type.offline_payments && (
                <Badge variant="warning" label="Private" />
              )}
              {!type._private && !type.offline_payments && (
                <Badge variant="success" label="Public" />
              )}
              {type.offline_payments && <Badge variant="info" label="Manual payments" />}
              {(type.terms === '' || type.terms === null) && (
                <Badge variant="danger" label="T&amp;Cs not set" />
              )}
              {type.minimum_start_date !== null && <Badge variant="info" label="Presale" />}
            </>
          </div>
        ),
        description: type.description ? (
          <span className="truncate overflow-hidden block">
            {type.description.replace(/(<([^>]+)>)/gi, '')}
          </span>
        ) : (
          <span className="italic">No description provided</span>
        ),
      }))
    )
    .flat()

  const paymentMethodOptions = [
    {
      value: null,
      name: 'Not now',
    },
    ...(dataPaymentMethods?.pages ?? [])
      .map((page) =>
        (page?.data ?? []).map((paymentMethod) => ({
          value: paymentMethod.id,
          name: (
            <div className="flex space-x-2 align-center text-sm">
              <PaymentMethodIcon
                size={32}
                type={
                  paymentMethod.type === 'direct_debit' ? 'direct_debit' : paymentMethod.card_brand
                }
              />
              <div>
                <div className="font-medium">{paymentMethod.cardholder_name}</div>
                <div className="text-gray-500 text-xs">
                  {paymentMethod.type === 'direct_debit' ? '•••• ' : '•••• •••• •••• '}
                  {paymentMethod.last_4}
                </div>
              </div>
            </div>
          ),
        }))
      )
      .flat(),
  ]

  const paymentMethodRequired = useMemo(() => {
    if (!selectedMembershipType || !selectedMembershipRate) {
      return false
    }

    return !selectedMembershipType.type.offline_payments && selectedMembershipRate.rate.price > 0
  }, [selectedMembershipType, selectedMembershipRate])

  const membershipRateOptions = (dataRates?.pages ?? [])
    .map((page) =>
      (page?.data ?? []).map((rate) => {
        let freq

        switch (rate.billing_frequency) {
          case 'P1M':
            freq = 'month'
            break
          case 'P3M':
            freq = 'quarter'
            break
          case 'P6M':
            freq = 'half-year'
            break
          case 'P1Y':
            freq = 'year'
            break
        }

        return {
          rate,
          value: rate.id,
          name: (
            <div className="flex space-x-1">
              <span>{rate.name}</span>
              <>
                {rate._private && <Badge variant="warning" label="Private" />}

                {!rate._private && <Badge variant="info" label="Public" />}
              </>
            </div>
          ),
          description: (
            <>
              <Money amount={rate.price} currency={rate.currency} />
              {` per ${freq}`}
              {<span className="mx-1">&middot;</span>}
              <Money amount={rate.joining_fee} currency={rate.currency} />
              {' joining fee'}
            </>
          ),
        }
      })
    )
    .flat()

  return (
    <>
      <Button label="New" leadingIcon={PlusIcon} onClick={() => setShow(true)} />
      <Modal title="Create membership" isOpen={show} onClose={() => setShow(false)} styleReset>
        <Formik initialValues={initialValues} onSubmit={create} validationSchema={validationSchema}>
          {({ submitForm, setFieldValue, values }) => (
            <Form>
              <div className="grid grid-cols-1 gap-4 gap-x-6">
                <div>
                  <Select
                    label="Membership type"
                    isLoadingMore={isFetchingNextPageTypes}
                    loadMore={() => fetchNextPageTypes()}
                    hasMore={hasNextPageTypes}
                    options={membershipTypesOptions}
                    filterValue={membershipTypeSearch}
                    onFilterChange={setMembershipTypeSearch}
                    placeholder={isLoadingTypes ? 'Loading types...' : 'Pick a membership type...'}
                    onChange={(e) => {
                      const type = membershipTypesOptions.find((type) => type.value === e.value)

                      setSelectedMembershipType(type)

                      if (type.type.minimum_start_date) {
                        setFieldValue('start_date', toIsoDate(type.type.minimum_start_date))
                      } else {
                        setFieldValue('start_date', DateTime.now().toISODate())
                      }

                      setFieldValue('membership_type_id', e.value)
                      setFieldValue('membership_rate_id', null)
                    }}
                    name="membership_type_id"
                    selected={selectedMembershipType}
                    disabled={isLoadingTypes}
                  />
                  <ErrorMessage name="membership_type_id" />
                </div>
                <div>
                  <Select
                    label="Membership rate"
                    isLoadingMore={isFetchingNextPageRates}
                    loadMore={() => fetchNextPageRates()}
                    hasMore={hasNextPageRates}
                    options={membershipRateOptions}
                    filterValue={membershipRateSearch}
                    onFilterChange={setMembershipRateSearch}
                    placeholder={
                      !selectedMembershipType
                        ? 'Pick a type first'
                        : isLoadingRates
                        ? 'Loading rates...'
                        : 'Pick a membership rate...'
                    }
                    onChange={(e) => {
                      setSelectedMembershipRate(
                        membershipRateOptions.find((rate) => rate.value === e.value)
                      )

                      setFieldValue('membership_rate_id', e.value)
                      setFieldValue(
                        'end_date',
                        calculateEndDateFromInterval(values.start_date, e.rate.default_duration)
                      )
                    }}
                    name="membership_rate_id"
                    disabled={!selectedMembershipType || isLoadingRates}
                    selected={selectedMembershipRate}
                  />
                  <ErrorMessage name="membership_rate_id" />
                </div>

                {/* We check for length > 1 because there's always a "Not now" option */}
                {paymentMethodRequired && paymentMethodOptions.length > 1 && (
                  <div>
                    <Select
                      label="Linked payment method"
                      isLoadingMore={isFetchingNextPagePaymentMethods}
                      loadMore={() => fetchNextPagePaymentMethods()}
                      options={paymentMethodOptions}
                      placeholder={isLoadingPaymentMethods ? 'Loading methods...' : 'Not now'}
                      name="payment_method_id"
                      onChange={(e) => {
                        setFieldValue('payment_method_id', e.value)
                      }}
                      selected={paymentMethodOptions.find(
                        (paymentMethod) => paymentMethod.value === values.payment_method_id
                      )}
                      disabled={isLoadingPaymentMethods}
                    />

                    <div className="mt-2">
                      <Hint>
                        This customer already has an active payment method which you can reuse for
                        this membership.
                      </Hint>
                    </div>
                  </div>
                )}

                <div className="w-full grid grid-cols-2 gap-x-6">
                  <ConnectedField
                    component={Input}
                    type="date"
                    name="start_date"
                    label="Start date"
                  />
                  <ConnectedField
                    component={Input}
                    canClear
                    type="date"
                    name="end_date"
                    label="End date"
                  />
                  <div className="col-span-2 mt-1">
                    <Hint>
                      The membership will end at{' '}
                      {DateTime.local()
                        .set({ hour: 23, minute: 59 })
                        .toLocaleString(DateTime.TIME_SIMPLE)}{' '}
                      on{' '}
                      {values.end_date
                        ? `${DateTime.fromISO(values.end_date).toLocaleString(DateTime.DATE_SHORT)}`
                        : 'the end date, if set'}
                      .
                    </Hint>
                  </div>
                </div>

                {selectedMembershipType && creditRules.length > 0 && (
                  <div className="text-xs text-gray-500">
                    <span>
                      We'll issue the client with the following credits when the membership is
                      created:
                    </span>

                    <ul className="list-disc pl-3 mt-2">
                      {creditRules.slice(0, 3).map((creditRule, i) => (
                        <li key={i}>{creditRule.coupon_name}</li>
                      ))}

                      {creditRulesMeta.total > 3 && <li>and {creditRulesMeta.total - 3} more</li>}
                    </ul>
                  </div>
                )}
              </div>
              <ModalButtons>
                <Button
                  variant="primary"
                  label="Create membership"
                  onClick={submitForm}
                  loading={isUpdating}
                />
                <Button label="Cancel" onClick={() => setShow(false)} />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
