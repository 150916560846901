import { Button } from 'ui/components/Button'
import {
  NOTIFICATION_ACTION_VIEW_ORDER,
  NOTIFICATION_ACTION_DOWNLOAD_REPORT,
} from 'constants/notifications'
import { LinkWithPreload } from '../../../utilities/PreloadLinks'

export const NotificationAction = ({ action }) => {
  const ActionComponent = ({ label, type }) => {
    switch (type) {
      case NOTIFICATION_ACTION_VIEW_ORDER:
        return <ViewOrderButton label={action.label} url={action.url} />
      case NOTIFICATION_ACTION_DOWNLOAD_REPORT:
        return <DownloadReportButton label={action.label} url={action.url} />
      default:
        return null
    }
  }

  return <ActionComponent {...action} />
}

const ViewOrderButton = ({ label, url }: { label: string; url: string }) => {
  if (url.startsWith(window.location.origin)) {
    const pathname = url.replace(window.location.origin, '')

    return <Button as={LinkWithPreload} to={pathname} label={label} size="xs" styleReset={true} />
  }

  return <Button as="a" href={url} label={label} size="xs" target="_blank" rel="noreferrer" />
}

const DownloadReportButton = ({ label, url }: { label: string; url: string }) => {
  return <Button as="a" href={url} label={label} size="xs" target="_blank" rel="noreferrer" />
}
