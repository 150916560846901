import { useOrderItem } from './contexts'
import { isEmpty } from 'lodash'

export const useIsEditMode = () => {
  const {
    orderItem: { id = '' },
  } = useOrderItem()

  return !isEmpty(id)
}
