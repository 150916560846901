import { ExclamationIcon, PlusIcon, MinusIcon, BanIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'
import {
  NOTIFICATION_GUESTLINE_ORDER_CREATED,
  NOTIFICATION_GUESTLINE_ORDER_GUEST_ADDED,
  NOTIFICATION_GUESTLINE_ORDER_GUEST_REMOVED,
  NOTIFICATION_GUESTLINE_ORDER_CANCELLED,
  NOTIFICATION_GUESTLINE_UNASSIGEND_ORDER_CREATED,
} from 'constants/notifications'
import { useBulkMarkNotificationsAsRead } from 'api/UserNotifications'
import { useHistory } from 'react-router-dom'
import { NotificationAction } from './NotificationAction'

export const GroupedNotification = ({ groupedNotification, setOpen }) => {
  const notificationIconBgColor = () => {
    switch (groupedNotification.type) {
      case NOTIFICATION_GUESTLINE_UNASSIGEND_ORDER_CREATED:
      case NOTIFICATION_GUESTLINE_ORDER_GUEST_REMOVED:
      case NOTIFICATION_GUESTLINE_ORDER_CANCELLED:
        return 'bg-red-500'
      case NOTIFICATION_GUESTLINE_ORDER_CREATED:
      case NOTIFICATION_GUESTLINE_ORDER_GUEST_ADDED:
        return 'bg-green-500'
      default:
        return 'bg-blue-500'
    }
  }

  const history = useHistory()

  const { mutate } = useBulkMarkNotificationsAsRead()

  const handleMarkGroupedNotificationsAsRead = () => {
    mutate({
      notification_ids: groupedNotification.notifications.map(({ id }) => id),
    })
  }

  const handleClick = () => {
    setOpen(false)
    if (groupedNotification.read === false) {
      handleMarkGroupedNotificationsAsRead()
    }
    history.push('notifications?type=' + groupedNotification.type)
  }

  const bgClasses = groupedNotification.read
    ? 'bg-gray-100 hover:bg-gray-200'
    : 'bg-white hover:bg-gray-50 cursor-pointer'

  const textClasses = groupedNotification.read ? 'font-normal text-gray-800' : 'font-semibold'

  return (
    <li className={`relative py-3 px-6 hover:bg-gray-50 ${bgClasses}`} onClick={handleClick}>
      <div className="flex justify-between space-x-3">
        <div
          className={`h-8 w-8 rounded-full flex items-center ${notificationIconBgColor()} ${
            groupedNotification.read ? 'bg-opacity-60' : null
          } justify-center`}
        >
          <NotificationIcon type={groupedNotification.type} />
        </div>

        <div className="min-w-0 flex-1">
          <span className="block focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className={`text-sm ${textClasses}`}>{groupedNotification.title}</p>
          </span>
          {groupedNotification.count === 1 && groupedNotification.read === false && (
            <div className="mt-1">
              {groupedNotification.notifications[0].actions.map((action) => (
                <NotificationAction action={action} key={action.label} />
              ))}
            </div>
          )}
        </div>
        <time
          dateTime={DateTime.fromJSDate(groupedNotification.date).toISO()}
          className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
        >
          {DateTime.fromJSDate(groupedNotification.date).toRelative()}
        </time>
      </div>
    </li>
  )
}

const NotificationIcon = ({ type }) => {
  const IconComponent = (props) => {
    switch (type) {
      case NOTIFICATION_GUESTLINE_ORDER_GUEST_REMOVED:
        return <MinusIcon {...props} />
      case NOTIFICATION_GUESTLINE_ORDER_CANCELLED:
        return <BanIcon {...props} />
      case NOTIFICATION_GUESTLINE_ORDER_CREATED:
      case NOTIFICATION_GUESTLINE_ORDER_GUEST_ADDED:
        return <PlusIcon {...props} />
      default:
        return <ExclamationIcon {...props} />
    }
  }

  return <IconComponent className="h-4 w-4 text-white" aria-hidden="true" />
}
