import { useCreateNewCustomerAddress } from 'api/Customers'
import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useState } from 'react'
import { Form, Formik } from 'formik'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ErrorAlert } from 'components/ErrorAlert'
import { ErrorProvider } from 'contexts/Error'
import { PlusIcon } from '@heroicons/react/solid'
import { AddressForm } from './AddressForm'

const initialValues = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  county: '',
  country: '',
  postcode: '',
}

export const AddAddressModal = ({ clientId }) => {
  const [show, setShow] = useState(false)
  const notification = useNotificationContext()

  const { isLoading: isUpdating, isError, error, mutate } = useCreateNewCustomerAddress(clientId)

  const create = (vals) =>
    mutate(vals, {
      onSuccess: () => {
        setShow(false)
        notification.notify(
          <Notification
            title="Address created"
            description={`The address was successfully created`}
            variant="success"
            autoDismiss
          />
        )
      },
    })

  return (
    <>
      <Button label="New" leadingIcon={PlusIcon} onClick={() => setShow(true)} />
      <Modal title="Add address" isOpen={show} onClose={() => setShow(false)} styleReset>
        <Formik initialValues={initialValues} onSubmit={create}>
          {({ submitForm }) => (
            <Form>
              {isError && (
                <ErrorProvider error={error}>
                  <ErrorAlert />
                </ErrorProvider>
              )}

              <AddressForm />

              <ModalButtons>
                <Button
                  variant="primary"
                  label="Create"
                  onClick={submitForm}
                  loading={isUpdating}
                />
                <Button label="Cancel" onClick={() => setShow(false)} />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
