import { createSelector } from 'reselect'

/**
 * @deprecated
 */
export const selectPackageTypes = (state) => state.resources.PackageTypesReducer
/**
 * @deprecated
 */
export const selectPackageTypesById = (state) => state.resources.PackageTypesReducer.byId
/**
 * @deprecated
 */
export const selectPackageTypeIds = (state) => state.resources.PackageTypesReducer.allIds

/**
 * @deprecated
 */
export const selectAllPackageTypes = createSelector(
  [selectPackageTypeIds, selectPackageTypesById],
  (allIds, byId) => allIds.map((id) => byId[id]).filter((v) => typeof v !== 'undefined')
)

/**
 * @deprecated
 */
export const selectPackageType = (typeId) =>
  createSelector([selectPackageTypesById], (byId = {}) => byId[typeId])

/**
 * @deprecated
 */
export const selectSitePackageTypes = (siteId) =>
  createSelector(selectAllPackageTypes, (allTypes) =>
    allTypes.filter((type) => type.site_id === siteId)
  )

/**
 * @deprecated
 */
export const selectSitePackageTypeIds = (siteId) =>
  createSelector([selectSitePackageTypes(siteId)], (packages = []) => packages.map(({ id }) => id))

/**
 * @deprecated
 */
export const selectPackageItemChoicesById = (state) =>
  state.resources.PackageItemChoicesReducer.byId
/**
 * @deprecated
 */
export const selectPackageItemChoiceIds = (state) =>
  state.resources.PackageItemChoicesReducer.allIds

/**
 * @deprecated
 */
export const selectPackageItemChoice = (id) =>
  createSelector([selectPackageItemChoicesById], (byId = {}) => byId[id])

/**
 * @deprecated
 */
export const selectPackageItemChoiceOptionsById = (state) =>
  state.resources.PackageItemChoiceOptionsReducer.byId
/**
 * @deprecated
 */
export const selectPackageItemChoiceOptionIds = (state) =>
  state.resources.PackageItemChoiceOptionsReducer.allIds

/**
 * @deprecated
 */
export const selectPackageItemChoiceOption = (choiceId, optionId) =>
  createSelector(
    [selectPackageItemChoiceOptionsById],
    (byId = {}) => byId[`${choiceId}_${optionId}`]
  )
