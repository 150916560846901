export const EVENT_ORDER_CREATED = 'Order Created'

/**
 * Appointments
 */
export const EVENT_APPOINTMENT_CREATED = 'Appointment Created'
export const EVENT_APPOINTMENT_UPDATED = 'Appointment Updated'

export const EVENT_ADD_APPOINTMENT_STARTED = 'Add Appointment Started'
export const EVENT_ADD_APPOINTMENT_CANCELLED = 'Add Appointment Cancelled'
export const EVENT_ADD_APPOINTMENT_STEP_COMPLETED = 'Add Appointment Step Completed'

export const EVENT_EDIT_APPOINTMENT_STARTED = 'Edit Appointment Started'
export const EVENT_EDIT_APPOINTMENT_CANCELLED = 'Edit Appointment Cancelled'
export const EVENT_EDIT_APPOINTMENT_STEP_COMPLETED = 'Edit Appointment Step Completed'

export const EVENT_APPOINTMENT_SLOT_SELECTED = 'Appointment Slot Selected'
export const EVENT_APPOINTMENT_SLOT_ROOM_AUTOSUGGESTED = 'Appointment Slot Room Autosuggested'
export const EVENT_APPOINTMENT_SLOT_PRACTITIONER_AUTOSUGGESTED =
  'Appointment Slot Practitioner Autosuggested'

export const EVENT_APPOINTMENT_CANCELLED = 'Appointment Cancelled'

export const EVENT_CALENDAR_APPOINTMENT_DRAGGED = 'Appointment on Calendar Dragged'

/**
 * Area bookings
 */
export const EVENT_ADD_AREA_BOOKING_STARTED = 'Add Area Booking Started'
export const EVENT_ADD_AREA_BOOKING_CANCELLED = 'Add Area Booking Cancelled'
export const EVENT_ADD_AREA_BOOKING_STEP_COMPLETED = 'Add Area Booking Step Completed'

export const EVENT_EDIT_AREA_BOOKING_STARTED = 'Edit Area Booking Started'
export const EVENT_EDIT_AREA_BOOKING_CANCELLED = 'Edit Area Booking Cancelled'
export const EVENT_EDIT_AREA_BOOKING_STEP_COMPLETED = 'Edit Area Booking Step Completed'

export const EVENT_AREA_BOOKING_SLOT_SELECTED = 'Area Booking Slot Selected'

/**
 * Packages
 */
export const EVENT_PACKAGE_CREATED = 'Package Created'
export const EVENT_PACKAGE_UPDATED = 'Package Updated'

export const EVENT_ADD_PACKAGE_STARTED = 'Add Package Started'
export const EVENT_ADD_PACKAGE_CANCELLED = 'Add Package Cancelled'
export const EVENT_ADD_PACKAGE_STEP_COMPLETED = 'Add Package Step Completed'

export const EVENT_EDIT_PACKAGE_STARTED = 'Edit Package Started'
export const EVENT_EDIT_PACKAGE_CANCELLED = 'Edit Package Cancelled'
export const EVENT_EDIT_PACKAGE_STEP_COMPLETED = 'Edit Package Step Completed'

/**
 * Courses
 */
export const EVENT_COURSE_PURCHASE_CREATED = 'Course Purchase Created'
export const EVENT_COURSE_PURCHASE_UPDATED = 'Course Purchase Updated'

/**
 * Calendar
 */
export const EVENT_CALENDAR_VIEW_DAYS = 'Change view days'
