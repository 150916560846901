import { useArchiveMembership } from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'

export const ArchiveModal = ({ isOpen, onClose, membershipId }) => {
  const { isLoading, mutate } = useArchiveMembership()
  const notification = useNotificationContext()

  const handleConfirm = () => {
    mutate(membershipId, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Membership archived"
            description={`The membership was successfully archived`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Archive membership"
      onClose={onClose}
      onConfirm={handleConfirm}
      confirming={isLoading}
      confirmButtonLabel="Archive"
      cancelButtonLabel="Cancel"
    >
      <div className="space-y-2 text-sm text-gray-700">
        <p className="font-medium text-sm">Are you sure you want to archive this membership?</p>

        <p>Archived memberships will no longer be accessible in Trybe</p>
      </div>
    </ConfirmationModal>
  )
}
