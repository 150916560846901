import { HomeIcon } from '@heroicons/react/outline'
import { MapIcon, TrashIcon, ClipboardIcon, PencilIcon } from '@heroicons/react/solid'
import { useDeleteCustomerAddress, useGetCustomer, useGetCustomerAddresses } from 'api/Customers'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown, DropdownDivider, DropdownItem } from 'ui/components/Dropdown'
import { EmptyState } from 'ui/components/EmptyState'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'
import { Section } from '../Shared/Section'
import { AddAddressModal } from '../Addresses/AddAddressModal'
import { EditAddressModal } from './EditAddressModal'

const Address = ({ address, onDelete, onEdit }) => {
  const notifications = useNotificationContext()

  const query = [
    address.address_line_1,
    address.address_line_2,
    address.city,
    address.county,
    address.country,
    address.postcode,
  ]
    .filter((a) => a)
    .join(', ')

  const handleCopy = (e) => {
    e.preventDefault() // Stop the dropdown from closing
    navigator.clipboard.writeText(query)

    notifications.notify(
      <Notification
        title="Copied to clipboard!"
        description="The address was successfully copied to your clipboard"
        variant="success"
        autoDismiss
      />
    )
  }

  return (
    <li className="flex justify-between items-center p-3">
      <div>
        <div className="font-medium">{address.name}</div>
        <div className="text-gray-500 truncate">{query}</div>
      </div>
      <div className="flex-0">
        <Dropdown threeDots>
          <DropdownItem
            label="View on map"
            icon={MapIcon}
            href={`https://www.google.com/maps/search/?api=1&query=${query}`}
          />
          <DropdownItem label="Copy to clipboard" icon={ClipboardIcon} onClick={handleCopy} />
          <DropdownDivider />
          <DropdownItem label="Edit" icon={PencilIcon} onClick={() => onEdit(address)} />
          <DropdownDivider />
          <DropdownItem label="Delete" icon={TrashIcon} onClick={() => onDelete(address.id)} />
        </Dropdown>
      </div>
    </li>
  )
}

export const Addresses = () => {
  const { clientId } = useParams()
  const notification = useNotificationContext()
  const { isLoading, data: { data: addresses = [] } = {} } = useGetCustomerAddresses(clientId, {
    refetchOnMount: false,
  })
  const { isLoading: isDeleting, mutate } = useDeleteCustomerAddress(clientId)
  const { isLoading: isLoadingClient, data: { data: client = {} } = {} } = useGetCustomer(clientId)
  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(null)
  const [editingAddress, setEditingAddress] = useState(null)

  const deleteAddress = () => {
    mutate(showingDeleteConfirmation, {
      onSuccess: () => {
        setShowingDeleteConfirmation(null)
        notification.notify(
          <Notification
            title="Address deleted"
            description={`The address was successfully deleted`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: () => {
        setShowingDeleteConfirmation(null)
        notification.notify(
          <Notification
            title="Couldn't delete address"
            description={`An error occurred when deleting this address`}
            variant="danger"
            autoDismiss
          />
        )
      },
    })
  }

  return (
    <Section
      title="Addresses"
      button={
        !isLoadingClient && client.deleted_at === null && <AddAddressModal clientId={clientId} />
      }
    >
      {isLoading && <div className="bg-grape-200 animate-pulse h-16" />}

      {!isLoading && addresses.length === 0 && (
        <EmptyState title="This client has no addresses" icon={HomeIcon} />
      )}

      <ul className="text-sm divide-y divide-violet-100">
        {!isLoading &&
          addresses.length > 0 &&
          addresses.map((address) => (
            <Address
              address={address}
              onDelete={(id) => setShowingDeleteConfirmation(id)}
              onEdit={(address) => setEditingAddress(address)}
            />
          ))}
      </ul>

      <EditAddressModal address={editingAddress} onClose={() => setEditingAddress(null)} />

      <ConfirmationModal
        isOpen={!!showingDeleteConfirmation}
        title="Delete address"
        onClose={() => setShowingDeleteConfirmation(null)}
        confirmButtonLabel="Delete"
        onConfirm={deleteAddress}
        variant="danger"
        confirming={isDeleting}
      >
        <p className="text-sm font-medium text-gray-500">
          Are you sure you want to delete this address?
        </p>
      </ConfirmationModal>
    </Section>
  )
}
