import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { LabelsApi } from '@trybeapp/sdk.customer'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'

const api = new LabelsApi()

export const useLabels = (params: any = {}, options: UseQueryOptions = {}): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(['labels', { params }], () => api.listLabels(params), options)
}

export const usePagedLabels = (params = {}, options: UseQueryOptions = {}): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useLabels({ ...params, page }, options)
}

export const useLabel = (labelId: string, options = {}) =>
  useQuery(['labels', { labelId }], () => api.getLabel(labelId), options)

export const useCreateLabel = () => {
  const queryClient = useQueryClient()

  return useMutation((createLabelRequest) => api.createLabel({ createLabelRequest }), {
    onSuccess: () => {
      queryClient.invalidateQueries('labels')
    },
  })
}

export const useUpdateLabel = () => {
  const queryClient = useQueryClient()

  return useMutation<any, any, any>(
    ({ labelId, updateLabelRequest }) => api.updateLabel(labelId, { updateLabelRequest }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('labels')
      },
    }
  )
}

export const useDeleteLabel = () => {
  const queryClient = useQueryClient()

  return useMutation((labelId: string) => api.deleteLabel(labelId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['labels'])
    },
  })
}

export const useRestoreLabel = () => {
  const queryClient = useQueryClient()

  return useMutation((labelId: string) => api.restoreLabel(labelId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['labels'])
    },
  })
}

const fetchLabels = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listLabels({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useInfiniteLabels = (params = {}, options = {}) =>
  useInfiniteQuery(['labels', { params }], fetchLabels, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
    ...options,
  })
