interface H2Props {
  /** The contents of the H1 */
  children: any
  /** Whether the text should be truncated or wrap */
  truncate?: boolean
}

export const H2: React.FC<H2Props> = ({ children, truncate = true }) => (
  <h2 className={'text-lg font-medium text-gray-800' + (truncate ? ' truncate' : '')}>
    {children}
  </h2>
)
