import { Box } from '@trybeapp/ui.box'

import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { Alert } from '@trybeapp/ui.alert'
import { Text } from '@trybeapp/ui.text'
import { useError } from 'contexts/Error'
import { isEmpty } from 'lodash'

/**
 * @deprecated Use ui/components/Alert instead
 */
export const ErrorAlert = ({
  defaultMessage = 'Something went wrong!',
  showErrors = true,
  ...props
}) => {
  const error = useError()

  if (isEmpty(error) && error instanceof Error === false) {
    return null
  }

  const { body = {} } = error
  const { message = defaultMessage, errors = {} } = body
  const messages = Object.values(errors)

  return (
    <Alert variant="error" mb="lg" {...props}>
      <Box display="flex" alignItems="center">
        <FontAwesomeIcon icon="exclamation-circle" mr="lg" size="1x" />
        <Box>{message}</Box>
      </Box>

      {showErrors && messages.length > 0 && (
        <Box mt="sm">
          {messages.map((message, i) => (
            <Text as="span" fontSize="meta1" color="inherit" key={i}>
              {message}
            </Text>
          ))}
        </Box>
      )}
    </Alert>
  )
}
