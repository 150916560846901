import { Modal } from 'ui/Modal'
import { AuditLogs } from '../AuditLogs'

export const MembershipAuditLog = ({ isOpen, membershipId, onClose }) => {
  return (
    <Modal title="Audit log" isOpen={isOpen} onClose={onClose} styleReset>
      {membershipId && <AuditLogs ignoreVerbose showExpanded subjectId={membershipId} />}
    </Modal>
  )
}
