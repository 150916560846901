import { ReactLazyPreload } from 'utilities/PreloadLinks'

const Calendar = ReactLazyPreload(
  () => import(/* webpackChunkName: 'Calendar' */ '../components/Calendar')
)

export const useRoutes = () => {
  return [
    {
      path: '/calendar',
      name: 'Calendar',
      component: Calendar,
      exact: true,
    },
  ]
}
