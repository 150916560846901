import { XIcon } from '@heroicons/react/outline'

export type BadgeVariant = 'default' | 'success' | 'warning' | 'danger' | 'info'

export interface BadgeProps {
  label: any
  variant: BadgeVariant
  title?: string
  removeIcon?: any
  onRemove?: () => void
  preventWrapping?: boolean
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => (
  <span
    className={
      `
    inline-flex
    items-center
    relative
    px-2.5
    py-0.5
    rounded-full
    text-xs
    font-medium
    ${props.preventWrapping ? 'whitespace-nowrap' : ''}
    ` +
      (props.variant === 'default'
        ? 'bg-gray-100 text-gray-800 dark:bg-gray-500/25 dark:text-gray-400'
        : '') +
      (props.variant === 'success'
        ? 'bg-green-100 text-green-800 dark:bg-green-500/25 dark:text-green-400'
        : '') +
      (props.variant === 'warning'
        ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-500/25 dark:text-yellow-400'
        : '') +
      (props.variant === 'info'
        ? 'bg-blue-100 text-blue-800 dark:bg-blue-500/50 dark:text-blue-300'
        : '') +
      (props.variant === 'danger'
        ? 'bg-red-100 text-red-800 dark:bg-red-500/25 dark:text-red-400'
        : '')
    }
    title={props.title}
  >
    {props.label}
    {props.removeIcon ? (
      <button type="button" onClick={() => props.onRemove()}>
        <XIcon className="w-3.5 h-3.5 ml-2" />
      </button>
    ) : null}
  </span>
)
