import { supplierActionTypes } from '@trybeapp/ducks.inventory'
import { without } from 'lodash'
import { PAGINATION_META } from 'constants/paginationMeta'
import { RESET_STATE } from '../../actionTypes'

export const INITIAL_STATE = {
  supplierDeleted: false,
  suppliersFetched: false,
  deleteError: null,
  paginationMeta: { ...PAGINATION_META },
  selectedIds: [],
}

export const InventorySuppliersSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case supplierActionTypes.FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliersFetched: true,
        paginationMeta: action.meta,
        selectedIds: action.result,
      }

    case supplierActionTypes.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierDeleted: true,
        selectedIds: without(state.selectedIds, action.supplierId),
      }

    case supplierActionTypes.DELETE_SUPPLIER_ERROR:
      return {
        ...state,
        deleteError: action.message,
      }

    case RESET_STATE:
      return {
        supplierDeleted: false,
        deleteError: null,
        paginationMeta: { ...PAGINATION_META },
        ...state,
      }

    default:
      return state
  }
}
