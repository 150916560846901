import { Suspense } from 'react'
import { ReactLazyPreload } from 'utilities/PreloadLinks'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SpinnerOverlay } from 'components/SpinnerOverlay'
import { REPORTS_VIEW, RESERVATIONS_MANAGE } from 'constants/permissions'
import { NotFound404 } from 'screens/NotFound404'

const OccupancyOverview = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'OccupancyOverview' */ 'screens/Dashboards/OccupancyOverview')
)

const DailySales = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'DailySales' */ 'screens/Dashboards/DailySales')
)

const DailyRevenue = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'DailyRevenue' */ 'screens/Dashboards/DailyRevenue')
)

const RevenueProjection = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'RevenueProjection' */ 'screens/Dashboards/RevenueProjection')
)

const PayByLinkList = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'PayByLinkList' */ 'screens/Dashboards/PayByLink/PayByLinkList')
)

export const useRoutes = () => {
  return [
    {
      path: '/dashboards',
      name: 'Dashboards',
      component: () => <Redirect to="/" />,
      exact: true,
    },
    {
      path: '/dashboards/daily-sales',
      name: 'Daily sales',
      component: DailySales,
      exact: true,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/dashboards/daily-revenue',
      name: 'Daily revenue',
      component: DailyRevenue,
      exact: true,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/dashboards/revenue-projection',
      name: 'Revenue projection',
      component: RevenueProjection,
      exact: true,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/dashboards/occupancy',
      name: 'Occupancy',
      component: OccupancyOverview,
      exact: true,
      permissions: [REPORTS_VIEW],
    },
    {
      path: '/dashboards/pay-by-link',
      name: 'Pay by Link',
      component: PayByLinkList,
      exact: false,
      permissions: [RESERVATIONS_MANAGE],
    },
  ]
}

export const Dashboards = () => {
  const routes = useRoutes()

  return (
    <>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => (
              <Suspense fallback={<SpinnerOverlay />}>
                <route.component {...props} />
              </Suspense>
            )}
          />
        ))}

        <NotFound404 />
      </Switch>
    </>
  )
}
