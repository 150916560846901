import { categoryActionTypes } from '@trybeapp/ducks.inventory'
import { without } from 'lodash'
import { PAGINATION_META } from 'constants/paginationMeta'
import { RESET_STATE } from '../../actionTypes'

export const INITIAL_STATE = {
  categoryDeleted: false,
  categoriesFetched: false,
  deleteError: null,
  paginationMeta: { ...PAGINATION_META },
  selectedIds: [],
}

export const InventoryCategoriesSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case categoryActionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesFetched: true,
        paginationMeta: action.meta,
        selectedIds: action.result,
      }

    case categoryActionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryDeleted: true,
        selectedIds: without(state.selectedIds, action.categoryId),
      }

    case categoryActionTypes.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteError: action.message,
      }

    case RESET_STATE:
      return {
        categoryDeleted: false,
        deleteError: null,
        paginationMeta: { ...PAGINATION_META },
        ...state,
      }

    default:
      return state
  }
}
