import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { AreaBookingTypesApi } from '@trybeapp/sdk.shop'
import { AreaBookingTypesApi as AreaBookingTypesApiTs } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new AreaBookingTypesApi()
const tsApi = new AreaBookingTypesApiTs(apiConfiguration)

export const useAreaBookingTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(['areaBookingTypes', params], () => api.areaBookingTypesIndex(params), options)
}

export const useInfiniteAreaBookingTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['inifiniteAreaBookingTypes', { params }], fetchAreaBookingTypes, {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage
    },
  })
}

const fetchAreaBookingTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await api.areaBookingTypesIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetAreaBookingType = (
  areaBookingTypeId: string,
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['areaBookingType', { areaBookingTypeId }],
    () => api.areaBookingTypeShow(areaBookingTypeId),
    options
  )

export const useAreaBookingAvailabilityRules = (
  areaBookingTypeId: string,
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['areaBookingTypeAvailabilityRules', { areaBookingTypeId, params }],
    async () => {
      const response = await api.areaBookingTypeAvailabilityRulesIndex(areaBookingTypeId, params)

      response.data.forEach((availabilityRule) => {
        availabilityRule.date_from = availabilityRule.date_from
          ? sdkDateToSystemDateTime(availabilityRule.date_from).toJSDate()
          : null

        availabilityRule.date_to = availabilityRule.date_to
          ? sdkDateToSystemDateTime(availabilityRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedAreaBookingAvailabilityRules = (
  areaBookingTypeId,
  params = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useAreaBookingAvailabilityRules(areaBookingTypeId, { page, params }, options)
}

export const useCreateAreaBookingAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([areaBookingTypeId, values]: any) =>
      api.areaBookingTypeAvailabilityRuleStore(areaBookingTypeId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypeAvailabilityRules'])
      },
    }
  )
}

export const useUpdateAreaBookingAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([areaBookingTypeId, ruleId, values]: any) =>
      api.areaBookingTypeAvailabilityRuleUpdate(areaBookingTypeId, ruleId, {
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        is_available: values.is_available,
        time_from: values.time_from,
        time_to: values.time_to,
        weekdays: values.weekdays,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypeAvailabilityRules'])
      },
    }
  )
}

export const useDeleteAreaBookingAvailabilityRule = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([areaBookingTypeId, ruleId]: any) =>
      api.areaBookingTypeAvailabilityRuleDestroy(areaBookingTypeId, ruleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypeAvailabilityRules'])
      },
    }
  )
}

export const useAreaBookingTypePriceRules = (
  areaBookingTypeId: string,
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['areaBookingTypePriceRules', { areaBookingTypeId, params }],
    async () => {
      const response = await api.areaBookingTypePriceRulesIndex(areaBookingTypeId, params)

      response.data.forEach((priceRule) => {
        priceRule.date_from = priceRule.date_from
          ? sdkDateToSystemDateTime(priceRule.date_from).toJSDate()
          : null

        priceRule.date_to = priceRule.date_to
          ? sdkDateToSystemDateTime(priceRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedAreaBookingPriceRules = (
  areaBookingTypeId: string,
  params = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const [page] = usePageQueryParam()

  return useAreaBookingTypePriceRules(areaBookingTypeId, { page, params }, options)
}

export const useCreateAreaBookingTypePriceRule = (areaBookingTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: any) =>
      api.areaBookingTypePriceRuleStore(areaBookingTypeId, {
        weekdays: values.weekdays,
        is_available: values.is_available,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
        max_duration: values.max_duration,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypePriceRules'])
      },
    }
  )
}

export const useUpdateAreaBookingTypePriceRule = (areaBookingTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, values }: any) =>
      api.areaBookingTypePriceRuleUpdate(areaBookingTypeId, id, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        max_duration: values.max_duration,
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypePriceRules'])
      },
    }
  )
}

export const useDeleteAreaBookingTypePriceRule = (areaBookingTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation((id: string) => api.areaBookingTypePriceRuleDestroy(areaBookingTypeId, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['areaBookingTypePriceRules'])
    },
  })
}

export const useCreateAreaBookingType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (areaBookingType: any) => api.areaBookingTypeStore({ ...areaBookingType, site_id: siteId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypes'])
      },
    }
  )
}

export const useCopyAreaBookingType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, areaBookingType] = args

      return await api.areaBookingTypeCopy(copyFromId, { areaBookingType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypes'])
      },
    }
  )
}

export const useUpdateAreaBookingType = (areaBookingTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, any>(
    (areaBookingType) => api.areaBookingTypeUpdate(areaBookingTypeId, areaBookingType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['areaBookingTypes'])
        queryClient.invalidateQueries(['areaBookingType', { areaBookingTypeId }])
      },
    }
  )
}

export const useDeleteAreaBookingType = () => {
  const queryClient = useQueryClient()

  return useMutation((areaBookingTypeId: string) => api.areaBookingTypeDestroy(areaBookingTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['areaBookingTypes'])
    },
  })
}

export const useRestoreAreaBookingType = () => {
  const queryClient = useQueryClient()

  return useMutation((areaBookingTypeId: any) => api.areaBookingTypeRestore(areaBookingTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('areaBookingTypes')
    },
  })
}

export const useSuggestDescriptionForAreaBookingType = (areaBookingTypeId: string) =>
  useMutation((description?: string) =>
    tsApi.actionSuggestDescriptionForAreaBookingType({
      areaBookingTypeId,
      suggestDescription1: { description },
    })
  )
