import { Modal, ModalButtons } from 'ui/Modal'
import { Button } from 'ui/components/Button'
import { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Input } from 'ui/components/Form/Input'
import { ConnectedField as BaseConnectedField } from 'components/ConnectedField'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { ErrorAlert } from 'components/ErrorAlert'
import { ErrorProvider } from 'contexts/Error'
import { useInfiniteMembershipTypes } from 'api/MembershipTypes'
import { useInfiniteMembershipRates } from 'api/MembershipRates'
import { useSiteConfig } from 'hooks/UseSiteConfig'
import { useCustomerMembership, useUpdateMembership } from 'api/Memberships'
import { date, object, string } from 'yup'
import { Select } from 'ui/components/Form/Select'
import { Badge } from 'ui/components/Badge'
import { Money } from 'ui/components/Money'
import { MembershipRate, MembershipType } from '@trybeapp/sdk'
import useDebounce from 'hooks/Debounce'
import { toIsoDate } from 'utilities/DateUtils/dateUtils'

const ConnectedField = BaseConnectedField as any

const validationSchema = object({
  membership_type_id: string().required(),
  membership_rate_id: string().required(),
  start_date: date().required(),
  end_date: date().nullable(),
})

export const EditMembershipModal = ({ membershipId, onClose }) => {
  const { site } = useSiteConfig()
  const { brand_id: brandId } = site ?? {}
  const notification = useNotificationContext()
  const [selectedMembershipType, setSelectedMembershipType] = useState<any>({})
  const [selectedMembershipRate, setSelectedMembershipRate] = useState<any>({})

  const [membershipTypeSearch, setMembershipTypeSearch] = useState('')
  const debouncedMembershipTypeSearch = useDebounce(membershipTypeSearch, 500)

  const [membershipRateSearch, setMembershipRateSearch] = useState('')
  const debouncedMembershipRateSearch = useDebounce(membershipRateSearch, 500)

  const {
    hasNextPage: hasNextPageTypes,
    fetchNextPage: fetchNextPageTypes,
    data: dataTypes,
    isFetchingNextPage: isFetchingNextPageTypes,
    isLoading: isLoadingTypes,
  } = useInfiniteMembershipTypes({
    brandId,
    query: debouncedMembershipTypeSearch,
  })

  const {
    hasNextPage: hasNextPageRates,
    fetchNextPage: fetchNextPageRates,
    data: dataRates,
    isFetchingNextPage: isFetchingNextPageRates,
    isLoading: isLoadingRates,
  } = useInfiniteMembershipRates(
    {
      membershipTypeId: selectedMembershipType?.id,
      query: debouncedMembershipRateSearch,
    },
    {
      enabled: !!selectedMembershipType,
    }
  )

  const { data: { data: membership = {} } = {} } = useCustomerMembership(membershipId, {
    enabled: !!membershipId,
  })

  const initialValues = {
    membership_type_id: membership?.type?.id,
    membership_rate_id: membership?.rate?.id,
    start_date: membership?.start_date ? toIsoDate(membership.start_date) : null,
    end_date: membership?.end_date ? toIsoDate(membership.end_date) : null,
  }

  useEffect(() => {
    if (membership?.type?.id) {
      setSelectedMembershipType(membership.type)
    }
  }, [membership?.type])

  useEffect(() => {
    if (membership?.rate?.id) {
      setSelectedMembershipRate(membership.rate)
    }
  }, [membership?.rate])

  const { isLoading: isUpdating, isError, error, mutate } = useUpdateMembership(membershipId)

  const hasAlreadyStarted = new Date() > new Date(initialValues.start_date)

  const update = (vals) => {
    if (hasAlreadyStarted) {
      // Don't send the start_date if it's already started
      delete vals.start_date
    }

    // SC-12583. Was the rate or type changed? If not, don't send it.
    if (initialValues.membership_type_id === vals.membership_type_id) {
      delete vals.membership_type_id
    }

    if (initialValues.membership_rate_id === vals.membership_rate_id) {
      delete vals.membership_rate_id
    }

    mutate(vals, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Membership updated"
            description="The membership was successfully updated"
            variant="success"
            autoDismiss
          />
        )
      },
    })
  }

  const getLabelForType = (type: MembershipType) => {
    return (
      <div className="flex space-x-1">
        <span>{type.name}</span>
        <>
          {type._private && !type.offline_payments && <Badge variant="warning" label="Private" />}
          {!type._private && !type.offline_payments && <Badge variant="success" label="Public" />}
          {type.offline_payments && <Badge variant="info" label="Manual payments" />}
          {(type.terms === '' || type.terms === null) && (
            <Badge variant="danger" label="T&amp;Cs not set" />
          )}

          {type.minimum_start_date !== null && <Badge variant="info" label="Presale" />}
        </>
      </div>
    )
  }

  const getLabelForRate = (rate: MembershipRate) => (
    <div className="flex space-x-1">
      <span>{rate.name}</span>
      <>
        {rate._private && <Badge variant="warning" label="Private" />}

        {!rate._private && <Badge variant="info" label="Public" />}
      </>
    </div>
  )

  const getDescriptionForType = (type: MembershipType) =>
    type.description ? (
      <span className="truncate overflow-hidden block">
        {type.description.replace(/(<([^>]+)>)/gi, '')}
      </span>
    ) : (
      <span className="italic">No description provided</span>
    )

  const getDescriptionForRate = (rate: MembershipRate) => {
    let freq

    switch (rate.billing_frequency) {
      case 'P1M':
        freq = 'month'
        break
      case 'P3M':
        freq = 'quarter'
        break
      case 'P6M':
        freq = 'half-year'
        break
      case 'P1Y':
        freq = 'year'
        break
    }

    return (
      <>
        <Money amount={rate.price} currency={rate.currency} />
        {` per ${freq}`}
        {<span className="mx-1">&middot;</span>}
        <Money amount={rate.joining_fee} currency={rate.currency} />
        {' joining fee'}
      </>
    )
  }

  const membershipTypesOptions = (dataTypes?.pages ?? [])
    .map((page) =>
      (page?.data ?? []).map((type) => ({
        value: type.id,
        name: getLabelForType(type),
        altText: type.name,
        description: getDescriptionForType(type),
      }))
    )
    .flat()

  const membershipRateOptions = (dataRates?.pages ?? [])
    .map((page) =>
      (page?.data ?? []).map((rate) => ({
        value: rate.id,
        name: getLabelForRate(rate),
        description: getDescriptionForRate(rate),
      }))
    )
    .flat()

  return (
    <>
      <Modal title="Edit membership" isOpen={membershipId !== null} onClose={onClose} styleReset>
        <Formik
          initialValues={initialValues}
          onSubmit={update}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ submitForm, setFieldValue, values }) => (
            <Form>
              {isError && (
                <ErrorProvider error={error}>
                  <ErrorAlert />
                </ErrorProvider>
              )}

              <div className="grid grid-cols-2 gap-4 gap-x-6">
                <div className="col-span-2">
                  <Select
                    label="Membership type"
                    isLoadingMore={isFetchingNextPageTypes}
                    loadMore={() => fetchNextPageTypes()}
                    hasMore={hasNextPageTypes}
                    options={membershipTypesOptions}
                    filterValue={membershipTypeSearch}
                    onFilterChange={setMembershipTypeSearch}
                    placeholder={isLoadingTypes ? 'Loading types...' : 'Pick a membership type...'}
                    onChange={(e) => {
                      setSelectedMembershipType(
                        dataTypes.pages
                          .flatMap((page) => page.data)
                          .find((type) => type.id === e.value)
                      )

                      setSelectedMembershipRate(null)

                      setFieldValue('membership_type_id', e.value)
                      setFieldValue('membership_rate_id', null)
                    }}
                    name="membership_type_id"
                    selected={{
                      value: values.membership_type_id,
                      name: getLabelForType(selectedMembershipType),
                      description: getDescriptionForType(selectedMembershipType),
                    }}
                    disabled={isLoadingTypes}
                  />
                </div>
                <div className="col-span-2">
                  <Select
                    label="Membership rate"
                    isLoadingMore={isFetchingNextPageRates}
                    loadMore={() => fetchNextPageRates()}
                    hasMore={hasNextPageRates}
                    options={membershipRateOptions}
                    filterValue={membershipRateSearch}
                    onFilterChange={setMembershipRateSearch}
                    placeholder={
                      !selectedMembershipType
                        ? 'Pick a type first'
                        : isLoadingRates
                        ? 'Loading rates...'
                        : 'Pick a membership rate...'
                    }
                    onChange={(e) => {
                      setSelectedMembershipRate(
                        dataRates.pages
                          .flatMap((page) => page.data)
                          .find((rate) => rate.id === e.value)
                      )

                      setFieldValue('membership_rate_id', e.value)
                    }}
                    name="membership_rate_id"
                    disabled={!selectedMembershipType || isLoadingRates}
                    selected={
                      values.membership_rate_id
                        ? {
                            value: values.membership_rate_id,
                            name: getLabelForRate(selectedMembershipRate),
                            description: getDescriptionForRate(selectedMembershipRate),
                          }
                        : null
                    }
                  />
                </div>

                <ConnectedField
                  component={Input}
                  type="date"
                  name="start_date"
                  label="Start date"
                  disabled={hasAlreadyStarted}
                />
                <ConnectedField
                  component={Input}
                  canClear
                  type="date"
                  name="end_date"
                  label="End date"
                />

                {hasAlreadyStarted && (
                  <div className="text-gray-500 text-sm">
                    This membership has already started, so the start date can't be modified.
                  </div>
                )}
              </div>
              <ModalButtons>
                <Button
                  variant="primary"
                  label="Save changes"
                  onClick={submitForm}
                  loading={isUpdating}
                />
                <Button label="Cancel" onClick={onClose} />
              </ModalButtons>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
