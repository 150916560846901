import {
  CreateDiscountType,
  DiscountTypesApi,
  ListDiscountTypes,
  UpdateDiscountType,
} from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { DateTime } from 'luxon'

const api = new DiscountTypesApi(apiConfiguration)

export const useDiscountTypes = (params: any = {}, options = {}) => {
  params.siteId = useCurrentSiteId()
  const [page] = usePageQueryParam()

  return useQuery<ListDiscountTypes>(
    ['discountTypes', { params }],
    () => api.listDiscountTypes({ ...params, page }),
    options
  )
}

export const useInfiniteDiscountTypes = (params: any = {}, options = {}) => {
  params.siteId = useCurrentSiteId()

  return useInfiniteQuery(['discountTypesInfinite', { params }], fetchDiscountTypes, {
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage
    },
    staleTime: 10000,
    ...options,
  })
}

const fetchDiscountTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listDiscountTypes({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useDiscountType = (discountTypeId: string, options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['discountTypes', { siteId, discountTypeId }],
    async () =>
      await api.getDiscountType({
        discountType: discountTypeId,
      }),
    options
  )
}

export const useCreateDiscountType = () => {
  const siteId = useCurrentSiteId()
  const queryClient = useQueryClient()

  return useMutation(
    (createDiscountType: CreateDiscountType) =>
      api.createDiscountType({
        createDiscountType: {
          ...createDiscountType,
          site_id: siteId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['discountTypes'])
      },
    }
  )
}

export const useUpdateDiscountType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([discountTypeId, updateDiscountType]: [string, UpdateDiscountType]) => {
      if (updateDiscountType.valid_from) {
        updateDiscountType.valid_from = DateTime.fromJSDate(updateDiscountType.valid_from)
          .setZone('UTC', { keepLocalTime: true })
          .toJSDate()
      }

      if (updateDiscountType.valid_to) {
        updateDiscountType.valid_to = DateTime.fromJSDate(updateDiscountType.valid_to)
          .setZone('UTC', { keepLocalTime: true })
          .toJSDate()
      }

      if (updateDiscountType.item_date_from) {
        updateDiscountType.item_date_from = DateTime.fromJSDate(updateDiscountType.item_date_from)
          .setZone('UTC', { keepLocalTime: true })
          .toJSDate()
      }

      if (updateDiscountType.item_date_to) {
        updateDiscountType.item_date_to = DateTime.fromJSDate(updateDiscountType.item_date_to)
          .setZone('UTC', { keepLocalTime: true })
          .toJSDate()
      }

      return api.updateDiscountType({
        discountType: discountTypeId,
        updateDiscountType,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['discountTypes'])
      },
    }
  )
}

export const useOrderedDiscountTypes = (
  params: any = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(
    ['discountTypes', { params }],
    async () => await api.listOrderedDiscountTypes({ ...params }),
    options
  )
}

export const useUpdateOrderedDiscountTypes = (): UseMutationResult<any> => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (discountTypeIds: any) =>
      api.updateDiscountTypeOrder({
        updateDiscountTypeOrder: {
          discount_type_ids: discountTypeIds,
          site_id: siteId,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['discountTypes'])
      },
    }
  )
}

export const useArchiveDiscountType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (discountTypeId: string) =>
      await api.deleteDiscountType({ discountType: discountTypeId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('discountTypes')
      },
    }
  )
}

export const useRestoreDiscountType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (discountTypeId: string) => api.restoreDiscountType({ discountType: discountTypeId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('discountTypes')
      },
    }
  )
}
