export const getCharts = ({ colors }) => {
  const chartColors = [
    colors.cobalt4,
    colors.turquoise4,
    colors.gold4,
    colors.red4,
    colors.violet4,
    colors.green4,
    colors.rose4,
  ]

  return {
    colors: chartColors,
    gradients: {
      turquoise: [
        {
          offset: 0,
          color: colors.turquoise3,
        },
        {
          offset: 100,
          color: colors.turquoise4,
        },
      ],
      cobalt: [
        {
          offset: 0,
          color: colors.cobalt3,
        },
        {
          offset: 100,
          color: colors.cobalt4,
        },
      ],
      gold: [
        {
          offset: 0,
          color: colors.gold3,
        },
        {
          offset: 100,
          color: colors.gold4,
        },
      ],
      cobaltToTurquoise: [
        {
          offset: 0,
          color: colors.cobalt5,
        },
        {
          offset: 100,
          color: colors.turquoise4,
        },
      ],
      vermilionToRose: [
        {
          offset: 0,
          color: colors.vermilion4,
        },
        {
          offset: 100,
          color: colors.rose4,
        },
      ],
      violetToIndigo: [
        {
          offset: 0,
          color: colors.violet4,
        },
        {
          offset: 100,
          color: colors.indigo5,
        },
      ],
    },
  }
}
