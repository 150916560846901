import { useRefundMembershipCharge } from 'api/MembershipCharges'
import { PROCESSOR_STRIPE } from 'constants/paymentProcessors'
import { useEffect, useState } from 'react'
import { ConfirmationModal } from 'ui/Modal'
import { CurrencyInput } from 'ui/components/Form/Input'
import { RadioCards } from 'ui/components/Form/RadioCards/RadioCards'
import { Money } from 'ui/components/Money'
import { Notification, useNotificationContext } from 'ui/components/Notification'

enum RefundType {
  FULL = 'full',
  PARTIAL = 'partial',
}

const refundTypeOptions = [
  { title: 'Full', id: RefundType.FULL, description: 'Refund the full amount' },
  {
    title: 'Partial',
    id: RefundType.PARTIAL,
    description: 'Pick how much to refund',
  },
]

export const RefundModal = ({ isOpen, charge, onClose }) => {
  const [amountToRefund, setAmountToRefund] = useState(charge?.amount)
  const [refundType, setRefundType] = useState(refundTypeOptions[0])
  const notifications = useNotificationContext()
  const { isLoading: isRefunding, mutate: refund } = useRefundMembershipCharge()

  useEffect(() => {
    setAmountToRefund(charge?.amount - charge?.amount_refunded)
  }, [charge?.amount, charge?.amount_refunded])

  useEffect(() => {
    setRefundType(refundTypeOptions[0])
  }, [charge?.id])

  const handleRefund = () => {
    refund(
      {
        chargeId: charge.id,
        amount:
          refundType.id === RefundType.FULL
            ? charge.amount - charge.amount_refunded
            : amountToRefund,
      },
      {
        onSuccess: () => {
          onClose()
          notifications.notify(
            <Notification
              title="Refund issued"
              description="The refund was successfully initiated, and should appear in the customer's account in 3 working days"
              variant="success"
              autoDismiss
            />
          )
        },
        onError: () => {
          onClose()
          notifications.notify(
            <Notification
              title="Couldn't issue refund"
              description="An error occurred when issuing a refund for this charge"
              variant="danger"
              autoDismiss
            />
          )
        },
      }
    )
  }

  const canRefund = () => {
    if (refundType.id === RefundType.PARTIAL) {
      return amountToRefund > 0 && amountToRefund <= charge?.amount - charge?.amount_refunded
    }

    return true
  }

  return (
    <ConfirmationModal
      title="Refund charge"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleRefund}
      confirmButtonLabel="Refund"
      confirming={isRefunding}
      disabled={!canRefund()}
      variant="info"
    >
      {charge && (
        <>
          {charge.processor === PROCESSOR_STRIPE && (
            <div className="mt-4 space-y-2 text-sm p-3 bg-yellow-100 text-yellow-600 rounded-lg">
              <p className="font-medium">Refunds aren’t part of the Bacs Direct Debit scheme.</p>
              <p>
                Since Bacs Direct Debit has an indefinite indemnity period, if the customer creates
                a dispute any time after a refund has been issued, you can lose both the disputed
                amount and the amount you refunded separately.
              </p>
            </div>
          )}

          {charge.amount_refunded > 0 && (
            <div className="mt-4 space-y-2 text-sm p-3 bg-blue-100 text-blue-600 rounded-lg">
              <span className="font-medium">
                <Money currency={charge.currency} amount={charge.amount_refunded} />
              </span>{' '}
              has already been refunded.
            </div>
          )}

          <div className="my-4">
            <RadioCards options={refundTypeOptions} value={refundType} onChange={setRefundType} />
          </div>

          {refundType.id === RefundType.PARTIAL && (
            <div>
              <CurrencyInput
                label="Amount to refund"
                value={amountToRefund}
                onChange={setAmountToRefund}
                currency={charge.currency}
                max={charge.amount - charge.amount_refunded}
              />
            </div>
          )}

          {!canRefund() && (
            <div className="mt-4 space-y-2 text-sm p-3 bg-red-100 text-red-600 rounded-lg">
              <p className="font-medium">
                Enter an amount up to{' '}
                <Money currency={charge.currency} amount={charge.amount - charge.amount_refunded} />
              </p>
            </div>
          )}

          {canRefund() && (
            <div className="space-y-2 mt-4">
              {refundType.id === RefundType.FULL && (
                <p className="text-gray-700 font-medium">
                  Are you sure you want to refund this charge of{' '}
                  <Money
                    currency={charge.currency}
                    amount={charge.amount - charge.amount_refunded}
                  />
                  ?
                </p>
              )}

              {refundType.id === RefundType.PARTIAL && (
                <p className="text-gray-700 font-medium">
                  Are you sure you want to refund{' '}
                  <Money currency={charge.currency} amount={amountToRefund} /> of this charge?
                </p>
              )}
              <p className="text-gray-500 text-sm">
                The refund will take around 3 working days to process.
              </p>
              <p className="text-gray-500 text-sm">
                Once you click Refund, you can't undo this action.
              </p>
            </div>
          )}
        </>
      )}
    </ConfirmationModal>
  )
}
