import { Box } from '@trybeapp/ui.box'
import { Money } from '@trybeapp/ui.money'
import { STATUS_RESERVATION_EXPIRED, STATUS_RESERVED } from 'constants/orderStatuses'
import { Col } from '@trybeapp/ui.grid'
import { DateTime } from 'luxon'
import { StatusTag } from './Overview'
import { Text } from 'components/Text'
import { useState } from 'react'
import { useCurrentOrder } from 'contexts/Order'
import { XIcon } from '@heroicons/react/solid'
import { RemoveDiscountFromItem } from '../Discounts/RemoveOrderItemDiscountConfirmation'
import { minutesToFriendlyDuration } from 'utilities/DateUtils/dateUtils'

export const StatusColumn = ({ item }) => {
  const { booking_reserved_until: reservedUntil, status: itemStatus } = item
  const reservedUntilObj = DateTime.fromJSDate(reservedUntil)
  const isStillReserved = reservedUntilObj.diffNow().valueOf() > 0
  const reservable = itemStatus === STATUS_RESERVED

  const displayStatus =
    itemStatus === STATUS_RESERVED && !isStillReserved ? STATUS_RESERVATION_EXPIRED : itemStatus

  return (
    <Col col={0.13}>
      <StatusTag status={displayStatus} />
      {reservable && isStillReserved && (
        <Text variant="meta2">
          Until {reservedUntilObj.toLocaleString(DateTime.TIME_24_SIMPLE)}
        </Text>
      )}
    </Col>
  )
}

export const DateColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime } = bookingSummary

  return (
    <Col col={0.15}>
      <Text variant="body2" color="nude.900">
        {DateTime.fromJSDate(startTime).toLocaleString()}
      </Text>
    </Col>
  )
}

export const TypeColumn = ({ item, children }) => {
  const { type_name: typeName } = item

  return (
    <Col col={0.3}>
      <Text variant="body2" color="nude.900" lineHeight={1.2}>
        {typeName}
      </Text>
      {children}
    </Col>
  )
}

export const CostColumn = ({ item }) => {
  const { order: { id: orderId, currency, applied_promo_code_id: appliedPromoCodeId } = {} } =
    useCurrentOrder()
  const { id: itemId, total_cost: totalCost, discounts = [] } = item
  const [removeDiscountConfirmation, setRemoveDiscountConfirmation] = useState(null)

  const canDeleteDiscount = (discount) => {
    return discount.reason_code !== 'voucher_difference'
  }

  return (
    <Col col={0.2}>
      <Text
        as={Money}
        variant="body2"
        color="nude.900"
        currency={currency}
        amount={totalCost / 100}
        lineHeight={1.2}
      />
      {discounts.map((discount) => (
        <Text color="info.500" variant="body3" lineHeight={1.2} key={discount.id}>
          inc{' '}
          <Text
            as={Money}
            variant="meta3"
            lineHeight="inherit"
            fontSize="inherit"
            color="inherit"
            currency={currency}
            amount={discount.calculated_amount / 100}
            display="inline-block"
          />{' '}
          <span className="whitespace-nowrap">
            {`${'discount'}`}
            {canDeleteDiscount(discount) && appliedPromoCodeId === null && (
              <>
                <XIcon
                  className="h-3 w-3 cursor-pointer"
                  onClick={() => setRemoveDiscountConfirmation(discount)}
                />
                <RemoveDiscountFromItem
                  discount={removeDiscountConfirmation}
                  orderId={orderId}
                  itemId={itemId}
                  onClose={() => setRemoveDiscountConfirmation(null)}
                />
              </>
            )}
          </span>
        </Text>
      ))}
    </Col>
  )
}

export const StartTimeColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime, duration } = bookingSummary

  return (
    <Col col={0.12}>
      <Box display="flex" flexDirection="column">
        <Text variant="body2" color="nude.900" lineHeight={1.2}>
          {DateTime.fromJSDate(startTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
        </Text>
        <Text variant="meta1" color="nude.500" lineHeight={1.2}>
          {minutesToFriendlyDuration(duration)}
        </Text>
      </Box>
    </Col>
  )
}
