import { map } from 'lodash'
import { useGetShopSite } from 'api/Sites'
import { createSelector } from 'reselect'

/**
 * @deprecated
 */
export const selectSite = (siteId) => {
  const { data: { data: site } = {} } = useGetShopSite(siteId)

  return site
}

/**
 * @deprecated
 */
export const selectSiteTimesFromSiteId = (siteId) => {
  return createSelector([], () => {
    const site = selectSite(siteId)

    let timeFrom, timeTo

    if (site) {
      // Pluck the times from opening hours to get the earliest opening time
      // and the latest closing time
      timeFrom = map(site.opening_hours, 'time_from').sort()[0] || '00:00'
      timeTo = map(site.opening_hours, 'time_to').sort((a, b) => (a > b ? -1 : 1))[0] || '00:00'
    } else {
      timeFrom = '00:00'
      timeTo = '00:00'
    }

    return [timeFrom, timeTo]
  })
}

/**
 * @deprecated
 */
export const selectSiteTimesFromSiteIdAndDate = (siteId, date) => {
  return createSelector([selectSiteTimesFromSiteId(siteId)], ([timeFrom = '', timeTo = '']) => {
    const timeFromParts = timeFrom.split(':')
    const timeToParts = timeTo.split(':')

    return [
      date.startOf('day').set({
        hour: timeFromParts[0],
        minute: timeFromParts[1],
      }),
      date.startOf('day').set({
        hour: timeToParts[0],
        minute: timeToParts[1],
      }),
    ]
  })
}
