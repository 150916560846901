import ReactDOM from 'react-dom'
import App from './containers/App'
import { Provider } from 'react-redux'
import { configureStore } from './store'
import { ThemeProvider } from '@xstyled/styled-components'
import theme from './theme'
import { GlobalStyle } from '@trybeapp/ui.core'
import './index.css'

const store = configureStore()
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle useReset={false} />
      <App />
    </ThemeProvider>
  </Provider>,

  document.getElementById('root')
)
