import {
  CreateEscPosConnector,
  EscPosConnectorsApi,
  ListEscPosConnectors,
  OrdersApi,
  PrintPosReceiptForOrder,
  UpdateEscPosConnector,
} from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new EscPosConnectorsApi(apiConfiguration)
const ordersApi = new OrdersApi(apiConfiguration)

export const useEscPosConnectors = (options) => {
  const siteId = useCurrentSiteId()

  return useQuery<ListEscPosConnectors>(
    ['escPosConnectors', { siteId }],
    () => api.listEscPosConnectors({ siteId }),
    options
  )
}

export const useCreateEscPosConnector = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (createEscPosConnector: CreateEscPosConnector) =>
      api.createEscPosConnector({
        createEscPosConnector: {
          ...createEscPosConnector,
        },
      }),
    {
      onSuccess: (_, vars) => {
        queryClient.invalidateQueries(['escPosConnectors', { siteId: vars.site_id }])
      },
    }
  )
}

export const useUpdateEscPosConnector = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([id, updateEscPosConnector]: [string, UpdateEscPosConnector]) =>
      api.updateEscPosConnector({
        escPosConnector: id,
        updateEscPosConnector: {
          ...updateEscPosConnector,
        },
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['escPosConnectors', { siteId: data.site_id }])
      },
    }
  )
}

export const usePrintOrderReceipt = () => {
  return useMutation(([orderId, payload]: [string, PrintPosReceiptForOrder]) =>
    ordersApi.actionPrintPosReceiptForOrder({
      orderId,
      printPosReceiptForOrder: {
        ...payload,
      },
    })
  )
}

export const useDeleteEscPosConnector = () => {
  const queryClient = useQueryClient()

  return useMutation((id: string) => api.deleteEscPosConnector({ escPosConnector: id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['escPosConnectors'])
    },
  })
}
