import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Logo as BaseLogo } from 'ui/components/Logo'
import { useSidebar } from './contexts'

export const Logo = () => {
  const { minimised, lightTheme } = useSidebar()

  return (
    <Link
      className={classNames('relative -top-0.5 mx-2 h-10 flex cursor-pointer')}
      to={'/dashboards'}
    >
      {/* Light-mode variant, mobile */}
      <div className="md:hidden dark:hidden py-2">
        <BaseLogo variant={lightTheme ? 'light' : 'dark'} width={100} />
      </div>
      {/* Dark-mode variant, mobile */}
      <div className="hidden dark:block md:dark:hidden py-2">
        <BaseLogo variant="dark" width={100} />
      </div>
      {/* Light-mode variant, desktop, minimised */}
      {minimised && (
        <div className="hidden md:block md:dark:hidden py-2">
          <BaseLogo variant="light" width={21} icon />
        </div>
      )}
      {/* Light-mode variant, desktop, full-width */}
      {!minimised && (
        <div className="hidden md:block dark:md:hidden py-2">
          <BaseLogo variant={lightTheme ? 'light' : 'dark'} width={100} />
        </div>
      )}
      {/* Dark-mode variant, desktop, minimised */}
      {minimised && (
        <div className="hidden md:dark:block py-2">
          <BaseLogo variant="dark" width={21} icon />
        </div>
      )}
      {/* Dark-mode variant, desktop, full-width */}
      {!minimised && (
        <div className="hidden md:dark:block py-2">
          <BaseLogo variant="dark" width={100} />
        </div>
      )}
    </Link>
  )
}
