import { DateTime } from 'luxon'
import { useAreaBookingForm, useSlotsByHourContext } from './contexts'

export const useCombinationForRoomId = (roomId) => {
  const { suggested_combinations: combinations = [] } = useCurrentSlot()
  const combinationForRoom = combinations.find(({ room_id: suggestedId }) => suggestedId === roomId)

  return combinationForRoom
}

export const useCurrentAreaIds = () => {
  const { areaIds = [] } = useAreaBookingForm()

  return areaIds
}

export const useCurrentTime = () => {
  const { time = '' } = useAreaBookingForm()

  return time
}

export const useCurrentDuration = () => {
  const { duration = '' } = useAreaBookingForm()

  return duration
}

export const useInvalidTime = () => {
  const currentTime = useCurrentTime()
  const allSlots = useAllSlots()
  const foundSlot = allSlots.find(
    ({ start_time: startTime }) => DateTime.fromJSDate(startTime).toFormat('HH:mm') === currentTime
  )

  return currentTime && !foundSlot && allSlots.length > 0
}

export const useCurrentSlot = () => {
  const allSlots = useAllSlots()
  const { time: currentTime = '' } = useAreaBookingForm()

  return (
    allSlots.find(({ start_time: startTime }) => {
      return DateTime.fromJSDate(startTime).toFormat('HH:mm') === currentTime
    }) ?? {}
  )
}

export const useSlotsByHour = () => useSlotsByHourContext().groupedSlots
export const useAllSlots = () => useSlotsByHourContext().slots
