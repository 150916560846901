import { Box } from '@trybeapp/ui.box'
import { Button } from '@trybeapp/ui.button'
import { Card } from '@trybeapp/ui.card'
import { FontAwesomeIcon } from 'components/FontAwesomeIcon'
import { Text } from 'components/Text'
import { useAuth } from 'react-oidc-context'

export const NotAuthorized = () => {
  const auth = useAuth()

  return (
    <Box {...styles.wrapper}>
      <Card width={450}>
        <Card.Body>
          <FontAwesomeIcon
            display="block"
            mx="auto"
            fontSize={75}
            color="danger.200"
            icon="exclamation-triangle"
          />
          <Text textAlign="center" variant="body1" color="nude.900" my="lg">
            Oh no! It looks like you don't have permission to access this area.
          </Text>
          <Text textAlign="center" variant="body2" color="nude.700" my="lg">
            If you think this is a mistake, please contact your Spa Manager to fix this for you.
          </Text>
          <Button onClick={() => auth.signoutRedirect()}>Logout</Button>
        </Card.Body>
      </Card>
    </Box>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'pageBackground',
  },
}
