import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { MembershipCreditRulesApi } from '@trybeapp/sdk.customer'

const api = new MembershipCreditRulesApi()

export const useMembershipCreditRules = (
  params = {},
  options: UseQueryOptions = {}
): UseQueryResult<any> => {
  // With these defaults, data will always be refreshed
  // in the background if the cache is older than 1 hour.
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? 60 * 60 * 1000 // 60 minutes

  return useQuery(
    ['membershipCreditRules', { params }],
    () => api.listMembershipCreditRules(params),
    options
  )
}

export const useInfiniteMembershipCreditRules = (params = {}) =>
  useInfiniteQuery(['membershipCreditRules', { params }], fetchMembershipCreditRules, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
  })

const fetchMembershipCreditRules = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listMembershipCreditRules({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useCreateMembershipCreditRule = (membershipTypeId: string) =>
  useMutation(
    async (creditRule: any) =>
      await api.createMembershipCreditRule({
        ...creditRule,
        membership_type_id: membershipTypeId,
      })
  )

export const useMembershipCreditRule = (creditRuleId: string, options?: UseQueryOptions) =>
  useQuery<any, any>(
    ['membershipCreditRule', { creditRuleId }],
    async () => (await api.getMembershipCreditRule(creditRuleId)).data,
    options
  )

export const useUpdateMembershipCreditRule = () =>
  useMutation(
    async (creditRule: any) =>
      await api.updateMembershipCreditRule(creditRule.id, {
        ...creditRule,
      })
  )
export const useDeleteMembershipCreditRule = (creditRuleId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (creditRuleIdPassed) => api.deleteMembershipCreditRule(creditRuleId ?? creditRuleIdPassed),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('membershipCreditRules')
      },
    }
  )
}

export const useIssueCreditRule = () => {
  const queryClient = useQueryClient()

  return useMutation((creditRuleId: string) => api.issueMembershipCreditRuleCredits(creditRuleId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['customerCredits'])
    },
  })
}
