interface AvatarProps {
  width?: number
  height?: number
  src?: string
  firstName?: string
  lastName?: string
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const firstInitial = props.firstName?.charAt(0).trim() ?? ''
  const lastInitial = props.lastName?.charAt(0).trim() ?? ''
  const initials = `${firstInitial}${lastInitial}`.toUpperCase()

  const width = props.width ?? 32
  const height = props.height ?? 32

  return (
    <div
      className="flex items-center justify-center bg-gray-200 rounded-full text-gray-600 font-medium"
      style={{ width, height }}
    >
      {props.src && (
        <img
          src={props.src}
          alt={`${props.firstName} ${props.lastName}`}
          className="rounded-full"
          style={{ width, height }}
        />
      )}

      {!props.src && initials}
    </div>
  )
}
