import { useLockCustomer, useUnlockCustomer } from 'api/Customers'
import React from 'react'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'

interface LockConfirmationProps {
  clientId: string | null
  onClose: () => void
  state: 'locking' | 'unlocking'
}
export const LockConfirmation: React.FC<LockConfirmationProps> = (props) => {
  const { isLoading: isLocking, mutate: lock } = useLockCustomer()
  const { isLoading: isUnlocking, mutate: unlock } = useUnlockCustomer()
  const notifications = useNotificationContext()

  const mutationOptions = {
    onSuccess: () => {
      const keyword = props.state === 'locking' ? 'locked' : 'unlocked'
      notifications.notify(
        <Notification
          title={`Customer ${keyword}`}
          description={`The customer was successfully ${keyword}`}
          variant="success"
          autoDismiss
        />
      )

      props.onClose()
    },
    onError: (e: any) => {
      notifications.notify(<ApiErrorNotification error={e} />)

      props.onClose()
    },
  }
  const handleConfirm = () => {
    if (props.state === 'locking') {
      lock(props.clientId, mutationOptions)
    } else if (props.state === 'unlocking') {
      unlock(props.clientId, mutationOptions)
    }
  }

  return (
    <ConfirmationModal
      isOpen={props.clientId !== null}
      title={props.state === 'locking' ? 'Lock customer' : 'Unlock customer'}
      onClose={() => props.onClose()}
      confirmButtonLabel={props.state === 'locking' ? 'Lock' : 'Unlock'}
      onConfirm={handleConfirm}
      confirming={isLocking || isUnlocking}
      variant="warning"
    >
      {props.state === 'locking' ? (
        <div className="space-y-4">
          <p className="text-sm font-medium text-gray-700">
            Are you sure you want to lock this customer?
          </p>
          <p className="text-sm text-gray-500">
            When locked, the personal information of this customer won't be able to be modified.
          </p>
          <p className="text-sm text-gray-500">
            Only a user with the "Spa manager" permission can unlock a customer.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          <p className="text-sm font-medium text-gray-700">
            Are you sure you want to unlock this customer?
          </p>
          <p className="text-sm text-gray-500">
            When unlocked, the personal information of this customer will be able to be modified.
          </p>
        </div>
      )}
    </ConfirmationModal>
  )
}
