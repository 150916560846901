import { createSelector } from 'reselect'

/**
 * @deprecated
 */
export const selectSessionTypes = (state) => state.resources.SessionTypesReducer
/**
 * @deprecated
 */
export const selectSessionTypesById = (state) => state.resources.SessionTypesReducer.byId
/**
 * @deprecated
 */
export const selectSessionTypeIds = (state) => state.resources.SessionTypesReducer.allIds

/**
 * @deprecated
 */
export const selectAllSessionTypes = createSelector(
  [selectSessionTypeIds, selectSessionTypesById],
  (allIds, byId) => allIds.map((id) => byId[id]).filter((v) => typeof v !== 'undefined')
)

/**
 * @deprecated
 */
export const selectSessionType = (typeId) =>
  createSelector([selectSessionTypesById], (byId = {}) => byId[typeId])

/**
 * @deprecated
 */
export const selectSiteSessionTypes = (siteId) =>
  createSelector(selectAllSessionTypes, (allTypes) =>
    allTypes.filter((type) => type.site_id === siteId)
  )

/**
 * @deprecated
 */
export const selectSiteSessionTypeIds = (siteId) =>
  createSelector(selectSiteSessionTypes(siteId), (types = []) => types.map((type) => type.id))
