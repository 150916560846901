import { useCallback, useEffect, useState } from 'react'
import { Modal } from '@trybeapp/ui.modal'
import { Button } from '@trybeapp/ui.button'
import { TIMES_STAGE, useAddAreaBookingOrderItemOverlay, useOrderItem, useStage } from './contexts'
import { DefaultStage } from './stages/Default'
import { useIsEditMode } from './hooks'
import { useFormikContext } from 'formik'
import { TimesStage } from './stages/Times'

const DEFAULT_STAGE = 'appointment'

export const AddAreaBookingOrderItemOverlay = () => {
  const {
    modal: { visible },
  } = useAddAreaBookingOrderItemOverlay()
  const { setOrderItem } = useOrderItem()

  useEffect(
    () => () => {
      if (visible) {
        setOrderItem({})
      }
    },
    [setOrderItem, visible]
  )

  return visible && <AddAreaBookingOrderItemOverlayWrapper />
}

const AddAreaBookingOrderItemOverlayWrapper = () => {
  const { modal } = useAddAreaBookingOrderItemOverlay()
  const { stage } = useStage()
  const isEditMode = useIsEditMode()
  const [size, setSize] = useState('md')

  useEffect(() => {
    switch (stage) {
      case TIMES_STAGE:
        setSize('auto')
        break
      default:
      case DEFAULT_STAGE:
        setSize('md')
    }
  }, [stage])

  const StageComponent = useCallback(() => {
    switch (stage) {
      case TIMES_STAGE:
        return <TimesStage />
      default:
      case DEFAULT_STAGE:
        return <DefaultStage />
    }
  }, [stage])

  return (
    <Modal
      {...modal}
      size={size}
      ariaLabel={`${isEditMode ? 'Edit' : 'Add'}`}
      hideOnClickOutside={false}
    >
      <StageComponent />
    </Modal>
  )
}

export const NextButton = () => {
  const { submitForm } = useFormikContext()

  return (
    <Button variant="primary" onClick={submitForm}>
      Next
    </Button>
  )
}
