import { SET_HIGHLIGHTED_BOOKING_IDS, SET_SELECTED_BLOCKED_TIME } from './actionTypes'

export const VIEW_TYPE_PRACTITIONER = 'practitioner'
export const VIEW_TYPE_ROOM = 'room'
export const VIEW_TYPE_AREA = 'area'
export const EVENT_TYPE_APPOINTMENT = 'appointment'
export const EVENT_TYPE_SESSION = 'session'
export const EVENT_TYPE_BLOCKED_TIME = 'blocked_time'
export const EVENT_TYPE_AREA_BOOKING = 'area_booking'

export const INITIAL_STATE = {
  highlightedBookingIds: [],
  selectedBlockedTime: null,
}

const reducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case SET_HIGHLIGHTED_BOOKING_IDS:
      return {
        ...state,
        highlightedBookingIds: action.highlightedBookingIds ?? [],
      }

    case SET_SELECTED_BLOCKED_TIME:
      return {
        ...state,
        selectedBlockedTime: action.blockedTime,
      }
    default:
      return state
  }
}

export default reducer
