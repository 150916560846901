import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { RoomReservationTypesApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new RoomReservationTypesApi()

export const useRoomReservationTypes = (
  params: any = {},
  options?: UseQueryOptions
): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery(
    ['roomReservationTypes', params],
    () => api.roomReservationTypesIndex(params.siteId, params),
    options
  )
}

export const useInfiniteRoomReservationTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(
    ['inifiniteRoomReservationTypes', { params }],
    fetchRoomReservationTypes,
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.nextPage
      },
    }
  )
}

const fetchRoomReservationTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]
  const response = await api.roomReservationTypesIndex(params.siteId, {
    page,
    ...params,
  })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

export const useGetRoomReservationType = (roomReservationTypeId: string, options = {}) =>
  useQuery(
    ['roomReservationType', { roomReservationTypeId }],
    () => api.roomReservationTypeShow(roomReservationTypeId),
    options
  )

export const useCreateRoomReservationType = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (roomReservationType: any) =>
      api.roomReservationTypeStore({ ...roomReservationType, site_id: siteId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomReservationTypes'])
      },
    }
  )
}

export const useCopyRoomReservationType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ copyFromId, roomReservationType }: any) =>
      api.roomReservationTypeCopy(copyFromId, roomReservationType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomReservationTypes'])
      },
    }
  )
}

export const useUpdateRoomReservationType = (roomReservationTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, unknown, any>(
    (roomReservationType) =>
      api.roomReservationTypeUpdate(roomReservationTypeId, roomReservationType),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomReservationTypes'])
        queryClient.invalidateQueries(['roomReservationType', { roomReservationTypeId }])
      },
    }
  )
}

export const useDeleteRoomReservationType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (roomReservationTypeId: string) => api.roomReservationTypeDestroy(roomReservationTypeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomReservationTypes'])
      },
    }
  )
}
