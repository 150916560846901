import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import {
  CreateMarketingPreference,
  CreateSiteMarketingPreference,
  ListMarketingPreferences,
  ListSiteMarketingPreferences,
  MarketingPreferencesApi,
  UpdateMarketingPreference,
} from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new MarketingPreferencesApi(apiConfiguration)

export const useMarketingPreferences = (params = {}, options = {}): UseQueryResult<any> => {
  return useQuery<ListMarketingPreferences>(
    ['marketingPreferences', { params }],
    async () => api.listMarketingPreferences(params),
    options
  )
}

export const useCreateMarketingPreference = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (createMarketingPreference: CreateMarketingPreference) =>
      api.createMarketingPreference({ createMarketingPreference }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('marketingPreferences')
      },
    }
  )
}

export const useUpdateMarketingPreference = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      marketingPreferenceId,
      updateMarketingPreference,
    }: {
      marketingPreferenceId: string
      updateMarketingPreference: UpdateMarketingPreference
    }) => {
      return api.updateMarketingPreference({ marketingPreferenceId, updateMarketingPreference })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('marketingPreferences')
      },
    }
  )
}

export const useArchiveMarketingPreference = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (marketingPreferenceId: string) => api.deleteMarketingPreference({ marketingPreferenceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('marketingPreferences')
      },
    }
  )
}

export const useSiteMarketingPreferences = (options = {}): UseQueryResult<any> => {
  const siteId = useCurrentSiteId()

  return useQuery<ListSiteMarketingPreferences>(
    ['marketingPreferences', { siteId }],
    async () => api.listSiteMarketingPreferences({ siteId }),
    options
  )
}

export const useCreateSiteMarketingPreference = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSiteId()

  return useMutation(
    (createSiteMarketingPreference: CreateSiteMarketingPreference) =>
      api.createSiteMarketingPreference({
        siteId,
        createSiteMarketingPreference: createSiteMarketingPreference,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('marketingPreferences')
      },
    }
  )
}

export const useDeleteSiteMarketingPreference = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ siteId, marketingPreferenceId }: { siteId: string; marketingPreferenceId: string }) =>
      api.deleteSiteMarketingPreference({ siteId, marketingPreferenceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('marketingPreferences')
      },
    }
  )
}
