import { IntakeFormsApi } from '@trybeapp/sdk.shop'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { ReportsApi } from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new IntakeFormsApi()
const tsApi = new ReportsApi(apiConfiguration)

/**
 * Gets all the completed intake forms for the given basket
 */
export const useGetIntakeFormsForOrder = (
  orderId: string,
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['orderIntakeForms', orderId],
    async () => await api.visitIntakeFormIndex(orderId),
    options
  )

/**
 * Gets all guests who will be required to fill out intake forms for the given basket
 */
export const useGetIntakeFormGuestsForOrder = (
  orderId: string,
  options?: UseQueryOptions
): UseQueryResult<any> =>
  useQuery(
    ['orderIntakeFormsForGuests', orderId],
    async () => await api.getGuestsForVisitIntakeForm(orderId),
    options
  )

/**
 * Gets all the completed intake forms for the given baskets
 */
export const useGetIntakeFormAnswersForOrders = (orderIds: string[], options?: UseQueryOptions) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['orderIntakeForms', { orderIds }],
    () => api.visitIntakeFormsForOrders(orderIds.join(','), siteId),
    options
  )
}
export const useExportIntakeFormAnswersForOrders = (options) => {
  const siteId = useCurrentSiteId()

  return useMutation(
    (orderIds: string[]) => api.visitIntakeFormsForOrdersPdf(orderIds.join(','), siteId),
    options
  )
}

export const useQueueExportIntakeFormAnswersForOrders = (orderIdsPassed, options) => {
  const siteId = useCurrentSiteId()

  return useMutation(
    () =>
      tsApi.actionQueueIntakeFormsExport({
        basketIds: orderIdsPassed,
        siteId: siteId,
      }),
    options
  )
}

/**
 * Gets a single completed intake form
 */
export const useVisitIntakeForm = (visitIntakeFormId: string, options = {}) =>
  useQuery(
    ['visitIntakeForm', { visitIntakeFormId: visitIntakeFormId }],
    () => api.visitIntakeFormShow(visitIntakeFormId),
    options
  )

export const useVisitIntakeFormHtml = (visitIntakeFormId: string, options = {}) =>
  useQuery(
    ['visitIntakeForm', { visitIntakeFormId: visitIntakeFormId }],
    () => api.visitIntakeFormShowPreview(visitIntakeFormId),
    options
  )

export const useVisitIntakeForms = (params, options = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(
    ['visitIntakeForms', { siteId, ...params }],
    () => api.visitIntakeFormList(siteId, params),
    options
  )
}
