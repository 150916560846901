import moment from 'moment'
import Moment from 'react-moment'
import { Box } from '@trybeapp/ui.box'
import { Money } from '@trybeapp/ui.money'
import { Text } from 'components/Text'
import { STATUS_RESERVATION_EXPIRED, STATUS_RESERVED } from 'constants/orderStatuses'
import { renderFriendlyLabel } from 'services/TimeOptionsGenerator'
import { Col } from '@trybeapp/ui.grid'
import { DateTime } from 'luxon'
import { StatusTag } from './Overview'
import { useState } from 'react'
import { useCurrentOrder } from 'contexts/Order'
import { XIcon } from '@heroicons/react/solid'
import { RemoveDiscountFromItem } from '../Discounts/RemoveOrderItemDiscountConfirmation'

export const StatusColumn = ({ item, ...props }) => {
  const { reserved_until: reservedUntil, booking_summary: bookingSummary = {} } = item
  const { status } = bookingSummary

  const isStillReserved = moment(reservedUntil).isAfter(moment.now())
  const reservable = status === STATUS_RESERVED

  const displayStatus =
    status === STATUS_RESERVED && !isStillReserved ? STATUS_RESERVATION_EXPIRED : status

  return (
    <Col col={0.13} {...props}>
      <StatusTag status={displayStatus} />
      {reservable && isStillReserved && (
        <Text variant="meta2">
          Until <Moment format="LT">{reservedUntil}</Moment>
        </Text>
      )}
    </Col>
  )
}

export const DateColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime } = bookingSummary

  return (
    <Col col={0.15}>
      <Text variant="body2" color="nude.900">
        {DateTime.fromJSDate(startTime).toLocaleString()}
      </Text>
    </Col>
  )
}

export const PackageItemTypeColumn = ({ item, children }) => {
  const { offering_name: offeringName } = item

  return (
    <Col col={0.35}>
      <Text variant="body2" color="nude.900" lineHeight={1.2}>
        {offeringName}
      </Text>
      {children}
    </Col>
  )
}

export const CostColumn = ({ item }) => {
  const { order: { id: orderId, currency, applied_promo_code_id: appliedPromoCodeId } = {} } =
    useCurrentOrder()
  const { id: itemId, total_cost: totalCost, discounts = [] } = item
  const [removeDiscountConfirmation, setRemoveDiscountConfirmation] = useState(null)

  const canRemoveDiscount = (discount) => {
    return discount.reason_code !== 'voucher_difference'
  }

  return (
    <Col col={0.2}>
      <Text
        as={Money}
        variant="body2"
        color="nude.900"
        currency={currency}
        amount={totalCost / 100}
        lineHeight={1.2}
      />
      {discounts.map((discount) => (
        <Text color="info.500" variant="body3" lineHeight={1.2} key={discount.id}>
          inc{' '}
          <Text
            as={Money}
            variant="meta3"
            lineHeight="inherit"
            fontSize="inherit"
            color="inherit"
            currency={currency}
            amount={discount.calculated_amount / 100}
            display="inline-block"
          />{' '}
          discount
          {canRemoveDiscount(discount) && appliedPromoCodeId === null && (
            <>
              <XIcon
                className="h-3 w-3 cursor-pointer"
                onClick={() => setRemoveDiscountConfirmation(discount)}
              />
              <RemoveDiscountFromItem
                discount={removeDiscountConfirmation}
                orderId={orderId}
                itemId={itemId}
                onClose={() => setRemoveDiscountConfirmation(null)}
              />
            </>
          )}
        </Text>
      ))}
    </Col>
  )
}

export const StartTimeColumn = ({ item }) => {
  const { booking_summary: bookingSummary = {} } = item
  const { start_time: startTime, duration } = bookingSummary

  const durationToMoment = (duration) => {
    const durationMoment = moment.duration(duration, 'minutes')

    return moment().hours(durationMoment.hours()).minutes(durationMoment.minutes())
  }

  return (
    <Col col={0.12}>
      <Box display="flex" flexDirection="column">
        <Text variant="body2" color="nude.900" lineHeight={1.2}>
          {DateTime.fromJSDate(startTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
        </Text>
        <Text variant="meta1" color="nude.500" lineHeight={1.2}>
          {renderFriendlyLabel(durationToMoment(duration))}
        </Text>
      </Box>
    </Col>
  )
}
