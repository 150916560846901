import { useInfiniteDiscountTypes } from 'api/DiscountTypesAndPromoCodes'
import { useFormikContext } from 'formik'
import { ForwardedRef, forwardRef, useMemo, useState } from 'react'
import { createEvent } from 'utilities/createEvent'
import { Select } from './Select'
import useDebounce from 'hooks/Debounce'
import { Hint } from './Field'

export const DiscountTypeSelect = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { values } = useFormikContext<any>()
  const [searchDiscountType, setSearchDiscountType] = useState('')
  const debouncedDiscountTypeSearch = useDebounce(searchDiscountType, 500)

  const { hasNextPage, fetchNextPage, data, isFetchingNextPage, isLoading } =
    useInfiniteDiscountTypes({
      applicableFor: values.applicable_for,
      query: debouncedDiscountTypeSearch !== '' ? debouncedDiscountTypeSearch : undefined,
    })

  const discountTypes = (data?.pages ?? []).reduce(
    (discountTypes, page) => discountTypes.concat(page.data),
    []
  )

  const promoCodeOptions = (data?.pages ?? [])
    .map((page) =>
      (page.data ?? []).map((promoCode) => ({
        value: promoCode.code,
        name: promoCode.code,
      }))
    )
    .flat()

  const inAppOptions = (data?.pages ?? [])
    .map((page) =>
      (page.data ?? []).map((type) => ({
        value: type.code,
        name: type.code,
      }))
    )
    .flat()

  const codeValue = useMemo(() => {
    if (!promoCodeOptions.length) {
      return null
    }

    const selectedIds = [props.value]

    const selectedCodes = promoCodeOptions.filter((option) =>
      (selectedIds ?? []).includes(option.value)
    )

    return selectedCodes[0]
  }, [promoCodeOptions, props.value])

  const typeValue = useMemo(() => {
    if (!inAppOptions.length) {
      return null
    }

    const selectedIds = [props.value]

    const selectedTypes = inAppOptions.filter((option) =>
      (selectedIds ?? []).includes(option.value)
    )

    return selectedTypes[0]
  }, [inAppOptions, props.value])

  return (
    <>
      {values.applicable_for === 'app_and_booking_engine' && (
        <Select
          placeholder="Select promo code"
          isLoadingMore={isFetchingNextPage}
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}
          options={promoCodeOptions}
          filterValue={searchDiscountType}
          onFilterChange={setSearchDiscountType}
          selected={codeValue}
          ref={ref}
          {...props}
          onChange={(code) => {
            props.onChange(code, discountTypes)
          }}
        />
      )}
      {values.applicable_for === 'app_only' && (
        <Select
          placeholder="Select discount type"
          isLoadingMore={isFetchingNextPage}
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}
          options={inAppOptions}
          filterValue={searchDiscountType}
          onFilterChange={setSearchDiscountType}
          selected={typeValue}
          ref={ref}
          {...props}
          onChange={(discountType) => {
            props.onChange(discountType, discountTypes)
          }}
        />
      )}
      {values.applicable_for === 'app_and_booking_engine' &&
        !promoCodeOptions.length &&
        !isLoading &&
        !debouncedDiscountTypeSearch && (
          <Hint>
            No valid promo codes are available for application.
            <br />
            Please complete setup in the Discount types and promo codes settings.
          </Hint>
        )}
      {values.applicable_for === 'app_only' &&
        !inAppOptions.length &&
        !isLoading &&
        !debouncedDiscountTypeSearch && (
          <Hint>
            No valid discount types are available for application.
            <br />
            Please complete set-up in the Discount types and promo codes settings.
          </Hint>
        )}
    </>
  )
})

export const FormikDiscountTypeSelect = forwardRef<any, any>(
  ({ onChange: parentOnChange, name, ...props }, ref) => {
    const onChange = (value, discountTypes) => {
      const updatedValue = value.value
      parentOnChange(createEvent({ name, value: updatedValue }))

      const selected = discountTypes.find((discountType) => updatedValue === discountType.code)

      if (selected.id) {
        parentOnChange(
          createEvent({
            name: 'discount_type_id',
            value: selected.id,
            discountType: selected,
          })
        )
      }

      if (selected.amount_type) {
        parentOnChange(
          createEvent({
            name: 'amount_type',
            value: selected.amount_type,
            discountType: selected,
          })
        )
      }

      if (selected.discount_amount) {
        parentOnChange(
          createEvent({
            name: 'discount_amount',
            value: selected.discount_amount,
            discountType: selected,
          })
        )
      }
    }

    return <DiscountTypeSelect name={name} onChange={onChange} ref={ref} {...props} />
  }
)
