import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Spinner } from './Spinner'

export type ButtonVariants =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'danger'
  | 'ghost'
export type ButtonSizes = 'xs' | 'sm'

interface ButtonProps {
  /**
   * The label to display within the button. Even if you don't want this to
   * display, you still need to provide a meaningful value for screen readers
   */
  label: string
  /** Whether the label should be hidden */
  hideLabel?: boolean
  /**
   * Whether the button should be disabled. When disabled, the cursor indicates
   * the button can't be clicked and onClick events aren't fired.
   */
  disabled?: boolean
  /**
   * How the button should look, and what's it going to be used for.
   * Don't overpopulate a page with `primary` and `danger` buttons.
   */
  variant?: ButtonVariants
  /**
   * The size of the button, defaults to sm.
   */
  size?: ButtonSizes
  /**
   * Whether the button is loading. When true, a loading spinner will be displayed
   * and onClick events won't be fired. The width of the button stays the same
   */
  loading?: boolean
  /**
   * An icon to display before the label.
   */
  leadingIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  /**
   * An icon to display after the label.
   */
  trailingIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  /**
   * The event which is fired when the button is clicked
   */
  onClick?: (event: any) => void
  /**
   * Whether this button should take up the full width of its container
   */
  fullWidth?: false
  /**
   * Whether the button should not be rounded
   */
  noRounding?: false
  /**
   * Whether the button label should be prevented from wrapping
   */
  noWrap?: false
  /**
   * Optional additional classes to attach
   */
  className?: string
  /**
   * Optional ID
   */
  id?: string

  as?: React.ElementType

  children?: React.ReactNode
}

export const Button = React.forwardRef<any, any>(
  (
    {
      as: As = 'button',
      label,
      disabled = false,
      hideLabel = false,
      variant = 'default',
      size = 'sm',
      leadingIcon = null,
      trailingIcon = null,
      loading = false,
      onClick = null,
      fullWidth = false,
      noWrap = false,
      noRounding = false,
      className = '',
      id = null,
      children = undefined,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const handleClick = (e) => {
      if (onClick && !disabled && !loading) {
        onClick(e)
      }
    }

    const hasLabel = useMemo(() => {
      return !!label && !hideLabel
    }, [label, hideLabel])

    const resolvedLeadingIcon = useMemo(() => {
      if (!leadingIcon) return null

      let className

      switch (size) {
        case 'xs':
          className = (hasLabel ? 'mr-1 -ml-1' : '') + ' h-4 w-4'
          break

        case 'sm':
        default:
          className = (hasLabel ? 'mr-1 -ml-1' : '') + ' h-5 w-5'
      }

      return leadingIcon({
        className: className,
        'aria-hidden': true,
      })
    }, [leadingIcon, hasLabel, size])

    const resolvedTrailingIcon = useMemo(() => {
      if (!trailingIcon) return null

      return trailingIcon({
        className: (hasLabel ? 'ml-2' : '') + ' -mr-0.5 h-4 w-4',
        'aria-hidden': true,
      })
    }, [trailingIcon, hasLabel])

    let classes = [
      'relative',
      'overflow-hidden',
      'inline-flex',
      'items-center',
      'border',
      'font-medium',
      'shadow-sm',
      'focus:outline-none',
      'focus:ring-2',
      'focus:ring-violet',
      'focus:z-10',
      'justify-center',
      'disabled:opacity-50',
      'disabled:cursor-not-allowed',
    ]

    switch (size) {
      case 'xs':
        classes = classes.concat(['px-2', 'py-1', 'text-xs', 'focus:ring-offset-1'])

        if (noRounding === false) {
          classes.push('rounded-md')
        }

        break

      case 'sm':
        classes = classes.concat([
          leadingIcon && hideLabel ? 'px-3' : 'px-4',
          'py-2',
          'text-sm',
          'focus:ring-offset-2',
        ])

        if (noRounding === false) {
          classes.push('rounded-lg')
        }

        break
    }

    switch (variant) {
      case 'default':
        classes = classes.concat([
          'border-gray-300',
          'text-gray-700',
          'bg-white',
          'hover:bg-gray-50',
          'disabled:hover:bg-white', // disable hover effect when disabled
          'dark:border-gray-700',
          'dark:text-gray-200',
          'dark:bg-gray-600',
          'dark:hover:bg-gray-700',
          'dark:border-transparent',
        ])
        break

      case 'primary':
        classes = classes.concat([
          'border-transparent',
          'text-white',
          'bg-grape-700',
          'hover:bg-grape-500',
          'disabled:hover:bg-grape-700', // disable hover effect when disabled
          'dark:bg-grape-600',
        ])
        break

      case 'secondary':
        classes = classes.concat([
          'border-transparent',
          'text-violet-700',
          'bg-violet-100',
          'hover:bg-violet-200',
          'disabled:hover:bg-violet-200', // disable hover effect when disabled
          'dark:text-violet-100',
          'dark:bg-violet-500',
          'dark:hover:bg-violet-600',
        ])
        break

      case 'info':
        classes = classes.concat([
          'border-transparent',
          'text-blue-800',
          'bg-blue-100',
          'hover:bg-blue-200',
          'disabled:hover:bg-blue-100', // disable hover effect when disabled
          'focus:ring-blue-300',
          'dark:text-blue-100',
          'dark:bg-blue-700',
          'dark:hover:bg-blue-800',
        ])
        break

      case 'warning':
        classes = classes.concat([
          'border-transparent',
          'text-orange-800',
          'bg-orange-100',
          'hover:bg-orange-200',
          'disabled:hover:bg-orange-100', // disable hover effect when disabled
          'focus:ring-orange-300',
          'dark:text-orange-100',
          'dark:bg-orange-700',
          'dark:hover:bg-orange-800',
        ])
        break

      case 'danger':
        classes = classes.concat([
          'border-transparent',
          'text-white',
          'bg-red-600',
          'hover:bg-red-700',
          'disabled:hover:bg-red-700', // disable hover effect when disabled
          'focus:ring-red-500',
          'dark:text-gray-200',
          'dark:bg-red-700',
          'dark:hover:bg-red-800',
        ])
        break

      case 'ghost':
        classes = classes.concat([
          'border-transparent',
          '!shadow-none',
          'text-gray-700',
          'hover:bg-gray-50',
          'disabled:hover:bg-gray-50', // disable hover effect when disabled
          'dark:text-gray-200',
          'dark:bg-gray-600',
          'dark:hover:bg-gray-700',
        ])
        break
    }

    if (fullWidth) {
      classes.push('w-full')
    }

    const labelClasses = {
      'opacity-0': loading,
      'sr-only': hideLabel,
      'whitespace-nowrap': noWrap,
    }

    return (
      <As
        type={As === 'button' ? 'button' : null}
        id={id}
        disabled={disabled}
        onClick={(e) => handleClick(e)}
        className={classNames(className, classes)}
        {...props}
        title={hideLabel ? label : undefined}
        ref={ref}
      >
        {!loading && leadingIcon && resolvedLeadingIcon}

        <span className={classNames(labelClasses)}>{label}</span>

        {!loading && trailingIcon && resolvedTrailingIcon}

        {loading && (
          <div className="absolute inset-0">
            <Spinner />
          </div>
        )}

        {children}
      </As>
    )
  }
)
