import React from 'react'
import { DateTime } from 'luxon'
import { Modal } from 'ui/Modal'
import { useGetVoucherDeliveryOption } from 'api/VoucherDeliveryOptions'
import { formatMoney } from 'ui/components/Money'
import { useCurrentSite } from 'contexts/SiteConfig'
interface VoucherDeliveryDetailsModalProps {
  item: any
  isOpen: boolean
  onClose: any
}

export const VoucherDeliveryDetailsModal: React.FC<VoucherDeliveryDetailsModalProps> = ({
  isOpen,
  onClose,
  item,
}) => {
  const EmailDeliveryDetails = () => {
    const date =
      item.item_configuration['delivery_date'] !== null
        ? DateTime.fromISO(item.item_configuration['delivery_date'])
        : null

    return (
      <div>
        <div className="my-3">
          <div className="mb-2">
            {item.item_configuration['recipient_email']}
            {date !== null ? ' on ' + date.toLocaleString(DateTime.DATE_MED) : ''}
          </div>
        </div>
      </div>
    )
  }

  const PhysicalDeliveryDetails = () => {
    const {
      data: { data: deliveryOption } = {},
      isLoading,
      isSuccess,
      isError,
    } = useGetVoucherDeliveryOption(item.item_configuration['delivery_option_id'])
    const { currency = {} } = useCurrentSite()

    return (
      <div>
        <div>{item.item_configuration['address_line1']}</div>
        <div>{item.item_configuration['address_line2']}</div>
        <div>{item.item_configuration['town']}</div>
        <div>{item.item_configuration['county']}</div>
        <div>{item.item_configuration['postcode']}</div>
        <div>{item.item_configuration['country']}</div>

        {isSuccess && (
          <div className="my-3">
            {deliveryOption.name} @ {formatMoney({ amount: deliveryOption.price, currency })}
          </div>
        )}
      </div>
    )
  }

  const DeliveryDetailsSection = () => {
    if (item.item_configuration['delivery_method_type'] === 'physical') {
      return (
        <div>
          <PhysicalDeliveryDetails />
        </div>
      )
    }
    return (
      <div>
        <EmailDeliveryDetails />
      </div>
    )
  }

  return (
    <Modal size={'xs'} isOpen={isOpen} onClose={onClose} title={'Delivery details'} styleReset>
      <div className="flex min-h-screen flex-col sm:min-h-0">
        <DeliveryDetailsSection />
      </div>
    </Modal>
  )
}
