import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { SessionsApi } from '@trybeapp/sdk.shop'
import { SessionTypesApi as SessionTypesApiTs } from '@trybeapp/sdk'
import { useCurrentSiteId } from 'contexts/SiteConfig'
import { usePageQueryParam } from 'hooks/UsePageQueryParam'
import { apiConfiguration } from 'utilities/TrybeApiClient'
import { sdkDateToSystemDateTime, toIsoDate } from 'utilities/DateUtils/dateUtils'

const api = new SessionsApi()
const tsApi = new SessionTypesApiTs(apiConfiguration)

export const useSessionTypes = (params: any = {}, options: UseQueryOptions = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useQuery<unknown, unknown, any>(
    ['sessionTypes', { params }],
    async () => api.sessionTypeIndex(params),
    options
  )
}

export const useAllSessionTypes = (options: UseQueryOptions = {}) => {
  options.cacheTime = options.cacheTime ?? Infinity
  options.staleTime = options.staleTime ?? Infinity

  return useSessionTypes({ perPage: -1 }, options)
}

export const useSessionType = (sessionTypeId: string, options = {}) =>
  useQuery(
    ['sessionType', { sessionTypeId }],
    async () => {
      const response = await api.sessionTypeShow(sessionTypeId)
      const recurrenceGroups = response.data?.recurrence_groups ?? []

      recurrenceGroups.forEach((recurrenceGroup) => {
        if (recurrenceGroup.recurrence_start) {
          recurrenceGroup.recurrence_start = sdkDateToSystemDateTime(
            recurrenceGroup.recurrence_start
          ).toJSDate()
        }

        if (recurrenceGroup.recurrence_end) {
          recurrenceGroup.recurrence_end = sdkDateToSystemDateTime(
            recurrenceGroup.recurrence_end
          ).toJSDate()
        }
      })

      return response
    },
    options
  )

export const useCopySessionType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [copyFromId, sessionType] = args

      return await api.sessionTypeCopy(copyFromId, { sessionType })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sessionTypes'])
      },
    }
  )
}

export const useCreateSessionType = () => {
  const queryClient = useQueryClient()

  return useMutation((sessionType: any) => api.sessionTypeStore(sessionType), {
    onSuccess: (result: any) => {
      queryClient.invalidateQueries(['sessionTypes'])
    },
  })
}

export const useUpdateSessionType = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ([sessionTypeId, vals]: any) => api.sessionTypeUpdate(sessionTypeId, vals),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sessionType')
      },
    }
  )
}

export const useDeleteSessionType = () => {
  const queryClient = useQueryClient()

  return useMutation((sessionTypeId: string) => api.sessionTypeDestroy(sessionTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['sessionTypes'])
    },
  })
}

export const useRestoreSessionType = () => {
  const queryClient = useQueryClient()

  return useMutation((sessionTypeId: any) => api.sessionTypeRestore(sessionTypeId), {
    onSuccess: () => {
      queryClient.invalidateQueries('sessionTypes')
    },
  })
}

export const useInfiniteSessionTypes = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['sessionTypes', { params }], fetchSessionTypes, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
  })
}

export const useCreateRecurrenceGroup = (options = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (args: any) => {
      const [sessionTypeId, recurrenceGroup] = args
      return await api.sessionRecurrenceGroupStore(
        sessionTypeId,
        formatRequestBody(recurrenceGroup)
      )
    },
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries('sessionType')
      },
    }
  )
}

export const useUpdateRecurrenceGroup = (options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (args: any) => {
      const [sessionTypeId, recurrenceGroupId, recurrenceGroup] = args
      return await api.sessionRecurrenceGroupUpdate(
        sessionTypeId,
        recurrenceGroupId,
        formatRequestBody(recurrenceGroup)
      )
    },
    {
      ...options,
      onSuccess: ({ data: { id: recurrenceGroupId } }) => {
        queryClient.invalidateQueries(['sessionTypeRecurrenceGroups', recurrenceGroupId])
        queryClient.invalidateQueries(['sessions'])
        queryClient.invalidateQueries('sessionType')
      },
    }
  )
}

export const useCheckUpdateRecurrenceGroup = () => {
  return useMutation(async (args: any) => {
    const [sessionTypeId, recurrenceGroupId, recurrenceGroup] = args
    return await api.sessionRecurrenceGroupCheckUpdate(
      sessionTypeId,
      recurrenceGroupId,
      formatRequestBody(recurrenceGroup)
    )
  })
}

const fetchSessionTypes = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.sessionTypeIndex({ page, ...params })

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}

const formatRequestBody = (requestBody) => {
  const updatedBody = { ...requestBody }

  if (updatedBody.recurrence_start) {
    updatedBody.recurrence_start = toIsoDate(updatedBody.recurrence_start)
  }

  if (updatedBody.recurrence_end) {
    updatedBody.recurrence_end = toIsoDate(updatedBody.recurrence_end)
  }

  return updatedBody
}

export const useSessionTypePriceRules = (
  sessionTypeId: string,
  params: any = {},
  options: UseQueryOptions = {}
) =>
  useQuery<any, any, any>(
    ['sessionTypePriceRules', sessionTypeId, { params }],
    async () => {
      const response = await api.sessionTypePriceRulesIndex(sessionTypeId, params)

      response.data.forEach((priceRule) => {
        priceRule.date_from = priceRule.date_from
          ? sdkDateToSystemDateTime(priceRule.date_from).toJSDate()
          : null

        priceRule.date_to = priceRule.date_to
          ? sdkDateToSystemDateTime(priceRule.date_to).toJSDate()
          : null
      })

      return response
    },
    options
  )

export const usePagedSessionTypePriceRulesIndex = (
  sessionTypeId: string,
  params = {},
  options: UseQueryOptions = {}
) => {
  const [page] = usePageQueryParam()

  return useSessionTypePriceRules(sessionTypeId, { page, params }, options)
}

export const useCreateSessionTypePriceRule = (sessionTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: any) =>
      api.sessionTypePriceRuleStore(sessionTypeId, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sessionTypePriceRules', sessionTypeId])
        queryClient.invalidateQueries(['sessionTypes', sessionTypeId])
      },
    }
  )
}

export const useUpdateSessionTypePriceRule = (sessionTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ([ruleId, values]: any) =>
      api.sessionTypePriceRuleUpdate(sessionTypeId, ruleId, {
        weekdays: values.weekdays,
        date_from: toIsoDate(values.date_from),
        date_to: toIsoDate(values.date_to),
        price: values.price,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sessionTypePriceRules', sessionTypeId])
        queryClient.invalidateQueries(['sessionTypes', sessionTypeId])
      },
    }
  )
}

export const useDeleteSessionTypePriceRule = (sessionTypeId: string) => {
  const queryClient = useQueryClient()

  return useMutation((ruleId: any) => api.sessionTypePriceRuleDestroy(sessionTypeId, ruleId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['sessionTypePriceRules', sessionTypeId])
      queryClient.invalidateQueries(['sessionTypes', sessionTypeId])
    },
  })
}

export const useSuggestDescriptionForSessionType = (sessionTypeId: string) =>
  useMutation((description?: string) =>
    tsApi.actionSuggestDescriptionForSessionType({
      sessionTypeId,
      suggestDescription4: { description },
    })
  )
