import { useCancelMembership } from 'api/Memberships'
import {
  ApiErrorNotification,
  Notification,
  useNotificationContext,
} from 'ui/components/Notification'
import { ConfirmationModal } from 'ui/Modal'

export const CancelModal = ({ isOpen, onClose, membershipId }) => {
  const { isLoading, mutate } = useCancelMembership()
  const notification = useNotificationContext()

  const handleConfirm = () => {
    mutate(membershipId, {
      onSuccess: () => {
        onClose()
        notification.notify(
          <Notification
            title="Membership cancelled"
            description={`The membership was successfully cancelled`}
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        onClose()
        notification.notify(<ApiErrorNotification error={e} />)
      },
    })
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Cancel membership"
      onClose={onClose}
      onConfirm={handleConfirm}
      confirming={isLoading}
      confirmButtonLabel="Yes, cancel membership"
      cancelButtonLabel="No, go back"
    >
      <p className="font-medium text-sm text-gray-700">
        Are you sure you want to cancel this membership?
      </p>

      <p className="text-sm text-gray-500 mt-4">
        This will cancel the membership immediately and cannot be undone. If you'd like to schedule
        a cancellation, Edit the membership and modify the end date.
      </p>
    </ConfirmationModal>
  )
}
