import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { GantnerDevicesApi } from '@trybeapp/sdk.customer'
import { useCurrentSiteId } from 'contexts/SiteConfig'

const api = new GantnerDevicesApi()

export const useGantnerDevices = (params = {}) => {
  const siteId = useCurrentSiteId()

  return useQuery(['gantnerDevices', { siteId, ...params }], () =>
    api.listGantnerDevices(siteId, params)
  )
}

export const useCreateGantnerDevice = () => {
  const queryClient = useQueryClient()

  return useMutation<any, any, any>(
    ([siteId, data]: [string, any]) => api.createGantnerDevice(siteId, data),
    {
      onSuccess: (e, [siteId]) => {
        queryClient.invalidateQueries(['gantnerDevices', { siteId }])
      },
    }
  )
}

export const useGantnerDevice = (siteId: string, deviceId: string, options: UseQueryOptions = {}) =>
  useQuery<any>(
    ['gantnerDevice', { deviceId }],
    () => api.getGantnerDevice(siteId, deviceId),
    options
  )

export const useUpdateGantnerDevice = (siteId: string, deviceId: string) => {
  const queryClient = useQueryClient()

  return useMutation((vals: any) => api.updateGantnerDevice(siteId, deviceId, vals), {
    onSuccess: () => {
      queryClient.invalidateQueries(['gantnerDevices', { siteId }])
      queryClient.invalidateQueries(['gantnerDevice', { deviceId }])
    },
  })
}

export const useDeleteGantnerDevice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ([siteId, deviceId]: [string, string]) => api.deleteGantnerDevice(siteId, deviceId),
    {
      onSuccess: (e, [siteId, deviceId]) => {
        queryClient.invalidateQueries(['gantnerDevice', { deviceId }])
        queryClient.invalidateQueries(['gantnerDevices', { siteId }])
      },
    }
  )
}

export const useLogs = (params) => {
  const siteId = useCurrentSiteId()

  return useQuery(['gantnerLogs', { siteId }], () => api.listGantnerDeviceLogs(siteId, params))
}

export const useInfiniteGantnerDevices = (params: any = {}) => {
  const siteId = useCurrentSiteId()
  params.siteId = params.siteId ?? siteId

  return useInfiniteQuery(['gantnerDevices', { params }], fetchGantnerDevices, {
    getNextPageParam: (lastPage: any, pages) => {
      return lastPage.nextPage
    },
  })
}

const fetchGantnerDevices = async ({ queryKey, pageParam: page = 1 }) => {
  const { params } = queryKey[1]

  const response = await api.listGantnerDevices(params.siteId, params)

  return {
    data: response.data,
    page,
    nextPage: response.meta.last_page > page ? page + 1 : undefined,
    total: response.meta.total,
  }
}
