import { Badge } from 'ui/components/Badge'
import { Money } from 'ui/components/Money'
import { DateTime } from 'luxon'
import { Table } from 'ui/components/Table'
import { useMemo } from 'react'
import { MembershipDropdown } from 'components/CustomerDirectory/Memberships/MembershipDropdown'
import { CalendarIcon, UserIcon } from '@heroicons/react/solid'

export const MembershipRow = ({
  membership,
  onAddPayment,
  onCancel,
  onEdit,
  onRequestMandate,
  onArchive,
  onRetryPayment,
  onManageMembers,
  isLead = true,
  onOpenAuditLog,
}) => {
  const canRecordManualPayment = useCanRecordManualPayment(membership)

  let badge

  switch (true) {
    case membership.status === 'needs_dd_mandate':
    case membership.status === 'needs_attention':
      badge = <Badge variant="warning" label="Needs attention" />
      break
    case membership.status === 'active':
      badge = <Badge variant="success" label="Active" />
      break
    case membership.status === 'inactive':
      badge = <Badge variant="danger" label="Cancelled" />
      break
    case membership.status === 'expired':
      badge = <Badge variant="danger" label="Expired" />
      break
    case membership.status === 'upcoming':
      badge = <Badge variant="info" label="Upcoming" />
      break
    default:
      badge = <Badge variant="info" label={membership.status} />
  }

  const duration = useMemo(() => {
    switch (membership.rate.billing_frequency) {
      case 'P1M':
        return 'month'
      case 'P3M':
        return 'quarter'
      case 'P6M':
        return 'half-year'
      case 'P1Y':
        return 'year'
    }
  }, [membership.rate.billing_frequency])

  return (
    <>
      <Table.Tr className="md:hidden">
        <Table.TdLink to={`clients/${membership.customer.id}`} condensed>
          <div className="flex mb-1">
            {badge}
            <div className="font-medium">{membership.type.name}</div>
          </div>
          <div className="text-gray-500 ml-1 space-y-1">
            <div>
              {membership.rate.name} at{' '}
              <Money currency={membership.rate.currency} amount={membership.rate.price} />
              {` per ${duration}`}
            </div>
            <div className="flex space-x-2 items-center">
              <UserIcon className="w-4 h-4" />
              <div>
                {membership.customer.full_name} ({membership.customer.email})
              </div>
            </div>
            <div className="text-xs flex space-x-2 items-center">
              <CalendarIcon className="w-4 h-4" />
              {membership.start_date && (
                <div>
                  Starts{' '}
                  {DateTime.fromJSDate(membership.start_date).toLocaleString(DateTime.DATE_MED)}
                </div>
              )}
              {membership.end_date && (
                <div>
                  Ends {DateTime.fromJSDate(membership.end_date).toLocaleString(DateTime.DATE_MED)}
                </div>
              )}
              {membership.next_billing_date && (
                <div>
                  Next bill{' '}
                  {DateTime.fromJSDate(membership.next_billing_date).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </div>
              )}
            </div>
          </div>
        </Table.TdLink>
      </Table.Tr>

      <Table.Tr className="hidden md:table-row">
        <Table.TdLink to={`/clients/${membership.customer.id}`} shrink condensed>
          <span className="block -ml-3">{badge}</span>
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          <div className="font-medium">{membership.customer.full_name}</div>
          <div className="text-xs text-gray-500">{membership.customer.email}</div>
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          <span className="font-medium">{membership.type.name}</span>
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          <span className="block">
            <div className="text-gray-500">{membership.rate.name}</div>
            <div className="text-gray-500">
              <Money currency={membership.rate.currency} amount={membership.rate.price} />
              {` per ${duration}`}
            </div>
          </span>
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          {membership.start_date &&
            DateTime.fromJSDate(membership.start_date).toLocaleString(DateTime.DATE_MED)}
          {!membership.start_date && <span className="italic text-gray-500">N/A</span>}
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          {membership.end_date &&
            DateTime.fromJSDate(membership.end_date).toLocaleString(DateTime.DATE_MED)}
          {!membership.end_date && <span className="italic text-gray-500">N/A</span>}
        </Table.TdLink>
        <Table.TdLink to={`/clients/${membership.customer.id}`} condensed>
          {membership.next_billing_date &&
            DateTime.fromJSDate(membership.next_billing_date).toLocaleString(DateTime.DATE_MED)}
          {!membership.next_billing_date && <span className="italic text-gray-500">N/A</span>}
        </Table.TdLink>
        <Table.Td>
          <MembershipDropdown
            isLead={isLead}
            membership={membership}
            onAddPayment={onAddPayment}
            onCancel={onCancel}
            onEdit={onEdit}
            onRequestMandate={onRequestMandate}
            onArchive={onArchive}
            onRetryPayment={onRetryPayment}
            onManageMembers={onManageMembers}
            canRecordManualPayment={canRecordManualPayment}
            onOpenAuditLog={onOpenAuditLog}
          />
        </Table.Td>
      </Table.Tr>
    </>
  )
}

export const useCanRecordManualPayment = (membership) => {
  return useMemo(() => {
    if (membership.status === 'inactive') {
      // Payments can't be recorded for cancelled memberships
      return false
    }

    if (membership.status === 'expired') {
      // Payments can't be recorded for expired memberships
      return false
    }

    if (
      membership.status === 'needs_attention' &&
      ['setup_unpaid', 'payment_disputed'].includes(membership.attention_reason)
    ) {
      // Payments can't be recorded if the setup fee hasn't been paid yet
      // or the membership is disputed
      return false
    }

    if (membership.next_billing_date === null) {
      // Payments can't be recorded for memberships with no next billing date
      return false
    }

    return true
  }, [membership.status, membership.attention_reason, membership.next_billing_date])
}
