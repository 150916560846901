import { useQuery } from 'react-query'
import { MetaApi } from '@trybeapp/sdk'
import { apiConfiguration } from 'utilities/TrybeApiClient'

const api = new MetaApi(apiConfiguration)

export const useSupportedLocales = () =>
  useQuery(['supportedLocales'], () => api.listMetaSupportedLocales(), {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

export const useSupportedCurrencies = () =>
  useQuery(['supportedCurrencies'], () => api.listMetaSupportedCurrencies(), {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

export const useSupportedTimezones = () =>
  useQuery(['supportedTimezones'], () => api.listMetaSupportedTimezones(), {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
