import { useAllPractitioners } from 'api/Practitioners'
import { createContext, useContext } from 'react'

export const PractitionerContext = createContext()

export const PractitionerProvider = ({ id, practitioner: object, children }) => {
  const { data: { data: practitioners = [] } = {} } = useAllPractitioners()
  const practitioner = practitioners.find((practitioner) => practitioner.id === id) ?? {}

  return (
    <PractitionerContext.Provider value={object ?? practitioner}>
      {children}
    </PractitionerContext.Provider>
  )
}

export const withPractitioner =
  (Component) =>
  ({ id, ...props }) => (
    <PractitionerProvider id={id}>
      <Component {...props} />
    </PractitionerProvider>
  )

export const usePractitioner = () => useContext(PractitionerContext)

export const PractitionersContext = createContext()
