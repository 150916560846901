import { useGetCustomer, useResendEmailVerification, useResetCustomerPassword } from 'api/Customers'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { EditDetailsModal } from './EditDetailsModal'
import { Badge } from 'ui/components/Badge'
import { useNotificationContext, Notification } from 'ui/components/Notification'
import { Spinner } from 'ui/components/Spinner'
import { DescriptionItem } from 'ui/components/DescriptionItem'
import { H2 } from 'ui/components/Text/H2'
import { LockClosedIcon } from '@heroicons/react/solid'
import { LocaleDescriptor } from 'ui/components/LocaleDescriptor'

const useFields = () => {
  return [
    {
      label: 'Name',
      formatter: (customer) => `${customer.first_name} ${customer.last_name}`,
    },
    {
      label: 'Email',
      formatter: (customer) => customer.email || null,
    },
    {
      label: 'Phone',
      formatter: (customer) => customer.phone,
    },
    {
      label: 'Date of birth',
      formatter: (customer) => {
        if (!customer.dob) {
          return null
        }

        const dobString = DateTime.fromJSDate(customer.dob).toLocaleString(DateTime.DATE_FULL)
        const isBirthday =
          customer.dob.getDate() === new Date().getDate() &&
          customer.dob.getMonth() === new Date().getMonth()

        return `${dobString}${isBirthday ? ' 🎂' : ''}`
      },
    },
    {
      label: 'Customer since',
      formatter: (customer) =>
        DateTime.fromJSDate(customer.created_at).toLocaleString(DateTime.DATE_MED),
    },
    {
      label: 'Password',
      formatter: (customer) =>
        customer.has_password ? (
          <>
            <span>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
            <SendPasswordResetButton clientId={customer.id} />
          </>
        ) : (
          <>
            <span className="italic text-gray-500">Not set</span>
            <SendSetPasswordEmailButton clientId={customer.id} />
          </>
        ),
    },
    {
      label: 'Email verified',
      formatter: (customer) => (
        <div className="-ml-3">
          {customer.email_verified_at && <Badge variant="success" label="Verified" />}
          {!customer.email_verified_at && (
            <>
              <Badge variant="warning" label="Not verified" />
              <ResendEmailVerificationButton clientId={customer.id} />
            </>
          )}
        </div>
      ),
    },
    {
      label: 'Last checked in',
      formatter: (customer) =>
        customer.last_check_in ? (
          <div>
            <div>
              {DateTime.fromJSDate(customer.last_check_in.checked_in_at).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </div>
            {customer.last_check_in.method && (
              <div className="text-xs">via {customer.last_check_in.method}</div>
            )}
          </div>
        ) : (
          <span className="text-gray-500 italic">Never</span>
        ),
    },
    {
      label: 'Preferred language',
      formatter: (customer) => <LocaleDescriptor>{customer.preferred_locale}</LocaleDescriptor>,
    },
  ]
}

const SendPasswordResetButton = ({ clientId }) => {
  const { isLoading, isSuccess, mutate } = useResetCustomerPassword(clientId)
  const notification = useNotificationContext()
  const send = () =>
    mutate(null, {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Password reset email sent"
            description="We've sent an email to the client with instructions on how to reset their password"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notification.notify(
          e.status === 429 ? (
            <Notification
              title="Password reset email already sent"
              description="The password reset email was already sent within the last 5 minutes"
              variant="warning"
              autoDismiss
            />
          ) : (
            <Notification
              title="Couldn't send password reset email"
              description="Sorry, an error occurred when sending the password reset email. Please try again"
              variant="danger"
              autoDismiss
            />
          )
        )
      },
    })

  if (isSuccess) return null

  return (
    <button
      className="ml-1 font-medium text-xs text-blue-600 hover:text-blue-700"
      onClick={send}
      disabled={isLoading}
    >
      {isLoading && (
        <div className="absolute -mt-5">
          <Spinner />
        </div>
      )}
      {!isLoading && 'Reset'}
    </button>
  )
}

const SendSetPasswordEmailButton = ({ clientId }) => {
  const { isLoading, isSuccess, mutate } = useResetCustomerPassword(clientId)
  const notification = useNotificationContext()
  const send = () =>
    mutate(null, {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Account setup email sent"
            description="We've sent an email to the client with instructions on how to set their password"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notification.notify(
          e.status === 429 ? (
            <Notification
              title="Setup email already sent"
              description="The setup email was already sent within the last 5 minutes"
              variant="warning"
              autoDismiss
            />
          ) : (
            <Notification
              title="Couldn't send account setup email"
              description="Sorry, an error occurred when sending the account setup email. Please try again"
              variant="danger"
              autoDismiss
            />
          )
        )
      },
    })

  if (isSuccess) return null

  return (
    <button
      className="ml-1 font-medium text-xs text-blue-600 hover:text-blue-700"
      onClick={send}
      disabled={isLoading}
    >
      {isLoading && (
        <div className="absolute -mt-5">
          <Spinner />
        </div>
      )}
      {!isLoading && 'Send setup email'}
    </button>
  )
}

const ResendEmailVerificationButton = ({ clientId }) => {
  const { isLoading, isSuccess, mutate } = useResendEmailVerification(clientId)
  const notification = useNotificationContext()
  const send = () =>
    mutate(null, {
      onSuccess: () => {
        notification.notify(
          <Notification
            title="Verification email resent"
            description="We've resent an email to the client with a link to verify their email address"
            variant="success"
            autoDismiss
          />
        )
      },
      onError: (e: any) => {
        notification.notify(
          e.status === 429 ? (
            <Notification
              title="Verification email already resent"
              description="The verification email was already resent within the last 5 minutes"
              variant="warning"
              autoDismiss
            />
          ) : (
            <Notification
              title="Couldn't resend verificaton email"
              description="Sorry, an error occurred when resending the verification email. Please try again"
              variant="danger"
              autoDismiss
            />
          )
        )
      },
    })

  if (isSuccess) return null

  return (
    <button
      className="ml-1 font-medium text-xs text-blue-600 hover:text-blue-700"
      onClick={send}
      disabled={isLoading}
    >
      {isLoading && (
        <div className="absolute -mt-5">
          <Spinner />
        </div>
      )}
      {!isLoading && 'Resend'}
    </button>
  )
}

export const Details = () => {
  const fields = useFields().filter((field) => field !== null)
  const { clientId } = useParams()
  const { isLoading, data: { data: client = {} } = {} } = useGetCustomer(clientId)
  return (
    <div className="col-span-1">
      <div className="mt-4 lg:mt-0">
        <div className="flex justify-between items-center border-b border-violet-200 lg:border-transparent p-3 ">
          <div className="flex space-x-1 items-center">
            {client.locked_at && <LockClosedIcon className="w-5 h-5" />}

            <H2>Details</H2>
          </div>
          {client && client.deleted_at === null && <EditDetailsModal client={client} />}
        </div>
        <dl className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 p-3">
          {fields.map((field) => (
            <div key={field.label} className="sm:col-span-1">
              <DescriptionItem label={field.label}>
                {isLoading && <div className="w-16 h-4 bg-grape-200 animate-pulse" />}
                {!isLoading && client.deleted_at && (
                  <span className="bg-grape-100 rounded-lg font-medium px-2 py-1 text-xs text-gray-500">
                    Deleted
                  </span>
                )}
                {!isLoading &&
                  client.deleted_at === null &&
                  (field.formatter(client) ?? (
                    <span className="italic text-gray-500">Not set</span>
                  ))}
              </DescriptionItem>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
