import { useMutation } from 'react-query'
import { MediaApi } from '@trybeapp/sdk.shop'

const api = new MediaApi()

export const useUploadMedia = () =>
  useMutation<any, any, any>((file: any) =>
    api.mediaStore({
      image: file,
    })
  )

export const useUploadFile = () =>
  useMutation<any, any, any>((file: any) =>
    api.mediaStore({
      file,
    })
  )
